import React from 'react'
import { Col, Row } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import GroupToggle from '../groupToggle/groupToggle'

const locations = [
  { label: 'Internal', value: 'Internal' },
  { label: 'External', value: 'External' }
]

const DependencyEntryFields = ({ entry, readOnly = false }) => {
  const {
    location = 'Internal',
    deliverables = '',
    deliveryDate = '',
    importance = 1
  } = entry
  return (
    <>
      <Row>
        <Col>
          <div className='styledTitle'>
            Location
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Location' bodyCopy='Select from Internal or External to identify if the Dependency is within your control or from an external party' id='imLocation' />
        </Col>
        <Col sm='9'>
          <GroupToggle disabled={readOnly} name='location' items={locations} currentValue={location} onValueChanged={() => {}} className='mb-0' />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Deliverables
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Deliverables' bodyCopy='What is the deliverable or activity this Dependency relates to' id='imDeliverables' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='deliverables' value={deliverables} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Delivery Date
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Delivery Date' bodyCopy='When should this Dependency be delivered or completed by' id='imDeliveryDate' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='deliveryDate' type='date' value={deliveryDate.substring(0, 10)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Importance
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Importance' bodyCopy={<>Select the Importance this Dependency will have by choosing a score from: <br /><br />1 = Negligible<br />3 = Low<br />5 = Moderate<br />7 = High<br />10 = Critical</>} id='imLikelihood' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} type='select' name='importance' value={importance}>
            <option value='Negligible'>1 = Negligible</option>
            <option value='Low'>3 = Low</option>
            <option value='Moderate'>5 = Moderate</option>
            <option value='High'>7 = High</option>
            <option value='Critical'>10 = Critical</option>
          </AvField>
        </Col>
      </Row>
    </>
  )
}

export default DependencyEntryFields

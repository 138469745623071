import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import ChangeRequestModal from '../changeRequestModal/changeRequestModal'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import SowaasButtonInteraction from '../sowaasButtonInteraction/sowaasButtonInteraction'

const ChangeRequestCard = ({
  changeRequestsForApproval = [],
  project = {},
  statementOfWorks = [],
  suppliers = [],
  deliverables = [],
  milestones = [],
  onChangeRequestSaved,
  onChangeRequestApproved
}) => {
  const [addChangeRequestModalOpen, setAddChangeRequestModalOpen] = useState(false)
  const [approveChangeRequestModalOpen, setApproveChangeRequestModalOpen] = useState(false)
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-3'>
                Change Request
                <InfoMarker title='Change Request' bodyCopy='A change request is a formal process to alter/amend/update the original SOW with more specific information following a change to the deliverables or tasks originally outlined. This is an important process to maintain overall contract and service accuracy' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setAddChangeRequestModalOpen(true) }} title='New Change Request' />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col sm='12'>
              <SowaasButtonInteraction
                className='btn-block'
                title={`Approve Change Request${changeRequestsForApproval.length > 1 ? 's' : ''} (${changeRequestsForApproval.length})`}
                disabled={changeRequestsForApproval.length === 0}
                onClick={() => { setApproveChangeRequestModalOpen(changeRequestsForApproval[0]) }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {
        (addChangeRequestModalOpen !== false) && (
          <ChangeRequestModal
            isOpen={addChangeRequestModalOpen}
            setOpen={setAddChangeRequestModalOpen}
            statementOfWorks={statementOfWorks}
            suppliers={suppliers}
            deliverables={deliverables}
            milestones={milestones}
            project={project}
            onSave={onChangeRequestSaved}
            mode='add'
          />
        )
      }
      {
        (approveChangeRequestModalOpen !== false) && (
          <ChangeRequestModal
            isOpen={(approveChangeRequestModalOpen !== false)}
            setOpen={setApproveChangeRequestModalOpen}
            changeRequest={approveChangeRequestModalOpen}
            statementOfWorks={statementOfWorks}
            suppliers={suppliers}
            deliverables={deliverables}
            milestones={milestones}
            project={project}
            onSave={onChangeRequestSaved}
            mode='approve'
          />
        )
      }
    </>
  )
}

export default ChangeRequestCard

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import TimeUtilForm from '../timeUtilForm/timeUtilForm'
import demoTu from '../../helpers/demoMode/exampleTu.json'
import _ from 'lodash'

const TimeUtilModal = ({ isOpen, setOpen, mode = 'add', tu = demoTu, project = {}, onSave }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  let title = ''
  let readOnly = true

  switch (mode) {
    case 'add':
      title = 'New Time Utilisation'
      readOnly = false
      break
    case 'approve':
      title = 'Approve Time Utilisation'
      break
    case 'view':
      title = 'View Time Utilisation'
      break
    default:
      break
  }

  const onAdd = (values) => {
    onSave(values)
    onClose()
  }

  const solutionSuppliers = _.get(project, 'solution.solutionSuppliers', [])
  const suppliers = _.filter(solutionSuppliers, (sol) => { return sol.supplier !== null }).map((ss) => { return ss.supplier })

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='customer'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            {title}
          </ModalHeader>
          <ModalBody>
            <TimeUtilForm mode={mode} tu={tu} readOnly={readOnly} onSubmit={onAdd} suppliers={suppliers} project={project} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default TimeUtilModal

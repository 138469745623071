import React from 'react'
import styled from 'styled-components'
import { Col, Card, CardBody, CardTitle, Progress, Row } from 'reactstrap'
import { AvForm, AvGroup, AvField, AvInput } from 'availity-reactstrap-validation'

const StyledProgress = styled.div`
  display: flex;
  flex: 1;
  .progress {
    height: 20px;
    flex: 1;
  }
`

const StyledCard = styled.div`
  padding: 5px 0;
`

const SolutionOverview = ({ salesMode = true, project = {} }) => {
  const {
    slug: projectSlug = '',
    projectProcurer,
    name: projectName = ''
  } = project
  const { name: projectProcurerName = '' } = projectProcurer || {}

  const salesPerson = 'Sam Foot'
  const progress = 10

  const renderProgress = (progressValue) => {
    const progressSections = []
    for (let i = 0; i <= 100; i += 10) {
      if (progressValue < i) {
        progressSections.push()
      }
    }
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='5'>
              <CardTitle>
                Solution Overview
              </CardTitle>
            </Col>
            <Col sm='7'>
              <StyledProgress>
                <Progress multi>
                  {renderProgress(progress)}
                </Progress>
              </StyledProgress>
            </Col>
          </Row>
          <StyledCard>
            <AvForm className='validation-required'>
              <Row className='mt-3'>
                <Col sm='3'>
                  <div className='styledTitle'>
                    Unique ID
                  </div>
                </Col>
                <Col sm='9'>
                  <AvGroup>
                    <AvInput
                      name='uniqueId'
                      type='text'
                      placeholder=''
                      required
                      value={projectSlug}
                      disabled
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col sm='3'>
                  <div className='styledTitle'>
                    Customer Name
                  </div>
                </Col>
                <Col sm='9'>
                  <AvField
                    name='customerName'
                    type='text'
                    className='form-control'
                    value={projectProcurerName}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='3'>
                  <div className='styledTitle'>
                    SOW Service Name
                  </div>
                </Col>
                <Col sm='9'>
                  <AvField
                    name='projectName'
                    type='text'
                    className='form-control'
                    value={projectName}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='3'>
                  <div className='styledTitle'>
                    {salesMode ? 'Sales Person' : 'Consultant Name'}
                  </div>
                </Col>
                <Col sm='9'>
                  <AvField
                    name='otherRateType'
                    type='text'
                    className='form-control'
                    value={salesPerson}
                    disabled
                  />
                </Col>
              </Row>
            </AvForm>
          </StyledCard>
        </CardBody>
      </Card>
    </>
  )
}

export default SolutionOverview

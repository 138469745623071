import React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import PageTitle from '../../components/pageTitle/pageTitle'

const HomeTile = ({ title, subtitle, icon, to, onClick }) => {
  return (
    <Col sm='6' className='mb-4' onClick={() => { onClick(to) }} style={{ cursor: 'pointer' }}>
      <Card className='p-1 card-hover' style={{ height: '10rem', backgroundColor: 'white' }}>
        <CardBody>
          <PageTitle title={title} icon={icon} />
          <div className='styledTitleBold mt-4 p-0'>
            {subtitle}
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default HomeTile

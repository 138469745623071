import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Vimeo from '@u-wave/react-vimeo'

const VideoMarker = ({ title = '', bodyCopy = '', id = '', className = '', style = {}, videoUrl = '' }) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [opened, setOpened] = useState(false)

  const toggle = () => setPopoverOpen(!popoverOpen)

  return (
    <>
      <i id={id} className={`mdi mdi-video-box pl-1 ${className}`} onClick={() => { setPopoverOpen(true) }} style={{ color: '#0DD0CD', cursor: 'pointer', fontSize: '18px', ...style }} />
      <Modal centered placement='auto' isOpen={popoverOpen} target={id} toggle={toggle} size='lg' onOpened={() => { setOpened(true) }}>
        <ModalHeader toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody>
          {
            opened === true && (
              <Vimeo
                video={videoUrl}
                height='500px'
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            )
          }
        </ModalBody>
      </Modal>
    </>
  )
}

export default VideoMarker

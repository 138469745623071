import React from 'react'
import { Modal, ModalHeader, ModalBody, Col } from 'reactstrap'
import SoWForm from '../sowForm/sowForm'

const SOWModal = ({ isOpen, setOpen, onSubmit, mode = 'customer', suppliers = [], sow = {}, startDate, endDate, readOnly = false }) => {
  const updatingExisting = ('id' in sow)

  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSubmit = (values) => {
    onSubmit(values)
    onClose()
  }

  let title = ''
  if (readOnly) {
    title = 'View'
  } else {
    if (updatingExisting) {
      title = 'Update'
    } else {
      title = 'Add'
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='customer'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            {title} Statement of Work
          </ModalHeader>
          <ModalBody>
            <Col sm='12'>
              <SoWForm readOnly={readOnly} onSubmit={handleSubmit} mode={mode} suppliers={suppliers} sow={sow} startDate={startDate} endDate={endDate} />
            </Col>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default SOWModal

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import ProfileCard from '../../components/profileCard/profileCard'
import { putProfile } from '../../store/user/actions'
import PageTitle from '../../components/pageTitle/pageTitle'

const Profile = () => {
  const userProfile = useSelector(state => state.User)
  const dispatch = useDispatch()

  const onProfileUpdated = (profile) => {
    dispatch(putProfile(profile))
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm='12'>
              <PageTitle title='Profile' className='mb-3' />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <ProfileCard userProfile={userProfile} onProfileUpdated={onProfileUpdated} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Profile

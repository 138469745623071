import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import RaidDashboard from '../raidDashboard/raidDashboard'
import RaidLog from '../raidLog/raidLog'
import InfoMarker from '../infoMarker/infoMarker'

const RaidModal = ({ isOpen, setOpen, raidCategories = [], priorities = [], onSubmit, raidItems, readOnly = false }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const [activeTab, setActiveTab] = useState('1')

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            RAID Log
          </ModalHeader>
          <ModalBody>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1') }}
                  >
                    Dashboard
                    <InfoMarker title='Dashboard' bodyCopy='The dashboard provides a quick colour coded view on the 4 areas with 5 levels of severity or impact for rapid assessment.' />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2') }}
                  >
                    Log
                    <InfoMarker title='Log' bodyCopy='The log section allows the entry of new artefacts into the RAID dashboard for quick assesment.' />
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId='1'>
                  <RaidDashboard raidCategories={raidCategories} raidItems={raidItems} />
                </TabPane>
                <TabPane tabId='2'>
                  <RaidLog raidCategories={raidCategories} priorities={priorities} onSubmit={onSubmit} raidItems={raidItems} readOnly={readOnly} />
                </TabPane>
              </TabContent>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default RaidModal

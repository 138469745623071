import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { Link } from 'react-router-dom'

import { updateNewOpportunity } from '../../store/newOpportunity/actions'

const StyledCard = styled.div`
  padding: 5px 0;
`

const StyledButton = styled(Link)`
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  float: right;
  margin: 5px;
  width: 100px;
`

const NewOpportunity = () => {
  const [customer, setCustomer] = useState('')
  const [stakeholder, setStakeholder] = useState('')
  const [project, setProject] = useState('')
  const dispatch = useDispatch()
  const onClick = () => dispatch(updateNewOpportunity({
    customer,
    stakeholder,
    project
  }))

  const GoButton = () => {
    return (
      <>
        <StyledButton
          to='/sales-opportunity'
          className='btn btn-primary'
          onClick={() => onClick()}
        >
          Next <i className='mdi mdi-arrow-right ml-1' />
        </StyledButton>
      </>
    )
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Log New Opportunity
              </CardTitle>
            </Col>
          </Row>
          <StyledCard>
            <Row>
              <Col sm='3'>
                <div className='styledTitle'>
                  Customer
                </div>
              </Col>
              <Col sm='9'>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Enter customer name'
                    onChange={(event) => setCustomer(event.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='3'>
                <div className='styledTitle'>
                  Stakeholder
                </div>
              </Col>
              <Col sm='9'>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Enter stakeholder name'
                    onChange={(event) => setStakeholder(event.target.value)}
                    value={stakeholder}
                  />
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='3'>
                <div className='styledTitle'>
                  Project Name
                </div>
              </Col>
              <Col sm='9'>
                <div className='col-md-12'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Enter project name'
                    onChange={(event) => setProject(event.target.value)}
                    value={project}
                  />
                </div>
              </Col>
            </Row>
          </StyledCard>
          <Row>
            <Col sm='12'>
              <GoButton />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default NewOpportunity

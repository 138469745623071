import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import FinancialDesignForm from './financialDesignForm'

const FinancialDesign = ({ project = {}, onFinancialDesignSaved, onBackToSOWs, readOnly = false }) => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            Financial Design
            <InfoMarker title='Financial Design' bodyCopy='This section is where you will define the financial model for the SOW Service you will provide to your Customer, detailing costs and charging for the entire Service, including Delivery Management overhead (if applicable) across each phase.' id='imFinancialDesign' />
          </CardTitle>
          <FinancialDesignForm project={project} onFinancialDesignSaved={onFinancialDesignSaved} onBackToSOWs={onBackToSOWs} readOnly={readOnly} />
        </CardBody>
      </Card>
    </>
  )
}

export default FinancialDesign

export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ADD_MESSAGES = 'ADD_MESSAGES'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const OPEN_MESSAGE = 'OPEN_MESSAGE'

export const GET_MESSAGES = 'GET_MESSAGES'
export const POST_MESSAGE = 'POST_MESSAGE'
export const DEL_MESSAGE = 'DEL_MESSAGE'
export const MARK_MESSAGE_READ = 'MARK_MESSAGE_READ'

import React from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledCard = styled.div`
  padding: 5px 0;
`

const AddStakeHolderModal = ({ isOpen, setOpen, onSubmit }) => {
  const { addToast } = useToasts()

  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      addToast('Stakeholder Invited & Added', { appearance: 'success', autoDismiss: true })
      onSubmit(values)
      onClose()
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose}>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Add Stakeholder
          </ModalHeader>
          <ModalBody>
            <Col sm='12'>
              <AvForm className='validation-required' onSubmit={handleSubmit}>
                <StyledCard>
                  <Row>
                    <Col sm='3'>
                      <div className='styledTitle'>
                        Name:
                      </div>
                    </Col>
                    <Col sm='9'>
                      <AvField
                        name='name'
                        placeholder='Enter name'
                        type='text'
                        errorMessage='Enter name'
                        className='form-control'
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='3'>
                      <div className='styledTitle'>
                        Role:
                      </div>
                    </Col>
                    <Col sm='9'>
                      <AvField
                        name='role'
                        placeholder='Enter role'
                        type='text'
                        errorMessage='Enter role'
                        className='form-control'
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='3'>
                      <div className='styledTitle'>
                        Phone:
                      </div>
                    </Col>
                    <Col sm='9'>
                      <AvField
                        name='phone'
                        placeholder='Enter phone number'
                        type='text'
                        errorMessage='Enter value'
                        className='form-control'
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='3'>
                      <div className='styledTitle'>
                        Email:
                      </div>
                    </Col>
                    <Col sm='9'>
                      <AvField
                        name='email'
                        placeholder='Enter email'
                        type='email'
                        errorMessage='Enter email'
                        className='form-control'
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <SowaasButton className='float-right' title='Invite' />
                    </Col>
                  </Row>
                </StyledCard>
              </AvForm>
            </Col>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default AddStakeHolderModal

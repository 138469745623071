import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import KmdbModal from '../kmdbModal/kmdbModal'
import SowaasButton from '../sowaasButton/sowaasButton'

const KMDBCard = ({ kmdbItems = [], project = {}, onSave }) => {
  const [kmdbOpen, setKmdbOpen] = useState(false)
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                KMDB
                <InfoMarker title='KMDB' bodyCopy='The KMDB is a Knowledge Management Database which is used to record key information relating to specific tasks, such as particular documents or processes.' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setKmdbOpen(true) }} title='View KMDB' />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <KmdbModal isOpen={kmdbOpen} setOpen={setKmdbOpen} kmdbItems={kmdbItems} project={project} onSave={onSave} />
    </>
  )
}

export default KMDBCard

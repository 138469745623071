import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

import { getInvoices } from './actions'

import InvoiceCard from './InvoiceCard'

const StyledHeading = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  padding-bottom: 20px;
  margin: 0 2px;
`

const Invoicing = () => {
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xl='12'>
              <Card className='overflow-hidden'>
                <CardBody>
                  <Row>
                    <Col sm='12'>
                      <StyledHeading>
                        <CardTitle className='mb-2'>
                          Invoicing
                        </CardTitle>
                      </StyledHeading>
                    </Col>
                  </Row>
                  <Row>
                    {getInvoices().map((invoice, idx) => (
                      <InvoiceCard invoice={invoice} key={idx} />
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Invoicing

import React from 'react'
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap'

const ProjectAndSupplierStatus = () => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            Project and Supplier Status
          </CardTitle>
          <Row>
            <Col sm='12'>
              Card contents
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default ProjectAndSupplierStatus

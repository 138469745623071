import React from 'react'
import styled from 'styled-components'

import { Col, Row, Card, CardBody, CardTitle, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

const StyledButton = styled(Link)`
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  border-radius: 0.2rem;
`

const StyledHeading = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  padding-bottom: 20px;
  margin: 0 2px;
`

const StyledTableHeaders = styled.div`
  padding: 0 15px;
  display: grid;
  grid-template-columns: 27% 27% 27% 19%;
  text-align: left;
`

const StyledBar = styled(Button)`
  width: 100%;
  margin: 5px 0;
`

const StyledBarContent = styled.div`
  display: grid;
  grid-template-columns: 27% 27% 27% 19%;
  text-align: left;
`

const PaddedText = styled.div`
  margin-right: 5px;
`

const SalesSummary = ({ salesSummary }) => {
  const GoButton = () => {
    return (
      <>
        <StyledButton to='/sales-summary' className='btn btn-primary'>View All <i className='mdi mdi-arrow-right ml-1' /></StyledButton>
      </>
    )
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <StyledHeading>
                <CardTitle className='mb-2'>
                  Sales Summary
                </CardTitle>
                <GoButton />
              </StyledHeading>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <StyledTableHeaders>
                <PaddedText>
                  Company
                </PaddedText>
                <PaddedText>
                  Project
                </PaddedText>
                <PaddedText>
                  Status
                </PaddedText>
                <PaddedText>
                  Value
                </PaddedText>
              </StyledTableHeaders>
            </Col>
          </Row>
          <Row>
            {salesSummary.map((sales, idx) => (
              <Col sm='12' key={idx}>
                <StyledBar color='secondary' outline className='waves-effect'>
                  <StyledBarContent>
                    <PaddedText>
                      {sales.company}
                    </PaddedText>
                    <PaddedText>
                      {sales.project}
                    </PaddedText>
                    <PaddedText>
                      {sales.status}
                    </PaddedText>
                    <PaddedText>
                      £{sales.projectValue}
                    </PaddedText>
                  </StyledBarContent>
                </StyledBar>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default SalesSummary

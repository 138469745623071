import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Table } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'
import ProjectUserAddModal from '../projectUserAddModal/projectUserAddModal'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ProjectUsersForm = ({ projectUsers = [] }) => {
  const [addProjectUserModalOpen, setAddProjectUserModalOpen] = useState(false)

  const onUserRemove = () => {
    confirm('Are you sure you wish to remove this user from the project? They will no longer have access to the project.') // eslint-disable-line
  }

  return (
    <AvForm className='needs-validation'>
      <StyledCard>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Name</th>
              <th style={{ width: '25%' }}>Email</th>
              <th style={{ width: '25%' }}>Role</th>
              <th style={{ width: '25%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              projectUsers.map((user, idx) => (
                <tr key={idx}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <SowaasButton title='Remove User From SOW Service' onClick={() => { onUserRemove(user) }} />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <Row>
          <Col sm='12'>
            <SowaasButton title='Add User' onClick={() => { setAddProjectUserModalOpen(true) }} />
          </Col>
        </Row>
      </StyledCard>
      {
        addProjectUserModalOpen && (
          <ProjectUserAddModal isOpen={addProjectUserModalOpen} setOpen={setAddProjectUserModalOpen} />
        )
      }
    </AvForm>
  )
}

export default ProjectUsersForm

import React from 'react'
import { Row, Col } from 'reactstrap'

import Notifications from '../../components/notifications/Notifications'
import Messaging from '../../components/messaging/Messaging'
import SolutionsDesign from '../../components/solutionsDesign/SolutionsDesign'
import PipeLinePosition from '../../components/pipelinePosition/PipelinePosition'
import Calendar from '../../components/calendar/Calendar'

const SolutionsDashboard = ({ messaging, notifications }) => {
  const customerData = [
    {
      id: 123,
      name: 'Barclays',
      product: 'Website Rebuild',
      sales: 'Peter Green'
    },
    {
      id: 124,
      name: 'Santander',
      product: 'Sales Platform',
      sales: 'Amy Pond'
    },
    {
      id: 126,
      name: 'Waitrose',
      product: 'eCommerce Update',
      sales: 'Tom Harris'
    }
  ]

  return (
    <>
      <Row>
        <Col sm='6'>
          <SolutionsDesign customerData={customerData} />
          <Calendar />
        </Col>
        <Col sm='6'>
          <PipeLinePosition />
          <Notifications notifications={notifications} />
          <Messaging messages={messaging} />
        </Col>
      </Row>
    </>
  )
}

export default SolutionsDashboard

import React, { useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import Competitor from './Competitor'
import { AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const Competition = () => {
  const [competitors, setCompetitors] = useState([])

  const addNewCompetitor = () => {
    const newCompetitors = [
      ...competitors,
      {}
    ]
    setCompetitors(newCompetitors)
  }

  return (
    <AvForm>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Competition
              </CardTitle>
            </Col>
          </Row>
          {
            competitors.map((comp, idx) => (
              <Competitor key={idx} />
            ))
          }
          <SowaasButton onClick={addNewCompetitor} title='Add Competitor' />
        </CardBody>
      </Card>
    </AvForm>
  )
}

export default Competition

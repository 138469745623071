import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import BootstrapTheme from '@fullcalendar/bootstrap'
import NewEventModal from '../../containers/calendar/NewEventModal'

const FullCalendarControl = () => {
  const [modal, setModal] = useState(false)

  const handleDateClick = () => {
    setModal(!modal)
  }

  const calendarComponentRef = React.createRef()

  return (
    <>
      <FullCalendar
        ref={calendarComponentRef}
        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
        slotDuration='00:15:00'
        handleWindowResize
        themeSystem='bootstrap'
        events={[]}
        editable={false}
        droppable
        selectable
        dateClick={handleDateClick}
      />
      <NewEventModal
        modal={modal}
        setModal={setModal}
      />
    </>
  )
}

export default FullCalendarControl

import React from 'react'
import { Redirect } from 'react-router-dom'

// Pages requiring auth
import Login from '../containers/authentication/login/Login'
import Logout from '../containers/authentication/logout/Logout'
import ForgotPassword from '../containers/authentication/forgotPassword/ForgotPassword'
import Activate from '../containers/authentication/activate/activate'

// Landing pages
import LandingPage from '../containers/landingPageIndex/LandingPageIndex'

// Hubs
import MessagingHub from '../containers/messagingHub/MessagingHub'
import NotificationsHub from '../containers/notificationsHub/NotificationsHub'
import Invoicing from '../containers/invoicing/Invoicing'
import Calendar from '../containers/calendar/Calendar'

// Other pages
import SalesSummaryExpanded from '../components/salesSummary/SalesSummaryExpanded'
import SolutionsSummaryExpanded from '../components/solutionsSummary/SolutionsSummaryExpanded'
import EngagementSummaryExpanded from '../components/engagementSummary/EngagementSummaryExpanded'
import SalesOpportunity from '../containers/salesOpportunity/SalesOpportunity'
import InvoiceDetails from '../containers/invoiceDetails/InvoiceDetails'
import MessageBody from '../containers/messagingHub/MessageBody'

import SolutionIndex from '../containers/solutionIndex/solutionIndex'
import CRM from '../containers/crm/crm'
import CRMProfile from '../containers/crmProfile/crmProfile'
import RecEngagementLanding from '../containers/recruiterEngagement/recEngagement'
import Profile from '../containers/profile/profile'
import Organisation from '../containers/organisation/organisation'
import Project from '../containers/project/project'
import ErrorPage from '../containers/errorPage/errorPage'
import Customers from '../containers/customers/customers'
import Suppliers from '../containers/suppliers/suppliers'
import NewProject from '../containers/newProject/newProject'
import ActivityFeed from '../containers/activityFeed/activityFeed'
import HelpCentre from '../containers/helpCentre/helpCentre'
import ForgotPasswordReset from '../containers/authentication/forgotPasswordReset/ForgotPasswordReset'
import Home from '../containers/home/home'

export const userRoutes = [
  { path: '/home', component: Home, exact: true },
  { path: '/dashboard', component: LandingPage, exact: true },
  { path: '/messaging-hub', component: MessagingHub, exact: true },
  { path: '/message/:messageId', component: MessageBody, exact: true },
  { path: '/notifications-hub', component: NotificationsHub, exact: true },
  { path: '/sales-summary', component: SalesSummaryExpanded, exact: true },
  { path: '/existing', component: SolutionsSummaryExpanded, exact: true },
  { path: '/engagement-summary', component: EngagementSummaryExpanded, exact: true },
  { path: '/sales-opportunity/new', component: SalesOpportunity, exact: true },
  { path: '/sales-opportunity/:opportunityId', component: SalesOpportunity, exact: true },
  { path: '/solution/new', component: SolutionIndex, exact: true },
  { path: '/solution/:solutionId', component: SolutionIndex, exact: true },
  { path: '/engagement', component: EngagementSummaryExpanded, exact: true },
  { path: '/engagement/:engagementId', component: RecEngagementLanding, exact: true },
  { path: '/invoicing', component: Invoicing, exact: true },
  { path: '/invoice-details', component: InvoiceDetails, exact: true },
  { path: '/organisation/:organisationId', component: Organisation, exact: true },
  { path: '/project/:projectId', component: Project, exact: true },
  { path: '/calendar', component: Calendar, exact: true },
  { path: '/crm', component: CRM, exact: true },
  { path: '/customers', component: Customers, exact: true },
  { path: '/suppliers', component: Suppliers, exact: true },
  { path: '/crm/profile', component: CRMProfile, exact: true },
  { path: '/profile', component: Profile, exact: true },
  { path: '/new', component: NewProject, exact: true },
  { path: '/new/:projectId', component: NewProject, exact: true },
  { path: '/activity-feed', component: ActivityFeed, exact: true },
  { path: '/help-centre', component: HelpCentre, exact: true },
  { path: '/', exact: true, component: () => <Redirect to='/home' /> }
]

export const authRoutes = [
  { path: '/layout', component: Logout, exact: true },
  { path: '/sign-in', component: Login, exact: true },
  { path: '/forgot-password', component: ForgotPassword, exact: true },
  { path: '/forgot-password-reset/:resetToken', component: ForgotPasswordReset, exact: true },
  { path: '/activate/:activationToken', component: Activate, exact: true },
  { path: '/error', component: ErrorPage, exact: true }
]

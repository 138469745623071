import { takeEvery, fork, put, all } from 'redux-saga/effects'
import { PUT_PROFILE } from './actionTypes'
import { updateUserProfile } from './actions'

import api from '../../helpers/api'
import errorService from '../../helpers/errorService'
import { addToast } from '../toast/actions'

function * putProfile ({ payload: { profile } }) {
  try {
    const { id, ...rest } = profile

    const client = yield api.getClient()
    const { status } = yield client.apis.ApplicationUser.patch_api_ApplicationUser__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateUserProfile(profile))
      yield put(addToast('Profile Updated', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchUser () {
  yield takeEvery(PUT_PROFILE, putProfile)
}

function * userSaga () {
  yield all([
    fork(watchUser)
  ])
}

export default userSaga

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ServiceReportForm from '../serviceReportForm/serviceReportForm'

const ServiceReportModal = ({ isOpen, setOpen, onNewServiceReport }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const onAdd = (serviceReport) => {
    onNewServiceReport(serviceReport)
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='lg'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            New Service Report
          </ModalHeader>
          <ModalBody>
            <ServiceReportForm onSubmit={onAdd} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default ServiceReportModal

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col, Card, CardBody, CardTitle, Progress } from 'reactstrap'

const StyledCard = styled.div`
  padding: 5px 0;
`

const StyledProgress = styled.div`
  .progress {
    height: 20px;
    margin-bottom: 20px
  }
`

const StyledButton = styled(Link)`
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  float: right;
  width: 100px;
`

const SolutionsDesign = () => {
  const progressValue = Math.floor(Math.random() * Math.floor(100))

  const GoButton = () => {
    return (
      <>
        <StyledButton to='/' className='btn btn-primary'>Next<i className='mdi mdi-arrow-right ml-1' /></StyledButton>
      </>
    )
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='4'>
              <CardTitle>
                Solution Design
              </CardTitle>
            </Col>
            <Col sm='8'>
              <StyledProgress className='mb-30'>
                <Progress color='primary' value={progressValue}>{progressValue}%</Progress>
              </StyledProgress>
            </Col>
          </Row>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Unique ID
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>12345678</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Customer Name
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Jason Jason</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Opp / Project
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Project Name</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Sales Person
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Eleanor Shellstrop</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Sales Stage
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Stage</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Further Info Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Third Party Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Proposal Design Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  SOW Design Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Financial Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Legal Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Engagement Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Internal Sign-off
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>''</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Customer Status
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>Status</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <Row>
            <Col sm='12'>
              <GoButton />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default SolutionsDesign

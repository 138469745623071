import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Table } from 'reactstrap'
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import produce from 'immer'

const StyledCard = styled.div`
  padding: 5px 0;
`

const CustomerForm = ({ onSubmit, forCustomer = {} }) => {
  const {
    id = '',
    organisationId = '',
    slug = '',
    name = '',
    tradingName = '',
    companyNumber = '',
    vatNumber = '',
    address1 = '',
    website = '',
    stakeholders = [{}]
  } = forCustomer

  const editingExisting = (id !== '')

  const [stakeHoldersValues, setStakeHoldersValues] = useState(stakeholders)

  const addStakeholder = () => {
    const nextStakeholders = produce(stakeHoldersValues, draftStakeholders => {
      draftStakeholders.push({})
    })
    setStakeHoldersValues(nextStakeholders)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      if (values.stakeholders) {
        values.stakeholders = Object.values(values.stakeholders)
      }
      onSubmit({ ...values })
    }
  }

  return (
    <>
      <AvForm className='validation-required' onSubmit={handleSubmit}>
        {
          editingExisting && (
            <>
              <AvInput type='hidden' name='id' value={id} />
              <AvInput type='hidden' name='organisationId' value={organisationId} />
              <AvInput type='hidden' name='slug' value={slug} />
            </>
          )
        }
        <StyledCard>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Customer Registered Name
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Customer Registered Name' bodyCopy="Start typing the Customer's registered company name and select from the dropdown list linked to Companies House" id='imCustomerName' />
            </Col>
            <Col sm='9'>
              <AvField
                name='name'
                placeholder='Enter customer name'
                type='text'
                errorMessage='Enter customer name'
                className='form-control'
                validate={{ required: { value: true } }}
                value={name}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Customer Trading Name
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Customer Trading Name' bodyCopy='If the customer trades by a different name to their registered name, please enter this here.' id='imCustomerTradingName' />
            </Col>
            <Col sm='9'>
              <AvField
                name='tradingName'
                placeholder='Enter trading name'
                type='text'
                errorMessage='Enter trading name'
                className='form-control'
                validate={{ required: { value: true } }}
                value={tradingName}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Company Number
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Company Number' bodyCopy='The registered company number of the Customer. This information is pulled from Companies House.' id='imCompnayNumber' />
            </Col>
            <Col sm='9'>
              <AvField
                name='companyNumber'
                placeholder='Enter company number'
                type='text'
                errorMessage='Enter company number'
                className='form-control'
                validate={{ required: { value: true } }}
                value={companyNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Registered Address
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Registered Address' bodyCopy='The registered company address for the Customer. This information is pulled from Companies House.' id='imAddress' />
            </Col>
            <Col sm='9'>
              <AvField
                name='address1'
                placeholder='Enter address'
                type='text'
                errorMessage='Enter valid address'
                className='form-control'
                validate={{ required: { value: true } }}
                value={address1}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                VAT Number
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='VAT Number' bodyCopy={<>The VAT number of the Customer. You can find this information via <a href='https://vat-search.co.uk/' target='_blank' rel='noopener noreferrer'>this</a> VAT search website.</>} id='imVatNumber' />
            </Col>
            <Col sm='9'>
              <AvField
                name='vatNumber'
                placeholder='Enter VAT number'
                type='text'
                errorMessage='Enter VAT number'
                className='form-control'
                validate={{ required: { value: true } }}
                value={vatNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Customer Website
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Customer Website' bodyCopy="Copy the Customer's website URL into this field" id='imWebsiteURL' />
            </Col>
            <Col sm='9'>
              <AvField
                name='website'
                placeholder='Enter customer website URL'
                type='text'
                errorMessage='Enter valid customer website URL'
                className='form-control'
                validate={{ required: { value: true } }}
                value={website}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <div className='styledTitle'>
                Customer Stakeholders
                <InfoMarker title='Customer Stakeholders' bodyCopy='Add details for the Customer Stakeholders who will need to review and accept this SOW on behalf of their organisation. Stakeholders you nominate here will be able to invite others in their organisation to review the SOW if needed.' id='imCustomerStakeholders' />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Table>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Second Name</th>
                    <th>Job Title</th>
                    <th>Contact Phone</th>
                    <th>Contact Email</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    stakeHoldersValues.map((s, idx) => (
                      <tr key={idx}>
                        <td>
                          <AvInput type='hidden' name={`stakeholders[${idx}].id`} value={s.id} />
                          <AvField
                            name={`stakeholders[${idx}].firstName`}
                            value={s.firstName}
                            errorMessage='Enter valid first name'
                            validate={{ required: { value: true } }}
                          />
                        </td>
                        <td>
                          <AvField
                            errorMessage='Enter valid last name'
                            validate={{ required: { value: true } }}
                            name={`stakeholders[${idx}].lastName`}
                            value={s.lastName}
                          />
                        </td>
                        <td>
                          <AvField
                            errorMessage='Enter valid job title'
                            validate={{ required: { value: true } }}
                            name={`stakeholders[${idx}].jobTitle`}
                            value={s.jobTitle}
                          />
                        </td>
                        <td>
                          <AvField
                            errorMessage='Enter valid contact phone'
                            validate={{ required: { value: true } }}
                            name={`stakeholders[${idx}].contactPhone`}
                            value={s.contactPhone}
                          />
                        </td>
                        <td>
                          <AvField
                            errorMessage='Enter valid contact email'
                            validate={{ required: { value: true } }}
                            name={`stakeholders[${idx}].contactEmail`}
                            value={s.contactEmail}
                          />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <SowaasButton className='mr-2' onClick={addStakeholder} title='Add Stakeholder' />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='float-right' title={(editingExisting ? 'Update' : 'Save & Close')} type='submit' />
            </Col>
          </Row>
        </StyledCard>
      </AvForm>
    </>
  )
}

export default CustomerForm

import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import ProjectInfo from '../../components/projectInfo/projectInfo'
import TimeUtilCard from '../../components/timeUtilCard/timeUtilCard'
import InvoiceCard from '../../components/invoiceCard/invoiceCard'
import ChangeRequestCard from '../../components/changeRequestCard/changeRequestCard'
import ProjectPlanCard from '../../components/projectPlanCard/projectPlanCard'
import RaidLogCard from '../../components/raidLogCard/raidLogCard'
import DeliverablesCard from '../../components/deliverablesCard/deliverablesCard'
import ServiceReportCard from '../../components/serviceReportCard/serviceReportCard'
import FinancesCard from '../../components/financesCard/financesCard'
import KMDBCard from '../../components/kmdbCard/kmdbCard'
import MilestonesCard from '../../components/milestonesCard/milestonesCard'
import ContractsCard from '../../components/contractsCard/contractsCard'
import SolutionFilterPopover from '../../components/solutionFilterPopover/solutionFilterPopover'

import {
  postServiceReport,
  postRaidRisk,
  patchRaidRisk,
  postRaidAssumption,
  patchRaidAssumption,
  postRaidIssue,
  patchRaidIssue,
  postRaidDependency,
  patchRaidDependency,
  syncInvoice,
  syncTimeUtil,
  syncChangeRequest,
  syncKmdb
} from '../../store/project/actions'
import SowaasButton from '../../components/sowaasButton/sowaasButton'
import PageTitle from '../../components/pageTitle/pageTitle'

const RecEngagementLanding = ({ history }) => {
  const { engagementId } = useParams()
  const dispatch = useDispatch()

  const solutions = useSelector((state) => state.Project.projects)

  const { priorities } = useSelector((state) => state.Lookup)
  const raidCategories = [{ name: 'Risk', id: 1 }, { name: 'Assumption', id: 2 }, { name: 'Impact', id: 3 }, { name: 'Dependency', id: 4 }]

  const solution = solutions.find(s => s.id === Number(engagementId))

  const solutionSuppliers = _.get(solution, 'solution.solutionSuppliers', [])
  const statementOfWorks = _.get(solution, 'solution.statementOfWorks', [])
  const serviceReports = _.get(solution, 'engagement.serviceReports', [])
  const invoices = _.get(solution, 'engagement.invoices', [])
  const kmdbs = _.get(solution, 'engagement.kmdbs', [])
  const timeUtilisations = _.get(solution, 'engagement.timeUtilisations', [])
  const changeRequests = _.get(solution, 'engagement.changeRequests', [])
  const engagementFinance = _.get(solution, 'engagement.engagementFinance', {})
  const legalDesign = _.get(solution, 'solution.legalDesign', {})
  const financialDesign = _.get(solution, 'solution.financialDesign', {})
  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const deliverables = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.deliverables || []))
  const milestones = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.milestones || []))
  const suppliers = _.filter(solutionSuppliers, (sol) => { return sol.supplier !== null }).map((ss) => { return ss.supplier })
  const raidRisks = _.map(_.get(solution, 'engagement.raidRisks', []), (o) => ({ ...o, categoryId: 1 }))
  const raidAssumptions = _.map(_.get(solution, 'engagement.raidAssumptions', []), (o) => ({ ...o, categoryId: 2 }))
  const raidIssues = _.map(_.get(solution, 'engagement.raidIssues', []), (o) => ({ ...o, categoryId: 3 }))
  const raidDependencies = _.map(_.get(solution, 'engagement.raidDependencies', []), (o) => ({ ...o, categoryId: 4 }))

  const raidItems = [...raidRisks, ...raidAssumptions, ...raidIssues, ...raidDependencies]

  const onFilterSelected = (entry) => {
    history.push(`/engagement/${entry}`)
  }

  const onNewServiceReport = (serviceReport) => {
    dispatch(postServiceReport(solution, serviceReport))
  }

  const handleRaidSaved = (values) => {
    if (values.dateClosed === '') {
      delete values.dateClosed
    }
    delete values.idNumber

    switch (values.categoryId) {
      case 1: // risk
        delete values.categoryId
        if ('id' in values) {
          dispatch(patchRaidRisk(solution, values))
        } else {
          dispatch(postRaidRisk(solution, values))
        }
        break
      case 2: // assumption
        delete values.categoryId
        if ('id' in values) {
          dispatch(patchRaidAssumption(solution, values))
        } else {
          dispatch(postRaidAssumption(solution, values))
        }
        break
      case 3: // impact
        delete values.categoryId
        if ('id' in values) {
          dispatch(patchRaidIssue(solution, values))
        } else {
          dispatch(postRaidIssue(solution, values))
        }
        break
      case 4: // dependency
        delete values.categoryId
        if ('id' in values) {
          dispatch(patchRaidDependency(solution, values))
        } else {
          dispatch(postRaidDependency(solution, values))
        }
        break
    }
  }

  const handleInvoiceSaved = (values) => {
    dispatch(syncInvoice(solution, values))
  }

  const handleTimeUtilSaved = (values) => {
    dispatch(syncTimeUtil(solution, values))
  }

  const handleTimeUtilApprove = () => {

  }

  const handKmdbSaved = (kmdb) => {
    dispatch(syncKmdb(solution, kmdb))
  }

  const handleChangeRequestSaved = (changeRequest) => {
    dispatch(syncChangeRequest(solution, changeRequest))
  }

  const handleChangeRequestApproved = () => {

  }

  return (
    <>
      <SolutionFilterPopover target='filterPopoverButton' placement='left' onFilter={onFilterSelected} solutions={solutions} />
      <div className='page-content'>
        <Container fluid>
          <Row className='mb-3'>
            <Col sm='6'>
              <PageTitle title='SOW Service Delivery' className='mb-0' />
            </Col>
            <Col sm='6'>
              <SowaasButton id='filterPopoverButton' className='float-right' title='Filter / Search' />
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col sm='3'>
              <ProjectInfo solution={solution} />
            </Col>
            <Col sm='3'>
              <TimeUtilCard
                suppliers={suppliers}
                project={solution}
                onSave={handleTimeUtilSaved}
                onApprove={handleTimeUtilApprove}
                timeUtilsForApproval={timeUtilisations}
              />
            </Col>
            <Col sm='3'>
              <InvoiceCard
                project={solution}
                onSave={handleInvoiceSaved}
                approvalInvoices={invoices}
              />
            </Col>
            <Col sm='3'>
              <ChangeRequestCard
                onChangeRequestSaved={handleChangeRequestSaved}
                onChangeRequestApproved={handleChangeRequestApproved}
                changeRequestsForApproval={changeRequests}
                statementOfWorks={statementOfWorks}
                project={solution}
                suppliers={suppliers}
                deliverables={deliverables}
                milestones={milestones}
              />
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col sm='2'>
              <ProjectPlanCard project={solution} />
            </Col>
            <Col sm='2'>
              <RaidLogCard
                raidItems={raidItems}
                raidCategories={raidCategories}
                priorities={priorities}
                onSubmit={handleRaidSaved}
              />
            </Col>
            <Col sm='2'>
              <ServiceReportCard onNewServiceReport={onNewServiceReport} serviceReports={serviceReports} />
            </Col>
            <Col sm='2'>
              <KMDBCard kmdbItems={kmdbs} project={solution} onSave={handKmdbSaved} />
            </Col>
            <Col sm='4'>
              <ContractsCard legalDesign={legalDesign} />
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <FinancesCard
                financialDesign={financialDesign}
                invoices={invoices}
                engagementFinance={engagementFinance}
              />
            </Col>
            <Col sm='4'>
              <DeliverablesCard deliverables={deliverables} />
            </Col>
            <Col sm='4'>
              <MilestonesCard milestones={milestones} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default RecEngagementLanding

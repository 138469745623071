import {
  ACTIVATE_RESET,
  ACTIVATE_SUBMIT,
  ACTIVATE_SUBMIT_SUCCESS,
  ACTIVATE_SUBMIT_ERROR
} from './actionTypes'

export const userActivateSubmit: any = (values: string) => {
  return {
    type: ACTIVATE_SUBMIT,
    payload: values
  }
}

export const userActivateSubmitSuccess: any = (message: string) => {
  return {
    type: ACTIVATE_SUBMIT_SUCCESS,
    payload: message
  }
}

export const userActivateSubmitError: any = (message: string) => {
  return {
    type: ACTIVATE_SUBMIT_ERROR,
    payload: message
  }
}

export const userActivateReset: any = () => {
  return {
    type: ACTIVATE_RESET
  }
}

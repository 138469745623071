import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useToasts } from 'react-toast-notifications'
import MessageForm from '../../components/messageForm/messageForm'

const MessagingModal = ({ setmodal, modal, isReply = false, replyingTo = undefined, onSend }) => {
  const { addToast } = useToasts()

  const sendMessage = () => {
    addToast('Message Sent', { appearance: 'success', autoDismiss: true })
    // onSend(subject, to, messageContent, replyingTo)
    closeModal()
  }

  const closeModal = () => {
    setmodal(false)
  }

  return (
    <>
      <Modal isOpen={modal} role='dialog' autoFocus centered className='exampleModal' tabIndex='-1' toggle={closeModal}>
        <div className='modal-content'>
          <ModalHeader toggle={closeModal}>
            {isReply ? `Reply to ${replyingTo.Title}` : 'New Message'}
          </ModalHeader>
          <ModalBody>
            <MessageForm onSend={sendMessage} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default MessagingModal

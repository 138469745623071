import { getAppInsights } from '../TelemetryService'

const errorService = {
  logError: (exception) => {
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.error(exception)
      } else {
        const appInsights = getAppInsights()
        appInsights.trackException(exception)
      }
    } catch (ex) {
      console.error(exception, ex)
    }
  }
}

export default errorService

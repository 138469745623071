import React, { useEffect } from 'react'

import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes'
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux'

// Import all middleware
import Authmiddleware from './routes/middleware/AuthMiddleware'
import NonAuthmiddleware from './routes/middleware/NonAuthMiddleware'

// layouts Format
import Layout from './containers/pageLayout/PageLayout'
import NonAuthLayout from './helpers/layoutTemplates/NonAuthLayout'
import ErrorPage from './containers/errorPage/errorPage'

import { useToasts } from 'react-toast-notifications'
import { clearToast } from './store/toast/actions'

import './assets/scss/theme.scss'

const App = (): any => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { User, Toast } = useSelector((state: RootStateOrAny) => state)
  const { toasts = [] } = Toast

  useEffect(() => {
    if (toasts.length > 0) {
      toasts.forEach((toast: any) => {
        addToast(toast.title, { appearance: toast.appearance, autoDismiss: true })
        dispatch(clearToast(toast))
      })
    }
  }, [toasts, addToast, dispatch])

  return (
    <>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => {
            return (
              <NonAuthmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                exact={route.exact}
              />
            )
          })}
          {userRoutes.map((route, idx) => {
            return (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                exact={route.exact}
                user={User}
              />
            )
          })}
          <Route render={() => <ErrorPage />} />
        </Switch>
      </Router>
    </>
  )
}

export default App

import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Container, TabContent, TabPane } from 'reactstrap'
import NewProject from '../../components/newProject/newProject'
import SolutionSuppliers from '../../components/solutionSuppliers/solutionSuppliers'
import FinancialDesign from '../../components/financialDesign/financialDesign'
import ProposalDesign from '../../components/proposalDesign/proposalDesign'
import SowDesign from '../../components/sowDesign/SowDesign'
import LegalDesign from '../../components/legalDesign/legalDesign'
import EngagementDesign from '../../components/engagementDesign/engagementDesign'
import SolutionCustomer from '../../components/solutionCustomer/SolutionCustomer'
import SolutionSignoffRecruiter from '../../components/solutionSignoffRecruiter/solutionSignoffRecruiter'
import api from '../../helpers/api'

import { postCustomer } from '../../store/customer/actions'

import {
  patchProposalDesign,
  syncFinancialDesign,
  syncSowDesign,
  syncEngagementDesign,
  syncSuppliersDesign,
  patchProject,
  addProject,
  syncLegalDesign
} from '../../store/project/actions'
import PageTitle from '../../components/pageTitle/pageTitle'

import { postSupplier } from '../../store/supplier/actions'

const NewProject1 = () => {
  const dispatch = useDispatch()
  const { projectId = -999 } = useParams()
  const history = useHistory()
  const location = useLocation()

  const projects = useSelector((state) => state.Project.projects)

  const [project, setProject] = useState({})

  useEffect(() => {
    const currentProject = projects.find(s => s.id === Number(projectId))
    if (currentProject !== undefined) {
      if (!_.isEqual(currentProject, project)) {
        setProject(currentProject)
      }
    }
  }, [projects])

  const {
    name = '',
    slug = '',
    projectProcurerId = '-1',
    markedAsComplete = false
  } = project

  const currentPage = _.get(location, 'state.currentTab', 0)

  const [activeTab, setActiveTab] = useState(currentPage)
  const customers = useSelector((state) => state.Customer.customers)
  const suppliers = useSelector((state) => state.Supplier.suppliers)

  const { SupplierRoleType, StatementOfWorkType } = useSelector(state => state.Lookup.enums)

  const supplierRoles = [
    {
      value: SupplierRoleType[0],
      label: 'Service Design input only'
    },
    {
      value: SupplierRoleType[1],
      label: 'Delivery only'
    },
    {
      value: SupplierRoleType[2],
      label: 'Service Design input and Delivery'
    }
  ]

  useEffect(() => {
    if (project.toBeSynced === true) {
      syncProject()
    }
  }, [project]) // eslint-disable-line

  const handleCustomerAdd = (customer) => {
    dispatch(postCustomer(customer))
  }

  const handleSupplierAdd = (supplier) => {
    dispatch(postSupplier(supplier))
  }

  const handleProjectAdd = (projectValues) => {
    setProject({ ...project, ...projectValues, toBeSynced: true })

    setActiveTab(1)
  }

  const handleProposalDesignUpdated = (proposalDesign) => {
    const {
      proposalRequired,
      ...proposalDesignData
    } = proposalDesign

    setProject({ ...project, proposalRequired, toBeSynced: true })

    dispatch(patchProposalDesign(project, proposalDesignData))

    setActiveTab(4)
  }

  const handleFinancialDesignUpdated = (financialDesign) => {
    dispatch(syncFinancialDesign(project, financialDesign))

    setActiveTab(7)
  }

  const handleSowDesignUpdated = (sowDesign) => {
    dispatch(syncSowDesign(project, sowDesign))
  }

  const handleLegalDesignUpdated = (legalDesign) => {
    dispatch(syncLegalDesign(project, legalDesign))
    setActiveTab(8)
  }

  const handleEngagementDesignUpdated = (engagementDesign) => {
    const {
      engagementRequired,
      ...engagementDesignData
    } = engagementDesign

    setProject({ ...project, engagementRequired, toBeSynced: true })

    dispatch(syncEngagementDesign(project, engagementDesignData))

    setActiveTab(6)
  }

  const handleSuppliersUpdated = (supplierData) => {
    const {
      suppliersRequired,
      suppliersKnown,
      suppliers = []
    } = supplierData

    setProject({ ...project, suppliersRequired, suppliersKnown, toBeSynced: true })

    if (suppliers.length > 0) {
      dispatch(syncSuppliersDesign(project, suppliers))
    }

    setActiveTab(3)
  }

  const handleToggleMarkedAsComplete = (value) => {
    setProject({ ...project, markedAsComplete: value, toBeSynced: true })
  }

  const handleCustomerUpdate = (event, errors, values) => {
    if (errors.length === 0) {
      const { customer = '-1' } = values

      setProject({
        ...project,
        projectFacilitatorId: Number(customer),
        projectProcurerId: Number(customer),
        projectSupplierId: Number(customer),
        toBeSynced: true
      })

      setActiveTab(2)
    }
  }

  const syncProject = async () => {
    const { id = -1, projectFacilitatorId = -1, toBeSynced = false } = project

    if (toBeSynced === true) {
      if (projectFacilitatorId === -1) return // we cannot sync until we have a customer

      if (id !== -1) { // existing
        const patchProjectObj = _.pick(project, [
          'id',
          'name',
          'startDate',
          'endDate',
          'suppliersRequired',
          'numberOfSuppliersRequired',
          'suppliersKnown',
          'proposalRequired',
          'engagementRequired',
          'markedAsComplete'
        ])

        dispatch(patchProject(patchProjectObj))
      } else { // new
        project.solution = {
          engagementDesign: {},
          financialDesign: {},
          legalDesign: {},
          proposalDesign: {}
        }
        project.engagement = {}

        const client = await api.getClient()
        const { status, body = {} } = await client.apis.Project.post_api_Project({}, { requestBody: project })

        if (status === 200) {
          setProject(body)

          history.replace(`/new/${body.id}`, { currentTab: 2 })

          dispatch(addProject(body))
        }
      }
    }
  }

  const activeStyles = { color: '#AD19FE', cursor: 'pointer' }

  const nonActiveStyles = { cursor: 'pointer' }

  const tabs = [
    { title: 'Overview' },
    { title: 'Customer' },
    { title: 'Suppliers' },
    { title: 'Proposal' },
    { title: 'Statements of Work' },
    { title: 'Delivery' },
    { title: 'Financial' },
    { title: 'Legal' },
    { title: 'Approval' }
  ]

  return (
    <div className='page-content'>
      <Container fluid>
        <Row>
          <Col sm='12'>
            <PageTitle title={`${(slug === '' ? 'New' : '')} SOW Service ${(slug !== '' ? `- ${slug}` : '')} ${(name !== '' ? `- ${name}` : '')}`} icon='bx-plus-circle' />
          </Col>
        </Row>
        <Row>
          <Col xl='12' style={{ textAlign: 'center', margin: '1.5rem 1rem', fontSize: '1.2em', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {
              tabs.map((tab, idx) => (
                <div key={idx} style={{ display: 'flex', justifyContent: 'center' }}>
                  <span onClick={() => { setActiveTab(idx) }} style={(activeTab === idx ? activeStyles : nonActiveStyles)}>{tab.title}</span>
                  {
                    idx < tabs.length - 1 && (
                      <i className='bx bx-chevron-right' style={{ margin: '-3px 5px', fontSize: '2em' }} />
                    )
                  }
                </div>
              ))
            }
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
            {
              activeTab === 0 && (
                <Row>
                  <Col xl='12'>
                    <NewProject onProjectAdd={handleProjectAdd} existingProject={project} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={1}>
            {
              activeTab === 1 && (
                <Row>
                  <Col xl='12'>
                    <SolutionCustomer customers={customers} onCustomerAdded={handleCustomerAdd} projectProcurerId={projectProcurerId} onCustomerUpdate={handleCustomerUpdate} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={2}>
            {
              activeTab === 2 && (
                <Row>
                  <Col xl='12'>
                    <SolutionSuppliers supplierRoles={supplierRoles} project={project} existingSuppliers={suppliers} onSupplierAdded={handleSupplierAdd} onSolutionSuppliersSaved={handleSuppliersUpdated} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={6}>
            {
              activeTab === 6 && (
                <Row>
                  <Col xl='12'>
                    <FinancialDesign project={project} onFinancialDesignSaved={handleFinancialDesignUpdated} onBackToSOWs={() => { setActiveTab(4) }} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={3}>
            {
              activeTab === 3 && (
                <Row>
                  <Col xl='12'>
                    <ProposalDesign project={project} onProposalDesignSaved={handleProposalDesignUpdated} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={4}>
            {
              activeTab === 4 && (
                <Row>
                  <Col xl='12'>
                    <SowDesign orgSuppliers={suppliers} project={project} onSowDesignSaved={handleSowDesignUpdated} types={StatementOfWorkType} onContinue={() => { setActiveTab(5) }} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={7}>
            {
              activeTab === 7 && (
                <Row>
                  <Col xl='12'>
                    <LegalDesign project={project} onLegalDesignSaved={handleLegalDesignUpdated} onBackToSuppliers={() => { setActiveTab(2) }} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={5}>
            {
              activeTab === 5 && (
                <Row>
                  <Col xl='12'>
                    <EngagementDesign project={project} onEngagmentDesignSaved={handleEngagementDesignUpdated} onBackToSOWs={() => { setActiveTab(4) }} readOnly={markedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
          <TabPane tabId={8}>
            {
              activeTab === 8 && (
                <Row>
                  <Col xl='12'>
                    <SolutionSignoffRecruiter markedAsComplete={markedAsComplete} onToggleMarkedAsCompelete={handleToggleMarkedAsComplete} />
                  </Col>
                </Row>
              )
            }
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}

export default NewProject1

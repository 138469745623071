import React from 'react'
import _ from 'lodash'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ChangeRequestForm from '../changeRequestForm/changeRequestForm'
import demoCr from '../../helpers/demoMode/exampleCr.json'

const ChangeRequestModal = ({
  isOpen,
  setOpen,
  mode = 'add',
  changeRequest = demoCr,
  project = {},
  onSave
}) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSubmit = (values) => {
    onClose()
    onSave(values)
  }

  let title = ''
  let readOnly = true

  switch (mode) {
    case 'add':
      title = 'New Change Request'
      readOnly = false
      break
    case 'approve':
      title = 'Approve Change Request'
      break
    default:
      break
  }

  const statementOfWorks = _.get(project, 'solution.statementOfWorks', [])
  const solutionSuppliers = _.get(project, 'solution.solutionSuppliers', [])
  const suppliers = _.filter(solutionSuppliers, (sol) => { return sol.supplier !== null }).map((ss) => { return ss.supplier })

  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const deliverables = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.deliverables || []))
  const milestones = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.milestones || []))

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='customer'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            {title}
          </ModalHeader>
          <ModalBody>
            <ChangeRequestForm
              mode={mode}
              readOnly={readOnly}
              changeRequest={changeRequest}
              statementOfWorks={statementOfWorks}
              suppliers={suppliers}
              deliverables={deliverables}
              milestones={milestones}
              project={project}
              onSave={handleSubmit}
            />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default ChangeRequestModal

import React, { useState } from 'react'
import { Row, Col, Container, Card, CardBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import SupplierTable from '../../components/supplierTable/supplierTable'
import SupplierOnboardModal from '../../components/supplierOnboardModal/supplierOnboardModal'

import { postSupplier, putSupplier } from '../../store/supplier/actions'
import PageTitle from '../../components/pageTitle/pageTitle'
import SowaasButton from '../../components/sowaasButton/sowaasButton'
import VideoMarker from '../../components/videoMarker/videoMarker'

const Suppliers = () => {
  const dispatch = useDispatch()
  const { suppliers } = useSelector(state => state.Supplier)

  const [supplierOnboardOpen, setSupplierOnboardOpen] = useState(false)
  const [activeSupplier, setActiveSupplier] = useState(undefined)

  const onAddSupplier = () => {
    setActiveSupplier(undefined)
    setSupplierOnboardOpen(true)
  }

  const onAddSupplierSubmit = (supplier) => {
    if ('id' in supplier) {
      dispatch(putSupplier(supplier))
    } else {
      dispatch(postSupplier(supplier))
    }

    setActiveSupplier(undefined)
    setSupplierOnboardOpen(false)
  }

  const onViewSupplier = (supplier) => {
    setActiveSupplier(supplier)
    setSupplierOnboardOpen(true)
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <Row>
          <Col xl='12'>
            <PageTitle className='mb-3' title='Suppliers' icon='bx-group' />
          </Col>
        </Row>
        <Card className='overflow-hidden card-100'>
          <CardBody>
            {
              suppliers.length === 0 && (
                <p>You do not currently have any suppliers</p>
              )
            }
            {
              suppliers.length > 0 && (
                <SupplierTable suppliers={suppliers} onSupplierSelected={onViewSupplier} />
              )
            }
            <Row>
              <Col sm='12' style={{ display: 'flex' }}>
                <SowaasButton onClick={onAddSupplier} title='Add Supplier' className='mr-1' />
                <VideoMarker id='vmAddSupplier' title='Add Supplier' videoUrl='https://vimeo.com/500952234/6b4e742874' />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {
          supplierOnboardOpen === true && (
            <SupplierOnboardModal isOpen={supplierOnboardOpen} setOpen={setSupplierOnboardOpen} onSubmit={onAddSupplierSubmit} forSupplier={activeSupplier} />
          )
        }
      </Container>
    </div>
  )
}

export default Suppliers

import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import DeliverablesCard from '../deliverablesCard/deliverablesCard'
import InfoMarker from '../infoMarker/infoMarker'
import MilestonesCard from '../milestonesCard/milestonesCard'
import SowaasButton from '../sowaasButton/sowaasButton'
import SowaasButtonInteraction from '../sowaasButtonInteraction/sowaasButtonInteraction'
import _ from 'lodash'
import TimeUtilModal from '../timeUtilModal/timeUtilModal'
import RaidModal from '../raidModal/raidModal'
import ProjectPlanModal from '../projectPlanModal/projectPlanModal'
import KmdbModal from '../kmdbModal/kmdbModal'
import DocumentModal from '../documentModal/documentModal'
import ServiceReportModal from '../serviceReportModal/serviceReportModal'
import ChangeRequestModal from '../changeRequestModal/changeRequestModal'
import InvoiceModal from '../invoiceModal/invoiceModal'
import { useDispatch } from 'react-redux'
import { syncChangeRequest } from '../../store/project/actions'

const ThirdPartyProjectStatus = ({ projects, priorities }) => {
  const dispatch = useDispatch()

  const [newServiceReportModalOpen, setNewServiceReportModalOpen] = useState(false)
  const [updateInvoiceModalOpen, setUpdateInvoiceModalOpen] = useState(false)
  const [kmdbOpen, setKmdbOpen] = useState(false)
  const [raidLogOpen, setRaidLogOpen] = useState(false)
  const [addChangeRequestModalOpen, setAddChangeRequestModalOpen] = useState(false)
  const [approveChangeRequestModalOpen, setApproveChangeRequestModalOpen] = useState(false)
  const [viewingContract, setViewingContract] = useState(false)
  const [updateTimeUtilModalOpen, setUpdateTimeUtilModalOpen] = useState(false)
  const [projectPlanShown, setProjectPlanShown] = useState(false)
  const [newTimeUtilModalOpen, setNewTimeUtilModalOpen] = useState(false)
  const [addInvoiceModalOpen, setAddInvoiceModalOpen] = useState(false)

  const project = projects[0]

  const raidCategories = [{ name: 'Risk', id: 1 }, { name: 'Assumption', id: 2 }, { name: 'Impact', id: 3 }, { name: 'Dependency', id: 4 }]

  const { name, slug, solution } = project
  const statementOfWorks = _.get(solution, 'statementOfWorks', [])
  const solutionSuppliers = _.get(solution, 'solution.solutionSuppliers', [])
  const suppliers = _.filter(solutionSuppliers, (sol) => { return sol.supplier !== null }).map((ss) => { return ss.supplier })
  const kmdbItems = _.get(solution, 'engagement.kmdbs', [])

  const supplierSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Supplier' })
  const deliverables = _.flatten(_.map(supplierSOWs, (sSOW) => sSOW.deliverables || []))
  const milestones = _.flatten(_.map(supplierSOWs, (sSOW) => sSOW.milestones || []))

  const raidRisks = _.map(_.get(solution, 'engagement.raidRisks', []), (o) => ({ ...o, categoryId: 1 }))
  const raidAssumptions = _.map(_.get(solution, 'engagement.raidAssumptions', []), (o) => ({ ...o, categoryId: 2 }))
  const raidIssues = _.map(_.get(solution, 'engagement.raidIssues', []), (o) => ({ ...o, categoryId: 3 }))
  const raidDependencies = _.map(_.get(solution, 'engagement.raidDependencies', []), (o) => ({ ...o, categoryId: 4 }))

  const raidItems = [...raidRisks, ...raidAssumptions, ...raidIssues, ...raidDependencies]

  const onNewServiceReport = () => {

  }

  const onChangeRequestSaved = (changeRequest) => {
    dispatch(syncChangeRequest(project, changeRequest))
  }

  const onChangeRequestApproved = () => {

  }

  const onSaveKMDB = () => {

  }

  const onTimeUtilSave = () => {

  }

  const onTimeUtilApprove = () => {

  }

  const onInvoiceUpdated = () => {

  }

  const onRaidSaved = () => {

  }

  const onInvoiceSaved = () => {

  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='2'>
              <CardTitle className='mb-2'>
                Project & Service Status
                <InfoMarker title='Project & Service Status' bodyCopy='' name='imProjectServiceStatus' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='3' className='pt-4'>
              <div className='styledTitle'>
                {name}
              </div>
              <div className='styledTitle'>
                {slug}
              </div>
            </Col>
            <Col sm='1' className='pt-4'>
              <CardTitle />
              <SowaasButton title='Project Plan' className='btn-block' onClick={() => setProjectPlanShown(true)} />
            </Col>
            <Col sm='1' className='pt-4'>
              <CardTitle />
              <SowaasButton title='Update KMDB' className='btn-block' onClick={() => setKmdbOpen(true)} />
            </Col>
            <Col sm='1' className='pt-4'>
              <CardTitle />
              <SowaasButton title='Update Raid Log' className='btn-block' onClick={() => setRaidLogOpen(true)} />
            </Col>
            <Col sm='2'>
              <CardTitle>
                Time / Utilisation
                <InfoMarker title='Time / Utilisation' bodyCopy='' name='imTu' />
              </CardTitle>
              <SowaasButton title='New TU' className='btn-block' onClick={() => setNewTimeUtilModalOpen(true)} />
              <SowaasButtonInteraction title='Update TU (0)' disabled className='btn-block' onClick={() => setUpdateTimeUtilModalOpen(true)} />
            </Col>
            <Col sm='2'>
              <CardTitle>
                Change Request
                <InfoMarker title='Change Request' bodyCopy='' name='imChangeRequest' />
              </CardTitle>
              <SowaasButton title='New CR' className='btn-block' onClick={() => { setAddChangeRequestModalOpen(true) }} />
              <SowaasButtonInteraction title='Accept CR (0)' disabled className='btn-block' />
            </Col>
            <Col sm='2'>
              <CardTitle>
                Invoicing
                <InfoMarker title='Invoicing' bodyCopy='' name='imInvoicing' />
              </CardTitle>
              <SowaasButton title='New Invoice' className='btn-block' onClick={() => { setAddInvoiceModalOpen(true) }} />
              <SowaasButtonInteraction
                title='Update Invoice (0)'
                className='btn-block'
                disabled
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col sm='4'>
              <DeliverablesCard deliverables={deliverables} />
            </Col>
            <Col sm='4'>
              <MilestonesCard milestones={milestones} />
            </Col>
            <Col sm='2' className='mt-3'>
              <CardTitle>
                Contract Information
                <InfoMarker title='Contract Information' bodyCopy='' name='imContractInformation' />
              </CardTitle>
              <SowaasButton
                title='Supplier Terms'
                className='btn-block'
                onClick={() => { setViewingContract('https://stroagesowaasdemo.blob.core.windows.net/example-docs/SUPPLIER TERMS - SRL to Cloud People Ltd Services Agreement.docx') }}
              />
              <SowaasButton
                title='Supplier SOW'
                className='btn-block'
                onClick={() => { setViewingContract('https://stroagesowaasdemo.blob.core.windows.net/example-docs/SUPPLIER SOW - SRL to Cloud People Ltd SOW.docx') }}
              />
            </Col>
            <Col sm='2' className='mt-3'>
              <CardTitle>
                Service Reporting
                <InfoMarker title='Service Reporting' bodyCopy='' name='imServiceReporting' />
              </CardTitle>
              <SowaasButton title='SR Entry' className='btn-block' onClick={() => { setNewServiceReportModalOpen(true) }} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {
        (addChangeRequestModalOpen !== false) && (
          <ChangeRequestModal
            isOpen={addChangeRequestModalOpen}
            setOpen={setAddChangeRequestModalOpen}
            statementOfWorks={statementOfWorks}
            suppliers={suppliers}
            deliverables={deliverables}
            milestones={milestones}
            project={project}
            onSave={onChangeRequestSaved}
            mode='add'
          />
        )
      }
      {
        (approveChangeRequestModalOpen !== false) && (
          <ChangeRequestModal
            isOpen={(approveChangeRequestModalOpen !== false)}
            setOpen={setApproveChangeRequestModalOpen}
            changeRequest={approveChangeRequestModalOpen}
            statementOfWorks={statementOfWorks}
            suppliers={suppliers}
            deliverables={deliverables}
            milestones={milestones}
            project={project}
            onSave={onChangeRequestApproved}
            mode='approve'
          />
        )
      }
      {
        (newTimeUtilModalOpen !== false) && (
          <TimeUtilModal isOpen setOpen={setNewTimeUtilModalOpen} mode='add' suppliers={suppliers} project={project} onSave={onTimeUtilSave} />
        )
      }
      {
        (updateTimeUtilModalOpen !== false) && (
          <TimeUtilModal isOpen setOpen={setUpdateTimeUtilModalOpen} mode='approve' tu={updateTimeUtilModalOpen} suppliers={suppliers} project={project} onSave={onTimeUtilApprove} />
        )
      }
      {
        raidLogOpen && (
          <RaidModal raidItems={raidItems} isOpen={raidLogOpen} setOpen={setRaidLogOpen} raidCategories={raidCategories} priorities={priorities} onSubmit={onRaidSaved} />
        )
      }
      {
        projectPlanShown && (
          <ProjectPlanModal
            isOpen={projectPlanShown}
            setOpen={setProjectPlanShown}
            project={project}
          />
        )
      }
      {
        kmdbOpen && (
          <KmdbModal
            isOpen={kmdbOpen}
            setOpen={setKmdbOpen}
            kmdbItems={kmdbItems}
            project={project}
            onSave={onSaveKMDB}
          />
        )
      }
      {
        updateInvoiceModalOpen && (
          <InvoiceModal
            onSave={onInvoiceUpdated}
            project={project}
            invoice={updateInvoiceModalOpen}
            isOpen={(updateInvoiceModalOpen !== false)}
            setOpen={setUpdateInvoiceModalOpen}
            mode='update'
          />
        )
      }
      {
        addInvoiceModalOpen && (
          <InvoiceModal
            onSave={onInvoiceSaved}
            project={project}
            isOpen={(addInvoiceModalOpen !== false)}
            setOpen={setAddInvoiceModalOpen}
            mode='add'
          />
        )
      }
      {
        viewingContract !== false && (
          <DocumentModal fileUrl={viewingContract} isOpen={(viewingContract !== false)} setOpen={setViewingContract} />
        )
      }
      {
        newServiceReportModalOpen && (
          <ServiceReportModal isOpen={newServiceReportModalOpen} setOpen={setNewServiceReportModalOpen} onNewServiceReport={onNewServiceReport} />
        )
      }
    </>
  )
}

export default ThirdPartyProjectStatus

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import KmdbLog from '../kmdbLog/kmdbLog'

const KmdbModal = ({ isOpen, setOpen, kmdbItems = [], project = {}, onSave }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Knowledge Management
          </ModalHeader>
          <ModalBody>
            <KmdbLog kmdbItems={kmdbItems} project={project} onSave={onSave} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default KmdbModal

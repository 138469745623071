import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { addNotification } from '../../store/notification/actions'
import moment from 'moment'

function * demoHook (action) {
  const now = moment().toISOString()

  switch (action.type) {
    case 'UPDATE_CHANGE_REQUEST':
      yield put(addNotification({
        role: 'recruiter_consultant',
        organisationUserId: 0,
        subject: 'CRN-44876 from Cloud People is Ready to Review',
        content: 'Change Request (CRN-44876) has been submitted for your review, please click below to view and action accordingly.',
        read: false,
        sentAt: now,
        readAt: now,
        notificationType: 'ReviewCR',
        data: 1,
        id: 3,
        createdAt: now,
        createdById: 0,
        updatedAt: now,
        updatedById: 0
      }))
      break
  }
}

export function * watchDemo () {
  yield takeEvery('*', demoHook)
}

function * demoSaga () {
  yield all([
    fork(watchDemo)
  ])
}

export default demoSaga

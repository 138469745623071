import React, { useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle, InputGroup } from 'reactstrap'
import InfoMarker from '../../components/infoMarker/infoMarker'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import SowaasButton from '../../components/sowaasButton/sowaasButton'
import CustomerModal from '../../components/customerModal/customerModal'
import VideoMarker from '../videoMarker/videoMarker'

const SolutionCustomer = ({ customers = [], onCustomerAdded, projectProcurerId = '-1', onCustomerUpdate, readOnly = false }) => {
  const [customerOnboardOpen, setCustomerOnboardOpen] = useState(false)

  const customerAdded = (customer) => {
    setCustomerOnboardOpen(false)
    onCustomerAdded(customer)
  }

  return (
    <>
      <Card className='overflow-hidden' style={{ height: '100%' }}>
        <CardBody className='flex-card'>
          <CardTitle className='mb-4'>
            Customer Details
            <InfoMarker title='Customer Details' bodyCopy='The Customer is the organisation buying your SOW service' id='imCustomerDetails' />
            <VideoMarker id='vmAddCustomer' title='Add Customer' videoUrl='https://vimeo.com/500951803/0157198e42' />
          </CardTitle>
          <AvForm onSubmit={onCustomerUpdate}>
            <Row>
              <Col sm='3'>
                <div className='styledTitle'>
                  Select Customer Name
                </div>
              </Col>
              <Col sm='6'>
                <AvGroup>
                  <InputGroup>
                    <AvInput type='select' name='customer' value={projectProcurerId} disabled={readOnly}>
                      <option disabled value='-1'>Select existing Customer</option>
                      {
                        customers.map((c, idx) => (
                          <option key={idx} value={c.id}>{c.name}</option>
                        ))
                      }
                    </AvInput>
                  </InputGroup>
                  <AvFeedback>Enter customer name</AvFeedback>
                </AvGroup>
              </Col>
              <Col sm='1' style={{ textAlign: 'center' }} className='pt-2'>
                Or
              </Col>
              <Col sm='2'>
                <SowaasButton className='btn-block' onClick={() => { setCustomerOnboardOpen(true) }} title='Add New Customer' disabled={readOnly} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right mt-5' title={(readOnly ? 'Next' : 'Save')} type='submit' />
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
      {
        customerOnboardOpen === true && (
          <CustomerModal isOpen={customerOnboardOpen} setOpen={setCustomerOnboardOpen} onSubmit={customerAdded} />
        )
      }
    </>
  )
}

export default SolutionCustomer

import React from 'react'
import { Row, Col } from 'reactstrap'

// Import components
import ProjectAndSupplierStatus from '../../components/projectAndSupplierStatus/ProjectAndSupplierStatus'
import Messaging from '../../components/messaging/Messaging'
import Notifications from '../../components/notifications/Notifications'

const EngagementDashboard = ({ messaging = [], notifications = [] }) => {
  return (
    <>
      <Row>
        <Col sm='12'>
          <ProjectAndSupplierStatus />
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <Messaging messages={messaging} />
        </Col>
        <Col sm='6'>
          <Notifications notifications={notifications} />
        </Col>
      </Row>
    </>
  )
}

export default EngagementDashboard

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import OrganisationUserTableCard from '../../components/organisationUserTableCard/organisationUserTableCard'
import PageTitle from '../../components/pageTitle/pageTitle'

const Organisation = () => {
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm='12'>
              <PageTitle title='Organisation' className='mb-3' />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <OrganisationUserTableCard />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Organisation

import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import _ from 'lodash'
import { setUserInfo } from '../../store/user/actions'
import { useDispatch } from 'react-redux'
import users from '../../helpers/demoMode/users.json'

const roles = [
  { name: 'Consultant', value: 'recruiter_consultant' },
  { name: 'Management', value: 'recruiter_executive' },
  { name: 'Finance & Legal', value: 'legal_finance' },
  { name: '3rd Party', value: 'thirdparty' },
  { name: 'Customer', value: 'customer' }
]

const DemoRoleSwitchMenu = ({ organisations = [], currentRole, history }) => {
  const dispatch = useDispatch()
  const [menuOpen, setMenuOpen] = useState(false)

  const { name = '' } = _.find(roles, { value: currentRole }) || {}

  const onRoleClicked = (role) => {
    const userProfile = _.find(users, { role: role.value })
    dispatch(setUserInfo(userProfile))
    history.push('/home')
  }

  return (
    <>
      <Dropdown isOpen={menuOpen} toggle={() => setMenuOpen(!menuOpen)} className='d-inline-block'>
        <DropdownToggle className='btn header-item waves-effect' id='page-header-user-dropdown' tag='button'>
          <span className='d-none d-xl-inline-block ml-2 mr-1'>{name}</span>
          <i className='mdi mdi-chevron-down d-none d-xl-inline-block' />
        </DropdownToggle>
        <DropdownMenu right>
          {
            roles.map((r, idx) => (
              <div key={idx}>
                <DropdownItem tag='div' className='dropdown-item' onClick={() => { onRoleClicked(r) }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ flex: 1 }} className='pt-1 pr-2'>{r.name}</span>
                  </div>
                </DropdownItem>
                {
                  idx < (organisations.length - 1) && (
                    <div className='dropdown-divider' />
                  )
                }
              </div>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default DemoRoleSwitchMenu

import { takeEvery, fork, put, all } from 'redux-saga/effects'
import { GET_MESSAGES, POST_MESSAGE, DEL_MESSAGE, MARK_MESSAGE_READ } from './actionTypes'
import { clearMessages, addMessages, addMessage, deleteMessage, updateMessage } from './actions'

import api from '../../helpers/api'
import errorService from '../../helpers/errorService'
import { addToast } from '../toast/actions'

function * getMessages () {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Message.get_api_Message()
    if (status === 200) {
      yield put(clearMessages())
      yield put(addMessages(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postMessage ({ payload: { message } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Message.post_api_Message({}, { requestBody: message })
    if (status === 200) {
      yield put(addMessage(body))
      yield put(addToast('Message Sent', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * delMessage ({ payload: { message } }) {
  try {
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Message.delete_api_Message__id_({ id: message.id })
    if (status === 200) {
      yield put(deleteMessage(message))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * markMessageAsRead ({ payload: { message } }) {
  try {
    const { id, ...rest } = message

    rest.id = id // TODO REMOVE
    rest.read = true

    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Message.patch_api_Message__id_({ id }, { requestBody: rest })

    if (status === 200) {
      yield put(updateMessage(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchMessages () {
  yield takeEvery(GET_MESSAGES, getMessages)
  yield takeEvery(POST_MESSAGE, postMessage)
  yield takeEvery(DEL_MESSAGE, delMessage)
  yield takeEvery(MARK_MESSAGE_READ, markMessageAsRead)
}

function * messageSaga () {
  yield all([
    fork(watchMessages)
  ])
}

export default messageSaga

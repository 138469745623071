import React from 'react'
import styled from 'styled-components'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButtonInteraction from '../sowaasButtonInteraction/sowaasButtonInteraction'

const StyledHeading = styled.div`
  padding-bottom: 20px;
  margin: 0 2px;
  display: flex;
  justify-content: space-between;
`

const Calendar = ({ events = [] }) => {
  const GoButton = () => {
    return (
      <>
        <SowaasButtonInteraction className='float-right' title='View All' icon='mdi mdi-arrow-right' to='/activity-feed' />
      </>
    )
  }

  return (
    <>
      <Card className='overflow-hidden interactionCard' style={{ height: '100%' }}>
        <div className='aquaVerticalGradient' style={{ height: '8px' }} />
        <CardBody className='flex-card'>
          <Row>
            <Col sm='7'>
              <StyledHeading>
                <CardTitle className='mb-2'>
                  Calendar
                  <InfoMarker title='Calendar' bodyCopy='To display latest information' name='imCalendar' />
                </CardTitle>
              </StyledHeading>
            </Col>
            <Col sm='5'>
              <GoButton />
            </Col>
          </Row>
          {
            events.length === 0 && (
              <div className='center-aligned-box'>
                <h6>No upcoming events found</h6>
              </div>
            )
          }
        </CardBody>
      </Card>
    </>
  )
}

export default Calendar

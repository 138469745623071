import React from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import ProjectPlanForm from '../projectPlanForm/projectPlanForm'
import SowaasButton from '../sowaasButton/sowaasButton'

const ProjectPlanModal = ({ isOpen, setOpen, project }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const onAdd = () => {
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Project Plan
          </ModalHeader>
          <ModalBody>
            <ProjectPlanForm project={project} onSubmit={() => { onAdd() }} />
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right' title='Close' onClick={() => { onClose() }} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default ProjectPlanModal

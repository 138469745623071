import { takeEvery, fork, put, all } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes'
import { loginUserFailed } from './actions'

import { setUserInfo } from '../../user/actions'

import api from '../../../helpers/api'

function * loginUser ({ payload: { history, user } }) {
  try {
    const loginResult = yield api.login(user.username, user.password)
    if (loginResult) {
      loginResult.role = 'recruiter_consultant'

      yield put(setUserInfo(loginResult))
      history.push('/home')
    } else {
      throw new Error('401')
    }
  } catch (error) {
    const { message = '' } = error
    if (message === '401') {
      yield put(loginUserFailed({ message: 'Incorrect username or password.' }))
    } else {
      history.push('/error')
    }
  }
}

function * logoutUser ({ payload: { history } }) {
  try {
    yield api.logout()
    yield put({ type: 'RESET-REDUX' })
    history.push('/sign-in')
  } catch (error) {
    yield put(loginUserFailed(error))
  }
}

export function * watchUserLogin () {
  yield takeEvery(LOGIN_USER, loginUser)
}

export function * watchUserLogout () {
  yield takeEvery(LOGOUT_USER, logoutUser)
}

function * authSaga () {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout)
  ])
}

export default authSaga

import React from 'react'
import { Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap'
import FinancesForm from '../financesForm/financesForm'

const FinancesModal = ({ isOpen, setOpen, onSubmit, mode = 'full', readOnly = false }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <Modal isOpen={isOpen} role='dialog' autoFocus centered className='financesModal' tabIndex='-1' toggle={onClose} size='lg'>
      <div className='modal-content'>
        <ModalHeader toggle={onClose}>
          Finances
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm='12'>
              <FinancesForm onSubmit={onSubmit} mode={mode} readOnly={readOnly} />
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default FinancesModal

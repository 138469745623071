// @flow
import {
  SET_NEW_OPPORTUNITY
} from './actionTypes'

const INIT_STATE = {
  customer: '',
  stakeholder: '',
  project: ''
}

const NewOpportunity = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_NEW_OPPORTUNITY:
      return {
        ...state,
        customer: action.payload.customer,
        stakeholder: action.payload.stakeholder,
        project: action.payload.project
      }

    default:
      return state
  }
}

export default NewOpportunity

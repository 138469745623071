import React from 'react'
import FullCalendarControl from './fullCalendar'
import { Card, CardBody, Row, Col, CardTitle } from 'reactstrap'
import styled from 'styled-components'
import InfoMarker from '../infoMarker/infoMarker'

const StyledHeading = styled.div`
  padding-bottom: 20px;
  margin: 0 2px;
  display: flex;
  justify-content: space-between;
`

const FullCalendarCard = () => {
  return (
    <>
      <Card className='overflow-hidden' style={{ height: '100%' }}>
        <div className='aquaVerticalGradient' style={{ height: '8px' }} />
        <CardBody className='flex-card'>
          <Row>
            <Col sm='12'>
              <StyledHeading>
                <CardTitle className='mb-2'>
                  Calendar
                  <InfoMarker title='Notifications' bodyCopy='To display latest information' name='imNotifications' />
                </CardTitle>
              </StyledHeading>
            </Col>
          </Row>
          <FullCalendarControl />
        </CardBody>
      </Card>
    </>
  )
}

export default FullCalendarCard

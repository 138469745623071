import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ProjectUserAddForm from '../projectUserAddForm/projectUserAddForm'

const ProjectUserAddModal = ({ isOpen, setOpen }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const onAdd = () => {
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='md'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Add User To Project
          </ModalHeader>
          <ModalBody>
            <ProjectUserAddForm onAddUser={onAdd} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default ProjectUserAddModal

import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'reactstrap'
import { AvField, AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import FileUpload from '../fileUpload/fileUpload'
import _ from 'lodash'

const StyledCard = styled.div`
  padding: 5px 0;
`

const KmdbItemForm = ({ onSubmit, kmdb = {}, onSave, project }) => {
  const {
    id = '',
    provider = '',
    keyInformation = '',
    keyHandoverTasks = '',
    // document = -1,
    deliverableId = -1,
    milestoneId = -1,
    files = []
  } = kmdb

  const readOnly = false

  const projectName = _.get(project, 'name')
  const statementOfWorks = _.get(project, 'solution.statementOfWorks', [])
  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const deliverables = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.deliverables || []))
  const milestones = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.milestones || []))

  const editingExisting = (id !== '')

  let existingFile = {}
  if (files.length > 0) {
    existingFile = files[0]
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      values.files = [values.fileId]
      delete values.projectName

      onSave(values)
    }
  }

  return (
    <>
      <AvForm className='validation-required' onSubmit={handleSubmit}>
        {
          editingExisting && (
            <>
              <AvInput type='hidden' name='id' value={id} />
            </>
          )
        }
        <StyledCard>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Consultant / Supplier / 3rd Party
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Consultant / Supplier / 3rd Party' bodyCopy='The name (person) or organisation providing the KM information' id='imConsultant' />
            </Col>
            <Col sm='9'>
              <AvField
                name='provider'
                placeholder='Enter consultant name'
                type='text'
                required
                value={provider}
                disabled={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Project / Service Name
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Project / Service Name' bodyCopy='What project or service this relates to as defined during the SOW Service design process' id='imProjectName' />
            </Col>
            <Col sm='9'>
              <AvField
                name='projectName'
                placeholder='Enter trading name'
                type='text'
                required
                disabled
                value={projectName}
              />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Deliverable
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Deliverable' bodyCopy='Select from the list the relevant Deliverable or Milestone that this information relates to.' id='imDeliverable' />
            </Col>
            <Col sm='9'>
              <AvField type='select' name='deliverableId' value={deliverableId} valueParser={window.parseInt} disabled={readOnly}>
                <option disabled value='-1'>Select Deliverable</option>
                {
                  deliverables.map((d) => (
                    <option key={d.id} value={d.id}>{d.name}</option>
                  ))
                }
              </AvField>
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Milestone
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Milestone' bodyCopy='Select from the list the relevant Deliverable or Milestone that this information relates to.' id='imMilestone' />
            </Col>
            <Col sm='9'>
              <AvField type='select' name='milestoneId' value={milestoneId} valueParser={window.parseInt} disabled={readOnly}>
                <option disabled value='-1'>Select Milestone</option>
                {
                  milestones.map((m) => (
                    <option key={m.id} value={m.id}>{m.name}</option>
                  ))
                }
              </AvField>
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Key Information
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Key Information' bodyCopy='What is the key information that needs to be recorded, detailing why this is required' id='imKeyInformation' />
            </Col>
            <Col sm='9'>
              <AvGroup>
                <AvInput
                  className='form-control extendedInput'
                  type='textarea'
                  name='keyInformation'
                  placeholder='Enter key information'
                  required
                  value={keyInformation}
                  disabled={readOnly}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Documents / Process Location
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Documents / Process Location' bodyCopy='' id='' />
            </Col>
            <Col sm='9'>
              <FileUpload formName='fileId' existingFile={existingFile} />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                Key Handover Tasks
              </div>
            </Col>
            <Col sm='1' className='pt-1 text-right pr-0'>
              <InfoMarker title='Key Handover Tasks' bodyCopy='What is the key handover tasks another party would need to complete relating to this entry.' id='imHandoverTasks' />
            </Col>
            <Col sm='9'>
              <AvGroup>
                <AvInput
                  className='form-control extendedInput'
                  type='textarea'
                  name='keyHandoverTasks'
                  placeholder='Enter key handover tasks'
                  required
                  value={keyHandoverTasks}
                  disabled={readOnly}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='float-right' title={(editingExisting ? 'Update' : 'Save & Close')} type='submit' />
            </Col>
          </Row>
        </StyledCard>
      </AvForm>
    </>
  )
}

export default KmdbItemForm

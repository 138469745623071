import React from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import ProjectUsersForm from '../projectUsersForm/projectUsersForm'

const ProjectUsersCard = ({ projectUsers = [{ name: 'Sam Foot', email: 'sam@apptimise.co.uk', role: 'Consultant' }] }) => {
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                SOW Service
                <InfoMarker title='SOW Service Users' bodyCopy='Select the users you wish to have access to this SOW Service and the role they will play' />
              </CardTitle>
            </Col>
          </Row>
          <ProjectUsersForm projectUsers={projectUsers} />
        </CardBody>
      </Card>
    </>
  )
}

export default ProjectUsersCard

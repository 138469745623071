import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

const ProfileMenu = ({ onLogout, name }) => {
  const [menu, setMenu] = useState(false)

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
        <DropdownToggle className='btn header-item waves-effect' id='page-header-user-dropdown' tag='button'>
          <span className='d-none d-xl-inline-block ml-2 mr-1'>{name}</span>
          <i className='mdi mdi-chevron-down d-none d-xl-inline-block' />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={Link} to='/profile' className='dropdown-item'>
            <i className='bx bx-user font-size-16 align-middle mr-2' />
            <span>Profile</span>
          </DropdownItem>
          <div className='dropdown-divider' />
          <DropdownItem onClick={onLogout} className='dropdown-item'>
            <i className='bx bx-power-off font-size-16 align-middle mr-2 text-danger' />
            <span>Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default ProfileMenu

import React from 'react'
import { Table } from 'reactstrap'
import moment from 'moment'

const ServiceReportReviewTable = ({ serviceReports }) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>ID</th>
            <th style={{ width: '10%' }}>Date</th>
            <th style={{ width: '20%' }}>Project Plan</th>
            <th style={{ width: '20%' }}>RAID Log</th>
            <th style={{ width: '20%' }}>Financial Information</th>
            <th style={{ width: '20%' }}>Other</th>
          </tr>
        </thead>
        <tbody>
          {
            serviceReports.map((serviceReport, idx) => (
              <tr key={idx}>
                <td>{serviceReport.slug}</td>
                <td>{moment(serviceReport.createdAt).format('DD/MM/YYYY')}</td>
                <td>{serviceReport.projectPlan}</td>
                <td>{serviceReport.raidLog}</td>
                <td>{serviceReport.financialInformation}</td>
                <td>{serviceReport.other}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  )
}

export default ServiceReportReviewTable

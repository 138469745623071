import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import EngagementDesignForm from './engagementDesignForm'

const EngagementDesign = ({ project, onEngagmentDesignSaved, onBackToSOWs, readOnly = false }) => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            Delivery Design
            <InfoMarker title='Delivery Design' bodyCopy='In this section we will define the level of Service Delivery and Management to provide to the Statement of Work (SOW) Service. These activities provide the governance, assurance and compliance across the SOW Service to ensure projects and outcomes stay on track.' id='imDeliveryDesign' />
          </CardTitle>
          <EngagementDesignForm readOnly={readOnly} project={project} onEngagmentDesignSaved={onEngagmentDesignSaved} onBackToSOWs={onBackToSOWs} />
        </CardBody>
      </Card>
    </>
  )
}

export default EngagementDesign

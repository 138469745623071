import React from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'

const ProjectInfo = ({ solution = {} }) => {
  const { name = '', slug = '', projectProcurer = { name: '' } } = solution
  return (
    <Card className='overflow-hidden card-100'>
      <CardBody>
        <AvForm className='validation-required'>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-3'>
                Service Info
                <InfoMarker title='Project Info' bodyCopy='Details of the Project / Service will be auto populated here based on the Filter/Search criteria chosen displaying Customer Name, Unique ID and Project Name' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='3'>
              <div className='styledTitle'>
                Customer
              </div>
            </Col>
            <Col sm='9'>
              <AvField
                name='customer'
                type='text'
                className='form-control'
                value={projectProcurer.name}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm='3'>
              <div className='styledTitle'>
                Service Name
              </div>
            </Col>
            <Col sm='9'>
              <AvField
                name='projectName'
                type='text'
                className='form-control'
                value={name}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm='3'>
              <div className='styledTitle'>
                Unique ID
              </div>
            </Col>
            <Col sm='9'>
              <AvField
                name='uniqueId'
                type='text'
                className='form-control mb-0'
                value={slug}
                disabled
              />
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card>
  )
}

export default ProjectInfo

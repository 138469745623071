import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

type PropsType = RouteComponentProps<{}> & {
  component: any,
  layout: any,
  path: string,
  exact: boolean
}

const NonAuthmiddleware = ({ component: Component, layout: Layout, path, exact }: PropsType): any => (
  <Route
    path={path}
    exact={exact}
    render={props => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

export default withRouter(NonAuthmiddleware)

import React from 'react'
import { Row, Col } from 'reactstrap'

import { useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import SowDesign from '../../components/sowDesign/SowDesign'
import SolutionOverview from '../../components/solutionOverview/solutionOverview'
import ProposalDesign from '../../components/proposalDesign/proposalDesign'
import FinancialDesign from '../../components/financialDesign/financialDesign'
import LegalDesign from '../../components/legalDesign/legalDesign'
import SolutionSuppliers from '../../components/solutionSuppliers/solutionSuppliers'
import EngagementDesign from '../../components/engagementDesign/engagementDesign'
import CustomerOnboard from '../../components/customerOnboard/customerOnboard'
import SolutionSignoffRecruiter from '../../components/solutionSignoffRecruiter/solutionSignoffRecruiter'

import { putSolutionSuppliers, updateSolution } from '../../store/project/actions'
import { postSupplier } from '../../store/supplier/actions'

function randomString (length, chars) {
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

const RecruiterSolution = () => {
  const dispatch = useDispatch()
  const { solutionId } = useParams()
  const location = useLocation()

  const projects = useSelector((state) => state.Project.projects)
  const project = projects.find(s => s.id === Number(solutionId))
  const suppliers = useSelector((state) => state.Supplier.suppliers)

  let { solution } = project

  if (location.pathname === '/solution/new') {
    solution = {
      uniqueId: 'PR-' + randomString(5, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'),
      numberOfSuppliers: 0,
      suppliers: []
    }
  }

  const proposalDesignEnabled = () => {
    const newSolution = { ...solution }
    newSolution.proposalRequired = true

    dispatch(updateSolution({ solution: newSolution }))
  }

  const sowAdded = (sow) => {
    const newSolution = { ...solution }
    if (newSolution.sows === undefined) {
      newSolution.sows = [sow]
    } else {
      newSolution.sows = [...newSolution.sows, sow]
    }
    dispatch(updateSolution({ solution: newSolution }))
  }

  const onSupplierAdded = (supplier) => {
    supplier.rate = supplier.rate + ''
    dispatch(postSupplier(supplier))
  }

  const onSolutionSuppliersSaved = (suppliers) => {
    dispatch(putSolutionSuppliers(solution, suppliers))
  }

  return (
    <>
      <Row>
        <Col sm='6'>
          <SolutionOverview salesMode={false} project={project} />
          <SolutionSuppliers
            solution={project}
            existingSuppliers={suppliers}
            onSupplierAdded={onSupplierAdded}
            onSolutionSuppliersSaved={onSolutionSuppliersSaved}
          />
          <FinancialDesign />
          <LegalDesign solution={project} />
        </Col>
        <Col sm='6'>
          <CustomerOnboard solution={project} />
          <ProposalDesign solution={project} onEnableProposalDesign={proposalDesignEnabled} />
          <SowDesign solution={project} onSowAdded={sowAdded} />
          <EngagementDesign />
          <SolutionSignoffRecruiter />
        </Col>
      </Row>
    </>
  )
}

export default RecruiterSolution

import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_RESET,
  FORGET_PASSWORD_RESET_SUBMIT_SUCCESS,
  FORGET_PASSWORD_RESET_SUBMIT_ERROR
} from './actionTypes'

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  forgetResetSuccessMsg: null,
  forgetResetErrorMsg: null
}

const forgetPassword = (state = initialState, action: any): any => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload }
      break
    case FORGET_PASSWORD_RESET_SUBMIT_SUCCESS:
      state = {
        ...state,
        forgetResetSuccessMsg: action.payload
      }
      break
    case FORGET_PASSWORD_RESET_SUBMIT_ERROR:
      state = { ...state, forgetResetErrorMsg: action.payload }
      break
    case FORGET_PASSWORD_RESET:
      state = initialState
      break
    default:
      return state
  }
  return state
}

export default forgetPassword

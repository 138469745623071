import { ADD_MESSAGE, ADD_MESSAGES, CLEAR_MESSAGES, UPDATE_MESSAGE, DELETE_MESSAGE, OPEN_MESSAGE } from './actionTypes'
import produce from 'immer'

const initState = {
  messages: []
}

const Message = (state = initState, action) => {
  switch (action.type) {
    case ADD_MESSAGE: {
      const nextState = produce(state, draftState => {
        draftState.messages.push(action.payload.message)
      })
      return nextState
    }
    case ADD_MESSAGES: {
      const nextState = produce(state, draftState => {
        draftState.messages.push(...action.payload.messages)
      })
      return nextState
    }
    case CLEAR_MESSAGES: {
      const nextState = produce(state, draftState => {
        draftState.messages = []
      })
      return nextState
    }
    case UPDATE_MESSAGE: {
      const { message } = action.payload
      const foundIndex = state.messages.findIndex(x => x.id === message.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.messages[foundIndex] = message
        })
        return nextState
      }
      return state
    }
    case DELETE_MESSAGE: {
      const { message } = action.payload
      const foundIndex = state.messages.findIndex(x => x.id === message.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.messages.splice(foundIndex, 1)
        })
        return nextState
      }
      return state
    }
    case OPEN_MESSAGE: {
      return state
    }
    default:
      return state
  }
}

export default Message

import { takeEvery, fork, put, all } from 'redux-saga/effects'

import {
  GET_CUSTOMERS,
  POST_CUSTOMER,
  PUT_CUSTOMER,
  DEL_CUSTOMER
} from './actionTypes'

import { addCustomers, addCustomer, deleteCustomer, clearCustomers } from './actions'

import api from '../../helpers/api'
import errorService from '../../helpers/errorService'

function * getCustomers () {
  try {
    const client = yield api.getClient()
    const { status = -1, body = {} } = yield client.apis.Customer.get_api_Customer()
    if (status === 200) {
      yield put(clearCustomers())
      yield put(addCustomers(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postCustomer ({ payload: { customer } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Customer.post_api_Customer({}, { requestBody: customer })
    if (status === 200) {
      yield put(addCustomer(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * delCustomer ({ payload: { customer } }) {
  try {
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Customer.delete_api_Customer__id_({ id: customer.id })
    if (status === 200) {
      yield put(deleteCustomer(customer))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * putCustomer ({ payload: { customer } }) {
  try {
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Customer.put_api_Customer__id_({ id: customer.id }, { requestBody: customer })
    if (status === 200) {
      yield put(deleteCustomer(customer))
      yield put(addCustomer(customer))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchGetCustomers () {
  yield takeEvery(GET_CUSTOMERS, getCustomers)
}

export function * watchPostCustomer () {
  yield takeEvery(POST_CUSTOMER, postCustomer)
}

export function * watchPutCustomer () {
  yield takeEvery(PUT_CUSTOMER, putCustomer)
}

export function * watchDelCustomers () {
  yield takeEvery(DEL_CUSTOMER, delCustomer)
}

function * customerSaga () {
  yield all([
    fork(watchGetCustomers),
    fork(watchPostCustomer),
    fork(watchPutCustomer),
    fork(watchDelCustomers)
  ])
}

export default customerSaga

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { AvField, AvInput } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasTextArea from '../sowaasTextArea/SowaasTextArea'
import SowaasButton from '../sowaasButton/sowaasButton'

const ProposalItem = ({ identifier, name, labelValue, value, infoCopy, readOnly, proposalRequired, hidden }) => {
  useEffect(() => {
    setHiddenValue(hidden)
  }, [hidden])

  const [hiddenValue, setHiddenValue] = useState(hidden)

  const rowHidden = (readOnly && hidden)

  if (rowHidden) {
    return null
  }

  return (
    <Row>
      <Col sm='2'>
        <div className='styledTitle'>
          <AvField type='text' name={`${identifier}Label`} value={labelValue} disabled={(readOnly || hiddenValue)} />
        </div>
        {
          hiddenValue && (
            <SowaasButton className='mb-5' title='Show' onClick={() => { setHiddenValue(!hiddenValue) }} />
          )
        }
        {
          !hiddenValue && (
            <SowaasButton title='Hide' onClick={() => { setHiddenValue(!hiddenValue) }} />
          )
        }
        <AvInput type='hidden' name={`${identifier}Hidden`} value={hiddenValue} />
      </Col>
      {
          !hiddenValue && (
            <>
              <Col sm='1' className='pt-1 text-right pr-0'>
                <InfoMarker title={name} bodyCopy={infoCopy} id={`im${identifier}`} />
              </Col>
              <Col sm='9' className='form-group'>
                <SowaasTextArea
                  value={value}
                  placeholder={proposalRequired ? `Enter ${name}` : ''}
                  name={identifier}
                  disabled={readOnly}
                />
              </Col>
            </>
          )
        }
    </Row>
  )
}

export default ProposalItem

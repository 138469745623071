import ClientOAuth2 from 'client-oauth2'
import SwaggerClient from 'swagger-client'
import jwtDecode from 'jwt-decode'
import swaggerSpec from './JSON/swagger.json'
import hubs from './hubs'
import errorService from './errorService'
import mockApi from './mockApi'

const api = {
  isLoggedIn: false,
  loggedInUser: undefined,
  client: undefined,
  unauthenticatedClient: undefined,
  login: async (username, password) => {
    const perficioAuth = new ClientOAuth2({
      clientId: 'Apptimise.Perficio.WebApi',
      clientSecret: '',
      accessTokenUri: `${process.env.REACT_APP_API_URL}connect/token`,
      authorizationUri: `${process.env.REACT_APP_API_URL}connect/authorize`,
      scopes: ['offline_access', 'profile', 'openid', 'Apptimise.Perficio.WebApiAPI']
    })
    try {
      const user = await perficioAuth.owner.getToken(username, password)
      return await api.setupUser(user.data)
    } catch (ex) {
      errorService.logError(ex)
      return false
    }
  },
  logout: async () => {
    window.localStorage.removeItem('SOWaaSAppUser')
    api.isLoggedIn = false
    await hubs.closeHubs()
    api.loggedInUser = undefined
    api.client = undefined
  },
  loggedIn: async () => {
    const currentlyLoggedIn = (api.isLoggedIn && api.user && api.client)
    if (currentlyLoggedIn) {
      return currentlyLoggedIn
    } else {
      const restoreResult = await api.restoreUser()
      return restoreResult
    }
  },
  setupUser: async (user) => {
    try {
      api.isLoggedIn = true
      api.user = user
      api.client = await new SwaggerClient({
        url: process.env.REACT_APP_API_URL,
        spec: swaggerSpec,
        authorizations: { oauth2: { token: { access_token: user.access_token } } },
        requestInterceptor:
          (request) => {
            if (request.method === 'PATCH') {
              request.headers['Content-Type'] = 'application/merge-patch+json'
            }
            request.credentials = 'include'
            return request
          }
      })

      window.localStorage.setItem('SOWaaSAppUser', JSON.stringify(user))

      const tokenContents = jwtDecode(user.access_token)

      const appUserResponse = await api.client.apis.ApplicationUser.get_api_ApplicationUser__id_({ id: tokenContents.sub })

      if (appUserResponse.status === 200) {
        const { organisationUsers = [] } = appUserResponse.body

        if (organisationUsers.length === 0) return false

        const selectedOrganisationUser = organisationUsers[0]

        await api.setCurrentOrganisation(selectedOrganisationUser.organisationId)

        appUserResponse.body.selectedOrganisationUser = selectedOrganisationUser

        return appUserResponse.body
      } else {
        return false
      }
    } catch (ex) {
      errorService.logError(ex)
      api.isLoggedIn = false
      api.user = undefined
      api.client = undefined
      return false
    }
  },
  setupUnauthenticatedClient: async () => {
    api.unauthenticatedClient = await new SwaggerClient({
      url: process.env.REACT_APP_API_URL,
      spec: swaggerSpec
    })
  },
  restoreUser: async () => {
    const localStorageUser = window.localStorage.getItem('SOWaaSAppUser')
    try {
      const sowaasAppUser = JSON.parse(localStorageUser)
      if (sowaasAppUser) {
        const setupResult = await api.setupUser(sowaasAppUser)
        return (setupResult !== false)
      }
      return false
    } catch (ex) {
      errorService.logError(ex)
      return false
    }
  },
  setCurrentOrganisation: async (organisationId) => {
    const client = await api.getClient()
    if (client) {
      await client.apis.Organisation.post_api_Organisation_current({}, { requestBody: { id: organisationId } })
    }
    return false
  },
  getClient: async () => {
    if (api.isLoggedIn && api.user && api.client) {
      hubs.setupHubs(api.user.access_token, 1) // need ORG before this
      return api.client
    } else {
      const restoreResult = await api.restoreUser()
      if (restoreResult) {
        hubs.setupHubs(restoreResult.access_token, 1) // need ORG before this
        return api.client
      }
    }
    return false
  }
}

export default mockApi

import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

const StyledCard = styled.div`
  padding: 5px 0;
`

const FinancialInfo = () => {
  const [opportunityValue, setOpportunityValue] = useState('')
  const [grossProfit, setGrossProfit] = useState('')
  const [grossMargin, setGrossMargin] = useState('')
  const [commercial, setCommercial] = useState('')
  const [contractType, setContractType] = useState('')

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Financial Information
              </CardTitle>
            </Col>
          </Row>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Opportunity Value:
                </div>
              </Col>
              <Col sm='8'>
                <input
                  className='form-control'
                  type='number'
                  placeholder='Enter value'
                  onChange={(event) => setOpportunityValue(event.target.value)}
                  value={opportunityValue}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Gross Profit:
                </div>
              </Col>
              <Col sm='8'>
                <input
                  className='form-control'
                  type='number'
                  placeholder='Enter value'
                  onChange={(event) => setGrossProfit(event.target.value)}
                  value={grossProfit}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Gross Margin:
                </div>
              </Col>
              <Col sm='8'>
                <input
                  className='form-control'
                  type='number'
                  placeholder='Enter percentage'
                  onChange={(event) => setGrossMargin(event.target.value)}
                  value={grossMargin}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                Commercial Type:
              </Col>
              <Col sm='8'>
                <div className='btn-group btn-group-toggle' data-toggle='buttons'>
                  <label
                    className={
                      commercial === 'temp'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='temp'
                      onClick={() => { setCommercial('temp') }}
                    />{' '}
                    Temp
                  </label>

                  <label
                    className={
                      commercial === 'fixed'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='fixed'
                      onClick={() => { setCommercial('fixed') }}
                    />{' '}
                    Fixed
                  </label>

                  <label
                    className={
                      commercial === 'capped'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='capped'
                      onClick={() => { setCommercial('capped') }}
                    />{' '}
                    Capped Temp
                  </label>
                  <label
                    className={
                      commercial === 'other'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='other'
                      onClick={() => { setCommercial('other') }}
                    />
                    {' '}
                    Other
                  </label>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                Contract Type Required:
              </Col>
              <Col sm='8'>
                <div className='btn-group btn-group-toggle' data-toggle='buttons'>
                  <label
                    className={
                      contractType === 'msa'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='msa'
                      onClick={() => { setContractType('msa') }}
                    />{' '}
                    MSA
                  </label>

                  <label
                    className={
                      contractType === 'psa'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='psa'
                      onClick={() => { setContractType('psa') }}
                    />{' '}
                    PSA
                  </label>

                  <label
                    className={
                      contractType === 'sow'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='sow'
                      onClick={() => { setContractType('sow') }}
                    />{' '}
                    SOW
                  </label>

                  <label
                    className={
                      contractType === 'nda'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='nda'
                      onClick={() => { setContractType('nda') }}
                    />{' '}
                    NDA
                  </label>
                </div>
              </Col>
            </Row>
          </StyledCard>
        </CardBody>
      </Card>
    </>
  )
}

export default FinancialInfo

import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import _ from 'lodash'

const scoringTable = [
  { value: 1, title: 'Negligible' },
  { value: 3, title: 'Low' },
  { value: 5, title: 'Moderate' },
  { value: 7, title: 'High' },
  { value: 10, title: 'Critical' }
]

const ImpactEntryFields = ({ entry, readOnly = false }) => {
  const {
    impact = 'Negligible',
    priority = 'Negligible',
    mitigationPlan = '',
    owner = ''
  } = entry

  const [priorityValue, setPriorityValue] = useState(priority)
  const [impactValue, setImpactValue] = useState(impact)

  const severityValue = _.find(scoringTable, { title: priorityValue }).value * _.find(scoringTable, { title: impactValue }).value

  let severityLevel = ''
  if (severityValue <= 15) {
    severityLevel = 'Negligible'
  } else if (severityValue >= 16 && severityValue <= 35) {
    severityLevel = 'Low'
  } else if (severityValue >= 36 && severityValue <= 55) {
    severityLevel = 'Moderate'
  } else if (severityValue >= 56 && severityValue <= 75) {
    severityLevel = 'High'
  } else if (severityValue >= 76) {
    severityLevel = 'Critical'
  }

  return (
    <>
      <Row>
        <Col>
          <div className='styledTitle'>
            Impact
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Impact' bodyCopy={<>Select the level this Impact will have by choosing a score from: <br /><br />1 = Negligible<br />3 = Low<br />5 = Moderate<br />7 = High<br />10 = Critical</>} id='imPriority' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} type='select' name='impact' onChange={(e) => { setImpactValue(e.target.value) }} value={impact}>
            <option value='Negligible'>1 = Negligible</option>
            <option value='Low'>3 = Low</option>
            <option value='Moderate'>5 = Moderate</option>
            <option value='High'>7 = High</option>
            <option value='Critical'>10 = Critical</option>
          </AvField>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Priority
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Priority' bodyCopy={<>Select the priority this Impact will have by choosing a score from: <br /><br />1 = Negligible<br />3 = Low<br />5 = Moderate<br />7 = High<br />10 = Critical</>} id='imPriority' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} type='select' name='priority' onChange={(e) => { setPriorityValue(e.target.value) }} value={priority}>
            <option value='Negligible'>1 = Negligible</option>
            <option value='Low'>3 = Low</option>
            <option value='Moderate'>5 = Moderate</option>
            <option value='High'>7 = High</option>
            <option value='Critical'>10 = Critical</option>
          </AvField>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Severity
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Severity' bodyCopy='This will be auto generated by the system, based on the likelihood and the impact using a multiplying effect' id='imSeverity' />
        </Col>
        <Col sm='9'>
          <AvField name='severity' value={`${severityLevel}`} disabled className={`background${severityLevel}`} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Mitigation Plan
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Mitigation Plan' bodyCopy='How do you intend to overcome (mitigate) this Impact from occuring' id='imMitigationPlan' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='mitigationPlan' value={mitigationPlan} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Owner
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Owner' bodyCopy='Detail who the owner of this particular impact will be - it might not be the person logging the impact' id='imOwner' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='owner' value={owner} />
        </Col>
      </Row>
    </>
  )
}

export default ImpactEntryFields

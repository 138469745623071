import React from 'react'
import SowaasButton from '../sowaasButton/sowaasButton'

const SowaasButtonImpace = ({ id = '', className = '', title = '', onClick = () => {}, icon = '', to = '' }) => {
  return (
    <SowaasButton id={id} className={className} title={title} onClick={onClick} backgroundColor='purpleHorizontalGradientInverted' icon={icon} to={to} />
  )
}

export default SowaasButtonImpace

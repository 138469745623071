import _ from 'lodash'
import React, { useState } from 'react'
import { Col, Row, Input } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import CommonRaidEntryFields from './commonRaidEntryFields'
import RiskEntryFields from './riskEntryFields'
import AssumptionEntryFields from './assumptionEntryFields'
import ImpactEntryFields from './impactEntryFields'
import DependencyEntryFields from './dependencyEntryFields'

const RaidForm = ({ children, handleSubmit, category, entry, readOnly = false }) => {
  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      <div>
        <CommonRaidEntryFields category={category} entry={entry} readOnly={readOnly} />
        {children}
        {
          (readOnly === false) && (
            <Row>
              <Col sm='12'>
                <SowaasButton title={`Save ${'&'} Close`} className='float-right' type='submit' />
              </Col>
            </Row>
          )
        }
      </div>
    </AvForm>
  )
}

const RaidEntryForm = ({ raidCategories = [], onSave, entry = {}, readOnly = false }) => {
  const {
    id = '',
    categoryId = 1
  } = entry

  const intialCategory = _.find(raidCategories, { id: Number(categoryId) })
  const [category, setCategory] = useState(intialCategory)

  const categoryChanged = (id) => {
    const category = _.find(raidCategories, { id: Number(id) })
    if (category !== undefined) {
      setCategory(category)
    }
  }

  const handleSubmit = (e, errors, values) => {
    if (errors.length === 0) {
      onSave(values)
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div className='styledTitle'>
            RAID Category
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='RAID Category' bodyCopy={<>Select from:<br /><br />Risk = A specific event with positive/negative impact and likelihood of occuring - can turn into an issue<br /><br />Assumption = Something we define as true in order to proceed with the project<br /><br />Issues = Any issue that arises during the project which needs to be handled to ensure success<br /><br />Dependency = The output of one activity having a direct impact on the input of another activity</>} id='imRAIDCategory' />
        </Col>
        <Col sm='9' className='form-group'>
          <Input disabled={(id !== '' || readOnly)} defaultValue={category.id} type='select' name='categoryId' onChange={(e) => { categoryChanged(e.target.value) }} valueParser={window.parseInt}>
            {
              raidCategories.map((raidCategory, idx) => (
                <option key={idx} value={raidCategory.id}>{raidCategory.name}</option>
              ))
            }
          </Input>
        </Col>
      </Row>
      {
        category.name === 'Risk' && (
          <RaidForm category={category} entry={entry} handleSubmit={handleSubmit} readOnly={readOnly}>
            <RiskEntryFields entry={entry} readOnly={readOnly} />
          </RaidForm>
        )
      }
      {
        category.name === 'Assumption' && (
          <RaidForm category={category} entry={entry} handleSubmit={handleSubmit} readOnly={readOnly}>
            <AssumptionEntryFields entry={entry} readOnly={readOnly} />
          </RaidForm>
        )
      }
      {
        category.name === 'Impact' && (
          <RaidForm category={category} entry={entry} handleSubmit={handleSubmit} readOnly={readOnly}>
            <ImpactEntryFields entry={entry} readOnly={readOnly} />
          </RaidForm>
        )
      }
      {
        category.name === 'Dependency' && (
          <RaidForm category={category} entry={entry} handleSubmit={handleSubmit} readOnly={readOnly}>
            <DependencyEntryFields entry={entry} readOnly={readOnly} />
          </RaidForm>
        )
      }
    </>
  )
}

export default RaidEntryForm

import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Card, CardBody, CardTitle, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from 'reactstrap'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ExtendedInput = styled.textarea`
  height: 100px;
  wrap: hard;
`

const OpportunityInfo = ({ stageUpdated }) => {
  const [name, setName] = useState('')
  const [requirements, setRequirements] = useState('')
  const [button, setButton] = useState(false)
  const [source, setSource] = useState('')
  const [stage, setStage] = useState('Project selection')
  const [nextSteps, setNextSteps] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState('')

  const onStageChanged = (stage) => {
    stageUpdated(stage)
    setStage(stage)
  }

  const dropdownOptions = [
    'Project selection',
    'Identify',
    'Qualify',
    'Develop',
    'Solution',
    'Propose',
    'Win/Pend'
  ]

  const DropdownSelector = () => {
    return (
      <ButtonDropdown
        isOpen={button}
        direction='right'
        toggle={() => setButton(!button)}
      >
        <DropdownToggle
          caret
          className='btn btn-secondary'
        >
          {stage} <i className='mdi mdi-chevron-down' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-right-custom'>
          {dropdownOptions.map((option, idx) => (
            <DropdownItem key={idx} onClick={() => onStageChanged(option)}>{option}</DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    )
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Opportunity Information
              </CardTitle>
            </Col>
          </Row>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Name:
                </div>
              </Col>
              <Col sm='8'>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Enter name'
                  onChange={(event) => setName(event.target.value)}
                  value={name}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Requirements:
                </div>
              </Col>
              <Col sm='8'>
                <ExtendedInput
                  className='form-control'
                  type='text'
                  placeholder='Enter description'
                  onChange={(event) => setRequirements(event.target.value)}
                  value={requirements}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Stage:
                </div>
              </Col>
              <Col sm='8'>
                <DropdownSelector />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Source:
                </div>
              </Col>
              <Col sm='8'>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Enter source'
                  onChange={(event) => setSource(event.target.value)}
                  value={source}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Next Steps:
                </div>
              </Col>
              <Col sm='8'>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Enter description'
                  onChange={(event) => setNextSteps(event.target.value)}
                  value={nextSteps}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Additional Info:
                </div>
              </Col>
              <Col sm='8'>
                <ExtendedInput
                  className='form-control'
                  type='text'
                  placeholder='Enter description'
                  onChange={(event) => setAdditionalInfo(event.target.value)}
                  value={additionalInfo}
                />
              </Col>
            </Row>
          </StyledCard>
        </CardBody>
      </Card>
    </>
  )
}

export default OpportunityInfo

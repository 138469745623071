import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/images/logo-dark-text.png'
import { Container, Row, Col } from 'reactstrap'
import SowaasButton from '../../components/sowaasButton/sowaasButton'

const StyledLogo = styled.img`
  width: 300px;
  margin: 0 50px;
`
const title = 'There\'s been an issue, sorry!'
const desc = 'Unfortunately SOWaaS has encountered an issue, we are sorry for any inconvinience caused. Our Technical team has been notified of the issue and will begin investigating shortly. You have been logged out.'

const errorPage = ({ errorTitle = title, errorDescription = desc }) => {
  return (
    <>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center mb-5'>
            <Col sm={6} className='text-center'>
              <StyledLogo src={logo} />
            </Col>
          </Row>
          <Row className='justify-content-center mb-4'>
            <Col sm={6} className='text-center'>
              <h1>{errorTitle}</h1>
            </Col>
          </Row>
          <Row className='justify-content-center mb-4'>
            <Col sm={6} className='text-center'>
              {errorDescription}
            </Col>
          </Row>
          <Row className='justify-content-center mb-4'>
            <Col sm={6} className='text-center'>
              You can try&nbsp;&nbsp;<SowaasButton style={{ display: 'inline-block' }} to='/sign-in' title='Signing In' />&nbsp;&nbsp;again, or reaching out to us for help: <a href='mailto:help@sowaas.com'>help@sowaas.com</a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default errorPage

import { all } from 'redux-saga/effects'

// public
import AccountSaga from './auth/register/saga'
import AuthSaga from './auth/login/saga'
import ForgetSaga from './auth/forgetpwd/saga'
import ProfileSaga from './auth/profile/saga'
import CustomerSaga from './customer/saga'
import SupplierSaga from './supplier/saga'
import ProjectSaga from './project/saga'
import MessageSaga from './message/saga'
import NotificationSaga from './notification/saga'
import EventSaga from './calendar/saga'
import LookupSaga from './lookup/saga'
import UserSaga from './user/saga'
import ActivateSaga from './auth/activate/saga'
import DemoSaga from './demo/saga'

export default function * rootSaga () {
  const mainSagas = [
    // public
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    CustomerSaga(),
    SupplierSaga(),
    ProjectSaga(),
    MessageSaga(),
    NotificationSaga(),
    EventSaga(),
    LookupSaga(),
    UserSaga(),
    ActivateSaga()
  ]

  if (process.env.REACT_APP_DEMO_MODE === 'true') {
    mainSagas.push(DemoSaga())
  }

  yield all(mainSagas)
}

import { ADD_SUPPLIER, ADD_SUPPLIERS, CLEAR_SUPPLIERS, UPDATE_SUPPLIER, DELETE_SUPPLIER } from './actionTypes'
import produce from 'immer'
import _ from 'lodash'

const initState = {
  suppliers: []
}

const Supplier = (state = initState, action) => {
  switch (action.type) {
    case ADD_SUPPLIER: {
      const nextState = produce(state, draftState => {
        draftState.suppliers.push(action.payload.supplier)
      })
      return nextState
    }
    case ADD_SUPPLIERS: {
      const nextState = produce(state, draftState => {
        draftState.suppliers.push(...action.payload.suppliers)
      })
      return nextState
    }
    case CLEAR_SUPPLIERS: {
      const nextState = produce(state, draftState => {
        draftState.suppliers = []
      })
      return nextState
    }
    case UPDATE_SUPPLIER: {
      const { supplier } = action.payload
      const returnSuppliers = state.suppliers.map((s) => {
        if (s.id !== supplier.id) return s
        return _.assign({}, s, supplier)
      })
      return {
        ...state,
        suppliers: returnSuppliers
      }
    }
    case DELETE_SUPPLIER: {
      const { supplier } = action.payload
      const foundIndex = state.suppliers.findIndex(x => x.id === supplier.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.suppliers.splice(foundIndex, 1)
        })
        return nextState
      }
      return state
    }
    default:
      return state
  }
}

export default Supplier

import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import SowaasButtonInteraction from '../sowaasButtonInteraction/sowaasButtonInteraction'
import TimeUtilModal from '../timeUtilModal/timeUtilModal'

const TimeUtilCard = ({ timeUtilsForApproval = [], suppliers = [], project = {}, onSave, onApprove }) => {
  const [newTimeUtilModalOpen, setNewTimeUtilModalOpen] = useState(false)
  const [approveTimeUtilModalOpen, setApproveTimeUtilModalOpen] = useState(false)
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-3'>
                Time Utilisation
                <InfoMarker title='Time Utilisation' bodyCopy='A useful way of tracking activity across the service, Time Utilisation allows you to create or review a Utilisation report based on hours performed on tasks. Suppliers can submit Utilisation reports here for you to approve.' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setNewTimeUtilModalOpen(true) }} title='New Time Utilisation' />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col sm='12'>
              <SowaasButtonInteraction
                disabled={timeUtilsForApproval.length === 0}
                className='btn-block'
                onClick={() => { setApproveTimeUtilModalOpen(timeUtilsForApproval[0]) }}
                title={`Approve Time Utilisation${timeUtilsForApproval.length > 1 ? 's' : ''} (${timeUtilsForApproval.length})`}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {
        (newTimeUtilModalOpen !== false) && (
          <TimeUtilModal isOpen setOpen={setNewTimeUtilModalOpen} mode='add' suppliers={suppliers} project={project} onSave={onSave} />
        )
      }
      {
        (approveTimeUtilModalOpen !== false) && (
          <TimeUtilModal isOpen setOpen={setApproveTimeUtilModalOpen} mode='approve' tu={approveTimeUtilModalOpen} suppliers={suppliers} project={project} onSave={onApprove} />
        )
      }
    </>
  )
}

export default TimeUtilCard

import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer, purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers'
import rootSaga from './sagas'

import errorService from '../helpers/errorService'

const persistConfig = {
  key: 'root',
  storage
  // whitelist: []
}

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    errorService.logError(error)
  }
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedReducer = persistReducer(persistConfig, rootReducer)

const resetEnhancer = persistedReducer => (state, action) => {
  if (action.type !== 'RESET-REDUX') {
    return persistedReducer(state, action)
  }
  purgeStoredState(persistConfig)
  return persistedReducer(undefined, {})
}

const store = createStore(resetEnhancer(persistedReducer), composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export default () => {
  const persistor = persistStore(store)
  return { store, persistor }
}

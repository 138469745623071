import React from 'react'
import styled from 'styled-components'
import { Row, Col, Card, CardBody, CardTitle, Progress } from 'reactstrap'

import opportunityProgress from '../../helpers/opportunityProgress'

const StyledProgress = styled.div`
.progress {
  height: 20px;
}
`

const OpportunityStage = ({ stage }) => {
  const renderProgressBar = (opportunity, idx) => {
    return (
      <StyledProgress className='' key={idx}>
        <Progress multi>
          <Progress bar color='primary' value={opportunity.values.identify}>Identify</Progress>
          <Progress bar color='success' value={opportunity.values.qualify}>Qualify</Progress>
          <Progress bar color='info' value={opportunity.values.develop}>Develop</Progress>
          <Progress bar color='primary' value={opportunity.values.solution}>Solution</Progress>
          <Progress bar color='success' value={opportunity.values.propose}>Propose</Progress>
          <Progress bar color='info' value={opportunity.values.winpend}>Win/Pend</Progress>
        </Progress>
      </StyledProgress>
    )
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Opportunity Stage
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              {opportunityProgress.map((opportunity, idx) => {
                if (opportunity.option === stage) {
                  return renderProgressBar(opportunity, idx)
                }
                return null
              })}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default OpportunityStage

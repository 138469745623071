import React from 'react'
import styled from 'styled-components'

import { Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap'

// Dummy Data
import { getEngagementInformation } from './actions'

const StyledHeading = styled.div`
  padding-bottom: 20px;
`

const StyledTableHeaders = styled.div`
  padding: 0 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: left;
`

const StyledBar = styled(Button)`
  width: 100%;
  margin: 5px 0;
`

const StyledBarContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: left;
`
const PaddedText = styled.div`
  margin-right: 5px;
`

const EngagementSummaryExpanded = ({ history }) => {
  const engagementClicked = (engagement) => {
    history.push(`/engagement/${engagement.id}`)
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xl='12'>
              <Card className='overflow-hidden'>
                <CardBody>
                  <Row>
                    <Col sm='12'>
                      <StyledHeading>
                        <CardTitle className='mb-2'>
                          Engagement Information
                        </CardTitle>
                      </StyledHeading>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <StyledTableHeaders>
                        <PaddedText>
                          Company
                        </PaddedText>
                        <PaddedText>
                          Project
                        </PaddedText>
                        <PaddedText>
                          Progress
                        </PaddedText>
                      </StyledTableHeaders>
                    </Col>
                  </Row>
                  <Row>
                    {getEngagementInformation().map((engagement, idx) => (
                      <Col sm='12' key={idx}>
                        <StyledBar color='secondary' outline className='waves-effect' onClick={() => { engagementClicked(engagement) }}>
                          <StyledBarContent>
                            <PaddedText>
                              {engagement.company}
                            </PaddedText>
                            <PaddedText>
                              {engagement.project}
                            </PaddedText>
                            <PaddedText>
                              {engagement.progress}%
                            </PaddedText>
                          </StyledBarContent>
                        </StyledBar>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default (EngagementSummaryExpanded)

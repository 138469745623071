import React, { useState } from 'react'
import { Row, Col, Table, InputGroup, InputGroupAddon } from 'reactstrap'
import SowaasButton from '../sowaasButton/sowaasButton'
import produce from 'immer'
import GroupToggle from '../groupToggle/groupToggle'
import MoneyInput from '../moneyInput/moneyInput'
import InfoMarker from '../infoMarker/infoMarker'
import _ from 'lodash'
import FinancialCostRow from './financialCostRow'
// import { Decimal } from 'decimal.js'
import { AvGroup, AvInput } from 'availity-reactstrap-validation'

const addtionalCostLabels = [
  { label: 'None', value: false },
  { label: 'Yes', value: true }
]

const FinancialDesignTab = ({ suppliers, costs, phase, readOnly = false, calculations = [] }) => {
  const calculation = _.find(calculations, { phaseId: phase.id })

  const {
    grossMarginRequired = 0,
    serviceManagementCosts = 0,
    additionalCostsRequired = false,
    additionalCostsAmount = 0,
    salesPrice = 0,
    grossProfit = 0
  } = calculation

  const [addtionalCostsLabel, setAdditionalCostsLabel] = useState(additionalCostsRequired)
  const filteredCosts = _.filter(costs, { phaseId: phase.id })
  const [financeData, setFinanceData] = useState(filteredCosts)

  const addRow = () => {
    const nextFinanceData = produce(financeData, draftFinanceData => {
      draftFinanceData.push({ phaseId: phase.id })
    })
    setFinanceData(nextFinanceData)
  }

  const removeRow = (idx) => {
    if (confirm('Are you sure you would like to remove this cost?')) { // eslint-disable-line
      const nextFinanceData = produce(financeData, draftFinanceData => {
        draftFinanceData.splice(idx, 1)
      })
      setFinanceData(nextFinanceData)
    }
  }

  /* const runCalculations = () => {
    // 1 - calculate total costs from phase costs
    const phaseCosts = new Decimal(_.sumBy(costs, (c) => { return c.cost * c.costQuantity }))

    // 2 - calculate total charges from phase costs
    const phaseRev = new Decimal(_.sumBy(costs, (c) => { return c.charge * c.chargeQuantity }))

    // 3 - get additional costs
    const additionalCosts = new Decimal(additionalCostsAmount)

    // 4 - get delivery costs (from engagement design)
    const deliveryCosts = new Decimal(serviceManagementCosts)

    const allCosts = phaseCosts.plus(additionalCosts).plus(deliveryCosts)

    // calculation (2 - (1 + 3 + 4)) - base p&l
    let grossProfit = phaseRev.minus(allCosts)

    const grossProfitMargin = grossProfit.dividedBy(phaseRev).times(new Decimal(100))

    // factor in gm required
    let gmRequired = new Decimal(grossMarginRequired)
    let revenue = ''
    if (gmRequired.greaterThan(new Decimal(0))) {
      const gmFactor = gmRequired.dividedBy(new Decimal(100))
      revenue = grossProfit.div(gmFactor)
    } else {
      gmRequired = ''
    }

    if (revenue !== '') {
      if (!revenue.greaterThanOrEqualTo(0) || !grossProfit.greaterThanOrEqualTo(0)) {
        revenue = new Decimal(0)
        grossProfit = new Decimal(0)
      }
    }

    const relCalcs = {
      cPhaseCosts: phaseCosts.toString(),
      cPhaseRev: phaseRev.toString(),
      cAdditionalCosts: additionalCosts.toString(),
      cDeliveryCosts: deliveryCosts.toString(),
      cGrossProfit: grossProfit.toString(),
      cGrossProfitMargin: grossProfitMargin.toString(),
      cGrossMarginRequired: gmRequired.toString(),
      cRevenue: revenue.toString()
    }
    console.log(relCalcs)
    return relCalcs
  } */

  // const { cGrossProfit, cRevenue, cAdditionalCosts, cDeliveryCosts, cGrossMarginRequired } = runCalculations()

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th colspan='4' style={{ fontSize: '30px' }}>
              <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>
                Costs
                <InfoMarker title='Costs' bodyCopy='Enter the relevant costs from the Supplier based on how they will charge you for this work (per hour, day, week etc.), these costs will be used to calculate the overall Service fees.' />
              </h5>
            </th>
            <th colspan='4'>
              <h5 style={{ fontWeight: 'bold', marginBottom: 0 }}>
                Charges
                <InfoMarker title='Charges' bodyCopy='How much will your Customer pay for each Service element listed below' />
              </h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <th>
            Supplier
            <InfoMarker title='Supplier' bodyCopy='Select from the list below the applicable Supplier/s and their Cost for the relevant Phase (if applicable).' />
          </th>
          <th>
            Supplier Rate
            <InfoMarker title='Supplier Rate' bodyCopy='The relevant rate you are being charged (the cost incurred) by using your 3rd Party Supplier for this activity - e.g. Fee Per Day, Per Phase etc. For Other you can specify what this is i.e. Milestone XYZ or Deliverable ABC' />
          </th>
          <th>
            Type
            <InfoMarker title='Type' bodyCopy='Select the applicable charging type for this Phase as Per Day, Per Phase or Other where you can detail specifics.' />
          </th>
          <th>
            Quantity
            <InfoMarker title='Quantity' bodyCopy='Enter the relevant quantity based on the type of charging you are using i.e. Per Day would be multiples of, Per Phase/Unit would be 1, Per Week/Month would be the number of weeks/months and Other would be 1' />
          </th>
          <th>
            Supplier Rate
            <InfoMarker title='Supplier Rate' bodyCopy='The relevant rate you are being charged (the cost incurred) by using your 3rd Party Supplier for this activity - e.g. Fee Per Day, Per Phase etc. For Other you can specify what this is i.e. Milestone XYZ or Deliverable ABC' />
          </th>
          <th>
            Type
            <InfoMarker title='Type' bodyCopy='Select the applicable charging type for this Phase as Per Day, Per Phase or Other where you can detail specifics.' />
          </th>
          <th>
            Quantity
            <InfoMarker title='Quantity' bodyCopy='Enter the relevant quantity based on the type of charging you are using i.e. Per Day would be multiples of, Per Phase/Unit would be 1, Per Week/Month would be the number of weeks/months and Other would be 1' />
          </th>
          {
            financeData.map((fd, idx) => (
              <FinancialCostRow key={idx} fd={fd} idx={idx} phase={phase} suppliers={suppliers} readOnly={readOnly} removeRow={removeRow} />
            ))
          }
        </tbody>
      </Table>
      {
        (readOnly === false) && (
          <Row>
            <Col sm='12'>
              <SowaasButton disabled={readOnly} className='float-left ml-2' onClick={() => { addRow() }} title='Add Cost' />
            </Col>
          </Row>
        )
      }
      <Row className='mt-4'>
        <Col sm='2'>
          <div className='styledTitle'>
            Gross Margin Required %
          </div>
        </Col>
        <Col sm='1' className='pt-1 text-right'>
          <InfoMarker title='Gross Margin Required %' bodyCopy='If you have a margin position you would like to achieve, enter this here and SOWaaS will calculate the applicable sales rate (Revenue) target. If left blank SOWaaS will calculate the margin based on the Sales Price (Revenue) you submit.' id='imGrossMarginRequired' />
        </Col>
        <Col sm='3' className='form-group'>
          <AvGroup>
            <InputGroup>
              <AvInput
                name='grossMarginRequired'
                type='number'
                placeholder=''
                required
                value={grossMarginRequired}
                disabled={readOnly}
              />
              <InputGroupAddon addonType='append'>
                %
              </InputGroupAddon>
            </InputGroup>
          </AvGroup>
        </Col>
      </Row>
      <Row>
        <Col sm='2'>
          <div className='styledTitle'>
            Service Management/Delivery Costs
          </div>
        </Col>
        <Col sm='1' className='pt-1 text-right'>
          <InfoMarker title='Service Management/Delivery Costs' bodyCopy='Enter the Service management costs. These will be auto populated from the Delivery section (if completed) or you can override if required.' id='imServiceDeliveryCosts' />
        </Col>
        <Col sm='3' className='form-group'>
          <MoneyInput disabled={readOnly} name='serviceManagementCosts' onChange={() => {}} value={serviceManagementCosts} required={false} />
        </Col>
      </Row>
      <Row>
        <Col sm='2'>
          <div className='styledTitle'>
            Additional Costs
          </div>
        </Col>
        <Col sm='1' className='pt-1 text-right'>
          <InfoMarker title='Additional Costs' bodyCopy='If there are likely to be any other costs beyond your Supplier and/or Management costs enter these here, examples could include equipment, software, licencing etc.' id='imAdditionalCosts' />
        </Col>
        <Col sm='6'>
          <Row>
            <Col sm='3'>
              <GroupToggle disabled={readOnly} name='additionalCostsRequired' items={addtionalCostLabels} currentValue={addtionalCostsLabel} onValueChanged={setAdditionalCostsLabel} />
            </Col>
            {
              addtionalCostsLabel === true && (
                <Col sm='5' className='form-group' style={{ display: 'flex' }}>
                  <div className='styledTitle mr-3'>Amount</div>
                  <div style={{ flex: 1 }}>
                    <MoneyInput disabled={readOnly} value={additionalCostsAmount} name='additionalCostsAmount' onChange={() => {}} />
                  </div>
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm='2'>
          <div className='styledTitle'>
            Sales Price (Revenue)
          </div>
        </Col>
        <Col sm='1' className='pt-1 text-right'>
          <InfoMarker title='Sales Price (Revenue)' bodyCopy='Enter the intended price of this Service to your Customer. If you have entered a required Gross Margin this will be auto calculated.' id='imRevenue' />
        </Col>
        <Col sm='3' className='form-group'>
          <MoneyInput disabled={readOnly} name='salesPrice' onChange={() => {}} value={salesPrice} required={false} />
        </Col>
      </Row>
      <Row>
        <Col sm='2'>
          <div className='styledTitle'>
            Gross Profit
          </div>
        </Col>
        <Col sm='1' className='pt-1 text-right'>
          <InfoMarker title='Gross Profit' bodyCopy='Gross Profit will be the amount of money this Service will provide to your business (Revenue minus Cost) before any Service Management or additional costs are added by the business.' id='imGrossProfit' />
        </Col>
        <Col sm='3' className='form-group'>
          <MoneyInput disabled={readOnly} name='grossProfit' onChange={() => {}} value={grossProfit} required={false} />
        </Col>
      </Row>
    </>
  )
}

export default FinancialDesignTab

import { SET_USER_INFO, SET_USER_ROLE, UPDATE_USER_PROFILE } from './actionTypes'

const initialState = {
  id: '',
  name: '',
  role: '',
  firstname: '',
  lastname: '',
  jobTitle: '',
  company: '',
  organisationUsers: []
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      state = {
        ...state,
        name: `${action.payload.userProfile.firstname} ${action.payload.userProfile.lastname}`,
        role: action.payload.userProfile.role,
        firstname: action.payload.userProfile.firstname,
        lastname: action.payload.userProfile.lastname,
        jobTitle: action.payload.userProfile.jobTitle,
        company: action.payload.userProfile.company,
        id: action.payload.userProfile.id,
        organisationUsers: action.payload.userProfile.organisationUsers,
        selectedOrganisationUser: action.payload.userProfile.selectedOrganisationUser
      }
      break
    case UPDATE_USER_PROFILE:
      state = {
        ...state,
        name: `${action.payload.userProfile.firstname} ${action.payload.userProfile.lastname}`,
        firstname: action.payload.userProfile.firstname,
        lastname: action.payload.userProfile.lastname,
        jobTitle: action.payload.userProfile.jobTitle,
        company: action.payload.userProfile.company
      }
      break
    case SET_USER_ROLE:
      state = {
        ...state,
        role: action.payload.role
      }
      break
    default:
      return state
  }
  return state
}

export default user

// @flow
import {
  SET_OPPORTUNITY_INFO
} from './actionTypes'

const INIT_STATE = {
  stage: 'Project selection'
}

const OpportunityInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_OPPORTUNITY_INFO:
      return {
        ...state,
        stage: action.payload.stage
      }

    default:
      return state
  }
}

export default OpportunityInfo

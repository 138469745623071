import users from './demoMode/users.json'
import projects from '../helpers/demoMode/projects.json'
import suppliers from '../helpers/demoMode/suppliers.json'
import customers from '../helpers/demoMode/customers.json'
import schemas from '../helpers/demoMode/schemas.json'
import messages from '../helpers/demoMode/messages.json'
import events from '../helpers/demoMode/events.json'
import notifications from '../helpers/demoMode/notifications.json'
import uniqid from 'uniqid'

const mockClient = {
  apis: {
    ApplicationUser: {
      get_api_ApplicationUser_providers: () => {},
      get_api_ApplicationUser__id_: () => {},
      patch_api_ApplicationUser__id_: () => {}
    },
    File: {
      get_api_File__fileId_: () => {},
      post_api_File: () => {}
    },
    Organisation: {
      post_api_Organisation_current: () => {}
    },
    Event: {
      get_api_Event: () => { return { status: 200, body: events } },
      post_api_Event: () => {},
      delete_api_Event__id_: () => {},
      put_api_Event__id_: () => {}
    },
    Customer: {
      get_api_Customer: () => { return { status: 200, body: customers } },
      post_api_Customer: () => {},
      delete_api_Customer__id_: () => {},
      put_api_Customer__id_: () => {}
    },
    RaidCategory: {
      get_api_RaidCategory: () => {}
    },
    Priority: {
      get_api_Priority: () => { return { status: 200, body: [] } }
    },
    OrganisationUser: {
      get_api_OrganisationUser: () => { return { status: 200, body: [] } }
    },
    Message: {
      get_api_Message: () => { return { status: 200, body: messages } },
      post_api_Message: (a, b) => { return { status: 200, body: b.requestBody } },
      delete_api_Message__id_: () => {},
      patch_api_Message__id_: (a, b) => { return { status: 200, body: b.requestBody } }
    },
    Notification: {
      get_api_Notification: () => { return { status: 200, body: notifications } },
      put_api_Notification__id_: () => {},
      patch_api_Notification__id_: (a, b) => { return { status: 200, body: b.requestBody } }
    },
    Solution: {
      get_api_Solution__id_: () => {}
    },
    Engagement: {
      get_api_Engagement__id_: () => {}
    },
    Project: {
      post_api_Project: () => {},
      patch_api_Project__id_: () => { return { status: 200 } },
      get_api_Project: () => { return { status: 200, body: projects } }
    },
    ServiceReport: {
      post_api_ServiceReport: () => {},
      get_api_ServiceReport__id_: () => { return { status: 200, body: [] } }
    },
    EngagementFinance: {
      patch_api_EngagementFinance__id_: () => {}
    },
    TimeUtilisation: {
      patch_api_TimeUtilisation__id_: () => {},
      post_api_TimeUtilisation: (a, b) => { return { status: 200, body: { ...b.requestBody, id: uniqid() } } },
      get_api_TimeUtilisation__id_: () => { return { status: 200, body: [] } }
    },
    TimeUtilisationSheet: {
      patch_api_TimeUtilisationSheet__id_: () => {},
      post_api_TimeUtilisationSheet: (a, b) => { return { status: 200, body: { ...b.requestBody, id: uniqid() } } }
    },
    Invoice: {
      post_api_Invoice: (a, b) => { return { status: 200, body: { ...b.requestBody, id: uniqid() } } },
      patch_api_Invoice__id_: (a, b) => { return { status: 200, body: b.requestBody } },
      get_api_Invoice__id_: () => { return { status: 200, body: [] } }
    },
    InvoiceItem: {
      post_api_InvoiceItem: (a, b) => { return { status: 200, body: { ...b.requestBody, id: uniqid() } } },
      patch_api_InvoiceItem__id_: () => {}
    },
    ChangeRequest: {
      post_api_ChangeRequest: (a, b) => { return { status: 200, body: { ...b.requestBody, id: uniqid() } } },
      patch_api_ChangeRequest__id_: (a, b) => { return { status: 200, body: b.requestBody } },
      get_api_ChangeRequest__id_: () => { return { status: 200, body: [] } }
    },
    ChangeRequestChange: {
      post_api_ChangeRequestChange: (a, b) => { return { status: 200, body: { ...b.requestBody, id: uniqid() } } },
      patch_api_ChangeRequestChange__id_: () => {}
    },
    RaidRisk: {
      post_api_RaidRisk: () => {},
      patch_api_RaidRisk__id_: () => {}
    },
    RaidAssumption: {
      post_api_RaidAssumption: () => {},
      patch_api_RaidAssumption__id_: () => {}
    },
    RaidIssue: {
      post_api_RaidIssue: () => {},
      patch_api_RaidIssue__id_: () => {}
    },
    RaidDependency: {
      post_api_RaidDependency: () => {},
      patch_api_RaidDependency__id_: () => {}
    },
    Kmdb: {
      post_api_Kmdb: () => {},
      patch_api_Kmdb__id_: () => {},
      get_api_Kmdb__id_: () => { return { status: 200, body: [] } }
    },
    ProposalDesign: {
      patch_api_ProposalDesign__id_: () => { return { status: 200 } }
    },
    StatementOfWork: {
      patch_api_StatementOfWork__id_: () => {},
      post_api_StatementOfWork: () => {},
      get_api_StatementOfWork__id_: () => {}
    },
    Phase: {
      patch_api_Phase__id_: () => {},
      post_api_Phase: () => {}
    },
    Milestone: {
      patch_api_Milestone__id_: () => {},
      post_api_Milestone: () => {}
    },
    Deliverable: {
      patch_api_Deliverable__id_: () => {},
      post_api_Deliverable: () => {}
    },
    LegalDesign: {
      get_api_LegalDesign__id_: () => {}
    },
    LegalDesignCustomerFile: {
      patch_api_LegalDesignCustomerFile__id_: () => {},
      post_api_LegalDesignCustomerFile: () => {}
    },
    LegalDesignSupplierFile: {
      patch_api_LegalDesignSupplierFile__id_: () => {},
      post_api_LegalDesignSupplierFile: () => {}
    },
    LegalDesignOtherFile: {
      patch_api_LegalDesignOtherFile__id_: () => {},
      post_api_LegalDesignOtherFile: () => {}
    },
    EngagementDesign: {
      patch_api_EngagementDesign__id_: () => {},
      get_api_EngagementDesign__id_: () => {}
    },
    EngagementDesignLevel: {
      patch_api_EngagementDesignLevel__id_: () => {},
      post_api_EngagementDesignLevel: () => {}
    },
    EngagementDesignReportingLevel: {
      patch_api_EngagementDesignReportingLevel__id_: () => {},
      post_api_EngagementDesignReportingLevel: () => {}
    },
    FinancialDesign: {
      patch_api_FinancialDesign__id_: () => {},
      get_api_FinancialDesign__id_: () => { return { status: 0 } }
    },
    FinancialDesignCost: {
      patch_api_FinancialDesignCost__id_: () => {},
      post_api_FinancialDesignCost: () => {}
    },
    SolutionSupplier: {
      patch_api_SolutionSupplier__id_: () => {},
      post_api_SolutionSupplier: () => {}
    },
    Supplier: {
      get_api_Supplier: () => { return { status: 200, body: suppliers } },
      post_api_Supplier: () => {},
      delete_api_Supplier__id_: () => {},
      patch_api_Supplier__id_: () => {}
    }
  },
  spec: {
    components: {
      schemas: schemas
    }
  }
}

const mockApi = {
  client: mockClient,
  getClient: () => {
    return mockApi.client
  },
  loggedIn: async () => {
    return {}
  },
  login: async (username, password) => {
    if (username === 'demo@sowaas.com' && password === 'pa55word') {
      return users[0]
    } else {
      return false
    }
  },
  logout: async () => {

  },
  setupUnauthenticatedClient: async () => {
    mockApi.unauthenticatedClient = mockClient
  }
}

export default mockApi

import salesSummary from './salesSummary.json'
import solutionsSummary from './solutionsSummary.json'
import engagementSummary from './engagementSummary.json'

export const getSalesSummary = () => {
  return salesSummary
}

export const getSolutionsSummary = () => {
  return solutionsSummary
}

export const getEngagementSummary = () => {
  return engagementSummary
}

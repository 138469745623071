import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import KmdbItemForm from '../kmdbItemForm/kmdbItemForm'

const KmdbItemModal = ({ isOpen, setOpen, kmdb = {}, onSave, project = {} }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSave = (values) => {
    setOpen(false)
    onSave(values)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Knowledge Management Item
          </ModalHeader>
          <ModalBody>
            <KmdbItemForm kmdb={kmdb} onSave={handleSave} project={project} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default KmdbItemModal

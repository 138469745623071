import {
  ADD_PROJECT,
  ADD_PROJECTS,
  UPDATE_PROJECT,
  CLEAR_PROJECTS,
  UPDATE_SOLUTION,
  UPDATE_SOLUTION_SOW,
  DELETE_SOLUTION_SOW,
  UPDATE_SOLUTION_SUPPLIERS,
  UPDATE_SOLUTION_LEGAL_DESIGN,
  UPDATE_SOLUTION_ENGAGEMENT_DESIGN,
  UPDATE_ENGAGEMENT,
  UPDATE_ENGAGEMENT_FINANCE,
  ADD_CHANGE_REQUEST,
  UPDATE_CHANGE_REQUEST,
  DELETE_CHANGE_REQUEST,
  ADD_INVOICE,
  UPDATE_INVOICE,
  DELETE_INVOICE,
  ADD_KMDB,
  UPDATE_KMDB,
  DELETE_KMDB,
  ADD_TU,
  UPDATE_TU,
  ADD_SERVICE_REPORT,
  UPDATE_SERVICE_REPORT,
  DELETE_SERVICE_REPORT,
  UPDATE_PROPOSAL_DESIGN,
  UPDATE_FINANCIAL_DESIGN,
  UPDATE_LEGAL_DESIGN,
  UPDATE_ENGAGEMENT_DESIGN,
  UPDATE_SUPPLIERS_DESIGN,
  ADD_RAID_RISK,
  UPDATE_RAID_RISK,
  ADD_RAID_ASSUMPTION,
  UPDATE_RAID_ASSUMPTION,
  ADD_RAID_ISSUE,
  UPDATE_RAID_ISSUE,
  ADD_RAID_DEPENDENCY,
  UPDATE_RAID_DEPENDENCY,
  ADD_CHANGE_REQUEST_CHANGE,
  UPDATE_CHANGE_REQUEST_CHANGE,
  DELETE_CHANGE_REQUEST_CHANGE,
  ADD_INVOICE_ITEM,
  UPDATE_INVOICE_ITEM,
  DELETE_INVOICE_ITEM,
  UPDATE_TIME_UTILISATION
} from './actionTypes'
import produce from 'immer'
import _ from 'lodash'

const INIT_STATE = {
  projects: []
}

const Project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_PROJECT: {
      const nextState = produce(state, draftState => {
        draftState.projects.push(action.payload.project)
      })
      return nextState
    }
    case ADD_PROJECTS: {
      const nextState = produce(state, draftState => {
        action.payload.projects.forEach((project) => {
          const existingProject = _.find(state.projects, { id: project.id })
          if (existingProject) {
            if (project.updatedAt !== existingProject.updatedAt) {
              const projectIndex = _.indexOf(state.projects, existingProject)
              draftState.projects[projectIndex] = project
            }
          } else {
            draftState.projects.push(project)
          }
        })
      })
      return nextState
    }
    case UPDATE_PROJECT: {
      const { project } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        return _.assign({}, p, project)
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case CLEAR_PROJECTS: {
      const nextState = produce(state, draftState => {
        draftState.projects = []
      })
      return nextState
    }
    case UPDATE_SOLUTION: {
      const { solution } = action.payload.solution
      const returnSolutions = state.projects.map((s) => {
        if (s.id !== solution.id) {
          return s
        }
        return solution
      })
      return {
        ...state,
        projects: returnSolutions
      }
    }
    case DELETE_SOLUTION_SOW: {
      return state
    }
    case UPDATE_SOLUTION_SUPPLIERS: {
      const { solution, solutionSuppliers } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (!p.solution) {
          return p
        }
        if (p.solution.id !== solution.id) {
          return p
        }
        p.solution.solutionSuppliers = solutionSuppliers
        return p
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_SOLUTION_LEGAL_DESIGN: {
      const { project, legalDesign } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p

        const nextP = produce(p, draftState => {
          draftState.solution.legalDesign = legalDesign
        })

        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_SOLUTION_ENGAGEMENT_DESIGN: {
      const { project, engagementDesign } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p

        const nextP = produce(p, draftState => {
          draftState.solution.engagementDesign = engagementDesign
        })

        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_PROPOSAL_DESIGN: {
      const { project, proposalDesign } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) {
          return p
        }

        const nextP = produce(p, draftState => {
          draftState.solution.proposalDesign = proposalDesign
        })

        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_FINANCIAL_DESIGN: {
      const { project, financialDesign } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p

        const nextP = produce(p, draftState => {
          draftState.solution.financialDesign = financialDesign
        })

        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_SOLUTION_SOW: {
      const { project, sow } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.solution.statementOfWorks, { id: sow.id })
          if (existingIndex > -1) {
            draftState.solution.statementOfWorks[existingIndex] = sow
          } else {
            draftState.solution.statementOfWorks.push(sow)
          }
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_LEGAL_DESIGN: {
      const { project, legalDesign } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p

        const nextP = produce(p, draftState => {
          draftState.solution.legalDesign = legalDesign
        })

        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_ENGAGEMENT_DESIGN: {
      const { project, engagementDesign } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p

        const nextP = produce(p, draftState => {
          draftState.solution.engagementDesign = engagementDesign
        })

        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_SUPPLIERS_DESIGN: {
      const { project, suppliersDesign } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p

        const nextP = produce(p, draftState => {
          draftState.solution.solutionSuppliers = suppliersDesign
        })

        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_ENGAGEMENT: {
      return state
    }
    case UPDATE_ENGAGEMENT_FINANCE: {
      return state
    }
    case ADD_CHANGE_REQUEST: {
      return state
    }
    case UPDATE_CHANGE_REQUEST: {
      const { project, changeRequest } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.changeRequests, { id: changeRequest.id })
          if (existingIndex > -1) {
            draftState.engagement.changeRequests[existingIndex] = changeRequest
          } else {
            draftState.engagement.changeRequests.push(changeRequest)
          }
        })
        return nextP
      })
      return { ...state, projects: returnProjects }
    }
    case DELETE_CHANGE_REQUEST: {
      return state
    }
    case ADD_CHANGE_REQUEST_CHANGE: {
      return state
    }
    case UPDATE_CHANGE_REQUEST_CHANGE: {
      return state
    }
    case DELETE_CHANGE_REQUEST_CHANGE: {
      return state
    }
    case ADD_INVOICE: {
      return state
    }
    case UPDATE_INVOICE: {
      const { project, invoice } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.invoices, { id: invoice.id })
          if (existingIndex > -1) {
            draftState.engagement.invoices[existingIndex] = invoice
          } else {
            draftState.engagement.invoices.push(invoice)
          }
        })
        return nextP
      })
      return { ...state, projects: returnProjects }
    }
    case DELETE_INVOICE: {
      return state
    }
    case ADD_INVOICE_ITEM: {
      return state
    }
    case UPDATE_INVOICE_ITEM: {
      return state
    }
    case DELETE_INVOICE_ITEM: {
      return state
    }
    case ADD_KMDB: {
      return state
    }
    case UPDATE_KMDB: {
      const { project, kmdb } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.kmdbs, { id: kmdb.id })
          if (existingIndex > -1) {
            draftState.engagement.kmdbs[existingIndex] = kmdb
          } else {
            draftState.engagement.kmdbs.push(kmdb)
          }
        })
        return nextP
      })
      return { ...state, projects: returnProjects }
    }
    case DELETE_KMDB: {
      return state
    }
    case ADD_TU: {
      return state
    }
    case UPDATE_TU: {
      const { project, timeUtil } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.timeUtilisations, { id: timeUtil.id })
          if (existingIndex > -1) {
            draftState.engagement.timeUtilisations[existingIndex] = timeUtil
          } else {
            draftState.engagement.timeUtilisations.push(timeUtil)
          }
        })
        return nextP
      })
      return { ...state, projects: returnProjects }
    }
    case ADD_SERVICE_REPORT: {
      const { project, serviceReport } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          draftState.engagement.serviceReports = [...p.engagement.serviceReports, serviceReport]
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_SERVICE_REPORT: {
      const { project, serviceReport } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.serviceReports, { id: serviceReport.id })
          if (existingIndex > -1) {
            draftState.engagement.serviceReports[existingIndex] = serviceReport
          } else {
            draftState.engagement.serviceReports.push(serviceReport)
          }
        })
        return nextP
      })
      return { ...state, projects: returnProjects }
    }
    case UPDATE_TIME_UTILISATION: {
      const { project, timeUtilisation } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.timeUtilisations, { id: timeUtilisation.id })
          if (existingIndex > -1) {
            draftState.engagement.timeUtilisations[existingIndex] = timeUtilisation
          } else {
            draftState.engagement.timeUtilisations.push(timeUtilisation)
          }
        })
        return nextP
      })
      return { ...state, projects: returnProjects }
    }
    case DELETE_SERVICE_REPORT: {
      return state
    }
    case ADD_RAID_RISK: {
      const { project, raidRisk } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          draftState.engagement.raidRisks = [...p.engagement.raidRisks, raidRisk]
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_RAID_RISK: {
      const { project, raidRisk } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.raidRisks, { id: raidRisk.id })
          if (existingIndex > -1) {
            draftState.engagement.raidRisks[existingIndex] = raidRisk
          } else {
            draftState.engagement.raidRisks.push(raidRisk)
          }
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case ADD_RAID_ASSUMPTION: {
      const { project, raidAssumption } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          draftState.engagement.raidAssumptions = [...p.engagement.raidAssumptions, raidAssumption]
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_RAID_ASSUMPTION: {
      const { project, raidAssumption } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.raidAssumptions, { id: raidAssumption.id })
          if (existingIndex > -1) {
            draftState.engagement.raidAssumptions[existingIndex] = raidAssumption
          } else {
            draftState.engagement.raidAssumptions.push(raidAssumption)
          }
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case ADD_RAID_ISSUE: {
      const { project, raidIssue } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          draftState.engagement.raidIssues = [...p.engagement.raidIssues, raidIssue]
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_RAID_ISSUE: {
      const { project, raidIssue } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.raidIssues, { id: raidIssue.id })
          if (existingIndex > -1) {
            draftState.engagement.raidIssues[existingIndex] = raidIssue
          } else {
            draftState.engagement.raidIssues.push(raidIssue)
          }
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case ADD_RAID_DEPENDENCY: {
      const { project, raidDependency } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          draftState.engagement.raidDependencies = [...p.engagement.raidDependencies, raidDependency]
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    case UPDATE_RAID_DEPENDENCY: {
      const { project, raidDependency } = action.payload
      const returnProjects = state.projects.map((p) => {
        if (p.id !== project.id) return p
        const nextP = produce(p, draftState => {
          const existingIndex = _.findIndex(draftState.engagement.raidDependencies, { id: raidDependency.id })
          if (existingIndex > -1) {
            draftState.engagement.raidDependencies[existingIndex] = raidDependency
          } else {
            draftState.engagement.raidDependencies.push(raidDependency)
          }
        })
        return nextP
      })
      return {
        ...state,
        projects: returnProjects
      }
    }
    default:
      return state
  }
}

export default Project

import React, { useState } from 'react'
import styled from 'styled-components'

import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

const StyledCard = styled.div`
  padding: 5px 0;
`

const StyledButtons = styled.button`
  float: right;
  width: auto;
  margin: 0 5px !important;
`

const StyledRadios = styled.div`
  margin: 0 13px;
`

const NewRequestClient = () => {
  const [type, setType] = useState('')
  const [contractStatus, setContractStatus] = useState('')

  const RowOne = () => {
    return (
      <StyledCard>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Type
            </div>
          </Col>
          <Col sm='9'>
            <StyledRadios className='btn-group btn-group-toggle' data-toggle='buttons'>
              <label
                className={
                  type === 'sales'
                    ? 'btn btn-primary active'
                    : 'btn btn-primary'
                }
              >
                <input
                  type='radio'
                  name='options'
                  id='sales'
                  onClick={() => { setType('sales') }}
                />{' '}
                Sales
              </label>

              <label
                className={
                  type === 'solution'
                    ? 'btn btn-primary active'
                    : 'btn btn-primary'
                }
              >
                <input
                  type='radio'
                  name='options'
                  id='solution'
                  onClick={() => { setType('solution') }}
                />{' '}
                Solution
              </label>

              <label
                className={
                  type === 'engagement'
                    ? 'btn btn-primary active'
                    : 'btn btn-primary'
                }
              >
                <input
                  type='radio'
                  name='options'
                  id='engagement'
                  onClick={() => setType('engagement')}
                />{' '}
                Engagement
              </label>
            </StyledRadios>
          </Col>
        </Row>
      </StyledCard>
    )
  }

  const RowTwo = () => {
    return (
      <StyledCard>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Desc of Req
            </div>
          </Col>
          <Col sm='9'>
            <div className='col-md-12'>
              <input className='form-control' type='text' placeholder='Enter request' />
            </div>
          </Col>
        </Row>
      </StyledCard>
    )
  }

  const RowThree = () => {
    return (
      <StyledCard>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Contract Status
            </div>
          </Col>
          <Col sm='9'>
            <StyledRadios className='btn-group btn-group-toggle' data-toggle='buttons'>
              <label
                className={
                  contractStatus === 'nda'
                    ? 'btn btn-primary active'
                    : 'btn btn-primary'
                }
              >
                <input
                  type='radio'
                  name='options'
                  id='nda'
                  onClick={() => { setContractStatus('nda') }}
                />{' '}
                NDA
              </label>

              <label
                className={
                  contractStatus === 'msa'
                    ? 'btn btn-primary active'
                    : 'btn btn-primary'
                }
              >
                <input
                  type='radio'
                  name='options'
                  id='msa'
                  onClick={() => { setContractStatus('msa') }}
                />{' '}
                MSA
              </label>

              <label
                className={
                  contractStatus === 'sow'
                    ? 'btn btn-primary active'
                    : 'btn btn-primary'
                }
              >
                <input
                  type='radio'
                  name='options'
                  id='sow'
                  onClick={() => setContractStatus('sow')}
                />{' '}
                SOW
              </label>

              <label
                className={
                  contractStatus === 'other'
                    ? 'btn btn-primary active'
                    : 'btn btn-primary'
                }
              >
                <input
                  type='radio'
                  name='options'
                  id='other'
                  onClick={() => setContractStatus('other')}
                />{' '}
                Other
              </label>
            </StyledRadios>
          </Col>
        </Row>
      </StyledCard>
    )
  }

  const RowFour = () => {
    return (
      <StyledCard>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Customer Name
            </div>
          </Col>
          <Col sm='9'>
            <div className='col-md-12'>
              <input className='form-control' type='text' placeholder='Enter name' />
            </div>
          </Col>
        </Row>
      </StyledCard>
    )
  }

  const RowFive = () => {
    return (
      <StyledCard>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              New/Existing
            </div>
          </Col>
          <Col sm='9'>
            <div className='col-md-12'>
              <input className='form-control' type='text' placeholder='Enter details' />
            </div>
          </Col>
        </Row>
      </StyledCard>
    )
  }

  const RowSix = () => {
    return (
      <StyledCard>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Other Info
            </div>
          </Col>
          <Col sm='9'>
            <div className='col-md-12'>
              <input className='form-control' type='text' placeholder='Enter info' />
            </div>
          </Col>
        </Row>
      </StyledCard>
    )
  }

  const SaveAndSubmit = () => {
    return (
      <StyledCard>
        <Row>
          <Col sm='12'>
            <StyledButtons className='btn btn-primary btn-block waves-effect waves-light' type='submit'>
              Save
            </StyledButtons>
            <StyledButtons className='btn btn-primary btn-block waves-effect waves-light' type='submit'>
              Submit
            </StyledButtons>
          </Col>
        </Row>
      </StyledCard>
    )
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            New Request
          </CardTitle>
          <RowOne />
          <RowTwo />
          <RowThree />
          <RowFour />
          <RowFive />
          <RowSix />
          <SaveAndSubmit />
        </CardBody>
      </Card>
    </>
  )
}

export default NewRequestClient

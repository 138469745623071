import React, { useState } from 'react'
import { Col, Card, CardBody, CardTitle, Row } from 'reactstrap'
import BooleanToggle from '../booleanToggle/booleanToggle'
import { AvForm } from 'availity-reactstrap-validation'
import SupplierOnboardModal from '../supplierOnboardModal/supplierOnboardModal'
import InfoMarker from '../infoMarker/infoMarker'
import Supplier from './supplier'
import SowaasButton from '../sowaasButton/sowaasButton'
import VideoMarker from '../videoMarker/videoMarker'

const SolutionSuppliers = ({ project, existingSuppliers = [], onSupplierAdded, onSolutionSuppliersSaved, supplierRoles = [], readOnly = false }) => {
  const {
    suppliersRequired = false,
    suppliersKnown = false
  } = project

  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false)
  const [addingForSupplier, setAddingForSupplier] = useState(-1)
  const [suppliersRequiredValue, setSuppliersRequiredValue] = useState(suppliersRequired)
  const [suppliersKnownValue, setSuppliersKnownValue] = useState(suppliersKnown)
  const [suppliers, setSuppliers] = useState([])

  React.useEffect(() => {
    const {
      suppliersRequired,
      numberOfSuppliersRequired,
      suppliersKnown,
      solution = {}
    } = project

    const {
      solutionSuppliers = []
    } = solution

    const newSuppliers = Array(numberOfSuppliersRequired).fill({})
    for (let i = 0; i < solutionSuppliers.length; i += 1) {
      newSuppliers[i] = solutionSuppliers[i]
    }

    setSuppliersRequiredValue(suppliersRequired)
    setSuppliersKnownValue(suppliersKnown)
    setSuppliers(newSuppliers)
  }, [project])

  const addSupplier = () => {
    const newSuppliers = [...suppliers, false]
    setSuppliers(newSuppliers)
  }

  const supplierAdded = (supplier) => {
    onSupplierAdded(supplier)

    setAddSupplierModalOpen(false)
    setAddingForSupplier(-1)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSolutionSuppliersSaved(values)
    }
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <AvForm className='validation-required' onSubmit={handleSubmit}>
          <CardBody>
            <CardTitle className='mb-4'>
              SOW Service Suppliers
              <InfoMarker title='Solution Suppliers' bodyCopy='A Supplier is the Contractor/PSC, 3rd party or independent consultant appointed by you to contribute and/or deliver aspects of this SOW Service activity' id='imSolutionSuppliers' />
              <VideoMarker id='vmAddSupplier' title='Add Supplier' videoUrl='https://vimeo.com/500952234/6b4e742874' />
            </CardTitle>
            <Row className='mb-3'>
              <Col sm='3'>
                <div className='styledTitle'>
                  Contractors / Suppliers Required
                </div>
              </Col>
              <Col sm='9' style={{ display: 'flex' }}>
                <BooleanToggle disabled={readOnly} className='mb-0' name='suppliersRequired' onValueChanged={setSuppliersRequiredValue} currentValue={suppliersRequiredValue} />
                {
                  !suppliersRequiredValue && (
                    <div className='styledTitle ml-3'>
                      You previously selected 'No' to this question at the Overview stage
                    </div>
                  )
                }
              </Col>
            </Row>
            {
              suppliersRequiredValue && (
                <>
                  <Row className='mb-3'>
                    <Col>
                      <div className='styledTitle'>
                        Are these Contractors/Suppliers known at this stage?
                      </div>
                    </Col>
                    <Col sm='auto' className='pt-1 text-right pr-0'>
                      <InfoMarker title='Are these Contractors/Suppliers known at this stage?' bodyCopy="If you know which Supplier/s you will be using for this Service, enter their details here. Select from an existing/known Supplier or create a new Supplier. You can add Supplier specific details later if you don't know who the actual Suppliers will be at this stage." id='imSuppliersKnown' />
                    </Col>
                    <Col sm='9'>
                      <BooleanToggle disabled={readOnly} className='mb-0' name='suppliersKnown' currentValue={suppliersKnownValue} onValueChanged={setSuppliersKnownValue} />
                    </Col>
                  </Row>
                  {
                    suppliersKnownValue && (
                      <Row>
                        <Col sm='12'>
                          {suppliers.length > 0 && (
                            <>
                              {
                                suppliers.map((supplier, idx) => (
                                  <Supplier supplierRoles={supplierRoles} key={idx} idx={idx} existingSuppliers={existingSuppliers} setAddSupplierModalOpen={setAddSupplierModalOpen} supplier={supplier} readOnly={readOnly} />
                                ))
                              }
                            </>
                          )}
                          <Row>
                            <Col sm='12'>
                              <SowaasButton disabled={readOnly} className='mt-3' onClick={() => { addSupplier() }} title='Add More Suppliers' />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  }
                </>
              )
            }
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right ml-2' title={(readOnly ? 'Next' : 'Save')} type='submit' />
              </Col>
            </Row>
          </CardBody>
        </AvForm>
      </Card>
      <SupplierOnboardModal isOpen={addSupplierModalOpen} setOpen={setAddSupplierModalOpen} onSubmit={supplierAdded} forSupplier={addingForSupplier} />
    </>
  )
}

export default SolutionSuppliers

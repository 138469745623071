import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from '../../components/pageTitle/pageTitle'
import NewProject from '../../components/newProject/newProject'
import ProjectUsersCard from '../../components/projectUsersCard/projectUsersCard'
import { patchProject } from '../../store/project/actions'

const Project = () => {
  const { projectId = -999 } = useParams()
  const dispatch = useDispatch()

  const projects = useSelector((state) => state.Project.projects)
  let existingProject = projects.find(s => s.id === Number(projectId))

  if (existingProject === undefined) existingProject = {}

  const {
    name = '',
    slug = ''
  } = existingProject

  const onProjectSave = (project) => {
    dispatch(patchProject(project))
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm='12'>
              <PageTitle title={`SOW Service - ${slug} - ${name}`} className='mb-3' icon='bx-layer' />
            </Col>
          </Row>
          <NewProject onProjectAdd={onProjectSave} existingProject={existingProject} ctaLabel='Save' />
          <ProjectUsersCard />
        </Container>
      </div>
    </>
  )
}

export default Project

import React from 'react'

// Import components
import Header from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Footer from '../footer/Footer'

const PageLayout = (props) => {
  return (
    <>
      <div id='layout-wrapper'>
        <Header />
        <Sidebar theme='dark' type='default' />
        <div className='main-content'>
          {props.children}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default PageLayout

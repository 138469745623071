import React from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { AvInput, AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledCard = styled.div`
  padding: 5px 0;
`

const CommentModal = ({ isOpen, setOpen, onSubmit, title, ctaTitle, approvingType = '' }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSubmit(values)
      onClose()
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='commentModal' tabIndex='-1' toggle={onClose}>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            {title}
          </ModalHeader>
          <ModalBody>
            <Col sm='12'>
              <AvForm className='validation-required' onSubmit={handleSubmit}>
                <StyledCard>
                  <Row>
                    <Col sm='3'>
                      <div className='styledTitle'>
                        Comment:
                      </div>
                    </Col>
                    <Col sm='9'>
                      <AvInput
                        className='form-control extendedInput'
                        type='textarea'
                        name='comment'
                        placeholder='Enter comment'
                      />
                    </Col>
                  </Row>
                  <Row className='pt-3'>
                    <Col sm='12'>
                      <SowaasButton className='float-right' title={ctaTitle} type='submit' />
                    </Col>
                  </Row>
                </StyledCard>
              </AvForm>
            </Col>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default CommentModal

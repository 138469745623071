import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Card, CardBody, CardTitle, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButtonImpace from '../sowaasButtonImpace/sowaasButtonImpace'
import SowaasButton from '../sowaasButton/sowaasButton'
import moment from 'moment'
import FilterPopover from '../filterPopover/filterPopover'
import { useSelector } from 'react-redux'

const StyledHeading = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  color: #002B7D;
`

const StyledNoSolutions = styled.p`
  text-align: center;
  margin-top: 1rem;
  padding: 0 1rem;
  font-size: 1.1em;
`

const SolutionSummary = ({ solutionsSummary, solutionClicked, engagementClicked, showingAll = false, customers = [] }) => {
  const [filter, setFilter] = useState('')
  const user = useSelector((state) => state.User)

  if (process.env.REACT_APP_DEMO_MODE === 'true') {
    if (user.role === 'recruiter_executive') {
      solutionsSummary = solutionsSummary.filter((s) => s.managementApproved === true)
    }
  }

  if (filter !== '') {
    solutionsSummary = solutionsSummary.filter((s) => s.projectProcurer.name === filter)
  }

  const onFilterSelected = (entry) => {
    setFilter(entry)
  }

  const GoButton = () => {
    return (
      <>
        <SowaasButtonImpace title='View All' icon='mdi mdi-arrow-right' to='/existing' />
      </>
    )
  }
  return (
    <>
      <FilterPopover target='filterPopover1' placement='left' onFilter={onFilterSelected} customers={customers} />
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <StyledHeading>
                <CardTitle className='mb-0'>
                  SOW Services Summary
                  <InfoMarker title='SOW Services Summary' bodyCopy='This section displays a list of all your existing SOW Services.' id='imSolutionSummary' />
                </CardTitle>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <SowaasButton id='filterPopover1' title='FILTER/SEARCH' className='mr-3' />
                  {
                    !showingAll && (
                      <GoButton />
                    )
                  }
                </div>
              </StyledHeading>
            </Col>
          </Row>
          {
            solutionsSummary.length === 0 && (
              <StyledNoSolutions>
                You don't currently have any SOW Services set-up. Click 'New SOW Service' to get started.
              </StyledNoSolutions>
            )
          }
          {
            solutionsSummary.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th style={{ borderTop: 'none' }} />
                    <th colSpan='6' className='styledTh'>SERVICE</th>
                    <th style={{ borderTop: 'none' }} />
                  </tr>
                  <tr>
                    <th>Customer</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Stage</th>
                    <th>Start Date</th>
                    <th>Duration</th>
                    <th>Value</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {solutionsSummary.map((project, idx) => {
                    const { startDate, endDate } = project
                    const { name = '' } = project.projectProcurer || {}
                    const value = ''

                    const startDateM = moment(startDate)
                    const endDateM = moment(endDate)

                    const duration = endDateM.diff(startDateM, 'days')
                    const humanDuration = moment.duration(duration, 'days').humanize()

                    return (
                      <tr key={idx}>
                        <td style={{ paddingTop: '1.4rem' }}>{name}</td>
                        <td style={{ paddingTop: '1.4rem' }}><Link to={`/project/${project.id}`}>{project.slug}</Link></td>
                        <td style={{ paddingTop: '1.4rem' }}>{project.name}</td>
                        <td style={{ paddingTop: '1.4rem' }}>Solution</td>
                        <td style={{ paddingTop: '1.4rem' }}>{startDateM.format('D/M/YYYY')}</td>
                        <td style={{ paddingTop: '1.4rem' }}>{humanDuration}</td>
                        <td style={{ paddingTop: '1.4rem' }}>{value}</td>
                        <td>
                          <SowaasButton className='ml-2 float-right' onClick={() => { engagementClicked(project) }} title='Delivery' />
                          <SowaasButton className='ml-2 float-right' onClick={() => { solutionClicked(project) }} title='Solution' />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            )
          }
        </CardBody>
      </Card>
    </>
  )
}

export default SolutionSummary

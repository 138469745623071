import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'

class PipelinePie extends Component {
    getOption = () => {
      return {

        toolbox: {
          show: false
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          x: 'left',
          data: ['Propose', 'Solution', 'Develop', 'Qualify', 'Identify', 'Win/Pend'],
          textStyle: {
            color: ['#74788d']
          }
        },
        color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf', '#50a5f1'],
        series: [
          {
            name: 'Pipeline Position',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { value: 335, name: 'Propose' },
              { value: 310, name: 'Solution' },
              { value: 234, name: 'Develop' },
              { value: 135, name: 'Qualify' },
              { value: 1548, name: 'Identify' },
              { value: 298, name: 'Win/Pend' }
            ]
          }
        ]
      }
    };

    render () {
      return (
        <>
          <ReactEcharts style={{ height: '350px' }} option={this.getOption()} />
        </>
      )
    }
}
export default PipelinePie

import React from 'react'
import { Col, Row } from 'reactstrap'
import { AvField, AvInput } from 'availity-reactstrap-validation'
import GroupToggle from '../groupToggle/groupToggle'

const frequencyValues = [
  { value: 'Once', label: 'Once' },
  { value: 'PerWeek', label: 'Per Week' },
  { value: 'PerMonth', label: 'Per Month' }
]

const timescaleValues = [
  { value: 'Hour', label: 'Hour' },
  { value: 'Day', label: 'Day' }
]

const EngagementRow = ({ title = '', index, type = '', phase = undefined, level = {}, readOnly = false }) => {
  const {
    id = '',
    timeFrame = '',
    levelFrequency = '',
    value
  } = level

  return (
    <>
      {
        id !== '' && (
          <AvInput type='hidden' name={`engagementDesignLevels[${type}-${index}].id`} value={id} />
        )
      }
      <Row className='ml-3'>
        {
          phase !== undefined && (
            <AvInput type='hidden' name={`engagementDesignLevels[${type}-${index}].phaseId`} value={phase.id} />
          )
        }
        <AvInput type='hidden' name={`engagementDesignLevels[${type}-${index}].type`} value={type} />
        <Col sm='3'>
          <div className='styledTitle'>
            {title}
          </div>
        </Col>
        <Col sm='2'>
          <AvField
            name={`engagementDesignLevels[${type}-${index}].value`}
            type='number'
            errorMessage='Enter value'
            className='form-control'
            validate={{ required: { value: true } }}
            value={value}
            disabled={readOnly}
          />
        </Col>
        <Col sm='2'>
          <GroupToggle disabled={readOnly} name={`engagementDesignLevels[${type}-${index}].timeFrame`} items={timescaleValues} onValueChanged={() => {}} currentValue={timeFrame} />
        </Col>
        <Col sm='4'>
          <GroupToggle disabled={readOnly} name={`engagementDesignLevels[${type}-${index}].levelFrequency`} items={frequencyValues} onValueChanged={() => {}} currentValue={levelFrequency} />
        </Col>
      </Row>
    </>
  )
}

export default EngagementRow

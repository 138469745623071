import React, { useState } from 'react'
import _ from 'lodash'
import { Table, Row, Col } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'
import RaidEntryModal from '../raidEntryModal/raidEntryModal'
import moment from 'moment'

const RaidLogRow = ({ raidItem = {}, raidCategories = [], onView }) => {
  const {
    categoryId = 0,
    slug = '',
    dateRaised,
    raidStatus
  } = raidItem

  const category = _.find(raidCategories, { id: categoryId })

  return (
    <tr>
      <td>
        {slug}
      </td>
      <td>
        {category.name}
      </td>
      <td>
        {moment(dateRaised).format('DD/MM/YYYY')}
      </td>
      <td>
        {raidStatus}
      </td>
      <td>
        <SowaasButton className='float-right' title='View' onClick={() => { onView(raidItem) }} />
      </td>
    </tr>
  )
}

const RaidLog = ({ raidCategories = [], onSubmit, priorities = [], raidItems = [], readOnly = false }) => {
  const [raidEntryModalOpen, setraidEntryModalOpen] = useState(false)

  const orderedRaidItems = _.orderBy(raidItems, ['raidStatus', 'dateRaised'], ['desc', 'asc'])

  const handleSubmit = (values) => {
    setraidEntryModalOpen(false)
    onSubmit(values)
  }

  const renderTable = () => {
    return (
      <AvForm>
        <Table className='mt-3'>
          <thead>
            <tr>
              <th style={{ width: '8rem' }}>ID</th>
              <th>RAID Category</th>
              <th>Date Raised</th>
              <th>Raid Status</th>
              <th style={{ width: '8rem' }} />
            </tr>
          </thead>
          <tbody>
            {
              orderedRaidItems.map((i, idx) => (
                <RaidLogRow key={i.id} raidItem={i} raidCategories={raidCategories} onView={(item) => { setraidEntryModalOpen(item) }} />
              ))
            }
          </tbody>
        </Table>
        <Row>
          <Col sm='12'>
            <SowaasButton onClick={() => { setraidEntryModalOpen({}) }} title='Add Item' />
            <SowaasButton className='float-right' title='Save' />
          </Col>
        </Row>
      </AvForm>
    )
  }

  const renderEmptyMessage = () => {
    return (
      <>
        <Row>
          <Col sm='12' className='pt-4'>
            <div className='styledTitle'>Your RAID Log is currently empty, get started by adding your first item below</div>
            <p style={{ textAlign: 'center' }}>
              <SowaasButton onClick={() => { setraidEntryModalOpen({}) }} title='Add Item' />
            </p>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      {raidItems.length > 0 && (
        renderTable()
      )}
      {raidItems.length === 0 && readOnly === false && (
        renderEmptyMessage()
      )}
      {
        raidEntryModalOpen !== false && (
          <RaidEntryModal readOnly={readOnly} onSave={handleSubmit} setOpen={setraidEntryModalOpen} isOpen={(raidEntryModalOpen !== false)} raidCategories={raidCategories} priorities={priorities} entry={raidEntryModalOpen} />
        )
      }
    </>
  )
}

export default RaidLog

import React, { useState } from 'react'
import { AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'

const BooleanToggle = ({ onValueChanged, name = 'toggle', disabled = false, currentValue = false, className = null }) => {
  const [value, setValue] = useState(currentValue)

  React.useEffect(() => {
    setValue(currentValue)
  }, [currentValue])

  const colour = (disabled === true ? 'inactiveToggle' : 'inactiveToggle')

  const buttonStyles = { color: '#fff', padding: '8px 12px', minHeight: '1.9rem', textTransform: 'uppercase', display: 'flex', alignItems: 'center', justifyContent: 'center' }

  return (
    <AvRadioGroup className={className} name={name} value={currentValue} defaultValue={currentValue} disabled={disabled}>
      <div className='btn-group btn-group-toggle form-group' data-toggle='buttons'>
        <label style={buttonStyles} className={value === true ? `btn ${colour} aquaVerticalGradient` : `btn ${colour}`}>
          <AvRadio type='radio' value onClick={() => { setValue(true); onValueChanged(true) }} />
          {' '}
          Yes
        </label>
        <label style={buttonStyles} className={value === false ? `btn ${colour} aquaVerticalGradient` : `btn ${colour}`}>
          <AvRadio type='radio' value={false} onClick={() => { setValue(false); onValueChanged(false) }} />
          {' '}
          No
        </label>
      </div>
    </AvRadioGroup>
  )
}

export default BooleanToggle

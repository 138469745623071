import React, { useState } from 'react'
import _ from 'lodash'
import { AvForm } from 'availity-reactstrap-validation'
import { Col, Card, CardBody, CardTitle, Row } from 'reactstrap'
import FinanceInfoModal from '../financeInfoModal/financeInfoModal'
import LegalInfoModal from '../legalInfoModal/legalInfoModal'
import SolutionFilterPopover from '../solutionFilterPopover/solutionFilterPopover'
import CommentModal from '../commentModal/commentModal'
import EngagementInfoModal from '../engagementInfoModal/engagementInfoModal'
import SowaasButton from '../sowaasButton/sowaasButton'
import SolutionInfoModal from '../solutionInfoModal/solutionInfoModal'
import InvoiceModal from '../invoiceModal/invoiceModal'
import { useDispatch } from 'react-redux'
import { approveLegal, approveFinance } from '../../store/project/actions'

const LegalFinanceProjectReview = ({ projects = [], statementOfWorkTypes = [], suppliers = [] }) => {
  const dispatch = useDispatch()

  const [filter, setFilter] = useState('')
  const [financeInfoOpen, setFinanceInfoOpen] = useState(false)
  const [legalInfoOpen, setLegalInfoOpen] = useState(false)
  const [financeCommentModalOpen, setFinanceCommentModalOpen] = useState(false)
  const [legalCommentModalOpen, setLegalCommentModalOpen] = useState(false)
  const [solutionInfoOpen, setSolutionInfoOpen] = useState(false)
  const [engagementInfoOpen, setEngagementInfoOpen] = useState(false)
  const [approveInvoiceModalOpen, setApproveInvoiceModalOpen] = useState(false)

  if (filter !== '') {
    projects = projects.filter((p) => p.id === filter)
  }

  if (projects.length === 0) {
    return null
  }

  const selectedProject = projects[0]

  const { name } = selectedProject

  const invoices = _.get(selectedProject, 'engagement.invoices', [])

  const onFilterSelected = (entry) => {
    setFilter(Number(entry))
  }

  const onInvoiceApproved = () => {

  }

  const onApproveFinance = (values) => {
    dispatch(approveFinance(selectedProject, values.comment))
  }

  const onApproveLegal = (values) => {
    dispatch(approveLegal(selectedProject, values.comment))
  }

  return (
    <>
      <SolutionFilterPopover target='filterPopoverButton' placement='left' onFilter={onFilterSelected} solutions={projects} />
      <AvForm className='validation-required'>
        <Card className='overflow-hidden'>
          <CardBody>
            <Row className='pb-3'>
              <Col sm='6'>
                <CardTitle>
                  Legal / Finance Project SOW Review
                </CardTitle>
              </Col>
              <Col sm='6'>
                <SowaasButton className='float-right' id='filterPopoverButton' title='Filter' />
              </Col>
            </Row>
            <Row>
              <Col sm='4' />
              <Col sm='4'>
                <div className='styledTitle'>
                  Finance Info
                </div>
              </Col>
              <Col sm='4'>
                <div className='styledTitle'>
                  Legal Info
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  {name}
                </div>
              </Col>
              <Col sm='2'>
                <SowaasButton className='btn-block' onClick={() => { setSolutionInfoOpen(selectedProject) }} title='Solution Info' />
                <SowaasButton className='btn-block' onClick={() => { setEngagementInfoOpen(selectedProject) }} title='Delivery Info' />
              </Col>
              <Col sm='4'>
                <Row>
                  <Col sm='6'>
                    <SowaasButton className='btn-block' title='View' onClick={() => { setFinanceInfoOpen(true) }} />
                  </Col>
                  {
                    selectedProject.financeApproved === false && (
                      <Col sm='6'>
                        <SowaasButton className='btn-block' title='Approve' onClick={() => { setFinanceCommentModalOpen(true) }} />
                      </Col>
                    )
                  }
                </Row>
                <Row>
                  <Col sm='12'>
                    <div className='styledTitle'>
                      Invoice #1
                    </div>
                    <SowaasButton className='btn-block' title='View / Approve' onClick={() => { setApproveInvoiceModalOpen(invoices[0]) }} />
                  </Col>
                </Row>
              </Col>
              <Col sm='4'>
                <Row>
                  <Col sm='6'>
                    <SowaasButton className='btn-block' title='View' onClick={() => { setLegalInfoOpen(true) }} />
                  </Col>
                  {
                    selectedProject.legalApproved === false && (
                      <Col sm='6'>
                        <SowaasButton className='btn-block' title='Approve' onClick={() => { setLegalCommentModalOpen(true) }} />
                      </Col>
                    )
                  }
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </AvForm>
      {
        (solutionInfoOpen !== false) && (
          <SolutionInfoModal
            isOpen={solutionInfoOpen}
            project={solutionInfoOpen}
            setOpen={setSolutionInfoOpen}
            statementOfWorkTypes={statementOfWorkTypes}
            suppliers={suppliers}
          />
        )
      }
      {
        engagementInfoOpen && (
          <EngagementInfoModal isOpen={engagementInfoOpen} setOpen={setEngagementInfoOpen} project={selectedProject} />
        )
      }
      {
        financeInfoOpen && (
          <FinanceInfoModal isOpen={financeInfoOpen} setOpen={setFinanceInfoOpen} project={selectedProject} />
        )
      }
      {
        legalInfoOpen && (
          <LegalInfoModal isOpen={legalInfoOpen} setOpen={setLegalInfoOpen} project={selectedProject} />
        )
      }
      {
        financeCommentModalOpen && (
          <CommentModal isOpen={financeCommentModalOpen} setOpen={setFinanceCommentModalOpen} title='Approve Finance Design with Comments' ctaTitle='Approve Finance Design with Comments' onSubmit={onApproveFinance} />
        )
      }
      {
        legalCommentModalOpen && (
          <CommentModal isOpen={legalCommentModalOpen} setOpen={setLegalCommentModalOpen} title='Approve Legal Design with Comments' ctaTitle='Approve Legal Design with Comments' onSubmit={onApproveLegal} />
        )
      }
      {
        approveInvoiceModalOpen !== false && (
          <InvoiceModal
            onSave={onInvoiceApproved}
            project={selectedProject}
            invoice={approveInvoiceModalOpen}
            isOpen
            setOpen={setApproveInvoiceModalOpen}
            mode='approve'
          />
        )
      }
    </>
  )
}

export default LegalFinanceProjectReview

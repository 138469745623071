import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import storeConfig from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastProvider } from 'react-toast-notifications'
import './i18n'
import 'bootstrap-select'
import TelemetryProvider from './telemetry-provider'

const { store, persistor } = storeConfig()

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <TelemetryProvider instrumentationKey={process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY} after={() => {}}>
          <Suspense fallback={null}>
            <ToastProvider>
              <App />
            </ToastProvider>
          </Suspense>
        </TelemetryProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()

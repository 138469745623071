import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Glossary from '../../components/glossary/glossary'
import PageTitle from '../../components/pageTitle/pageTitle'

const HelpCentre = () => {
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xl='12'>
              <PageTitle className='mb-3' title='Help Centre' icon='bx-bulb' />
            </Col>
          </Row>
          <Glossary />
        </Container>
      </div>
    </>
  )
}

export default HelpCentre

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Invoice from '../invoice/invoice'
import demoInvoice from '../../helpers/demoMode/exampleInvoice.json'
import _ from 'lodash'

const InvoiceModal = ({
  isOpen,
  setOpen,
  mode = 'add',
  onSave,
  invoice = demoInvoice,
  project = {}
}) => {
  const solutionSuppliers = _.get(project, 'solution.solutionSuppliers', [])
  const suppliers = _.filter(solutionSuppliers, (sol) => { return sol.supplier !== null }).map((ss) => { return ss.supplier })
  const statementOfWorks = _.get(project, 'solution.statementOfWorks', [])
  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const phases = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.phases || []))

  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSubmit = (values) => {
    onClose()
    onSave(values)
  }

  let title = ''
  let readOnly = true

  switch (mode) {
    case 'add':
      title = 'New Invoice'
      readOnly = false
      break
    case 'approve':
      title = 'Approve Invoice'
      break
    case 'view':
      title = 'View Invoice'
      break
    default:
      break
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            {title}
          </ModalHeader>
          <ModalBody>
            <Invoice project={project} mode={mode} readOnly={readOnly} onSubmit={handleSubmit} suppliers={suppliers} phases={phases} invoice={invoice} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default InvoiceModal

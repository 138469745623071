import React from 'react'
import { Table } from 'reactstrap'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const UserTable = ({ users = [], roles = [], onUserRemove }) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th style={{ width: '6rem' }} />
          </tr>
        </thead>
        <tbody>
          {
            users.map((user, idx) => (
              <tr key={idx}>
                <td className='pt-3'>{user.firstName} {user.lastName}</td>
                <td className='pt-3'>{user.email}</td>
                <td>
                  <AvForm>
                    <AvInput type='select' name='select' defaultValue={user.roleId}>
                      {
                        roles.map((r, idx) => (
                          <option key={idx} value={r.id}>{r.name}</option>
                        ))
                      }
                    </AvInput>
                  </AvForm>
                </td>
                <td>
                  <SowaasButton onClick={() => { onUserRemove(user) }} title='Remove' />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  )
}

export default UserTable

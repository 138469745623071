import React, { useState } from 'react'
import { Col, Card, CardBody, CardTitle, Row } from 'reactstrap'
import Switch from 'react-switch'

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2
      }}
    >
      {' '}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2
      }}
    >
      {' '}
      Yes
    </div>
  )
}

const SolutionSignoff = () => {
  const [exec, setExec] = useState(false)
  const [finance, setFinance] = useState(false)
  const [sales, setSales] = useState(false)
  const [legal, setLegal] = useState(false)
  const [customer, setCustomer] = useState(false)
  const [engage, setEngage] = useState(false)

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            Signoff
          </CardTitle>
          <Col sm='12' className='p-0'>
            <Row className='pt-3'>
              <Col cm='6'>
                <label>
                  <span className='font-size-14' style={{ width: '6rem', display: 'inline-block' }}>Exec{' '}</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#626ed4'
                    onChange={() => { setExec(!exec) }}
                    checked={exec}
                  />
                </label>
              </Col>
              <Col cm='6'>
                <label>
                  <span className='font-size-14' style={{ width: '6rem', display: 'inline-block' }}>Finance{' '}</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#626ed4'
                    onChange={() => { setFinance(!finance) }}
                    checked={finance}
                  />
                </label>
              </Col>
            </Row>
            <Row className='pt-3'>
              <Col cm='6'>
                <label>
                  <span className='font-size-14' style={{ width: '6rem', display: 'inline-block' }}>Sales{' '}</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#626ed4'
                    onChange={() => { setSales(!sales) }}
                    checked={sales}
                  />
                </label>
              </Col>
              <Col cm='6'>
                <label>
                  <span className='font-size-14' style={{ width: '6rem', display: 'inline-block' }}>Legal{' '}</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#626ed4'
                    onChange={() => { setLegal(!legal) }}
                    checked={legal}
                  />
                </label>
              </Col>
            </Row>
            <Row className='pt-3'>
              <Col cm='6'>
                <label>
                  <span className='font-size-14' style={{ width: '6rem', display: 'inline-block' }}>Customer{' '}</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#626ed4'
                    onChange={() => { setCustomer(!customer) }}
                    checked={customer}
                  />
                </label>
              </Col>
              <Col cm='6'>
                <label>
                  <span className='font-size-14' style={{ width: '6rem', display: 'inline-block' }}>Engage{' '}</span>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor='#626ed4'
                    onChange={() => { setEngage(!engage) }}
                    checked={engage}
                  />
                </label>
              </Col>
            </Row>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default SolutionSignoff

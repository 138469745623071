import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import ProjectPlanChart from '../projectPlanChart/projectPlanChart'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ProjectPlanForm = ({ project = {} }) => {
  const { name, slug, solution = {} } = project
  const { statementOfWorks = [] } = solution

  const customerName = _.get(project, 'projectProcurer.name', '')

  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const phases = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.phases || []))
  const deliverables = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.deliverables || []))
  const milestones = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.milestones || []))

  const allItems = [
    ...phases.map((p) => ({ ...p, type: 'phase', color: '#14266e' })),
    ...deliverables.map((p) => ({ ...p, type: 'deliverable', color: '#0dffe7' })),
    ...milestones.map((p) => ({ ...p, type: 'milestone', color: '#E221FF' }))
  ]

  const items = allItems.map((p) => ({
    type: p.type,
    name: p.name,
    start: p.start,
    end: p.end,
    color: p.color
  }))

  return (
    <AvForm className='needs-validation'>
      <StyledCard>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Project Name
            </div>
          </Col>
          <Col sm='10'>
            <AvField disabled name='customerName' value={name} />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Customer
            </div>
          </Col>
          <Col sm='10'>
            <AvField disabled name='supplierName' value={customerName} />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Unique ID
            </div>
          </Col>
          <Col sm='10'>
            <AvField disabled name='projectServiceName' value={slug} />
          </Col>
        </Row>
        <ProjectPlanChart dataItems={items} />
      </StyledCard>
    </AvForm>
  )
}

export default ProjectPlanForm

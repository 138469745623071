import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import RaidEntryForm from '../raidEntryForm/raidEntryForm'

const RaidEntryModal = ({ isOpen, setOpen, raidCategories = [], priorities = [], onSave, entry = {}, readOnly = false }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            RAID Entry
          </ModalHeader>
          <ModalBody>
            <RaidEntryForm readOnly={readOnly} raidCategories={raidCategories} priorities={priorities} onSave={onSave} entry={entry} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default RaidEntryModal

import React from 'react'
import { Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

import Notifications from '../../components/notifications/Notifications'
import Messaging from '../../components/messaging/Messaging'
import Calendar from '../../components/calendar/Calendar'
import SolutionsSummary from '../../components/solutionsSummary/SolutionsSummary'

import ExecNewProjectSowSignoff from '../../components/execNewProjectSowSignoff/execNewProjectSowSignoff'
import ManagementSalesSummary from '../../components/managementSalesSummary/managementSalesSummary'
import PageTitle from '../../components/pageTitle/pageTitle'

const RecruiterExecutiveDashboard = ({ messaging, notifications, history }) => {
  const solutions = useSelector((state) => state.Project.projects)
  const { StatementOfWorkType } = useSelector(state => state.Lookup.enums)
  const suppliers = useSelector((state) => state.Supplier.suppliers)
  const { role } = useSelector((state) => state.User)

  const solutionClicked = (solution) => {
    history.push(`/new/${solution.id}`)
  }

  const engagementClicked = (solution) => {
    history.push(`/engagement/${solution.id}`)
  }

  return (
    <>
      <Row className='mb-3'>
        <Col sm='8'>
          <PageTitle title='Dashboard' icon='bxs-dashboard' />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col sm='4'>
          <Messaging messages={messaging} role={role} />
        </Col>
        <Col sm='4'>
          <Calendar />
        </Col>
        <Col sm='4'>
          <Notifications notifications={notifications} role={role} />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col sm='6'>
          <ManagementSalesSummary />
        </Col>
        <Col sm='6'>
          <ExecNewProjectSowSignoff solutions={solutions} statementOfWorkTypes={StatementOfWorkType} suppliers={suppliers} />
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <SolutionsSummary solutionsSummary={solutions} solutionClicked={solutionClicked} engagementClicked={engagementClicked} />
        </Col>
      </Row>
    </>
  )
}

export default RecruiterExecutiveDashboard

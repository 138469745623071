import { ADD_EVENT, ADD_EVENTS, CLEAR_EVENTS, UPDATE_EVENT, DELETE_EVENT } from './actionTypes'
import produce from 'immer'

const initState = {
  events: []
}

const Calendar = (state = initState, action) => {
  switch (action.type) {
    case ADD_EVENT: {
      const nextState = produce(state, draftState => {
        draftState.events.push(action.payload.event)
      })
      return nextState
    }
    case ADD_EVENTS: {
      const nextState = produce(state, draftState => {
        draftState.events.push(...action.payload.events)
      })
      return nextState
    }
    case CLEAR_EVENTS: {
      const nextState = produce(state, draftState => {
        draftState.events = []
      })
      return nextState
    }
    case UPDATE_EVENT: {
      const { event } = action.payload
      const foundIndex = state.events.findIndex(x => x.id === event.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.events[foundIndex] = event
        })
        return nextState
      }
      return state
    }
    case DELETE_EVENT: {
      const { event } = action.payload
      const foundIndex = state.events.findIndex(x => x.id === event.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.events.splice(foundIndex, 1)
        })
        return nextState
      }
      return state
    }
    default:
      return state
  }
}

export default Calendar

import _ from 'lodash'

import {
  getStatementOfWork,
  getEngagementDesign,
  getFinancialDesign,
  getInvoice,
  getChangeRequest,
  getTimeUtilisation,
  getServiceReport,
  getLegalDesign,
  getKmdb
} from '../store/project/actions'

const projectSyncService = {
  syncProjects: (projects, dispatch) => {
    if (projects.length > 0) {
      for (let i = 0; i < projects.length; i += 1) {
        const project = projects[i]
        const solution = _.get(project, 'solution', {})
        const engagement = _.get(project, 'engagement', {})

        /* sync sows */
        const statementOfWorks = _.get(solution, 'statementOfWorks', [])
        statementOfWorks.forEach((sow) => {
          if (!sow.statementOfWorkType) {
            dispatch(getStatementOfWork(project, sow.id))
          }
        })

        /* sync engagementdesign */
        const engagementDesign = _.get(solution, 'engagementDesign', [])
        const differentManagementLevels = _.get(engagementDesign, 'differentServiceManagementLevels', null)
        if (differentManagementLevels === null) {
          dispatch(getEngagementDesign(project, engagementDesign.id))
        }

        /* sync legal design */
        const legalDesign = _.get(solution, 'legalDesign', [])
        const legalDesignCustomerFiles = _.get(legalDesign, 'legalDesignCustomerFiles', null)
        if (legalDesignCustomerFiles === null) {
          dispatch(getLegalDesign(project, legalDesign.id))
        }

        /* sync financialdesign */
        const financialDesign = _.get(solution, 'financialDesign', [])
        const additionalCostsRequired = _.get(financialDesign, 'additionalCostsRequired', null)
        if (additionalCostsRequired === null) {
          dispatch(getFinancialDesign(project, financialDesign.id))
        }

        /* sync invoices */
        const invoices = _.get(engagement, 'invoices', [])
        invoices.forEach(inv => {
          const recipient = _.get(inv, 'recipient', null)
          if (recipient === null) {
            dispatch(getInvoice(project, inv.id))
          }
        })

        /* sync change requests */
        const changeRequests = _.get(engagement, 'changeRequests', [])
        changeRequests.forEach(cr => {
          const requestor = _.get(cr, 'requestor', null)
          if (requestor === null) {
            dispatch(getChangeRequest(project, cr.id))
          }
        })

        /* sync time utilisations */
        const timeUtilisations = _.get(engagement, 'timeUtilisations', [])
        timeUtilisations.forEach(tu => {
          const weekCommencing = _.get(tu, 'weekCommencing', null)
          if (weekCommencing === null) {
            dispatch(getTimeUtilisation(project, tu.id))
          }
        })

        /* sync service reports */
        const serviceReports = _.get(engagement, 'serviceReports', [])
        serviceReports.forEach(sr => {
          const projectPlan = _.get(sr, 'projectPlan', null)
          if (projectPlan === null) {
            dispatch(getServiceReport(project, sr.id))
          }
        })

        /* sync kmdbs */
        const kmdbs = _.get(engagement, 'kmdbs', [])
        kmdbs.forEach(kmdb => {
          const provider = _.get(kmdb, 'provider', null)
          if (provider === null) {
            dispatch(getKmdb(project, kmdb.id))
          }
        })
      }
    }
  },
  syncProject: (project, dispatch) => {
    projectSyncService.syncProjects([project], dispatch)
  }
}

export default projectSyncService

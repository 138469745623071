import React from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

// Dashboard Views
import ClientDashboard from '../clientDashboard/ClientDashboard'
import ExecDashboard from '../execDashboard/ExecDashboard'
import SalesDashboard from '../salesDashboard/SalesDashboard'
import SolutionsDashboard from '../solutionsDashboard/SolutionsDashboard'
import EngagementDashboard from '../engagementDashboard/EngagementDashboard'
import CustomerDashboard from '../customerDashboard/CustomerDashboard'
import ThirdPartyDashboard from '../thirdPartyDashboard/ThirdPartyDashboard'
import RecruiterDashboard from '../recruiterDashboard/recruiterDashboard'
import RecruiterExecutiveDashboard from '../recruiterExecutiveDashboard/recruiterExecutiveDashboard'
import LegalFinanceDashbaord from '../legalFinanceDashboard/legalFinanceDashboard'

const LandingPage = ({ history }) => {
  const { t } = useTranslation()
  const view = useSelector((state) => state.User.role)
  const { messages } = useSelector((state) => state.Message)
  const { notifications } = useSelector((state) => state.Notification)

  const renderDashboard = (view) => {
    switch (view) {
      case 'client': return (
        <ClientDashboard
          messaging={messages}
          notifications={notifications}
          history={history}
        />
      )
      case 'exec': return (
        <ExecDashboard
          messaging={messages}
          notifications={notifications}
        />
      )
      case 'sales': return (
        <SalesDashboard
          messaging={messages}
          notifications={notifications}
        />
      )
      case 'solutions': return (
        <SolutionsDashboard
          messaging={messages}
          notifications={notifications}
        />
      )
      case 'engagement': return (
        <EngagementDashboard
          messaging={messages}
          notifications={notifications}
        />
      )
      case 'customer': return (
        <CustomerDashboard
          messaging={messages}
          notifications={notifications}
        />
      )
      case 'thirdparty': return (
        <ThirdPartyDashboard
          messaging={messages}
          notifications={notifications}
        />
      )
      case 'recruiter_consultant': return (
        <RecruiterDashboard
          messaging={messages}
          notifications={notifications}
          history={history}
        />
      )
      case 'recruiter_executive': return (
        <RecruiterExecutiveDashboard
          messaging={messages}
          notifications={notifications}
          history={history}
        />
      )
      case 'legal_finance': return (
        <LegalFinanceDashbaord
          messaging={messages}
          notifications={notifications}
          history={history}
        />
      )
      default: return 'Please choose a view'
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('dashboard_title')} | {t('app_name')}</title>
      </Helmet>
      <div className='page-content'>
        <Container fluid>
          {renderDashboard(view)}
        </Container>
      </div>
    </>
  )
}

export default (LandingPage)

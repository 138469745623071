import { combineReducers } from 'redux'

import Login from './auth/login/reducer'
import Account from './auth/register/reducer'
import ForgetPassword from './auth/forgetpwd/reducer'
import Profile from './auth/profile/reducer'
import Message from './message/reducer'
import NewOpportunity from './newOpportunity/reducer'
import OpportunityInfo from './opportunityInfo/reducer'
import User from './user/reducer'
import Calendar from './calendar/reducer'
import Project from './project/reducer'
import Customer from './customer/reducer'
import Supplier from './supplier/reducer'
import Notification from './notification/reducer'
import Lookup from './lookup/reducer'
import Toast from './toast/reducer'
import Activate from './auth/activate/reducer'

const rootReducer = combineReducers({
  Activate,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Message,
  NewOpportunity,
  OpportunityInfo,
  User,
  Calendar,
  Project,
  Customer,
  Supplier,
  Notification,
  Lookup,
  Toast
})

export default rootReducer

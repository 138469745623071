import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'

const GlossaryItem = ({ title = '', content = '' }) => {
  const [open, setOpen] = useState()

  return (
    <Card className='mb-1'>
      <CardHeader className='p-3' id='headingOne' style={{ cursor: 'pointer' }} onClick={() => { setOpen(!open) }}>
        <h6 className='m-0 font-14'>
          <span className='text-dark'>
            {title}
          </span>
        </h6>
      </CardHeader>
      <Collapse isOpen={open}>
        <Card>
          <CardBody>
            {content}
          </CardBody>
        </Card>
      </Collapse>
    </Card>
  )
}

export default GlossaryItem

import React from 'react'
import { Col, Row } from 'reactstrap'
import { AvField } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'

const AssumptionEntryFields = ({ entry, readOnly = false }) => {
  const {
    reason = '',
    action = '',
    impact = '',
    priority = 'Negligible'
  } = entry
  return (
    <>
      <Row>
        <Col>
          <div className='styledTitle'>
            Reason for Assumption
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Reason for Assumption' bodyCopy='Detail the reason the Assumption has come about' id='imAssumptionReason' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='reason' value={reason} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Action to Validate
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Action to Validate' bodyCopy='What needs to be done to validate the Assumption is correct and accurate' id='imActionToValidate' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='action' value={action} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Impact if Correct
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Impact if Correct' bodyCopy='What will be the impact to the project if the Assumption proves to be true' id='imImpactIfCorrect' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='impact' value={impact} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Priority
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Priority' bodyCopy={<>Select the priority this Assumption will have by choosing a score from: <br /><br />1 = Negligible<br />3 = Low<br />5 = Moderate<br />7 = High<br />10 = Critical</>} id='imPriority' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} type='select' name='priority' onChange={() => {}} value={priority}>
            <option value='Negligible'>1 = Negligible</option>
            <option value='Low'>3 = Low</option>
            <option value='Moderate'>5 = Moderate</option>
            <option value='High'>7 = High</option>
            <option value='Critical'>10 = Critical</option>
          </AvField>
        </Col>
      </Row>
    </>
  )
}

export default AssumptionEntryFields

import React from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import styled from 'styled-components'
import UserTable from '../userTable/userTable'

const StyledEmptyText = styled.p`
  margin-top: 1rem;
`

const OrganisationUserTableCard = ({ users = [], onUserRemove, roles }) => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Organisation Users
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              {
                users.length === 0 && (
                  <StyledEmptyText>You do not currently have any organisation users</StyledEmptyText>
                )
              }
              {
                users.length > 0 && (
                  <UserTable users={users} onUserRemove={onUserRemove} roles={roles} />
                )
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default OrganisationUserTableCard

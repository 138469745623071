import React, { useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import MoneyInput from '../moneyInput/moneyInput'

const frequencies = [
  { id: 'PerHour', title: 'Per Hour' },
  { id: 'PerDay', title: 'Per Day' },
  { id: 'PerWeek', title: 'Per Week' },
  { id: 'PerMonth', title: 'Per Month' },
  { id: 'PerUnit', title: 'Per Unit' },
  { id: 'PerPhase', title: 'Per Phase' },
  { id: 'Other', title: 'Other' }
]

const RateAndMetricInput = ({ name = '', quatity = '', value = '', frequency = '-1', frequencyOther = '', readOnly = false }) => {
  const [metric, setMetric] = useState(frequency)
  return (
    <>
      <td style={{ width: '10rem' }} className='pr-2'>
        <MoneyInput disabled={readOnly} name={`${name}`} onChange={() => {}} value={value} />
      </td>
      <td className='pr-2'>
        <AvField disabled={readOnly} name={`${name}Frequency`} type='select' onChange={(e) => { setMetric(e.target.value) }} value={frequency}>
          <option disabled value='-1'>Select Frequency</option>
          {
            frequencies.map((metric, idx) => (
              <option key={idx} value={metric.id}>{metric.title}</option>
            ))
          }
        </AvField>
        {
          metric === 'Other' && (
            <AvField name={`${name}FrequencyOther`} placeholder='Please specify' required value={frequencyOther} disabled={readOnly} />
          )
        }
      </td>
      <td style={{ width: '6.5rem' }} className='pr-2'>
        <AvField disabled={readOnly} type='number' name={`${name}Quantity`} placeholder='Quantity' required value={quatity} />
      </td>
    </>
  )
}

export default RateAndMetricInput

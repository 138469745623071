import React from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'
import ProfileForm from '../profileForm/profileForm'

const ProfileCard = ({ userProfile, onProfileUpdated }) => {
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <ProfileForm profile={userProfile} onProfileUpdated={onProfileUpdated} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default ProfileCard

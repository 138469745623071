import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import ProposalDesignForm from '../proposalDesignForm/proposalDesignForm'
import VideoMarker from '../videoMarker/videoMarker'

const ProposalDesign = ({ project = {}, onEnableProposalDesign, onProposalDesignSaved, readOnly = false }) => {
  const { proposalRequired, solution = {} } = project
  const { proposalDesign = {} } = solution

  return (
    <>
      <Card className='overflow-hidden' style={{ height: '100%' }}>
        <CardBody className='flex-card'>
          <CardTitle className='mb-4'>
            Proposal Design
            <InfoMarker title='Proposal Design' bodyCopy='Use this section to provide your Customer with an outline of the proposed benefits, solution, costs and outcomes you intend to provide as part of your Service' id='imProposalDesign' />
            <VideoMarker id='vmProposalDesign' title='Entering Proposal Information' videoUrl='https://vimeo.com/502289670/30f34384ac' />
          </CardTitle>
          <ProposalDesignForm
            proposalDesign={proposalDesign}
            proposalRequired={proposalRequired}
            onEnableProposalDesign={onEnableProposalDesign}
            onProposalDesignSaved={onProposalDesignSaved}
            readOnly={readOnly}
          />
        </CardBody>
      </Card>
    </>
  )
}

export default ProposalDesign

import { takeEvery, fork, put, all } from 'redux-saga/effects'

// Login Redux States
import { FORGET_PASSWORD, FORGET_PASSWORD_RESET_SUBMIT } from './actionTypes'
import { userForgetPasswordSuccess, userForgetPasswordResetSuccess, userForgetPasswordResetError } from './actions'

function * forgetUser ({ payload: { user } }: any): any {
  try {

  } catch (ex) {

  } finally {
    yield put(userForgetPasswordSuccess('A forgetton password email has been sent to you if you have an account with SOWaaS.'))
  }
}

function * forgetPasswordResetSubmit ({ payload }: any): any {
  try {
    yield put(userForgetPasswordResetSuccess('Your password has been reset, please login to continue to the application'))
  } catch (ex) {
    yield put(userForgetPasswordResetError('There was an error resetting your password, please contact support'))
  }
}

export function * watchUserPasswordForget (): any {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(FORGET_PASSWORD_RESET_SUBMIT, forgetPasswordResetSubmit)
}

function * forgetPasswordSaga (): any {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga

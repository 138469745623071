import _ from 'lodash'
import { takeEvery, fork, put, all } from 'redux-saga/effects'
import errorService from '../../helpers/errorService'

// Login Redux States
import {
  GET_RAIDCATEGORIES,
  GET_PRIORITIES,
  GET_ORGANISATION_USERS,
  GET_ENUMS
} from './actionTypes'
import { clearRaidCategories, addRaidCategories, clearPriorities, addPriorities, clearOrganisationUsers, addOrganisationUsers, setEnum } from './actions'

import api from '../../helpers/api'

function * getRaidCategories () {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.RaidCategory.get_api_RaidCategory()
    if (status === 200) {
      yield put(clearRaidCategories())
      yield put(addRaidCategories(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getPriorities () {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Priority.get_api_Priority()
    if (status === 200) {
      yield put(clearPriorities())
      yield put(addPriorities(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getOrganisationUsers () {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.OrganisationUser.get_api_OrganisationUser()
    if (status === 200) {
      yield put(clearOrganisationUsers())
      yield put(addOrganisationUsers(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getEnums () {
  try {
    const client = yield api.getClient()
    const schemas = client.spec.components.schemas
    const enums = _.pickBy(schemas, (schema) => { return schema.type === 'string' })
    for (const key in enums) {
      yield put(setEnum(key, enums[key].enum))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchLookups () {
  yield takeEvery(GET_RAIDCATEGORIES, getRaidCategories)
  yield takeEvery(GET_PRIORITIES, getPriorities)
  yield takeEvery(GET_ORGANISATION_USERS, getOrganisationUsers)
  yield takeEvery(GET_ENUMS, getEnums)
}

function * lookupSaga () {
  yield all([
    fork(watchLookups)
  ])
}

export default lookupSaga

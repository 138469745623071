import React from 'react'

import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

// import Charts
import PipelinePie from '../pipelinePie/PipelinePie'

const PipelinePosition = () => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle className='mb-1'>
            Pipeline Position
          </CardTitle>
          <Row>
            <Col sm='12'>
              <PipelinePie />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default PipelinePosition

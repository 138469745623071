import React, { useState } from 'react'
import { Col, Card, CardBody, CardTitle, Row } from 'reactstrap'
import ApprovalModal from '../approvalModal/approvalModal'
import { useToasts } from 'react-toast-notifications'
import SowaasButton from '../sowaasButton/sowaasButton'
import InfoMarker from '../infoMarker/infoMarker'

const SolutionSignoffRecruiter = ({ markedAsComplete, onToggleMarkedAsCompelete, approved = false }) => {
  const [markAllComplete, setMarkAllComplete] = useState(markedAsComplete)

  const [submittedForReview, setSubmittedForReview] = useState(false)

  const [sentToCustomer, setSentToCustomer] = useState(false)
  const [sentToCustomerModalOpen, setSentToCustomerModalOpen] = useState(false)

  const [sentToSupplier, setSentToSupplier] = useState(false)
  const [sentToSupplierModalOpen, setSentToSupplierModalOpen] = useState(false)

  const { addToast } = useToasts()

  const markedCompleteToggled = (value) => {
    setMarkAllComplete(value)
    onToggleMarkedAsCompelete(value)
  }

  const submitForReview = () => {
    addToast('Submitted for Review', { appearance: 'success', autoDismiss: true })
    setSubmittedForReview(true)
  }

  const sentToCustomerConfirmed = () => {
    addToast('Solution sent to Customer', { appearance: 'success', autoDismiss: true })
    setSentToCustomer(true)
  }

  const sentToSupplierConfirmed = () => {
    addToast('Solution sent to Supplier', { appearance: 'success', autoDismiss: true })
    setSentToSupplier(true)
  }

  const sendToSuppliersDisabled = (markAllComplete === false || sentToSupplier === true || submittedForReview === false || approved === false)
  const sendToCustomerDisabled = (markAllComplete === false || sentToCustomer === true || submittedForReview === false || approved === false)

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            Signoff / Approval
            <InfoMarker id='imSignoffApproval' title='Signoff / Approval' bodyCopy='This section allows you to lock the SOW and submit it for internal review and approval, following which you can then release to your Customer and Suppliers accordingly.' />
          </CardTitle>
          <Row>
            <Col sm='12'>
              <div className='custom-control custom-checkbox mb-2 mt-3 pt-1' style={{ height: '2rem' }}>
                <input type='checkbox' className='custom-control-input' id='CustomCheck1' onChange={() => false} checked={markAllComplete} />
                <label className='custom-control-label' onClick={() => { markedCompleteToggled(!markAllComplete) }}>Mark all sections complete</label>
                <InfoMarker style={{ display: 'inline-block', marginTop: '-3px' }} id='imMarkSec' title='Mark all Sections Complete' bodyCopy='Checking this box will lock all sections from being amended. You need to select this option in order to submit the SOW for internal review and approval.' />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm='4'>
              <InfoMarker className='float-right' id='imSubmit' title='Submit for Review' bodyCopy='Clicking this Button will send the SOW to your internal stakeholders to review, comment and/or approve as neccesary.' />
              <SowaasButton
                disabled={markAllComplete === false || submittedForReview === true}
                className='btn-block'
                onClick={submitForReview}
                title={submittedForReview ? 'Submitted' : 'Submit for Review'}
              />
            </Col>
            <Col sm='4'>
              <InfoMarker className='float-right' id='imSendToCustomer' title='Send to Customer' bodyCopy='When the SOW has been approved by internal review you will be able to release the SOW to your Customer by clicking this button.' />
              <SowaasButton
                disabled={sendToCustomerDisabled}
                className='btn-block'
                onClick={() => { setSentToCustomerModalOpen(true) }}
                title={sentToCustomer ? 'Sent to Customer' : 'Send to Customer'}
              />
            </Col>
            <Col sm='4'>
              <InfoMarker className='float-right' id='imSendToSupplier' title='Send to Supplier' bodyCopy='When the SOW has been approved by internal review you will be able to release the SOW to your Suppliers by clicking this button.' />
              <SowaasButton
                disabled={sendToSuppliersDisabled}
                className='btn-block'
                onClick={() => { setSentToSupplierModalOpen(true) }}
                title={sentToSupplier ? 'Sent to Supplier' : 'Send to Supplier'}
                id='stsButton'
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ApprovalModal
        isOpen={sentToCustomerModalOpen}
        setOpen={(v) => { setSentToCustomerModalOpen(v) }}
        onSubmit={sentToCustomerConfirmed}
        title='Send to Customer'
        message='Are you sure you wish to send the Solution to the customer?'
        confirmMessage='Yes, Send to Customer'
      />
      <ApprovalModal
        isOpen={sentToSupplierModalOpen}
        setOpen={(v) => { setSentToSupplierModalOpen(v) }}
        onSubmit={sentToSupplierConfirmed}
        title='Send to Supplier'
        message='Are you sure you wish to send the Solution to the supplier?'
        confirmMessage='Yes, Send to Supplier'
      />
    </>
  )
}

export default SolutionSignoffRecruiter

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ServiceReportReviewTable from '../serviceReportReviewTable/serviceReportReviewTable'

const ServiceReportReviewModal = ({ isOpen, setOpen, serviceReports }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Service Report Review
          </ModalHeader>
          <ModalBody>
            <ServiceReportReviewTable serviceReports={serviceReports} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default ServiceReportReviewModal

import React, { useState } from 'react'
import { Card, CardTitle, CardBody, Row, Col } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import ReactApexChart from 'react-apexcharts'
import ManagementSalesSummaryModal from '../managmentSalesSummaryModal/managmentSalesSummaryModal'

const ManagementSalesSummary = () => {
  const [managementSalesSummaryModalOpen, setManagementSalesSummaryModalOpen] = useState(false)

  const series = [1, 1, 1, 1, 1, 1]
  const options = {
    labels: [
      'New Project SOW Created',
      'SOW Service - Design In Progress',
      'SOW Service - Submitted for sign-off',
      'Released to Customer',
      'Released to Suppliers',
      'Approved/Rejected'
    ],
    colors: ['#090979', '#0dffe7', '#AD19FE'],
    legend: {
      show: true
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + '%'
      }
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          setManagementSalesSummaryModalOpen(options.labels[config.dataPointIndex])
        }
      }
    }
  }

  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody className='pb-3'>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Sales Summary
                <InfoMarker title='Sales Summary' bodyCopy='' />
              </CardTitle>
            </Col>
          </Row>
          <Row sm='12'>
            <ReactApexChart options={options} series={series} type='pie' width='600' />
          </Row>
        </CardBody>
      </Card>
      {
        managementSalesSummaryModalOpen !== false && (
          <ManagementSalesSummaryModal
            isOpen={(managementSalesSummaryModalOpen !== false)}
            setOpen={setManagementSalesSummaryModalOpen}
            title={managementSalesSummaryModalOpen}
          />
        )
      }
    </>
  )
}

export default ManagementSalesSummary

import { ADD_MESSAGE, ADD_MESSAGES, CLEAR_MESSAGES, UPDATE_MESSAGE, DELETE_MESSAGE, OPEN_MESSAGE, GET_MESSAGES, POST_MESSAGE, DEL_MESSAGE, MARK_MESSAGE_READ } from './actionTypes'

export const addMessage = message => ({
  type: ADD_MESSAGE,
  payload: { message }
})

export const addMessages = messages => ({
  type: ADD_MESSAGES,
  payload: { messages }
})

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
  payload: {}
})

export const updateMessage = message => ({
  type: UPDATE_MESSAGE,
  payload: { message }
})

export const deleteMessage = message => ({
  type: DELETE_MESSAGE,
  payload: { message }
})

export const openMessage = message => ({
  type: OPEN_MESSAGE,
  payload: { message }
})

export const getMessages = () => ({
  type: GET_MESSAGES
})

export const postMessage = message => ({
  type: POST_MESSAGE,
  payload: { message }
})

export const delMessage = message => ({
  type: DEL_MESSAGE,
  payload: { message }
})

export const markMessageAsRead = message => ({
  type: MARK_MESSAGE_READ,
  payload: { message }
})

import React from 'react'
import { Modal, ModalHeader, ModalBody, Col } from 'reactstrap'
import EngagementDesignForm from '../engagementDesign/engagementDesignForm'

const EngagementInfoModal = ({ isOpen, setOpen, project }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Engagement Info
          </ModalHeader>
          <ModalBody>
            <Col sm='12'>
              <EngagementDesignForm project={project} readOnly embedded />
            </Col>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default EngagementInfoModal

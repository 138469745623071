import React, { useState } from 'react'
import { Badge, Col, Row, Card, CardBody, CardTitle, CardFooter } from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import FinancesModal from '../financesModal/financesModal'

const series = [0, 100000]
const options = {
  labels: ['Amount Spent / Invoiced', 'Amount Remaining'],
  colors: ['#090979', '#74788d'],
  legend: {
    show: false
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return Math.round(val) + '%'
    }
  }
}

const FinancesCard = ({ mode = 'full', readOnly = false }) => {
  const [financesModalOpen, setFinancesModalOpen] = useState(false)

  const onFinancesSubmitted = () => {
    setFinancesModalOpen(false)
  }

  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Finances
                <InfoMarker title='Finances' bodyCopy='Within the Finance section is a snapshot of the service / projects financial performance including costs and revenue captured to date.' />
              </CardTitle>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col sm='6'>
              <ReactApexChart options={options} series={series} type='pie' />
            </Col>
            <Col sm='6'>
              <h6>Total Service Value: £100,000*</h6>
              <h5><Badge color='primary'>Invoiced To Date (Total): £0</Badge></h5>
              <h5><Badge color='secondary'>Amount Remaining: £100,000</Badge></h5>
              <SowaasButton title='View Finances' className='btn-block mt-4' onClick={() => setFinancesModalOpen(true)} />
            </Col>
          </Row>
        </CardBody>
        <CardFooter style={{ backgroundColor: 'white' }}>
          <div className='float-right'>* excl VAT.</div>
        </CardFooter>
      </Card>
      {
        financesModalOpen && (
          <FinancesModal readOnly={readOnly} mode={mode} isOpen={financesModalOpen} setOpen={setFinancesModalOpen} onSubmit={onFinancesSubmitted} />
        )
      }
    </>
  )
}

export default FinancesCard

import { takeEvery, fork, put, all } from 'redux-saga/effects'

import { ACTIVATE_SUBMIT } from './actionTypes'
import { userActivateSubmitSuccess, userActivateSubmitError } from './actions'

function * userActivateSubmit ({ payload }: any): any {
  try {
    yield put(userActivateSubmitSuccess('Your account has been activated, please login to continue to the application'))
  } catch (ex) {
    yield put(userActivateSubmitError('There was an error activating your account, please contact support'))
  }
}

export function * watchUserActivate (): any {
  yield takeEvery(ACTIVATE_SUBMIT, userActivateSubmit)
}

function * activateSaga (): any {
  yield all([fork(watchUserActivate)])
}

export default activateSaga

import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import BooleanToggle from '../booleanToggle/booleanToggle'
import InfoMarker from '../infoMarker/infoMarker'
import FileUpload from '../fileUpload/fileUpload'
import { AvInput } from 'availity-reactstrap-validation'
import _ from 'lodash'

const SupplierRow = ({ supplier, idx, readOnly = true, supplierFiles = [] }) => {
  const {
    id
  } = supplier

  const ssaRecord = _.find(supplierFiles, (sf) => (_.get(sf, 'file.fileType', '') === 'SSA')) || { fileProvided: false, id: '' }
  const ndaRecord = _.find(supplierFiles, (sf) => (_.get(sf, 'file.fileType', '') === 'NDA')) || { fileProvided: false, id: '' }

  const [ssaRecieved, setSsaRecieved] = useState(ssaRecord.fileProvided)
  const [ndaRecieved, setNdaRecieved] = useState(ndaRecord.fileProvided)

  return (
    <>
      {
        id && (
          <AvInput type='hidden' name={`legalDesignSupplierFiles[${idx}].supplierId`} value={id} />
        )
      }
      <Row className='mt-2'>
        <Col sm='12'>
          <div className='styledTitle'>
            {supplier.name}
          </div>
        </Col>
      </Row>
      <Row key={idx}>
        <Col sm='12'>
          <Row className='ml-3'>
            {
              (ssaRecord.id) !== '' && (
                <AvInput type='hidden' name={`legalDesignSupplierFiles[${idx}].SSA.id`} value={ssaRecord.id} />
              )
            }
            <Col sm='3'>
              <div className='styledTitle'>
                Supplier Services Agreement Signed
                <InfoMarker id='imSSA' title='Supplier Services Agreement' bodyCopy='If your supplier is known, they should have already signed a service agreement, if not (or they are new) you can choose to send them your standard supplier services agreement' />
              </div>
            </Col>
            <Col sm='2'>
              <BooleanToggle disabled={readOnly} className='mb-0' name={`legalDesignSupplierFiles[${idx}].SSA.fileProvided`} onValueChanged={setSsaRecieved} currentValue={ssaRecieved} />
            </Col>
            {
              ssaRecieved === true && (
                <Col sm='4'>
                  <FileUpload formName={`legalDesignSupplierFiles[${idx}].SSA.fileId`} readOnly={readOnly} fileType='SSA' existingFile={ssaRecord.file} />
                </Col>
              )
            }
          </Row>
          <Row className='ml-3'>
            {
              (ndaRecord.id) !== '' && (
                <AvInput type='hidden' name={`legalDesignSupplierFiles[${idx}].NDA.id`} value={ndaRecord.id} />
              )
            }
            <Col sm='3'>
              <div className='styledTitle'>
                NDA Received
                <InfoMarker title='NDA Received' bodyCopy='It is recommended at all times for an NDA to be signed between parties if discussing potentially sensitive or confidential items. You can upload the NDA from your Customer here, or elect to send a standard mutual NDA for signing.' />
              </div>
            </Col>
            <Col sm='2'>
              <BooleanToggle disabled={readOnly} onValueChanged={setNdaRecieved} name={`legalDesignSupplierFiles[${idx}].NDA.fileProvided`} currentValue={ndaRecieved} />
            </Col>
            {
              ndaRecieved === true && (
                <Col sm='4'>
                  <FileUpload formName={`legalDesignSupplierFiles[${idx}].NDA.fileId`} readOnly={readOnly} fileType='NDA' existingFile={ndaRecord.file} />
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default SupplierRow

import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const InfoMarker = ({ title = '', bodyCopy = '', id = '', className = '', style = {} }) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const toggle = () => setPopoverOpen(!popoverOpen)

  return (
    <>
      <i id={id} className={`mdi mdi-information pl-1 ${className}`} onClick={() => { setPopoverOpen(true) }} style={{ color: '#0DD0CD', cursor: 'pointer', fontSize: '18px', ...style }} />
      <Modal centered placement='auto' isOpen={popoverOpen} target={id} toggle={toggle} size='md'>
        <ModalHeader toggle={toggle}>
          {title}
        </ModalHeader>
        <ModalBody>{bodyCopy}</ModalBody>
      </Modal>
    </>
  )
}

export default InfoMarker

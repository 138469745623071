import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import FullCalendarCard from '../../components/fullCalendar/fullCalendarCard'
import Messaging from '../../components/messaging/Messaging'
import Notifications from '../../components/notifications/Notifications'
import PageTitle from '../../components/pageTitle/pageTitle'

const ActivityFeed = () => {
  const notifications = useSelector((state) => state.Notification.notifications)
  const messages = useSelector((state) => state.Message.messages)
  const { role } = useSelector((state) => state.User)

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xl='12'>
              <PageTitle className='mb-3' title='Activity Feed' icon='bx-pulse' />
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col>
              <FullCalendarCard />
            </Col>
            <Col>
              <Notifications notifications={notifications} role={role} hideView />
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Messaging messages={messages} role={role} numberToShow={50} hideView />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ActivityFeed

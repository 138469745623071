export const ADD_SUPPLIER = 'ADD_SUPPLIER'
export const ADD_SUPPLIERS = 'ADD_SUPPLIERS'
export const CLEAR_SUPPLIERS = 'CLEAR_SUPPLIERS'
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER'
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER'

export const GET_SUPPLIERS = 'GET_SUPPLIERS'
export const POST_SUPPLIER = 'POST_SUPPLIER'
export const PUT_SUPPLIER = 'PUT_SUPPLIER'
export const DEL_SUPPLIER = 'DEL_SUPPLIER'

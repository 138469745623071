import React, { useState, useEffect } from 'react'
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { AvInput } from 'availity-reactstrap-validation'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const SowaasTextArea = ({ value = '', placeholder = '', disabled = false, name = '', required }) => {
  useEffect(
    () => {
      const blocksFromHtml = convertFromHTML(value)
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      )
      setEditorState(EditorState.createWithContent(contentState))
      convertToRaw(contentState)
    },
    []
  )

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [currentValue, setCurrentValue] = useState()

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)

    const rawMessageContent = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))
    setCurrentValue(rawMessageContent)
  }

  return (
    <>
      <Editor
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        toolbarHidden
        editorState={editorState}
        placeholder={placeholder}
        readOnly={disabled}
        stripPastedStyles
        onEditorStateChange={onEditorStateChange}
      />
      <AvInput type='hidden' name={name} value={JSON.stringify(currentValue)} />
    </>
  )
}

export default SowaasTextArea

import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import RaidModal from '../raidModal/raidModal'
import SowaasButton from '../sowaasButton/sowaasButton'

const RaidLogCard = ({ raidCategories = [], priorities = [], onSubmit, raidItems = [] }) => {
  const [raidLogOpen, setRaidLogOpen] = useState(false)

  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Raid Log
                <InfoMarker title='Raid Log' bodyCopy='A RAID log provides quick view on the key consideration points relating to a project or service encompassing Risks, Assumptions, Issues and Dependencies. The RAID log provides an easy way to record this key information' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setRaidLogOpen(true) }} title='View RAID Log' />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {
        raidLogOpen && (
          <RaidModal raidItems={raidItems} isOpen={raidLogOpen} setOpen={setRaidLogOpen} raidCategories={raidCategories} priorities={priorities} onSubmit={onSubmit} />
        )
      }
    </>
  )
}

export default RaidLogCard

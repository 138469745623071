import React from 'react'
import styled from 'styled-components'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledHelpButton = styled(SowaasButton)`
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 100px;
`

const HelpButton = () => {
  return (
    <StyledHelpButton
      className='btn btn-primary btn-block waves-effect waves-light'
      type='submit'
      title='Help'
    />
  )
}

export default HelpButton

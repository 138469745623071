import React from 'react'
import Chart from 'react-apexcharts'
import moment from 'moment'
import _ from 'lodash'

const ProjectPlanChart = ({ dataItems = [] }) => {
  if (dataItems.length === 0) {
    return null
  }

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

  const allData = _.orderBy(dataItems, ['start'], ['asc'])
  const dataCategories = _.map(allData, (data) => { return data.name + ' ' + capitalizeFirstLetter(data.type) })

  const series = allData.map((data) => (
    {
      x: data.name + ' ' + capitalizeFirstLetter(data.type),
      y: [
        moment(data.start).valueOf(),
        moment(data.end).valueOf()
      ],
      color: data.color
    }
  ))

  const minDate = _.minBy(series, (s) => { return s.y[0] }).y[0]
  const maxDate = _.maxBy(series, (s) => { return s.y[1] }).y[1]

  const chartHeight = (allData.length * 35) + 'px'

  const options = {
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        dataLabels: {
          position: 'bottom'
        }
      }
    },
    fill: {
      colors: [function ({ dataPointIndex }) {
        const seriesItem = series[dataPointIndex]
        const { color = '#FF0000' } = seriesItem
        return color
      }]
    },
    xaxis: {
      type: 'datetime',
      min: minDate,
      max: maxDate + 1,
      categories: dataCategories,
      labels: {
        show: true,
        format: 'dd/MM/yyyy',
        style: {
          colors: ['#495057']
        }
      }
    },
    tooltip: {
      enabled: false
    },
    legend: {
      show: false
    }
  }

  return (
    <Chart options={options} series={[{ data: series }]} type='rangeBar' height={chartHeight} />
  )
}

export default ProjectPlanChart

import React from 'react'
import { Col, Row, Card, CardBody, CardTitle, Table } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import moment from 'moment'

const DeliverablesCard = ({ deliverables }) => {
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody className='pb-3'>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Deliverables
                <InfoMarker title='Deliverables' bodyCopy='Displayed here will be a list of the Deliverables as defined in the SOW for quick and easy reference' />
              </CardTitle>
            </Col>
          </Row>
          <Row sm='12'>
            <Table hover className='mb-0'>
              <tbody>
                {
                  deliverables.map((d, idx) => (
                    <tr key={idx}>
                      <td>{d.name}</td>
                      <td style={{ width: '40%', textAlign: 'right' }}>{moment(d.start).format('DD/MM/YY')} - {moment(d.end).format('DD/MM/YY')}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default DeliverablesCard

import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import BooleanToggle from '../booleanToggle/booleanToggle'
import { AvForm } from 'availity-reactstrap-validation'

const StyledCard = styled.div`
  padding: 5px 0;
`

const PreSalesInformation = () => {
  const [supportRequired, setSupportRequired] = useState(false)
  const [supportType, setSupportType] = useState('')
  const [supportDate, setSupportDate] = useState('')

  const renderSection = () => {
    if (supportRequired === true) {
      return (
        <>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Type of Support:
                </div>
              </Col>
              <Col sm='8'>
                <div className='btn-group btn-group-toggle' data-toggle='buttons'>
                  <label
                    className={
                      supportType === 'phone'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='phone'
                      onClick={() => { setSupportType('phone') }}
                    />{' '}
                    Phone
                  </label>

                  <label
                    className={
                      supportType === 'video'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='video'
                      onClick={() => { setSupportType('video') }}
                    />{' '}
                    Video
                  </label>

                  <label
                    className={
                      supportType === 'meeting'
                        ? 'btn btn-primary active'
                        : 'btn btn-primary'
                    }
                  >
                    <input
                      type='radio'
                      name='options'
                      id='meeting'
                      onClick={() => { setSupportType('meeting') }}
                    />{' '}
                    Meeting
                  </label>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Date Required:
                </div>
              </Col>
              <Col sm='8'>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Enter date'
                  onChange={(event) => setSupportDate(event.target.value)}
                  value={supportDate}
                />
              </Col>
            </Row>
          </StyledCard>
        </>
      )
    }
  }

  return (
    <AvForm>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                PreSales
              </CardTitle>
            </Col>
          </Row>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Support Required:
                </div>
              </Col>
              <Col sm='8'>
                <BooleanToggle value={supportRequired} onValueChanged={setSupportRequired} />
              </Col>
            </Row>
          </StyledCard>
          {renderSection()}
        </CardBody>
      </Card>
    </AvForm>
  )
}

export default PreSalesInformation

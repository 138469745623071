import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import ServiceReportModal from '../serviceReportModal/serviceReportModal'
import ServiceReportReviewModal from '../serviceReportReviewModal/serviceReportReviewModal'
import SowaasButton from '../sowaasButton/sowaasButton'

const ServiceReportCard = ({ onNewServiceReport, serviceReports = [] }) => {
  const [newServiceReportModalOpen, setNewServiceReportModalOpen] = useState(false)
  const [serviceReportReviewModalOpen, setServiceReportReviewModalOpen] = useState(false)
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-3'>
                Service Report
                <InfoMarker title='Service Report' bodyCopy='The service report is created to provide a consolidated view on everything being delivered in the project or service and is presented to the buyer of the service.' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setServiceReportReviewModalOpen(true) }} title='Service Report Review' />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setNewServiceReportModalOpen(true) }} title='Service Report Entry' />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {
        newServiceReportModalOpen && (
          <ServiceReportModal isOpen={newServiceReportModalOpen} setOpen={setNewServiceReportModalOpen} onNewServiceReport={onNewServiceReport} />
        )
      }
      {
        serviceReportReviewModalOpen && (
          <ServiceReportReviewModal isOpen={serviceReportReviewModalOpen} setOpen={setServiceReportReviewModalOpen} serviceReports={serviceReports} />
        )
      }
    </>
  )
}

export default ServiceReportCard

import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import Solution from '../solution/solution'
import RecruiterSolution from '../recruiterSolution/recruiterSolution'
import InfoMarker from '../../components/infoMarker/infoMarker'
import { useTranslation } from 'react-i18next'

const SolutionIndex = () => {
  const view = useSelector((state) => state.User.role)
  const { t } = useTranslation()

  const renderSolution = (view) => {
    switch (view) {
      case 'recruiter_consultant': return (
        <RecruiterSolution />
      )
      case 'recruiter_executive': return (
        <RecruiterSolution />
      )
      case 'legal_finance': return (
        <RecruiterSolution />
      )
      default: return <Solution />
    }
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm='12'>
              <h1 style={{ display: 'inline-block' }} className='mb-3'>SOW Service Solution</h1>
              <InfoMarker title='New Project Help' bodyCopy={t('lorem')} id='imNewProject' />
            </Col>
          </Row>
          {renderSolution(view)}
        </Container>
      </div>
    </>
  )
}

export default (SolutionIndex)

import React, { useState } from 'react'
import { Row, Col, Container, Card, CardBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { postCustomer, putCustomer } from '../../store/customer/actions'

import CustomerTable from '../../components/customerTable/customerTable'
import CustomerModal from '../../components/customerModal/customerModal'
import PageTitle from '../../components/pageTitle/pageTitle'
import SowaasButton from '../../components/sowaasButton/sowaasButton'
import VideoMarker from '../../components/videoMarker/videoMarker'

const Customers = () => {
  const dispatch = useDispatch()
  const { customers } = useSelector(state => state.Customer)

  const [customerOnboardOpen, setCustomerOnboardOpen] = useState(false)
  const [activeCustomer, setActiveCustomer] = useState(undefined)

  const onAddCustomer = () => {
    setActiveCustomer(undefined)
    setCustomerOnboardOpen(true)
  }

  const onViewCustomer = (customer) => {
    setActiveCustomer(customer)
    setCustomerOnboardOpen(true)
  }

  const onAddCustomerSubmit = (customer) => {
    if ('id' in customer) {
      dispatch(putCustomer(customer))
    } else {
      dispatch(postCustomer(customer))
    }

    setActiveCustomer(undefined)
    setCustomerOnboardOpen(false)
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <Row>
          <Col xl='12'>
            <PageTitle className='mb-3' title='Customers' icon='bx-buildings' />
          </Col>
        </Row>
        <Card className='overflow-hidden card-100'>
          <CardBody>
            {
              customers.length === 0 && (
                <p>You do not currently have any customers</p>
              )
            }
            {
              customers.length > 0 && (
                <CustomerTable customers={customers} onCustomerSelected={onViewCustomer} />
              )
            }
            <Row>
              <Col sm='12' style={{ display: 'flex' }}>
                <SowaasButton onClick={onAddCustomer} title='Add Customer' className='mr-1' />
                <VideoMarker id='vmAddCustomer' title='Add Customer' videoUrl='https://vimeo.com/500951803/0157198e42' />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {
          customerOnboardOpen === true && (
            <CustomerModal isOpen={customerOnboardOpen} setOpen={setCustomerOnboardOpen} onSubmit={onAddCustomerSubmit} forCustomer={activeCustomer} />
          )
        }
      </Container>
    </div>
  )
}

export default Customers

import React from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap'
import SowaasButton from '../sowaasButton/sowaasButton'

const notificationTypes = {
  StatusUpdateTU: {
    ctaLabel: 'View TU'
  },
  ReviewTU: {
    ctaLabel: 'View TU'
  },
  ReviewCR: {
    ctaLabel: 'View CR'
  },
  ReviewSOWService: {
    ctaLabel: 'View SOW Service'
  },
  StatusUpdateInvoice: {
    ctaLabel: 'View Invoice'
  },
  ReviewInvoice: {
    ctaLabel: 'View Invoice'
  }
}

const NotificationModal = ({ isOpen, setOpen, notification, onClickCTA }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const notificationTypeData = notificationTypes[notification.notificationType]

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Notification
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm='12'>
                <div className='styledTitle'>{notification.subject}</div>
                <p className='mt-3 mb-4'>{notification.content}</p>
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right' title={notificationTypeData.ctaLabel} onClick={() => { onClickCTA(notification) }} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default NotificationModal

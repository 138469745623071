import React, { useState } from 'react'
import { Col, Row, Modal, ModalHeader, ModalBody, Table } from 'reactstrap'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import styled from 'styled-components'
import BooleanToggle from '../booleanToggle/booleanToggle'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import produce from 'immer'
import SupplierContactRow from './supplierContactRow'
import VideoMarker from '../videoMarker/videoMarker'

const StyledCard = styled.div`
  padding: 5px 0;
`

const SupplierOnboardModal = ({ isOpen, setOpen, onSubmit, forSupplier = {} }) => {
  const {
    id = '',
    tradingName = '',
    name = '',
    vatNumber = '',
    address = '',
    isNdaRequired = false,
    isSupplierAgreementRequired = false,
    ndaReason = '',
    supplierAgreementReason = '',
    contacts = [{}]
  } = forSupplier

  const [contactsValues, setContactsValues] = useState(contacts)

  const addContact = () => {
    const nextContacts = produce(contactsValues, draftContacts => {
      draftContacts.push({})
    })
    setContactsValues(nextContacts)
  }

  const [ndaRequiredValue, setNdaRequiredValue] = useState(isNdaRequired)
  const [supplierAgreementRequiredValue, setSupplierAgreementRequiredValue] = useState(isSupplierAgreementRequired)

  const editingExisting = (id !== '')

  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSubmit({ ...values })
    }
  }

  return (
    <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='xxl'>
      <div className='modal-content'>
        <ModalHeader toggle={onClose}>
          {(editingExisting ? 'Update' : 'New')} Supplier Details
          <VideoMarker id='vmAddSupplier' title='Add Supplier' videoUrl='https://vimeo.com/500952234/6b4e742874' />
        </ModalHeader>
        <ModalBody>
          <Col sm='12'>
            <AvForm className='needs-validation' onSubmit={handleSubmit}>
              {
                editingExisting && (
                  <>
                    <AvInput type='hidden' name='id' value={id} />
                  </>
                )
              }
              <StyledCard>
                <Row>
                  <Col sm='2'>
                    <div className='styledTitle'>
                      Supplier Registered Company Name
                    </div>
                  </Col>
                  <Col sm='1' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Supplier Registered Company Name' bodyCopy="Start typing the Supplier's registered company name and select from the dropdown list linked to Companies House" id='imCompanyName' />
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='name'
                      placeholder='Enter company name'
                      type='text'
                      errorMessage='Enter valid company name'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      id='validationCompanyName'
                      value={name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='2'>
                    <div className='styledTitle'>
                      Supplier Trading Name
                    </div>
                  </Col>
                  <Col sm='1' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Supplier Trading Name' bodyCopy='If the Supplier trades by a different name to their registered name, enter their details here' id='imSupplierTradingName' />
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='tradingName'
                      placeholder='Enter trading name'
                      type='text'
                      errorMessage='Enter valid trading name'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      value={tradingName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='2'>
                    <div className='styledTitle'>
                      VAT Number
                    </div>
                  </Col>
                  <Col sm='1' className='pt-1 text-right pr-0'>
                    <InfoMarker title='VAT Number' bodyCopy={(<>The VAT number of the Supplier (if applicable). You can find this information via <a href='https://vat-search.co.uk/' target='_blank' rel='noopener noreferrer'>this</a> VAT search website.</>)} id='imVATNumber' />
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='vatNumber'
                      placeholder='Enter VAT number'
                      type='text'
                      errorMessage='Enter VAT number'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      value={vatNumber}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='2'>
                    <div className='styledTitle'>
                      Supplier Address
                    </div>
                  </Col>
                  <Col sm='1' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Supplier Address' bodyCopy='The registered company address for the Supplier. This information is pulled from Companies House, this can be overwritten if needed.' id='imAddress' />
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='address'
                      placeholder='Enter address'
                      type='text'
                      errorMessage='Enter valid address'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      value={address}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='2'>
                    <div className='styledTitle'>
                      NDA Required
                    </div>
                  </Col>
                  <Col sm='1' className='pt-1 text-right pr-0'>
                    <InfoMarker title='NDA Required' bodyCopy={(<>It is recommended that every Supplier (active or potential) signs a valid NDA.<br /><br />A non-disclosure agreement is a legal contract between two parties, outlining confidential material, knowledge or information that the parties wish to share with one another, but with certain restrictions in place.</>)} id='imNdaRequired' />
                  </Col>
                  <Col sm='2'>
                    <BooleanToggle name='isNdaRequired' currentValue={ndaRequiredValue} className='mb-0' onValueChanged={setNdaRequiredValue} />
                  </Col>
                  {
                    (ndaRequiredValue === true && !editingExisting) && (
                      <Col sm='4' style={{ display: 'flex', paddingTop: '0.3rem' }}>
                        <AvField type='checkbox' name='ndaRequiredToBeSent' /><p>Send NDA to New Supplier</p>
                      </Col>
                    )
                  }
                  {
                    (ndaRequiredValue === false) && (
                      <Col sm='7'>
                        <AvInput required type='text' name='ndaReason' placeholder='Why is an NDA not required?' value={ndaReason} />
                      </Col>
                    )
                  }
                </Row>
                <Row>
                  <Col sm='2'>
                    <div className='styledTitle'>
                      Supplier Agreement Required
                    </div>
                  </Col>
                  <Col sm='1' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Supplier Agreement Required' bodyCopy='A supplier agreement outlines the terms and conditions against which the Supplier and your organisation is accounatable, it defines the conditions of contractual engagement. The Supplier agreement accompanies the Statement of Work (SOW) and it is recommended all terms/conditions are kept separate from the SOW.' id='imSupplierAgreementRequired' />
                  </Col>
                  <Col sm='2'>
                    <BooleanToggle name='isSupplierAgreementRequired' currentValue={supplierAgreementRequiredValue} className='mb-0' onValueChanged={setSupplierAgreementRequiredValue} />
                  </Col>
                  {
                    (supplierAgreementRequiredValue === true && !editingExisting) && (
                      <Col sm='4' style={{ display: 'flex', paddingTop: '0.3rem' }}>
                        <AvField required type='checkbox' name='isSupplierAgreementRequired' /><p>Send Agreement to New Supplier</p>
                      </Col>
                    )
                  }
                  {
                    (supplierAgreementRequiredValue === false) && (
                      <Col sm='7'>
                        <AvInput type='text' name='supplierAgreementReason' placeholder='Why is an Agreement not required?' value={supplierAgreementReason} />
                      </Col>
                    )
                  }
                </Row>
                <Row>
                  <Col sm='12'>
                    <div className='styledTitle'>
                      Supplier Contacts
                      <InfoMarker title='Supplier Contacts' bodyCopy='' id='imSupplierContacts' />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ width: '8rem' }}>First Name</th>
                          <th style={{ width: '8rem' }}>Second Name</th>
                          <th>Rate</th>
                          <th style={{ width: '10rem' }}>Contact Phone</th>
                          <th style={{ width: '10rem' }}>Contact Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          contactsValues.map((s, idx) => (
                            <SupplierContactRow key={idx} idx={idx} s={s} />
                          ))
                        }
                      </tbody>
                    </Table>
                    <SowaasButton id='btnSupplierAddContact' className='mr-2' onClick={addContact} title='Add Contact' />
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <SowaasButton id='btnSupplierSubmit' className='float-right' title={(editingExisting ? 'Update' : 'Save')} type='submit' />
                  </Col>
                </Row>
              </StyledCard>
            </AvForm>
          </Col>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default SupplierOnboardModal

import {
  ADD_SUPPLIER,
  ADD_SUPPLIERS,
  CLEAR_SUPPLIERS,
  UPDATE_SUPPLIER,
  DELETE_SUPPLIER,
  GET_SUPPLIERS,
  POST_SUPPLIER,
  PUT_SUPPLIER,
  DEL_SUPPLIER
} from './actionTypes'

export const addSupplier = supplier => ({
  type: ADD_SUPPLIER,
  payload: { supplier }
})

export const addSuppliers = suppliers => ({
  type: ADD_SUPPLIERS,
  payload: { suppliers }
})

export const clearSuppliers = () => ({
  type: CLEAR_SUPPLIERS,
  payload: {}
})

export const updateSupplier = supplier => ({
  type: UPDATE_SUPPLIER,
  payload: { supplier }
})

export const deleteSupplier = supplier => ({
  type: DELETE_SUPPLIER,
  payload: { supplier }
})

export const getSuppliers = () => ({
  type: GET_SUPPLIERS
})

export const postSupplier = supplier => ({
  type: POST_SUPPLIER,
  payload: { supplier }
})

export const putSupplier = supplier => ({
  type: PUT_SUPPLIER,
  payload: { supplier }
})

export const delSupplier = supplier => ({
  type: DEL_SUPPLIER,
  payload: { supplier }
})

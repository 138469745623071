import React from 'react'
import { Row, Col } from 'reactstrap'

// Pages Components
import SalesSummary from '../../components/salesSummary/SalesSummary'
import SolutionsSummary from '../../components/solutionsSummary/SolutionsSummary'
import EngagementSummary from '../../components/engagementSummary/EngagementSummary'
import Notifications from '../../components/notifications/Notifications'
import Messaging from '../../components/messaging/Messaging'
import NewRequestClient from '../../components/newRequestClient/NewRequestClient'

import { getSalesSummary, getSolutionsSummary, getEngagementSummary } from './actions'

const ClientDashboard = ({ messaging, notifications, history }) => {
  const solutionClicked = (solution) => {
    history.push(`/solution/${solution.id}`)
  }

  return (
    <>
      <Row>
        <Col sm='6'>
          <SalesSummary salesSummary={getSalesSummary()} />
          <EngagementSummary engagementSummary={getEngagementSummary()} />
          <Notifications notifications={notifications} />
        </Col>
        <Col sm='6'>
          <SolutionsSummary solutionsSummary={getSolutionsSummary()} solutionClicked={solutionClicked} />
          <NewRequestClient />
          <Messaging messages={messaging} />
        </Col>
      </Row>
    </>
  )
}

export default ClientDashboard

import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import smallDateFormatter from '../../helpers/smallDateFormatter'
import MessagingHubSidebar from './MessagingHubSidebar'

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 4% 25% 55% 16%;
  color: #74788d;
`

const StyledUl = styled.ul`
  list-style: none;
  padding: 0.5rem 1rem;

  :hover {
    background: #f6f6f6;
    transition-duration: .05s;
  }
`

const AlignRight = styled.div`
  text-align: right;
`

const MessagingHub = () => {
  const messages = useSelector((state) => state.Message.messages)

  const GroupedEmailThreads = (messages) => {
    const messageGroup = messages.sort((a, b) => (
      new Date(b.Date) - new Date(a.Date)
    ))
    return (
      Object.values(messageGroup.reduce((r, a) => {
        r[a.EEID] = [...r[a.EEID] || [], a]
        return r
      }, {}))
    )
  }

  let unreadMessageSum = 0
  messages.forEach(m => {
    unreadMessageSum += (m.Unread ? 1 : 0)
  })

  const messageData = GroupedEmailThreads(messages)
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xs='12'>
              <MessagingHubSidebar unreadCount={unreadMessageSum} />
              <div className='email-rightbar mb-3'>
                <Card className='overflow-hidden'>
                  <CardBody>
                    <Row>
                      <Col sm='12'>
                        <div className='styledTitle'>
                          Messaging Hub
                        </div>
                      </Col>
                    </Row>
                    {
                      messageData.length === 0 && (
                        <p>
                          You do not have any messages yet, to get started Compose a new message.
                        </p>
                      )
                    }
                    {
                      messageData.length > 0 && (
                        <>
                          {messageData.map((message, idx) => (
                            <Link key={idx} to={`/message/${message[0].EEID}`}>
                              <StyledUl>
                                <li>
                                  <StyledRow>
                                    <div>
                                      {(message[0].Unread === true) ? <span className='fas fa-circle text-info' /> : null}
                                    </div>
                                    <div>
                                      {message[0].Name}
                                    </div>
                                    <div>
                                      {message[0].Title}
                                    </div>
                                    <AlignRight>
                                      {smallDateFormatter(message[0].Date)}
                                    </AlignRight>
                                  </StyledRow>
                                </li>
                              </StyledUl>
                            </Link>
                          ))}
                        </>
                      )
                    }
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MessagingHub

import React from 'react'
import { Col, Row } from 'reactstrap'
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const ProfileForm = ({ onProfileUpdated, profile = {} }) => {
  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onProfileUpdated(values)
    }
  }

  const { firstname = '', lastname = '', jobTitle = '', company = '', id = '' } = profile

  return (
    <>
      <AvForm className='validation-required' onSubmit={handleSubmit}>
        <AvInput type='hidden' name='id' value={id} />
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              First Name
            </div>
          </Col>
          <Col sm='9'>
            <AvField
              name='firstname'
              placeholder='Enter first name'
              type='text'
              errorMessage='Enter first name'
              className='form-control'
              validate={{ required: { value: true } }}
              value={firstname}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Last Name
            </div>
          </Col>
          <Col sm='9'>
            <AvField
              name='lastname'
              placeholder='Enter last name'
              type='text'
              errorMessage='Enter last name'
              className='form-control'
              validate={{ required: { value: true } }}
              value={lastname}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Job Title
            </div>
          </Col>
          <Col sm='9'>
            <AvField
              name='jobTitle'
              placeholder='Enter job title'
              type='text'
              errorMessage='Enter job title'
              className='form-control'
              validate={{ required: { value: true } }}
              value={jobTitle}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Company
            </div>
          </Col>
          <Col sm='9'>
            <AvField
              name='company'
              placeholder='Enter company'
              type='text'
              errorMessage='Enter company'
              className='form-control'
              validate={{ required: { value: true } }}
              value={company}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <SowaasButton className='float-right' title='Save' type='submit' />
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default ProfileForm

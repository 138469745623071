import React from 'react'
import SowaasButton from '../sowaasButton/sowaasButton'

const SowaasButtonInteraction = ({ id = '', className = '', title = '', onClick = () => {}, icon = '', to = '', disabled = false }) => {
  return (
    <SowaasButton fontColour='#002B7D' id={id} className={className} title={title} onClick={onClick} backgroundColor='aquaHorizontalGradient' icon={icon} to={to} disabled={disabled} />
  )
}

export default SowaasButtonInteraction

import React, { useState } from 'react'
import { Popover, PopoverBody, Input } from 'reactstrap'

const FilterPopover = ({ target, placement, onFilter, customers }) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const toggle = () => setPopoverOpen(!popoverOpen)

  return (
    <>
      <Popover placement={placement} isOpen={popoverOpen} target={target} toggle={toggle}>
        <PopoverBody style={{ width: '300px' }}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
            <div>
              <div className='styledTitle'>
                Customer:
              </div>
            </div>
            <div className='pl-3' style={{ width: '188px' }}>
              <Input type='select' name='select' id='exampleSelect' defaultValue='-1' onChange={(e) => onFilter(e.target.value)}>
                <option disabled value='-1'>Select Customer</option>
                {
                  customers.map((c, idx) => {
                    return (
                      <option key={idx} value={c.name}>{c.name}</option>
                    )
                  })
                }
              </Input>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    </>
  )
}

export default FilterPopover

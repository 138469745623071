import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Card, CardBody, CardTitle, Row, InputGroup, InputGroupAddon } from 'reactstrap'
import { AvField, AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import BooleanToggle from '../booleanToggle/booleanToggle'
import AddStakeHolderModal from '../addStakeholderModal/addStakeholderModal'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledCard = styled.div`
  padding: 5px 0;
`

const CustomerOnboard = ({ solution = {} }) => {
  const [customerName, setCustomerName] = useState(solution.customerName)
  const [stakeHoldersNames, setStakeholdersNames] = useState(solution.contactName)
  const [companyNumber, setCompanyNumber] = useState('')
  const [vatNumber, setVATNumber] = useState('')
  const [address, setAddress] = useState('')

  const [sowDesign, setSowDesign] = useState()
  const [proposalDesign, setProposalDesign] = useState()
  const [financialModel, setFinancialModel] = useState()
  const [ndaSigned, setNdaSigned] = useState()
  const [termsShared, setTermsShared] = useState()
  const [msaShared, setMsaShared] = useState()
  const [anyOther, setAnyOther] = useState('')

  const [stakeholderModalOpen, setStakeholderModalOpen] = useState(false)

  const stakeholderAdded = (stakeHolder) => {
    setStakeholdersNames(stakeHoldersNames + ',  ' + stakeHolder.name)
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            New Customer Details
          </CardTitle>
          <Col sm='12'>
            <AvForm className='validation-required'>
              <StyledCard>
                <Row>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Customer Name
                    </div>
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='customer_name'
                      placeholder='Enter customer name'
                      type='text'
                      errorMessage='Enter custoemr name'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      onChange={(event) => setCustomerName(event.target.value)}
                      value={customerName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Company Number
                    </div>
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='companyNumber'
                      placeholder='Enter company number'
                      type='text'
                      errorMessage='Enter company number'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      onChange={(event) => setCompanyNumber(event.target.value)}
                      value={companyNumber}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      VAT Number
                    </div>
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='vatNumber'
                      placeholder='Enter VAT number'
                      type='text'
                      errorMessage='Enter VAT number'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      onChange={(event) => setVATNumber(event.target.value)}
                      value={vatNumber}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Address
                    </div>
                  </Col>
                  <Col sm='9'>
                    <AvField
                      name='address'
                      placeholder='Enter address'
                      type='text'
                      errorMessage='Enter valid address'
                      className='form-control'
                      validate={{ required: { value: true } }}
                      onChange={(event) => setAddress(event.target.value)}
                      value={address}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Stakeholders
                    </div>
                  </Col>
                  <Col sm='9'>
                    <AvGroup>
                      <InputGroup>
                        <AvInput
                          name='stakeHolderNames'
                          type='text'
                          placeholder=''
                          required
                          onChange={(event) => setStakeholdersNames(event.target.value)}
                          value={stakeHoldersNames}
                        />
                        <InputGroupAddon addonType='append'>
                          <SowaasButton onClick={() => { setStakeholderModalOpen(true) }} title='Add' />
                        </InputGroupAddon>
                        <AvFeedback>This field is required.</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='4'>
                    <div className='styledTitle'>
                      Visibility
                    </div>
                    <p style={{ fontSize: '1.1em' }}>
                      For sign-off acceptance
                    </p>
                  </Col>
                  <Col sm='8'>
                    <Row>
                      <Col sm='4'>
                        <div className='styledTitle'>
                          SOW Design
                        </div>
                      </Col>
                      <Col sm='8'>
                        <BooleanToggle name='sowDesign' value={sowDesign} onValueChanged={setSowDesign} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <div className='styledTitle'>
                          Proposal Design
                        </div>
                      </Col>
                      <Col sm='8'>
                        <BooleanToggle disabled={solution.proposalRequired === false} name='proposalDesign' value={proposalDesign} onValueChanged={setProposalDesign} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <div className='styledTitle'>
                          Financial Model
                        </div>
                      </Col>
                      <Col sm='8'>
                        <BooleanToggle name='financialModel' value={financialModel} onValueChanged={setFinancialModel} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col sm='4'>
                    <div className='styledTitle'>
                      Contractual Position
                    </div>
                  </Col>
                  <Col sm='8'>
                    <Row>
                      <Col sm='4'>
                        <div className='styledTitle'>
                          NDA Signed
                        </div>
                      </Col>
                      <Col sm='8'>
                        <BooleanToggle name='ndaSigned' value={ndaSigned} onValueChanged={setNdaSigned} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <div className='styledTitle'>
                          Terms Shared
                        </div>
                      </Col>
                      <Col sm='8'>
                        <BooleanToggle name='termsShared' value={termsShared} onValueChanged={setTermsShared} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <div className='styledTitle'>
                          MSA Shared
                        </div>
                      </Col>
                      <Col sm='8'>
                        <BooleanToggle name='msaShared' value={msaShared} onValueChanged={setMsaShared} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='4'>
                        <div className='styledTitle'>
                          Any Other
                        </div>
                      </Col>
                      <Col sm='8'>
                        <AvField
                          name='any_other'
                          placeholder='Enter any other'
                          type='text'
                          errorMessage='Enter any other'
                          className='form-control'
                          validate={{ required: { value: true } }}
                          id='validationAnyOther'
                          onChange={(event) => setAnyOther(event.target.value)}
                          value={anyOther}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <SowaasButton className='float-right' title='Save' />
                  </Col>
                </Row>
              </StyledCard>
            </AvForm>
          </Col>
        </CardBody>
      </Card>
      <AddStakeHolderModal isOpen={stakeholderModalOpen} setOpen={(v) => { setStakeholderModalOpen(v) }} onSubmit={stakeholderAdded} />
    </>
  )
}

export default CustomerOnboard

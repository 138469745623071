import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ServiceReportForm = ({ onSubmit }) => {
  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSubmit(values)
    }
  }

  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      <StyledCard>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Project Plan
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Project Plan' bodyCopy='Allows for additional comments to be entered to support the service report relating to the Project Plan' id='imProjectPlan' />
          </Col>
          <Col sm='8' className='form-group'>
            <AvField
              className='form-control extendedInput'
              type='textarea'
              name='projectPlan'
              placeholder='Enter comments'
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              RAID Log
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='RAID Log' bodyCopy='Allows for additional comments to be entered to support the service report relating to the RAID Log' id='imRAIDLog' />
          </Col>
          <Col sm='8' className='form-group'>
            <AvField
              className='form-control extendedInput'
              type='textarea'
              name='raidLog'
              placeholder='Enter comments'
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Financial Information
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Financial Information' bodyCopy='Allows for additional comments to be entered to support the service report relating to the Financial Information' id='imFinancialInformation' />
          </Col>
          <Col sm='8' className='form-group'>
            <AvField
              className='form-control extendedInput'
              type='textarea'
              name='financialInformation'
              placeholder='Enter comments'
              required
            />
          </Col>
        </Row>
        <Row>
          <Col sm='3'>
            <div className='styledTitle'>
              Other
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Other' bodyCopy='Allows for any other comments or information to be entered to support the service report beyond what has already been documented' id='imOther' />
          </Col>
          <Col sm='8' className='form-group'>
            <AvField
              className='form-control extendedInput'
              type='textarea'
              name='other'
              placeholder='Enter comments'
            />
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <SowaasButton className='float-right' title='Save' type='submit' />
          </Col>
        </Row>
      </StyledCard>
    </AvForm>
  )
}

export default ServiceReportForm

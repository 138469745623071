import { ADD_CUSTOMER, ADD_CUSTOMERS, CLEAR_CUSTOMERS, UPDATE_CUSTOMER, DELETE_CUSTOMER } from './actionTypes'
import produce from 'immer'

const initState = {
  customers: []
}

const Customer = (state = initState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER: {
      const nextState = produce(state, draftState => {
        draftState.customers.push(action.payload.customer)
      })
      return nextState
    }
    case ADD_CUSTOMERS: {
      const nextState = produce(state, draftState => {
        draftState.customers.push(...action.payload.customers)
      })
      return nextState
    }
    case CLEAR_CUSTOMERS: {
      const nextState = produce(state, draftState => {
        draftState.customers = []
      })
      return nextState
    }
    case UPDATE_CUSTOMER: {
      const { customer } = action.payload
      const foundIndex = state.customers.findIndex(x => x.id === customer.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.customers[foundIndex] = customer
        })
        return nextState
      }
      return state
    }
    case DELETE_CUSTOMER: {
      const { customer } = action.payload
      const foundIndex = state.customers.findIndex(x => x.id === customer.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.customers.splice(foundIndex, 1)
        })
        return nextState
      }
      return state
    }
    default:
      return state
  }
}

export default Customer

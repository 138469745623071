import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle, FormGroup, Label, Input } from 'reactstrap'
import DocumentModal from '../documentModal/documentModal'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import Fuse from 'fuse.js'
import VideoMarker from '../videoMarker/videoMarker'

const customerContracts = [
  { title: 'Warwick Enterprises MSA', url: 'https://stroagesowaasdemo.blob.core.windows.net/example-docs/CUSTOMER MSA - SRL to Warwick Enterprises Ltd Services Agreement.docx' },
  { title: 'Warwick Enterprises SOW', url: 'https://stroagesowaasdemo.blob.core.windows.net/example-docs/CUSTOMER SOW - SRL to Warwick Enterprises Ltd SOW.docx' }

]

const supplierContracts = [
  { title: 'Cloud People Terms', url: 'https://stroagesowaasdemo.blob.core.windows.net/example-docs/SUPPLIER TERMS - SRL to Cloud People Ltd Services Agreement.docx' },
  { title: 'Cloud People SOW', url: 'https://stroagesowaasdemo.blob.core.windows.net/example-docs/SUPPLIER SOW - SRL to Cloud People Ltd SOW.docx' }
]

const ContractsCard = () => {
  const [viewingContract, setViewingContract] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const fuse = new Fuse(supplierContracts, {
    keys: ['title', 'url']
  })
  const filteredSupplierContracts = (searchValue.length > 0 ? fuse.search(searchValue) : supplierContracts.map((c) => ({ item: c })))

  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='6'>
              <CardTitle>
                Contracts
                <InfoMarker title='Contracts' bodyCopy='Provided below is quick and easy access to the relevant contract documents created during the SOW design process relating to Customer and/or Suppliers' />
                <VideoMarker id='vmContracts' title='View Contracts and SOWs' videoUrl='https://vimeo.com/502291978/2e972db6a8' />
              </CardTitle>
            </Col>
            <Col sm={{ size: 6, offset: 0 }}>
              <FormGroup row>
                <Label for='searchBox' sm={5} style={{ textAlign: 'right' }}>Search</Label>
                <Col sm={7}>
                  <Input type='text' name='searchBox' id='searchBox' placeholder='' onChange={(e) => { setSearchValue(e.target.value) }} />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {
              customerContracts.map((c, idx) => (
                <Col sm='6' className='mb-3' key={idx}>
                  <SowaasButton className='btn-block' title={c.title} onClick={() => { setViewingContract(c.url) }} />
                </Col>
              ))
            }
          </Row>
          <Row>
            {
              filteredSupplierContracts.map(({ item }, idx) => (
                <Col sm='6' className='mb-3' key={idx}>
                  <SowaasButton className='btn-block' title={item.title} onClick={() => { setViewingContract(item.url) }} />
                </Col>
              ))
            }
          </Row>
        </CardBody>
      </Card>
      {
        viewingContract !== false && (
          <DocumentModal fileUrl={viewingContract} isOpen={(viewingContract !== false)} setOpen={setViewingContract} />
        )
      }
    </>
  )
}

export default ContractsCard

import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Card, CardBody, CardTitle, Row } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ExtendedInput = styled.textarea`
  height: 100px;
  wrap: hard;
`

const FurtherInfoRequired = () => {
  const [furtherInfo, setFurtherInfo] = useState('')

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            Further Info Required
          </CardTitle>
          <Col sm='12'>
            <AvForm className='validation-required'>
              <StyledCard>
                <Row>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Info:
                    </div>
                  </Col>
                  <Col sm='9'>
                    <ExtendedInput
                      className='form-control'
                      type='text'
                      placeholder='Enter further info'
                      onChange={(event) => setFurtherInfo(event.target.value)}
                      value={furtherInfo}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col sm='12'>
                    <SowaasButton className='float-right ml-2' title='Submit' />
                    <SowaasButton className='float-right' title='Send as Message' />
                  </Col>
                </Row>
              </StyledCard>
            </AvForm>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default FurtherInfoRequired

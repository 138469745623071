import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, LOGIN_USER_FAILED } from './actionTypes'

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history }
  }
}

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  }
}

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history }
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  }
}

export const loginUserFailed = (error) => {
  return {
    type: LOGIN_USER_FAILED,
    payload: error
  }
}

import React, { useState } from 'react'
import { Col, Card, CardBody, CardTitle, Row } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import SolutionFilterPopover from '../solutionFilterPopover/solutionFilterPopover'
import ExecSummaryModal from '../execSummaryModal/execSummaryModal'
import ProposalInfoModal from '../proposalInfoModal/proposalInfoModal'
import SolutionInfoModal from '../solutionInfoModal/solutionInfoModal'
import EngagementInfoModal from '../engagementInfoModal/engagementInfoModal'
import FinanceInfoModal from '../financeInfoModal/financeInfoModal'
import LegalInfoModal from '../legalInfoModal/legalInfoModal'
import CommentModal from '../commentModal/commentModal'
import SowaasButton from '../sowaasButton/sowaasButton'
import { useDispatch } from 'react-redux'
import { approveManagement } from '../../store/project/actions'
import VideoMarker from '../videoMarker/videoMarker'

const ExecNewProjectSowSignoff = ({ solutions = [], statementOfWorkTypes = [], suppliers = [] }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState('')

  const [execSummaryOpen, setExecsummaryOpen] = useState(false)
  const [proposalInfoOpen, setProposalInfoOpen] = useState(false)
  const [solutionInfoOpen, setSolutionInfoOpen] = useState(false)
  const [engagementInfoOpen, setEngagementInfoOpen] = useState(false)
  const [financeInfoOpen, setFinanceInfoOpen] = useState(false)
  const [legalInfoOpen, setLegalInfoOpen] = useState(false)
  const [commentModalOpen, setCommentModalOpen] = useState(false)

  if (filter !== '') {
    solutions = solutions.filter((s) => s.id === filter)
  }

  if (solutions.length === 0) {
    return null
  }

  const selectedSolution = solutions[0]

  const onFilterSelected = (entry) => {
    setFilter(Number(entry))
  }

  const onApprove = (values) => {
    dispatch(approveManagement(selectedSolution, values.comment))
  }

  return (
    <>
      <SolutionFilterPopover target='filterPopoverButton' placement='left' onFilter={onFilterSelected} solutions={solutions} />
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <AvForm>
            <Row>
              <Col sm='6'>
                <CardTitle>
                  Management: New SOW Service Sign-off
                  <VideoMarker id='vmNewSowService' title='New SOW Review & Sign-Off' videoUrl='https://vimeo.com/502295974/e18c9b0fde' />
                </CardTitle>
              </Col>
              <Col sm='6'>
                <SowaasButton id='filterPopoverButton' className='float-right' title='Filter / Search' />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col sm='3'>
                <div className='styledTitle'>
                  Unique ID
                </div>
              </Col>
              <Col sm='9'>
                <AvField
                  name='unique_id'
                  type='text'
                  className='form-control'
                  value={selectedSolution.slug}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col sm='3'>
                <div className='styledTitle'>
                  Customer
                </div>
              </Col>
              <Col sm='9'>
                <AvField
                  name='customer_name'
                  type='text'
                  className='form-control'
                  value={selectedSolution.projectProcurer.name}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col sm='3'>
                <div className='styledTitle'>
                  Project Name
                </div>
              </Col>
              <Col sm='9'>
                <AvField
                  name='project_name'
                  type='text'
                  className='form-control'
                  value={selectedSolution.name}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col sm='3'>
                <div className='styledTitle'>
                  Consultant
                </div>
              </Col>
              <Col sm='9'>
                <AvField
                  name='consultant'
                  type='text'
                  className='form-control'
                  value={selectedSolution.consultantName}
                  disabled
                />
              </Col>
            </Row>
            <Row className='mt-3'>
              {
                /*
                <Col sm='4'>
                  <SowaasButton className='btn-block' onClick={() => { setExecsummaryOpen(true) }} title='Exec Summary' />
                </Col>
                */
              }
              <Col sm='4'>
                <SowaasButton className='btn-block' onClick={() => { setProposalInfoOpen(selectedSolution.solution.proposalDesign) }} title='Proposal Info' />
              </Col>
              <Col sm='4'>
                <SowaasButton className='btn-block' onClick={() => { setSolutionInfoOpen(selectedSolution) }} title='Solution Info' />
              </Col>
              <Col sm='4'>
                <SowaasButton className='btn-block' onClick={() => { setEngagementInfoOpen(selectedSolution) }} title='Delivery Info' />
              </Col>
            </Row>
            <Row className='pt-3'>
              <Col sm='4'>
                <SowaasButton className='btn-block' onClick={() => { setFinanceInfoOpen(selectedSolution) }} title='Finance Info' />
              </Col>
              <Col sm='4'>
                <SowaasButton className='btn-block' onClick={() => { setLegalInfoOpen(selectedSolution) }} title='Legal Info' />
              </Col>
            </Row>
            {
              selectedSolution.managementApproved === false && (
                <Row className='pt-3'>
                  <Col sm='12'>
                    <SowaasButton className='float-right' onClick={() => { setCommentModalOpen(selectedSolution) }} title='Approve SOW Service with Comments' />
                  </Col>
                </Row>
              )
            }
          </AvForm>
        </CardBody>
      </Card>
      <ExecSummaryModal isOpen={execSummaryOpen} setOpen={setExecsummaryOpen} />
      {
        (proposalInfoOpen !== false) && (
          <ProposalInfoModal isOpen={(proposalInfoOpen !== false)} proposalDesign={proposalInfoOpen} setOpen={setProposalInfoOpen} />
        )
      }
      {
        (solutionInfoOpen !== false) && (
          <SolutionInfoModal
            isOpen={solutionInfoOpen}
            project={solutionInfoOpen}
            setOpen={setSolutionInfoOpen}
            statementOfWorkTypes={statementOfWorkTypes}
            suppliers={suppliers}
          />
        )
      }
      {
        (engagementInfoOpen !== false) && (
          <EngagementInfoModal isOpen={engagementInfoOpen} setOpen={setEngagementInfoOpen} project={engagementInfoOpen} />
        )
      }
      {
        (financeInfoOpen !== false) && (
          <FinanceInfoModal isOpen={financeInfoOpen} setOpen={setFinanceInfoOpen} project={financeInfoOpen} />
        )
      }
      {
        (legalInfoOpen !== false) && (
          <LegalInfoModal isOpen={legalInfoOpen} setOpen={setLegalInfoOpen} project={legalInfoOpen} />
        )
      }
      {
        (commentModalOpen !== false) && (
          <CommentModal isOpen={commentModalOpen} setOpen={setCommentModalOpen} title='Approve SOW with Comments' approvingType='SOW Service' ctaTitle='Approve with Comments' onSubmit={onApprove} />
        )
      }
    </>
  )
}

export default ExecNewProjectSowSignoff

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import MessageForm from '../messageForm/messageForm'
import users from '../../helpers/demoMode/users.json'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const MessageModal = ({ isOpen, onClose, replyingTo = {}, onSend }) => {
  const user = useSelector((state) => state.User)

  const isReply = (!_.isEqual(replyingTo, {}))

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='exampleModal' tabIndex='-1' toggle={() => { onClose(false) }} size='lg'>
        <div className='modal-content'>
          <ModalHeader toggle={() => { onClose(false) }}>
            {isReply ? `Reply to ${replyingTo.fromUser}` : 'New Message'}
          </ModalHeader>
          <ModalBody>
            {
              isReply && (
                <MessageForm existingMessage={replyingTo} onSendMessage={onSend} users={users} user={user} />
              )
            }
            <MessageForm onSendMessage={onSend} existingMessage={replyingTo} replying={isReply} users={users} user={user} />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default MessageModal

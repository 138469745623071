import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

const SowaasButton = ({
  id = '',
  className = '',
  title = '',
  onClick = () => {},
  backgroundColor = 'blueHorizontalGradient',
  icon = '',
  disabled = false,
  to = '',
  type = 'button',
  fontColour = '#FFFFFF',
  style = null
}) => {
  const CustomLink = props => {
    const { navigate, ...rest } = props
    return (<Button {...rest} />)
  }

  const renderContents = () => (
    <>
      {title}
      {
        icon !== '' && (
          <i className={`${icon} ml-1`} style={{ height: '14px', marginTop: '-8px', fontSize: '1.6em', marginRight: '-0.5rem', float: 'right', color: fontColour }} />
        )
      }
    </>
  )

  const buttonProps = {
    style: { padding: '8px 15px', minHeight: '1.9rem', textTransform: 'uppercase', display: 'flex', alignItems: 'center', justifyContent: 'center', color: fontColour, ...style },
    disabled: disabled,
    id: id,
    className: `${backgroundColor} ${className}`,
    onClick: onClick,
    type: type
  }

  if (to !== '') {
    return (
      <Link component={CustomLink} to={(to === '' ? null : to)} {...buttonProps}>
        {renderContents()}
      </Link>
    )
  } else {
    return (
      <Button {...buttonProps}>
        {renderContents()}
      </Button>
    )
  }
}

export default SowaasButton

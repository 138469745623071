import React, { useState } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import SowaasButton from '../../components/sowaasButton/sowaasButton'

const NewEventModal = ({ modal, setModal }) => {
  const [selectedDay, setSelectedDay] = useState(0)
  const [calendarEvents, setCalendarEvents] = useState({ id: 1, title: 'Hey!', startDate: '2020-06-30T14:01:14Z', className: 'bg-warning text-white' })

  const handleValidEventSubmit = (e, values) => {
    let newEvent = {}

    newEvent = {
      id: calendarEvents.length + 1,
      title: values.title,
      start: selectedDay ? selectedDay.date : new Date(),
      className: values.category + ' text-white'
    }

    // save new event
    setCalendarEvents(calendarEvents.concat(newEvent))
    setSelectedDay(null)

    setModal(!modal)
  }

  return (
    <>
      <Modal isOpen={modal} toggle={() => setModal(!modal)} className=''>
        <ModalHeader toggle={() => setModal(!modal)} tag='h4'>
          Add Event
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidEventSubmit}>
            <Row form>
              <Col className='col-12'>
                <AvField
                  name='title' label='Event Name' type='text' errorMessage='Invalid name' validate={{
                    required: { value: true }
                  }} value=''
                />
              </Col>
              <Col className='col-12'>
                <AvField
                  type='select' name='category' label='Select Category'
                  value='bg-primary'
                >
                  <option value='bg-danger'>Danger</option>
                  <option value='bg-success'>Success</option>
                  <option value='bg-primary'>Primary</option>
                  <option value='bg-info'>Info</option>
                  <option value='bg-dark'>Dark</option>
                  <option value='bg-warning'>Warning</option>
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='text-right'>
                  <SowaasButton className='mr-2' onClick={() => setModal(!modal)} title='Close' />
                  <SowaasButton type='submit' onClick={() => setModal(!modal)} title='Save' />
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  )
}

export default NewEventModal

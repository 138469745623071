import {
  ACTIVATE_SUBMIT_SUCCESS,
  ACTIVATE_SUBMIT_ERROR,
  ACTIVATE_RESET
} from './actionTypes'

const initialState = {
  activateSuccessMsg: null,
  activateErrorMsg: null
}

const activate = (state = initialState, action: any): any => {
  switch (action.type) {
    case ACTIVATE_SUBMIT_SUCCESS:
      state = {
        ...state,
        activateSuccessMsg: action.payload
      }
      break
    case ACTIVATE_SUBMIT_ERROR:
      state = { ...state, activateErrorMsg: action.payload }
      break
    case ACTIVATE_RESET:
      state = initialState
      break
    default:
      return state
  }
  return state
}

export default activate

import React from 'react'
import moment from 'moment'

const smallDateFormatter = (date) => {
  const formattedDate = moment(date).format('DD/MM/YY')

  return (
    <>
      {formattedDate}
    </>
  )
}

export default smallDateFormatter

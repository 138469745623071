import { SHOW_TOAST, CLEAR_TOAST } from './actionTypes'
import produce from 'immer'

const initState = {
  toasts: []
}

const Toast = (state = initState, action) => {
  switch (action.type) {
    case SHOW_TOAST: {
      const nextState = produce(state, draftState => {
        draftState.toasts.push(action.payload)
      })
      return nextState
    }
    case CLEAR_TOAST: {
      const toast = action.payload.toast
      const foundIndex = state.toasts.findIndex(x => x.title === toast.title)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.toasts.splice(foundIndex, 1)
        })
        return nextState
      }
      return state
    }
    default:
      return state
  }
}

export default Toast

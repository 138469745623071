import React, { useState } from 'react'
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import CustomerTable from '../../components/customerTable/customerTable'
import SupplierTable from '../../components/supplierTable/supplierTable'
import SupplierOnboardModal from '../../components/supplierOnboardModal/supplierOnboardModal'

import { postSupplier, putSupplier } from '../../store/supplier/actions'
import { postCustomer, putCustomer } from '../../store/customer/actions'

import CustomerModal from '../../components/customerModal/customerModal'
import SowaasButton from '../../components/sowaasButton/sowaasButton'

const CRM = () => {
  const [activeTab, setActiveTab] = useState('1')

  const [supplierOnboardOpen, setSupplierOnboardOpen] = useState(false)
  const [activeSupplier, setActiveSupplier] = useState(undefined)

  const [customerOnboardOpen, setCustomerOnboardOpen] = useState(false)
  const [activeCustomer, setActiveCustomer] = useState(undefined)

  const dispatch = useDispatch()

  const { suppliers } = useSelector(state => state.Supplier)
  const { customers } = useSelector(state => state.Customer)

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const onAddCustomer = () => {
    setActiveCustomer(undefined)
    setCustomerOnboardOpen(true)
  }

  const onAddCustomerSubmit = (customer) => {
    if ('id' in customer) {
      dispatch(putCustomer(customer))
    } else {
      dispatch(postCustomer(customer))
    }

    setActiveCustomer(undefined)
    setCustomerOnboardOpen(false)
  }

  const onViewCustomer = (customer) => {
    setActiveCustomer(customer)
    setCustomerOnboardOpen(true)
  }

  const onAddSupplier = () => {
    setActiveSupplier(undefined)
    setSupplierOnboardOpen(true)
  }

  const onAddSupplierSubmit = (supplier) => {
    supplier.rate = supplier.rate + ''

    if ('id' in supplier) {
      dispatch(putSupplier(supplier))
    } else {
      dispatch(postSupplier(supplier))
    }

    setActiveSupplier(undefined)
    setSupplierOnboardOpen(false)
  }

  const onViewSupplier = (supplier) => {
    setActiveSupplier(supplier)
    setSupplierOnboardOpen(true)
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm='12'>
              <h1 style={{ display: 'inline-block' }} className='mb-3'>
                <i className='bx bx-buildings pr-2' />
                Customers
              </h1>
            </Col>
          </Row>
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1') }}
                      >
                        Customers
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2') }}
                      >
                        Suppliers
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId='1' className='pt-4'>
                      {
                        customers.length === 0 && (
                          <p>You do not currently have any customers</p>
                        )
                      }
                      {
                        customers.length > 0 && (
                          <CustomerTable customers={customers} onCustomerSelected={onViewCustomer} />
                        )
                      }
                      <Row>
                        <Col sm='12'>
                          <SowaasButton onClick={onAddCustomer} title='Add Customer' />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId='2' className='pt-4'>
                      {
                        suppliers.length === 0 && (
                          <p>You do not currently have any suppliers</p>
                        )
                      }
                      {
                        suppliers.length > 0 && (
                          <SupplierTable suppliers={suppliers} onSupplierSelected={onViewSupplier} />
                        )
                      }
                      <Row>
                        <Col sm='12'>
                          <SowaasButton onClick={onAddSupplier} title='Add Supplier' />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            supplierOnboardOpen === true && (
              <SupplierOnboardModal isOpen={supplierOnboardOpen} setOpen={setSupplierOnboardOpen} onSubmit={onAddSupplierSubmit} forSupplier={activeSupplier} />
            )
          }
          {
            customerOnboardOpen === true && (
              <CustomerModal isOpen={customerOnboardOpen} setOpen={setCustomerOnboardOpen} onSubmit={onAddCustomerSubmit} forCustomer={activeCustomer} />
            )
          }
        </Container>
      </div>
    </>
  )
}

export default CRM

import {
  CLEAR_TOAST,
  SHOW_TOAST
} from './actionTypes'

export const addToast = (title, appearance) => ({
  type: SHOW_TOAST,
  payload: { title, appearance }
})

export const clearToast = toast => ({
  type: CLEAR_TOAST,
  payload: { toast }
})

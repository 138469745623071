import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { AvField, AvInput } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import GroupToggle from '../groupToggle/groupToggle'

const statuses = [
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' }
]

const CommonRaidEntryFields = ({ category = {}, entry = {}, readOnly = false }) => {
  const { name = '' } = category

  const {
    id = '',
    slug = '',
    dateRaised = new Date().toISOString(),
    raidStatus = 'Open',
    dateClosed,
    description = ''
  } = entry

  let dateClosedValue = dateClosed
  if (dateClosed === null || dateClosed === '' || dateClosed === undefined) {
    dateClosedValue = new Date().toISOString()
  }

  const [raidStatusValue, setRaidStatusValue] = useState(raidStatus)

  return (
    <>
      {
        id !== '' && (
          <AvInput type='hidden' name='id' value={id} valueParser={window.parseInt} />
        )
      }
      {
        <AvInput type='hidden' name='categoryId' value={category.id} valueParser={window.parseInt} />
      }
      {
        slug !== '' && (
          <Row>
            <Col>
              <div className='styledTitle'>
                ID Number
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='ID Number' bodyCopy={`An automatically assigned number to identify the ${name}`} id='imIDNumber' />
            </Col>
            <Col sm='9'>
              <AvField name='slug' value={slug} disabled />
            </Col>
          </Row>
        )
      }
      <Row>
        <Col>
          <div className='styledTitle'>
            Date Raised
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Date Raised' bodyCopy={`A timestamp of when the ${name} was logged in the system`} id='imDateRaised' />
        </Col>
        <Col sm='9'>
          <AvField name='dateRaised' type='date' value={dateRaised.substring(0, 10)} disabled={readOnly} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='styledTitle'>
            Status
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title='Status' bodyCopy='Choose a status from Open or Closed' id='imStatus' />
        </Col>
        <Col sm='9'>
          <GroupToggle disabled={readOnly} name='raidStatus' items={statuses} currentValue={raidStatusValue} onValueChanged={setRaidStatusValue} className='mb-0' />
        </Col>
      </Row>
      {
        raidStatusValue === 'Closed' && (
          <Row>
            <Col>
              <div className='styledTitle'>
                Date Closed
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='Date Raised' bodyCopy={`A timestamp of when the ${name} was logged in the system`} id='imDateRaised' />
            </Col>
            <Col sm='9'>
              <AvField disabled={readOnly} name='dateClosed' type='date' value={dateClosedValue.substring(0, 10)} />
            </Col>
          </Row>
        )
      }
      <Row>
        <Col>
          <div className='styledTitle'>
            {name} Description
          </div>
        </Col>
        <Col sm='auto' className='pt-1 text-right pr-0'>
          <InfoMarker title={`${name} Description`} bodyCopy={`Enter a description of the ${name} being logged`} id='imDescription' />
        </Col>
        <Col sm='9'>
          <AvField disabled={readOnly} name='description' value={description} />
        </Col>
      </Row>
    </>
  )
}

export default CommonRaidEntryFields

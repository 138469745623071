import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'
import BooleanToggle from '../booleanToggle/booleanToggle'
import ProposalItem from './proposalItem'

const ProposalDesignForm = ({ proposalRequired = true, proposalDesign, onProposalDesignSaved, readOnly = false, embedded = false }) => {
  const id = proposalDesign.id || ''

  const excutiveSummaryLabel = proposalDesign.excutiveSummaryLabel || 'Executive Summary'
  const excutiveSummary = proposalDesign.excutiveSummary || ''
  const excutiveSummaryHidden = proposalDesign.excutiveSummaryHidden || false

  const understandingLabel = proposalDesign.understandingLabel || 'Understanding'
  const understanding = proposalDesign.understanding || ''
  const understandingHidden = proposalDesign.understandingHidden || false

  const solutionLabel = proposalDesign.solutionLabel || 'Solution'
  const solution = proposalDesign.solution || ''
  const solutionHidden = proposalDesign.solutionHidden || false

  const approachLabel = proposalDesign.approachLabel || 'Approach'
  const approach = proposalDesign.approach || ''
  const approachHidden = proposalDesign.approachHidden || false

  const benefitsAndOutcomesLabel = proposalDesign.benefitsAndOutcomesLabel || 'Benefits and Outcomes'
  const benefitsAndOutcomes = proposalDesign.benefitsAndOutcomes || ''
  const benefitsAndOutcomesHidden = proposalDesign.benefitsAndOutcomesHidden || ''

  const investmentLabel = proposalDesign.investmentLabel || 'Investment'
  const investment = proposalDesign.investment || ''
  const investmentHidden = proposalDesign.investmentHidden || false

  const closingSummaryLabel = proposalDesign.closingSummaryLabel || 'Closing Summary'
  const closingSummary = proposalDesign.closingSummary || ''
  const closingSummaryHidden = proposalDesign.closingSummaryHidden || false

  const [proposalRequiredValue, setProposalRequiredValue] = useState(proposalRequired)

  useEffect(() => {
    setProposalRequiredValue(proposalRequired)
  }, [proposalRequired])

  return (
    <AvForm className='needs-validation' onValidSubmit={(e, values) => { onProposalDesignSaved(values) }}>
      {
        id !== '' && (
          <AvInput type='hidden' name='id' value={id} />
        )
      }
      <div>
        <Row className='mb-3'>
          <Col sm='3'>
            <div className='styledTitle'>
              Proposal Design Required
            </div>
          </Col>
          <Col sm='1'>
            <BooleanToggle disabled={readOnly} className='mb-0' name='proposalRequired' onValueChanged={v => { setProposalRequiredValue(v) }} currentValue={proposalRequiredValue} />
          </Col>
        </Row>
        {
          proposalRequiredValue && (
            <>
              <ProposalItem
                identifier='excutiveSummary'
                name='Executive Summary'
                labelValue={excutiveSummaryLabel}
                value={excutiveSummary}
                infoCopy='Summarises the content of the Proposal, providing the reader with a quick synopsis of the Service. We recommend completing this section last.'
                readOnly={readOnly}
                proposalRequired={proposalRequired}
                hidden={excutiveSummaryHidden}
              />
              <ProposalItem
                identifier='understanding'
                name='Understanding'
                labelValue={understandingLabel}
                value={understanding}
                infoCopy='Use this section to replay to the Customer your understanding of their challenge and what needs to be overcome and/or achieved'
                readOnly={readOnly}
                proposalRequired={proposalRequired}
                hidden={understandingHidden}
              />
              <ProposalItem
                identifier='solution'
                name='Solution'
                labelValue={solutionLabel}
                value={solution}
                infoCopy="Outline your solution to addressing/resolving your Customer's challenge"
                readOnly={readOnly}
                proposalRequired={proposalRequired}
                hidden={solutionHidden}
              />
              <ProposalItem
                identifier='approach'
                name='Approach'
                labelValue={approachLabel}
                value={approach}
                infoCopy="Explain the approach/process you will follow to achieve success in resolving your Customer's challenges"
                readOnly={readOnly}
                proposalRequired={proposalRequired}
                hidden={approachHidden}
              />
              <ProposalItem
                identifier='benefitsAndOutcomes'
                name='Benefits and Outcomes'
                labelValue={benefitsAndOutcomesLabel}
                value={benefitsAndOutcomes}
                infoCopy='Detail the benefits of your Approach and Solution along with a set of expected Outcomes/Success criteria'
                readOnly={readOnly}
                proposalRequired={proposalRequired}
                hidden={benefitsAndOutcomesHidden}
              />
              <ProposalItem
                identifier='investment'
                name='Investment'
                labelValue={investmentLabel}
                value={investment}
                infoCopy='Provide details of the pricing for the Service you will provide, broken down into relevant phases where applicable'
                readOnly={readOnly}
                proposalRequired={proposalRequired}
                hidden={investmentHidden}
              />
              <ProposalItem
                identifier='closingSummary'
                name='Closing Summary'
                labelValue={closingSummaryLabel}
                value={closingSummary}
                infoCopy='A final summary of the benefits your customer will receive in using your Statement of Work (SOW) Service and next steps to move forward'
                readOnly={readOnly}
                proposalRequired={proposalRequired}
                hidden={closingSummaryHidden}
              />
            </>
          )
        }
        {
          (embedded === false) && (
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right' title={(readOnly ? 'Next' : 'Save')} type='submit' />
              </Col>
            </Row>
          )
        }
      </div>
    </AvForm>
  )
}

export default ProposalDesignForm

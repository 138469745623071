import { takeEvery, fork, put, all } from 'redux-saga/effects'
import { GET_NOTIFICATIONS, PUT_NOTIFICATION, MARK_NOTIFICATION_READ } from './actionTypes'
import { clearNotifications, addNotifications, addNotification, deleteNotification, updateNotification } from './actions'

import api from '../../helpers/api'
import errorService from '../../helpers/errorService'

function * getNotifications () {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Notification.get_api_Notification()
    if (status === 200) {
      yield put(clearNotifications())
      yield put(addNotifications(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * putNotification ({ payload: { notification } }) {
  try {
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Notification.put_api_Notification__id_({ id: notification.id }, { requestBody: notification })
    if (status === 200) {
      yield put(deleteNotification(notification))
      yield put(addNotification(notification))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * markNotificationAsRead ({ payload: { notification } }) {
  try {
    const { id, ...rest } = notification

    rest.id = id // TODO REMOVE
    rest.read = true

    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Notification.patch_api_Notification__id_({ id }, { requestBody: rest })

    if (status === 200) {
      yield put(updateNotification(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchNotifications () {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications)
  yield takeEvery(PUT_NOTIFICATION, putNotification)
  yield takeEvery(MARK_NOTIFICATION_READ, markNotificationAsRead)
}

function * notificationSaga () {
  yield all([
    fork(watchNotifications)
  ])
}

export default notificationSaga

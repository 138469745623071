import React, { useState } from 'react'
import { Table, FormGroup, Row, Col, Label, Input } from 'reactstrap'
import Fuse from 'fuse.js'

const CustomerTable = ({ customers = [], onCustomerSelected }) => {
  const [searchValue, setSearchValue] = useState('')

  const fuse = new Fuse(customers, {
    keys: ['name', 'stakeholders.firstName', 'stakeholders.lastName']
  })
  const filteredCustomers = (searchValue.length > 0 ? fuse.search(searchValue) : customers.map((c) => ({ item: c })))

  return (
    <>
      <Row>
        <Col sm={{ size: 2, offset: 10 }}>
          <FormGroup row>
            <Label for='searchBox' sm={5} style={{ textAlign: 'right' }}>Search</Label>
            <Col sm={7}>
              <Input type='text' name='searchBox' id='searchBox' placeholder='' onChange={(e) => { setSearchValue(e.target.value) }} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Table hover>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Company Number</th>
            <th>Primary Contact Name</th>
            <th>Email</th>
            <th>Telephone Number</th>
            <th>Customer ID</th>
          </tr>
        </thead>
        <tbody>
          {
            filteredCustomers.map(({ item }, idx) => (
              <tr key={idx} onClick={() => { onCustomerSelected(item) }}>
                <td>{item.name}</td>
                <td>{item.companyNumber}</td>
                <td>{item.stakeholders[0].firstName} {item.stakeholders[0].lastName}</td>
                <td>{item.stakeholders[0].contactEmail}</td>
                <td>{item.stakeholders[0].contactPhone}</td>
                <td>{item.slug}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  )
}

export default CustomerTable

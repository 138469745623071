import React from 'react'
import { Row, Col } from 'reactstrap'

import OpportunitiesSummary from '../../components/opportunitiesSummary/OpportunitiesSummary'
import PipelinePosition from '../../components/pipelinePosition/PipelinePosition'
import NewOpportunity from '../../components/newOpportunity/NewOpportunity'
import Messaging from '../../components/messaging/Messaging'
import Notification from '../../components/notifications/Notifications'

const SalesDashboard = ({ messaging, notifications }) => {
  return (
    <>
      <Row>
        <Col sm='6'>
          <OpportunitiesSummary />
          <NewOpportunity />
          <Messaging messages={messaging} />
        </Col>
        <Col sm='6'>
          <PipelinePosition />
          <Notification notifications={notifications} />
        </Col>
      </Row>
    </>
  )
}

export default SalesDashboard

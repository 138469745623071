import {
  CLEAR_RAIDCATEGORIES,
  ADD_RAIDCATEGORIES,
  ADD_PRIORITIES,
  CLEAR_PRIORITIES,
  CLEAR_ORGANISATION_USERS,
  ADD_ORGANISATION_USERS,
  SET_ENUM
} from './actionTypes'

import produce from 'immer'

const INIT_STATE = {
  raidCategories: [],
  priorities: [],
  organisationUsers: [],
  enums: {}
}

const Lookup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_RAIDCATEGORIES: {
      const nextState = produce(state, draftState => {
        draftState.raidCategories = []
      })
      return nextState
    }
    case ADD_RAIDCATEGORIES: {
      const nextState = produce(state, draftState => {
        draftState.raidCategories.push(...action.payload.raidCategories)
      })
      return nextState
    }
    case CLEAR_PRIORITIES: {
      const nextState = produce(state, draftState => {
        draftState.priorities = []
      })
      return nextState
    }
    case ADD_PRIORITIES: {
      const nextState = produce(state, draftState => {
        draftState.priorities.push(...action.payload.priorities)
      })
      return nextState
    }
    case CLEAR_ORGANISATION_USERS: {
      const nextState = produce(state, draftState => {
        draftState.organisationUsers = []
      })
      return nextState
    }
    case ADD_ORGANISATION_USERS: {
      const nextState = produce(state, draftState => {
        draftState.organisationUsers.push(...action.payload.organisationUsers)
      })
      return nextState
    }
    case SET_ENUM: {
      const nextState = produce(state, draftState => {
        if (!draftState.enums) {
          draftState.enums = {}
        }
        draftState.enums[action.payload.name] = action.payload.value
      })
      return nextState
    }
    default:
      return state
  }
}

export default Lookup

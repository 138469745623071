import { takeEvery, fork, put, all } from 'redux-saga/effects'

import { GET_SUPPLIERS, POST_SUPPLIER, PUT_SUPPLIER, DEL_SUPPLIER } from './actionTypes'
import { addSupplier, addSuppliers, clearSuppliers, deleteSupplier, updateSupplier } from './actions'

import api from '../../helpers/api'
import errorService from '../../helpers/errorService'

function * getSuppliers () {
  try {
    const client = yield api.getClient()
    const { status = -1, body = {} } = yield client.apis.Supplier.get_api_Supplier()
    if (status === 200) {
      yield put(clearSuppliers())
      yield put(addSuppliers(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postSupplier ({ payload: { supplier } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Supplier.post_api_Supplier({}, { requestBody: supplier })
    if (status === 200) {
      yield put(addSupplier(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * delSupplier ({ payload: { supplier } }) {
  try {
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Supplier.delete_api_Supplier__id_({ id: supplier.id })
    if (status === 200) {
      yield put(deleteSupplier(supplier))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * putSupplier ({ payload: { supplier } }) {
  try {
    const { id, ...rest } = supplier
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Supplier.patch_api_Supplier__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateSupplier(supplier))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchGetSuppliers () {
  yield takeEvery(GET_SUPPLIERS, getSuppliers)
}

export function * watchPostSupplier () {
  yield takeEvery(POST_SUPPLIER, postSupplier)
}

export function * watchPutSupplier () {
  yield takeEvery(PUT_SUPPLIER, putSupplier)
}

export function * watchDelSuppliers () {
  yield takeEvery(DEL_SUPPLIER, delSupplier)
}

function * supplierSaga () {
  yield all([
    fork(watchGetSuppliers),
    fork(watchPostSupplier),
    fork(watchPutSupplier),
    fork(watchDelSuppliers)
  ])
}

export default supplierSaga

import React from 'react'
import { Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap'
import CustomerForm from '../customerForm/customerForm'
import VideoMarker from '../videoMarker/videoMarker'

const CustomerModal = ({ isOpen, setOpen, onSubmit, forCustomer = {} }) => {
  const {
    id = ''
  } = forCustomer

  const editingExisting = (id !== '')

  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='customer'>
      <div className='modal-content'>
        <ModalHeader toggle={onClose}>
          {(editingExisting ? 'Update' : 'New')} Customer Details
          <VideoMarker id='vmAddCustomer' title='Add Customer' videoUrl='https://vimeo.com/500951803/0157198e42' />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm='12'>
              <CustomerForm onSubmit={onSubmit} forCustomer={forCustomer} />
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default CustomerModal

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import SolutionsSummary from '../../components/solutionsSummary/SolutionsSummary'
import PageTitle from '../pageTitle/pageTitle'

const SolutionsSummaryExpanded = ({ history }) => {
  const solutions = useSelector((state) => state.Project.projects)
  const customers = useSelector((state) => state.Customer.customers)

  const solutionClicked = (solution) => {
    history.push(`/new/${solution.id}`)
  }

  const engagementClicked = (solution) => {
    history.push(`/engagement/${solution.id}`)
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm='12'>
              <PageTitle title='Existing SOW Services' icon='bx-layer' className='mb-3' />
            </Col>
          </Row>
          <Row>
            <Col xl='12'>
              <SolutionsSummary customers={customers} solutionsSummary={solutions} solutionClicked={solutionClicked} engagementClicked={engagementClicked} showingAll />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default (SolutionsSummaryExpanded)

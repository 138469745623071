import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import styled from 'styled-components'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ClientInfo = ({ customer, stakeholder }) => {
  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [websiteURL, setWebsiteURL] = useState('')

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Client Information
              </CardTitle>
            </Col>
          </Row>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Customer Name
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>{customer}</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard>
            <Row>
              <Col sm='4'>
                <div className='styledTitle'>
                  Stakeholder Name
                </div>
              </Col>
              <Col sm='8'>
                <div className='styledTitle'>
                  <b>{stakeholder}</b>
                </div>
              </Col>
            </Row>
          </StyledCard>
          <AvForm className='needs-validation'>
            <StyledCard>
              <Row>
                <Col sm='4'>
                  <div className='styledTitle'>
                    Address
                  </div>
                </Col>
                <Col sm='8'>
                  <AvField
                    name='address'
                    placeholder='Enter address'
                    type='text'
                    errorMessage='Enter valid address'
                    className='form-control'
                    validate={{ required: { value: true } }}
                    id='validationAddress'
                    onChange={(event) => setAddress(event.target.value)}
                    value={address}
                  />
                </Col>
              </Row>
            </StyledCard>
            <StyledCard>
              <Row>
                <Col sm='4'>
                  <div className='styledTitle'>
                    Phone Number
                  </div>
                </Col>
                <Col sm='8'>
                  <AvField
                    name='phoneNumber'
                    placeholder='Enter phone number'
                    type='number'
                    errorMessage='Enter valid phone number'
                    className='form-control'
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: '^[0-9]+$',
                        errorMessage: 'Enter valid phone number'
                      }
                    }}
                    id='validationPhone'
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    value={phoneNumber}
                  />
                </Col>
              </Row>
            </StyledCard>
            <StyledCard>
              <Row>
                <Col sm='4'>
                  <div className='styledTitle'>
                    Email Address
                  </div>
                </Col>
                <Col sm='8'>
                  <AvField
                    name='emailAddress'
                    placeholder='Enter email address'
                    type='email'
                    errorMessage='Enter valid email address'
                    className='form-control'
                    validate={{
                      required: { value: true },
                      email: { value: true }
                    }}
                    id='validationEmail'
                    onChange={(event) => setEmailAddress(event.target.value)}
                    value={emailAddress}
                  />
                </Col>
              </Row>
            </StyledCard>
            <StyledCard>
              <Row>
                <Col sm='4'>
                  <div className='styledTitle'>
                    Website
                  </div>
                </Col>
                <Col sm='8'>
                  <AvField
                    name='website'
                    placeholder='Enter website URL'
                    type='text'
                    errorMessage='Enter a valid website URL'
                    className='form-control'
                    validate={{
                      required: { value: true }
                    }}
                    id='validationWebsite'
                    onChange={(event) => setWebsiteURL(event.target.value)}
                    value={websiteURL}
                  />
                </Col>
              </Row>
            </StyledCard>
          </AvForm>
        </CardBody>
      </Card>
    </>
  )
}

export default ClientInfo

import { SET_USER_INFO, SET_USER_ROLE, UPDATE_USER_PROFILE, PUT_PROFILE } from './actionTypes'

export const setUserInfo = (userProfile) => {
  return {
    type: SET_USER_INFO,
    payload: { userProfile }
  }
}

export const updateUserProfile = (userProfile) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: { userProfile }
  }
}

export const setUserRole = (role) => {
  return {
    type: SET_USER_ROLE,
    payload: { role }
  }
}

export const putProfile = (profile) => {
  return {
    type: PUT_PROFILE,
    payload: { profile }
  }
}

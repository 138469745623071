import React from 'react'
import { Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Notifications from '../../components/notifications/Notifications'
import Messaging from '../../components/messaging/Messaging'
import Calendar from '../../components/calendar/Calendar'
import SolutionsSummary from '../../components/solutionsSummary/SolutionsSummary'
import PageTitle from '../../components/pageTitle/pageTitle'
import projectSyncService from '../../helpers/projectSyncService'

const RecruiterDashboard = ({ messaging, notifications, history }) => {
  const dispatch = useDispatch()

  const solutions = useSelector((state) => state.Project.projects)
  const customers = useSelector((state) => state.Customer.customers)
  const { role } = useSelector((state) => state.User)

  const solutionClicked = (project) => {
    projectSyncService.syncProject(project, dispatch)

    const legalUpdatedAt = _.get(project, 'legalDesign.updatedAt', undefined)
    history.push(`/${(legalUpdatedAt ? 'solution' : 'new')}/${project.id}`)
  }

  const engagementClicked = (project) => {
    projectSyncService.syncProject(project, dispatch)

    history.push(`/engagement/${project.id}`)
  }

  const salesClicked = (solution) => {
    history.push(`/sales-opportunity/${solution.id}`)
  }

  return (
    <>
      <Row className='mb-3'>
        <Col sm='8'>
          <PageTitle title='Dashboard' icon='bxs-dashboard' />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm='4'>
          <Messaging
            messages={messaging}
            role={role}
            onSendMessage={() => {}}
          />
        </Col>
        <Col sm='4'>
          <Calendar />
        </Col>
        <Col sm='4'>
          <Notifications notifications={notifications} role={role} />
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <SolutionsSummary customers={customers} solutionsSummary={solutions} solutionClicked={solutionClicked} engagementClicked={engagementClicked} salesClicked={salesClicked} />
        </Col>
      </Row>
    </>
  )
}

export default RecruiterDashboard

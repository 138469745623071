import React, { useState, useEffect } from 'react'
import { Route, withRouter, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import api from '../../helpers/api'

import { getProjects } from '../../store/project/actions'
import { getSuppliers } from '../../store/supplier/actions'
import { getCustomers } from '../../store/customer/actions'
import { getEvents } from '../../store/calendar/actions'
import { getPriorities, getOrganisationUsers, getEnums } from '../../store/lookup/actions'
import { getMessages } from '../../store/message/actions'
import { getNotifications } from '../../store/notification/actions'

type PropsType = RouteComponentProps<{}> & {
  component: any,
  layout: any,
  path: string,
  exact: boolean,
  user: any,
}

const Authmiddleware = ({ component: Component, layout: Layout, path, exact, user }: PropsType): any => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [isLoggedIn, setIsLoggedIn] = useState(undefined)

  const projects = useSelector((state) => state.Project.projects)

  useEffect(() => {
    const getAuthStatus: any = async () => {
      const loggedIn = await api.loggedIn()
      setIsLoggedIn(loggedIn)
    }
    getAuthStatus()
  }, [])

  useEffect(() => {
    if (location.pathname === '/dashboard' || location.pathname === '/home') {
      if (process.env.REACT_APP_DEMO_MODE === 'true') {
        if (projects.length > 0) {
          return
        }
      }

      dispatch(getEnums())
      dispatch(getProjects())
      dispatch(getSuppliers())
      dispatch(getCustomers())
      dispatch(getEvents())
      dispatch(getPriorities())
      dispatch(getOrganisationUsers())
      dispatch(getMessages())
      dispatch(getNotifications())
    }
  }, [location.pathname])

  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        if (isLoggedIn === false || user === undefined || user.role === '') {
          return (
            <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
          )
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default withRouter(Authmiddleware)

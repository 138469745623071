import React from 'react'
import { Col, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import LegalDesignForm from '../legalDesignForm/legalDesignForm'

const LegalDesign = ({ project = {}, onLegalDesignSaved, onBackToSuppliers, readOnly = true }) => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle>
            Legal Design
            <InfoMarker title='Legal Design' bodyCopy='The purpose of this section is to define which legal contracts are required to be created and/or reviewed as part of the Service provision' id='imLegalDesign' />
          </CardTitle>
          <Col sm='12' className='px-0'>
            <LegalDesignForm project={project} onLegalDesignSaved={onLegalDesignSaved} onBackToSuppliers={onBackToSuppliers} readOnly={readOnly} />
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default LegalDesign

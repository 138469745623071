import React from 'react'
import { Row, Col, Card, CardBody, Button, Container } from 'reactstrap'
import styled from 'styled-components'

const StyledCreateNew = styled.div`
  float: right;
  padding-bottom: 20px;
`

const Calendar = () => {
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col className='col-lg-12'>
                      <StyledCreateNew>
                        <Button color='primary' className='font-16 btn-block'>
                          <i className='mdi mdi-plus-circle-outline' /> Create New Event
                        </Button>
                      </StyledCreateNew>
                    </Col>
                    <Col className='col-lg-12' />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Calendar

import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import errorService from './errorService'
import api from './api'

const hubs = {
  hubNames: ['messages', 'notifications'],
  messagesHubConnection: undefined,
  notificationsHubConnection: undefined,
  setupHubs: async (token, organisationId) => {
    hubs.hubNames.map(async (hub) => {
      if (hubs[`${hub}HubConnection`] === undefined) {
        await hubs.createConnection(hub, token, organisationId)
      }
    })
  },
  closeHubs: async () => {
    try {
      await hubs.messagesHubConnection.stop()
      await hubs.notificationsHubConnection.stop()
    } catch (ex) {
      errorService.logError(ex)
    }
  },
  createConnection: (type, token, organisationId) => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}hubs/${type}?organisationId=${organisationId}`, { accessTokenFactory: () => token })
      .configureLogging(LogLevel.Error)
      .build()

    async function start () {
      if (await api.loggedIn()) {
        try {
          hubs[`${type}HubConnection`] = null
          await connection.start()
          hubs[`${type}HubConnection`] = connection
        } catch (err) {
          errorService.logError(err)
          hubs[`${type}HubConnection`] = undefined
          setTimeout(() => start(), 5000)
        }
      }
    }

    connection.on('ReceiveNotification', message => {
      console.log(message)
    })

    connection.onclose(async () => {
      hubs[`${type}HubConnection`] = undefined
      await start()
    })

    start()
  }
}

export default hubs

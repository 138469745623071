import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ProjectUserAddForm = ({ onAddUser }) => {
  const onSubmit = (values) => {
    onAddUser(values)
  }

  return (
    <AvForm className='needs-validation' onValidSubmit={onSubmit}>
      <StyledCard>
        <Row>
          <Col sm='12'>
            <SowaasButton title='Add User to Project' type='submit' />
          </Col>
        </Row>
      </StyledCard>
    </AvForm>
  )
}

export default ProjectUserAddForm

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import LegalDesignForm from '../legalDesignForm/legalDesignForm'

const LegalInfoModal = ({ isOpen, setOpen, project }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='lg'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            Legal Info
          </ModalHeader>
          <ModalBody>
            <LegalDesignForm project={project} readOnly embedded />
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default LegalInfoModal

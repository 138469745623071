import React from 'react'
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap'
import styled from 'styled-components'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link, useParams } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'

// action
import { userActivateSubmit } from '../../../store/auth/activate/actions'

// import images
import logo from '../../../assets/images/logo.png'
import SowaasButton from '../../../components/sowaasButton/sowaasButton'
import HelpButton from '../../../components/helpButton/helpButton'

const StyledLogo = styled.img`
  width: 300px;
  margin: 0 50px;
`

const Activate = ({ userActivateSubmit, activateErrorMsg, activateSuccessMsg }) => {
  const { resetToken = '', email = '' } = useParams()

  function handleSubmit (event, values) {
    userActivateSubmit(values)
  }

  return (
    <>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <div className='gradient-background'>
                  <Row>
                    <Col className='col-7'>
                      <div className='text-primary p-4'>
                        <StyledLogo src={logo} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className='pt-0'>
                  <div className='p-2'>
                    {
                      activateErrorMsg && activateErrorMsg
                        ? (
                          <Alert color='danger' style={{ marginTop: '13px' }}>
                            {activateErrorMsg}
                          </Alert>
                          )
                        : null
                    }
                    {activateSuccessMsg
                      ? (
                        <Alert color='success' style={{ marginTop: '13px' }}>
                          {activateSuccessMsg}
                        </Alert>
                        )
                      : null}
                    <AvForm
                      className='form-horizontal mt-4'
                      onValidSubmit={handleSubmit}
                    >
                      <AvInput type='hidden' name='resetToken' value={resetToken} />
                      <AvInput type='hidden' name='email' value={email} />
                      <div className='form-group'>
                        <AvField
                          name='password'
                          label='Password'
                          className='form-control'
                          placeholder='Enter Password'
                          type='password'
                          required
                        />
                      </div>
                      <div className='form-group'>
                        <AvField
                          name='passwordConfirm'
                          label='Confirm Password'
                          className='form-control'
                          placeholder='Enter password'
                          type='password'
                          required
                          validate={{ match: { value: 'password' } }}
                        />
                      </div>
                      <Row className='form-group'>
                        <Col className='text-right'>
                          <SowaasButton className='btn-block mt-2' title='Activate your account' type='submit' />
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                <Link to='/sign-in' className='font-weight-medium text-primary'>
                  <p>Go back to Login</p>
                </Link>
                <p>© {new Date().getFullYear()} SOWaaS</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <HelpButton />
    </>
  )
}

const mapStatetoProps = state => {
  const { activateSuccessMsg, activateErrorMsg } = state.Activate
  return { activateSuccessMsg, activateErrorMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userActivateSubmit })(Activate)
)

const opportunityProgress = [
  {
    option: 'Project selection',
    values: {
      identify: 0,
      qualify: 0,
      develop: 0,
      solution: 0,
      propose: 0,
      winpend: 0
    }
  },
  {
    option: 'Identify',
    values: {
      identify: 100 / 6,
      qualify: 0,
      develop: 0,
      solution: 0,
      propose: 0,
      winpend: 0
    }
  },
  {
    option: 'Qualify',
    values: {
      identify: 100 / 6,
      qualify: 100 / 6,
      develop: 0,
      solution: 0,
      propose: 0,
      winpend: 0
    }
  },
  {
    option: 'Develop',
    values: {
      identify: 100 / 6,
      qualify: 100 / 6,
      develop: 100 / 6,
      solution: 0,
      propose: 0,
      winpend: 0
    }
  },
  {
    option: 'Solution',
    values: {
      identify: 100 / 6,
      qualify: 100 / 6,
      develop: 100 / 6,
      solution: 100 / 6,
      propose: 0,
      winpend: 0
    }
  },
  {
    option: 'Propose',
    values: {
      identify: 100 / 6,
      qualify: 100 / 6,
      develop: 100 / 6,
      solution: 100 / 6,
      propose: 100 / 6,
      winpend: 0
    }
  },
  {
    option: 'Win/Pend',
    values: {
      identify: 100 / 6,
      qualify: 100 / 6,
      develop: 100 / 6,
      solution: 100 / 6,
      propose: 100 / 6,
      winpend: 100 / 6
    }
  }
]

export default opportunityProgress

import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_RESET,
  FORGET_PASSWORD_RESET_SUBMIT,
  FORGET_PASSWORD_RESET_SUBMIT_SUCCESS,
  FORGET_PASSWORD_RESET_SUBMIT_ERROR
} from './actionTypes'

export const userForgetPassword: any = (user: any, history: any) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history }
  }
}

export const userForgetPasswordSuccess: any = (message: string) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message
  }
}

export const userForgetPasswordReset: any = (values: string) => {
  return {
    type: FORGET_PASSWORD_RESET_SUBMIT,
    payload: values
  }
}

export const userForgetPasswordResetSuccess: any = (message: string) => {
  return {
    type: FORGET_PASSWORD_RESET_SUBMIT_SUCCESS,
    payload: message
  }
}

export const userForgetPasswordResetError: any = (message: string) => {
  return {
    type: FORGET_PASSWORD_RESET_SUBMIT_ERROR,
    payload: message
  }
}

export const userForgetPasswordError: any = (message: string) => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message
  }
}

export const userForgetPasswordClear: any = () => {
  return {
    type: FORGET_PASSWORD_RESET
  }
}

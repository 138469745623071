import React from 'react'

const PageTitle = ({ title = '', icon = '', className = '', centeredText = false }) => {
  const styles = { display: 'flex', alignItems: 'center', fontFamily: 'AlongSans', fontWeight: 600 }
  if (centeredText === true) {
    styles.justifyContent = 'center'
  }

  return (
    <h1 style={styles} className={`mb-0 pageTitle flex flex-row ${className}`}>
      {
        icon !== '' && (
          <div className='purpleHorizontalGradient' style={{ marginRight: '0.8rem', width: '3rem', height: '3rem', borderRadius: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF' }}>
            <i className={`bx ${icon}`} />
          </div>
        )
      }
      {title}
    </h1>
  )
}

export default PageTitle

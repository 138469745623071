import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { AvInput } from 'availity-reactstrap-validation'
import GroupToggle from '../groupToggle/groupToggle'
import InfoMarker from '../infoMarker/infoMarker'

const frequencies = [
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Fortnightly', value: 'Fortnightly' },
  { label: 'Monthly', value: 'Monthly' }
]

const EngagementReportingFrequency = ({ name = '', reporting = {}, phase, readOnly = false }) => {
  const {
    id = '',
    supplierReportingFrequency = '',
    customerReportingFrequency = ''
  } = reporting

  const [supplierFrequency, setSupplierFrequency] = useState(supplierReportingFrequency)
  const [customerFrequency, setCustomerFrequency] = useState(customerReportingFrequency)

  return (
    <>
      {
        id !== '' && (
          <AvInput type='hidden' name={`${name}.id`} value={id} />
        )
      }
      <AvInput type='hidden' name={`${name}.phaseId`} value={phase.id} />
      <Row className='mb-3 ml-3'>
        <Col sm='3'>
          <div className='styledTitle'>
            {phase.name}
          </div>
        </Col>
        <Col sm='9'>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                With Supplier
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='With Supplier' bodyCopy='What is the frequency of reporting with the Supplier' />
            </Col>
            <Col sm='8'>
              <GroupToggle disabled={readOnly} name={`${name}.supplierReportingFrequency`} items={frequencies} currentValue={supplierFrequency} onValueChanged={setSupplierFrequency} />
            </Col>
          </Row>
          <Row>
            <Col sm='2'>
              <div className='styledTitle'>
                With Customer
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='With Customer' bodyCopy='What is the frequency of reporting with the Customer' />
            </Col>
            <Col sm='8'>
              <GroupToggle disabled={readOnly} name={`${name}.customerReportingFrequency`} items={frequencies} currentValue={customerFrequency} onValueChanged={setCustomerFrequency} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default EngagementReportingFrequency

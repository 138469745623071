/* LOCAL */
export const CLEAR_RAIDCATEGORIES = 'CLEAR_RAIDCATEGORIES'
export const ADD_RAIDCATEGORIES = 'ADD_RAIDCATEGORIES'

/* API */
export const GET_RAIDCATEGORIES = 'GET_RAIDCATEGORIES'

/* LOCAL */
export const CLEAR_PRIORITIES = 'CLEAR_PRIORITIES'
export const ADD_PRIORITIES = 'ADD_PRIORITIES'

/* API */
export const GET_PRIORITIES = 'GET_PRIORITIES'

/* LOCAL */
export const CLEAR_ORGANISATION_USERS = 'CLEAR_ORGANISATION_USERS'
export const ADD_ORGANISATION_USERS = 'ADD_ORGANISATION_USERS'

/* API */
export const GET_ORGANISATION_USERS = 'GET_ORGANISATION_USERS'

export const GET_ENUMS = 'GET_ENUMS'
export const SET_ENUM = 'SET_ENUM'

import React from 'react'
import _ from 'lodash'
import { Row, Col, Table } from 'reactstrap'
import SowaasButton from '../sowaasButton/sowaasButton'

const SowTable = ({ sows = [{}, {}], type = '', onRowClicked, onRowDuplicated, onAddSow, className = '', suppliers = [], readOnly = false }) => {
  const renderTable = () => {
    return (
      <Table hover>
        <thead>
          <tr>
            {type === 'Supplier' && (
              <th style={{ width: '25%' }}>Supplier</th>
            )}
            {type === 'Customer' && (
              <th style={{ width: '25%' }}>Type</th>
            )}
            <th style={{ width: '25%' }}>Phases</th>
            <th style={{ width: '25%' }}>Deliverables</th>
            <th style={{ width: '25%' }}>Milestones</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {
            sows.map((sow, idx) => (
              <tr key={idx}>
                <td className='pt-3'>
                  {sow.statementOfWorkType === 'Supplier' ? (_.find(suppliers, { id: sow.supplierId }).name) : sow.statementOfWorkType}
                </td>
                <td className='pt-3'>{sow.phases.length}</td>
                <td className='pt-3'>{sow.deliverables.length}</td>
                <td className='pt-3'>{sow.milestones.length}</td>
                <td style={{ display: 'flex' }}>
                  <SowaasButton className='mr-2' onClick={() => { onRowClicked(sow) }} title='View' />
                  {
                    readOnly === false && (
                      <SowaasButton disabled={readOnly} onClick={() => { onRowDuplicated(sow) }} title='Duplicate' />
                    )
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    )
  }

  const renderEmpty = () => {
    return (
      <></>
    )
  }

  return (
    <div className={` ${className}`}>
      <Row className='mb-3'>
        <Col sm='12'>
          {type} Statements Of Work
        </Col>
      </Row>
      {
        sows.length === 0 && (
          <>{renderEmpty()}</>
        )
      }
      {
        sows.length > 0 && (
          <>{renderTable()}</>
        )
      }
      {
        readOnly === false && (
          <Row className='mt-4'>
            <Col sm='12'>
              <SowaasButton disabled={readOnly} title={`Add ${type} Statement of Work`} onClick={onAddSow} />
            </Col>
          </Row>
        )
      }
    </div>
  )
}

export default SowTable

export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const ADD_CUSTOMERS = 'ADD_CUSTOMERS'
export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'

export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const POST_CUSTOMER = 'POST_CUSTOMER'
export const PUT_CUSTOMER = 'PUT_CUSTOMER'
export const DEL_CUSTOMER = 'DEL_CUSTOMER'

import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import { convertToHTML } from 'draft-convert'
import SowaasButton from '../../components/sowaasButton/sowaasButton'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import moment from 'moment'
import uniqid from 'uniqid'
import _ from 'lodash'

const MessageForm = ({ existingMessage = {}, replying = false, onSendMessage, users = [], user }) => {
  const {
    id = '',
    fromUser = '',
    subject = '',
    content = ''
  } = existingMessage

  const isNew = (id === '')

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [messageContent, setMessageContent] = useState((replying ? '' : content))

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
    const rawMessageContent = convertToHTML(newEditorState.getCurrentContent())
    setMessageContent(rawMessageContent)
  }

  useEffect(
    () => {
      if (!replying) {
        const blocksFromHtml = convertFromHTML(content)
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        )
        setEditorState(EditorState.createWithContent(contentState))
        convertToRaw(contentState)
      }
    },
    []
  )

  const handleSubmit = () => {
    const now = moment().toISOString()

    const baseMessage = {
      files: [],
      fromUserId: user.id,
      fromUser: `${user.name} (${user.company})`,
      content: messageContent,
      isPublic: true,
      read: false,
      sentAt: now,
      readAt: now,
      parentMessageId: 0,
      id: uniqid(),
      createdAt: now,
      createdById: 0,
      updatedAt: now,
      updatedById: 0
    }

    if (!replying) {
      baseMessage.toUserId = 0
      baseMessage.toUser = ''
      baseMessage.role = ''
      baseMessage.subject = ''
    } else {
      const toUser = _.find(users, { id: existingMessage.fromUserId })

      baseMessage.toUserId = existingMessage.fromUserId
      baseMessage.toUser = existingMessage.fromUser
      baseMessage.role = toUser.role
      baseMessage.subject = existingMessage.subject
    }

    onSendMessage(baseMessage)
  }

  return (
    <AvForm onSubmit={handleSubmit}>
      {
        (isNew && !replying) && (
          <AvField label='To' name='toUser' type='text' className='form-control' disabled={!isNew} />
        )
      }
      {
        (!isNew && !replying) && (
          <AvField label='From' name='fromUser' type='text' className='form-control' value={fromUser} disabled={!isNew} />
        )
      }
      {
        !replying && (
          <AvField label='Subject' name='subject' type='text' className='form-control' value={subject} disabled={!isNew} />
        )
      }
      <div className='form-group'>
        <label>{(replying ? 'Reply' : 'Message')}</label>
        <Editor
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          toolbarHidden
          editorState={editorState}
          readOnly={!isNew && !replying}
          stripPastedStyles
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      {
        (isNew || replying) && (
          <Row>
            <Col sm='12' className='mt-3'>
              <SowaasButton type='submit' title='Send' />
            </Col>
          </Row>
        )
      }
    </AvForm>
  )
}

export default MessageForm

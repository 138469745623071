import React from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap'
import SowaasButton from '../sowaasButton/sowaasButton'

const DocumentModal = ({ isOpen, setOpen, fileUrl }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose} size='customer'>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            View Document
          </ModalHeader>
          <ModalBody>
            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`} width='100%' height='623px' frameborder='0'>This is an embedded <a target='_blank' rel='noreferrer' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' rel='noreferrer' href='http://office.com/webapps'>Office Online</a>.</iframe>
            <Row className='mt-3'>
              <Col sm='12'>
                <SowaasButton title='Download' style={{ float: 'right' }} onClick={() => { const win = window.open(fileUrl, '_blank'); win.focus() }} />
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default DocumentModal

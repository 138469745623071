import React from 'react'
import { Col, Row, InputGroup } from 'reactstrap'
import { AvField, AvInput, AvGroup, AvFeedback } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'

const Supplier = ({ existingSuppliers, setAddSupplierModalOpen, idx, supplier = {}, supplierRoles = [], readOnly = false }) => {
  const {
    id = '',
    supplierId = '-1',
    role = 'ServiceDesignInputOnly',
    inputDescription = ''
  } = supplier

  return (
    <>
      <hr className='my-4' />
      {
        (id !== '') && (
          <AvInput type='hidden' name={`suppliers[${idx}].id`} value={id} />
        )
      }
      <Row>
        <Col sm='3'>
          <div className='styledTitle'>
            Select Supplier for this SOW Service
          </div>
        </Col>
        <Col sm='6'>
          <AvGroup style={{ marginBottom: '0.5rem' }}>
            <InputGroup>
              <AvInput disabled={readOnly} type='select' name={`suppliers[${idx}].supplierId`} value={supplierId} valueParser={window.parseInt}>
                <option disabled value='-1'>Select existing Supplier</option>
                {
                  existingSuppliers.map((c, idx) => (
                    <option key={idx} value={c.id}>{c.name}</option>
                  ))
                }
              </AvInput>
            </InputGroup>
            <AvFeedback>Enter supplier name</AvFeedback>
          </AvGroup>
        </Col>
        <Col sm='1' style={{ textAlign: 'center' }} className='pt-2'>
          Or
        </Col>
        <Col sm='2'>
          <SowaasButton disabled={readOnly} className='btn-block' onClick={() => { setAddSupplierModalOpen(true) }} title='Add New Supplier' />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col sm='3' />
        <Col sm='9'>
          <Row>
            <Col sm='3'>
              <div className='styledTitle' style={{ paddingLeft: 0 }}>
                What is the Supplier's role?
                <InfoMarker id='imSupplierRole' title="What is the Supplier's role?" bodyCopy={(<>Service Design Input Only = The Supplier will provide support to you in creating but not Delivering this SOW Service<br /><br />Delivery Only = The Supplier will be part of the Service team you deploy to Deliver the outcomes for your customer<br /><br />Service Design Input and Delivery = The Supplier will provide support in the creation and Delivery of the SOW Service</>)} />
              </div>
            </Col>
            <Col sm='5'>
              <AvField disabled={readOnly} type='select' name={`suppliers[${idx}].role`} value={role}>
                {
                  supplierRoles.map((ct, idx) => (
                    <option key={idx} value={ct.value}>{ct.label}</option>
                  ))
                }
              </AvField>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm='3' />
        <Col sm='9'>
          <Row>
            <Col sm='3'>
              <div className='styledTitle' style={{ paddingLeft: 0 }}>
                Provide high-level details of the support required?
              </div>
            </Col>
            <Col sm='5'>
              <AvInput
                className='form-control extendedInput mb-3'
                type='textarea'
                name={`suppliers[${idx}].inputDescription`}
                placeholder=''
                value={inputDescription}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Supplier

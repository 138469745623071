export const ADD_EVENT = 'ADD_EVENT'
export const ADD_EVENTS = 'ADD_EVENTS'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'

export const GET_EVENTS = 'GET_EVENTS'
export const POST_EVENT = 'POST_EVENT'
export const PUT_EVENT = 'PUT_EVENT'
export const DEL_EVENT = 'DEL_EVENT'

import { ADD_EVENT, ADD_EVENTS, CLEAR_EVENTS, UPDATE_EVENT, DELETE_EVENT, GET_EVENTS, POST_EVENT, PUT_EVENT, DEL_EVENT } from './actionTypes'

export const addEvent = event => ({
  type: ADD_EVENT,
  payload: { event }
})

export const addEvents = events => ({
  type: ADD_EVENTS,
  payload: { events }
})

export const clearEvents = () => ({
  type: CLEAR_EVENTS,
  payload: {}
})

export const updateEvents = event => ({
  type: UPDATE_EVENT,
  payload: { event }
})

export const deleteEvent = event => ({
  type: DELETE_EVENT,
  payload: { event }
})

export const getEvents = () => ({
  type: GET_EVENTS
})

export const postEvent = event => ({
  type: POST_EVENT,
  payload: { event }
})

export const putEvent = event => ({
  type: PUT_EVENT,
  payload: { event }
})

export const delEvent = event => ({
  type: DEL_EVENT,
  payload: { event }
})

import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Card, CardBody, CardTitle, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import smallDateFormatter from '../../helpers/smallDateFormatter'
import _ from 'lodash'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButtonInteraction from '../sowaasButtonInteraction/sowaasButtonInteraction'
import MessageModal from '../messageModal/messageModal'
import { useDispatch } from 'react-redux'
import { postMessage, markMessageAsRead } from '../../store/message/actions'
import VideoMarker from '../videoMarker/videoMarker'

const StyledHeading = styled.div`
  padding-bottom: 20px;
  margin: 0 2px;
  display: flex;
  justify-content: space-between;
`

const Messaging = ({ messages = [], numberToShow = 5, role = '', hideView = false }) => {
  const dispatch = useDispatch()

  const [modal, setmodal] = useState(false)

  const GoButton = () => {
    return (
      <>
        <SowaasButtonInteraction className='float-right' title='View All' icon='mdi mdi-arrow-right' to='/activity-feed' />
      </>
    )
  }

  const filteredMessages = _.chain(messages).filter({ role }).value()

  let unreadMessageSum = 0
  filteredMessages.forEach(m => {
    unreadMessageSum += (m.read ? 0 : 1)
  })

  const topXMessages = _.chain(filteredMessages).take(numberToShow).orderBy(['sentAt'], ['desc']).value()

  const onSendMessage = (message) => {
    dispatch(postMessage(message))
    setmodal(false)
  }

  const clickMessage = (message) => {
    dispatch(markMessageAsRead(message))
    setmodal(message)
  }

  return (
    <>
      <Card className='overflow-hidden' style={{ height: '100%' }}>
        <div className='aquaVerticalGradient' style={{ height: '8px' }} />
        <CardBody className='flex-card'>
          <Row>
            <Col sm='7'>
              <StyledHeading>
                <CardTitle className='mb-2'>
                  Messaging
                  <InfoMarker title='Messaging' bodyCopy='To display latest information' name='imMessaging' />
                  <VideoMarker id='vmMessaging' title='Messaging' videoUrl='https://vimeo.com/500148795/bd2569eb2b' />
                </CardTitle>
              </StyledHeading>
            </Col>
            <Col sm='5'>
              {
                hideView === false && (
                  <GoButton />
                )
              }
            </Col>
          </Row>
          <Row>
            <Table hover style={{ flex: 1 }}>
              <tbody>
                {topXMessages.map((message, idx) => (
                  <tr key={idx} onClick={() => { clickMessage(message) }}>
                    <td style={{ width: '2rem' }}>
                      {(message.read === true) ? null : <span className='fas fa-circle text-info' />}
                    </td>
                    <td>
                      {message.subject}<br />
                      {message.fromUser}
                    </td>
                    <td style={{ width: '5rem' }}>
                      {smallDateFormatter(message.sentAt)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          {
            topXMessages.length === 0 && (
              <div className='center-aligned-box pt-4'>
                <h6>No messages received yet</h6>
              </div>
            )
          }
          <Row sm='12'>
            <Col sm='6' className='pt-3'>
              <Link to='/activity-feed'>{unreadMessageSum} Unread</Link>
            </Col>
            <Col sm='6'>
              <SowaasButtonInteraction className='float-right' onClick={() => { setmodal({}) }} title='New' />
              {
                (modal !== false) && (
                  <MessageModal isOpen={(modal !== false)} replyingTo={modal} onSend={onSendMessage} onClose={setmodal} />
                )
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default Messaging

import React from 'react'
import RaidStatusTable from './raidStatusTable'
import { Col, Row, Input } from 'reactstrap'
import styled from 'styled-components'
import _ from 'lodash'

const StyledRow = styled(Row)`
  margin-bottom: 0.75rem;
`

const StyledInput = styled(Input)`
  width: 4.5rem;
  text-align: center;
  margin-left: 0.5rem;
`

const calculateRaidCounts = (items, property) => {
  const negligibles = _.filter(items, (itm) => { return itm[property] === 'Negligible' })
  const lows = _.filter(items, (itm) => { return itm[property] === 'Low' })
  const moderates = _.filter(items, (itm) => { return itm[property] === 'Moderate' })
  const highs = _.filter(items, (itm) => { return itm[property] === 'High' })
  const criticals = _.filter(items, (itm) => { return itm[property] === 'Critical' })

  const total = negligibles.length + lows.length + moderates.length + highs.length + criticals.length

  return {
    nCount: negligibles.length,
    lCount: lows.length,
    mCount: moderates.length,
    hCount: highs.length,
    cCount: criticals.length,
    total
  }
}

const RaidDashboard = ({ raidItems = [] }) => {
  // get risks
  const raidRisks = _.filter(raidItems, { categoryId: 1 })
  // get risk counts
  const riskCounts = calculateRaidCounts(raidRisks, 'severity')

  // get assumptions
  const raidAssumptions = _.filter(raidItems, { categoryId: 2 })

  // get assumption counts
  const assumptionCounts = calculateRaidCounts(raidAssumptions, 'priority')

  // get impacts
  const raidIssues = _.filter(raidItems, { categoryId: 3 })

  // get imapact counts
  const impactCounts = calculateRaidCounts(raidIssues, 'severity')

  // get dependencies
  const raidDependencies = _.filter(raidItems, { categoryId: 4 })

  // get dependencies counts
  const dependencyCounts = calculateRaidCounts(raidDependencies, 'importance')

  const tableData = [
    { title: 'Risks', ...riskCounts },
    { title: 'Assumptions', ...assumptionCounts },
    { title: 'Impacts', ...impactCounts },
    { title: 'Dependency', ...dependencyCounts }
  ]

  const nTotal = _.sumBy(tableData, 'nCount')
  const lTotal = _.sumBy(tableData, 'lCount')
  const mTotal = _.sumBy(tableData, 'mCount')
  const hTotal = _.sumBy(tableData, 'hCount')
  const cTotal = _.sumBy(tableData, 'cCount')
  const total = nTotal + lTotal + mTotal + hTotal + cTotal

  return (
    <>
      <Row>
        <Col sm='11'>
          <Row className='mt-3'>
            {
              tableData.map((row, idx) => (
                <Col sm='3' key={idx}>
                  <RaidStatusTable {...row} />
                </Col>
              ))
            }
          </Row>
        </Col>
        <Col sm='1' style={{ paddingTop: '3rem' }}>
          <StyledRow>
            <StyledInput value={cTotal} />
          </StyledRow>
          <StyledRow>
            <StyledInput value={hTotal} />
          </StyledRow>
          <StyledRow>
            <StyledInput value={mTotal} />
          </StyledRow>
          <StyledRow>
            <StyledInput value={lTotal} />
          </StyledRow>
          <StyledRow>
            <StyledInput value={nTotal} />
          </StyledRow>
          <StyledRow>
            <StyledInput value={total} />
          </StyledRow>
        </Col>
      </Row>
    </>
  )
}

export default RaidDashboard

import React, { useState } from 'react'
import SowaasButton from '../sowaasButton/sowaasButton'
import api from '../../helpers/api'
import download from 'downloadjs'
import contentDisposition from 'content-disposition'
import errorService from '../../helpers/errorService'
import { AvInput } from 'availity-reactstrap-validation'

const allowedFileUploadTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

const FileUpload = ({ formName, fileType = 'Unknown', readOnly = false, existingFile = {} }) => {
  const [selectedFile, setSelectedFile] = useState(existingFile)

  const {
    id = '',
    name = ''
  } = selectedFile

  const labelValue = (name === '' ? 'Choose file' : name)

  const filePicked = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0]

      const { type } = file

      if (!allowedFileUploadTypes.includes(type)) {
        window.alert('The selected file type is not allowed')
        return
      }

      setSelectedFile(file)
    }
  }

  const uploadFile = async () => {
    const client = await api.getClient()
    const { status, body = {} } = await client.apis.File.post_api_File({}, { requestBody: { File: selectedFile, FileType: fileType } })
    if (status === 200) {
      const newSelectedFile = { name: selectedFile.name, id: body }
      setSelectedFile(newSelectedFile)
    }
  }

  const downloadFile = async () => {
    try {
      const client = await api.getClient()
      const result = await client.apis.File.get_api_File__fileId_({ fileId: id })
      if (result.status === 200) {
        const dispositionString = result.headers['content-disposition']
        const disposition = contentDisposition.parse(dispositionString)
        const filename = disposition.parameters.filename
        download(result.data, filename)
      }
    } catch (ex) {
      errorService.logError(ex)
    }
  }

  return (
    <div>
      {
        selectedFile.id && (
          <AvInput type='hidden' name={formName} value={selectedFile.id} />
        )
      }
      <input disabled={readOnly} style={{ width: '25rem' }} type='file' className='custom-file-input' id={formName} onChange={filePicked} />
      <label disabled={readOnly} style={{ width: '25rem' }} className='custom-file-label' htmlFor={formName}>{labelValue}</label>
      {
        (id === '' && name !== '') && (
          <SowaasButton disabled={readOnly} style={{ display: 'inline' }} title='Upload' onClick={() => { uploadFile() }} />
        )
      }
      {
        (id !== '') && (
          <SowaasButton style={{ display: 'inline' }} title='Download' onClick={() => { downloadFile() }} />
        )
      }
    </div>
  )
}

export default FileUpload

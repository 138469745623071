import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Table } from 'reactstrap'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import produce from 'immer'
import uniqid from 'uniqid'
import _ from 'lodash'
import SowaasIconButton from '../sowaasIconButton/sowaasIconButton'

const StyledSubHeader = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  padding: 0.5rem 0 1rem 0;
`

const StyledCard = styled.div`
  padding: 5px 0;
`

const TimeUtilForm = ({ mode = 'add', tu = undefined, readOnly = true, onSubmit, suppliers = [], project = {} }) => {
  const projectName = _.get(project, 'name', '')
  const customerName = _.get(project, 'projectProcurer.name', '')

  const statementOfWorks = _.get(project, 'solution.statementOfWorks', [])
  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const deliverables = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.deliverables || []))
  const milestones = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.milestones || []))

  const {
    supplierId = '-1',
    supplierStakeholder = '',
    slug = '',
    id = '',
    createdAt = new Date().toISOString().substring(0, 10),
    weekCommencing = '',
    sheets = [{ localId: uniqid() }]
  } = tu

  let weekCommencingValue
  if (weekCommencing !== '') {
    weekCommencingValue = weekCommencing.substring(0, 10)
  }

  let createdAtValue
  if (createdAt !== '') {
    createdAtValue = createdAt.substring(0, 10)
  }

  const [timesheets, setTimesheets] = useState(sheets)

  const addTimesheet = () => {
    const nextTimesheets = produce(timesheets, draftTimesheets => {
      draftTimesheets.push({ localId: uniqid() })
    })
    setTimesheets(nextTimesheets)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      delete values.customerName
      delete values.projectServiceName
      onSubmit(values)
    }
  }

  const handleRemoveRow = (idx) => {
    if (window.confirm('Are you sure you wish to delete these hours?')) {
      const newTimesheets = produce(timesheets, draftItems => {
        draftItems.splice(idx, 1)
      })
      setTimesheets(newTimesheets)
    }
  }

  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      {
        (id !== '') && (
          <AvInput type='hidden' name='id' value={id} />
        )
      }
      <StyledCard>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Supplier / 3rd Party Name
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Supplier / 3rd Party Name' bodyCopy='The supplier / 3rd party legal name provided during the Service creation - this will be auto populated.' id='imSupplierName' />
          </Col>
          <Col sm='9'>
            <AvField disabled={readOnly} type='select' name='supplierId' value={supplierId} required valueParser={window.parseInt}>
              <option disabled value='-1'>Select Supplier</option>
              {
                suppliers.map((supplier, idx) => (
                  <option key={idx} value={supplier.id}>{supplier.name}</option>
                ))
              }
            </AvField>
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Supplier Stakeholder
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Supplier / Stakeholder' bodyCopy='This is the name of the consultant provided by the Supplier or 3rd Party.' id='imSupplierStakeholder' />
          </Col>
          <Col sm='9'>
            <AvField name='supplierStakeholder' disabled={readOnly} value={supplierStakeholder} />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Customer Name
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Customer Name' bodyCopy='The name of the end customer, this will be auto populated' id='imCustomerName' />
          </Col>
          <Col sm='9'>
            <AvField name='customerName' disabled value={customerName} />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Project / Service Name
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Project Name' bodyCopy='The name of the Project or Service as defined during SOW/Service creation.' id='imProjectName' />
          </Col>
          <Col sm='9'>
            <AvField name='projectServiceName' disabled value={projectName} />
          </Col>
        </Row>
        {
          slug !== '' && (
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  TU Report ID
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right pr-0'>
                <InfoMarker title='TU Report ID' bodyCopy='Auto generated by the system, a unique ID for the Time Utilisation report' id='imTuReportID' />
              </Col>
              <Col sm='9'>
                <AvField name='tuID' disabled={readOnly} value={slug} />
              </Col>
            </Row>
          )
        }
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Date Created
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Date Created' bodyCopy='The system will automatically enter the date this report was created' id='imDateCreated' />
          </Col>
          <Col sm='9'>
            <AvField type='date' name='dateCreated' disabled value={createdAtValue} />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Week Commencing
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Week Commencing' bodyCopy='Enter the week commencing date which this report relates to.' id='imWeekCommencing' />
          </Col>
          <Col sm='9'>
            <AvField required type='date' name='weekCommencing' disabled={readOnly} value={weekCommencingValue} />
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <StyledSubHeader>
              Hours
              <InfoMarker title='Hours' bodyCopy='Enter the number of hours (in 0.5 hour increments) spent against each specific task.' id='imHours' />
            </StyledSubHeader>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: '8rem' }}>
                    Deliverable
                    <InfoMarker title='Deliverable / Task ID' bodyCopy="This is the name of the deliverable created during SOW/Service design along with it's unique ID. Choose the relevant deliverable to enter time against." id='imDeliverable' />
                  </th>
                  <th style={{ width: '8rem' }}>
                    Milestone
                    <InfoMarker title='Sub-Task Name and ID' bodyCopy="This is the name of the milestone (sub-task) created during SOW/Service design along with it's unique ID. Choose the relevant sub-task to record time against." id='imSubtask' />
                  </th>
                  <th>Mon</th>
                  <th>Tues</th>
                  <th>Wed</th>
                  <th>Thurs</th>
                  <th>Fri</th>
                  <th>Sat</th>
                  <th>Sun</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  timesheets.map((ts, idx) => {
                    const {
                      id,
                      localId,
                      deliverableId = '-1',
                      milestoneId = '-1',
                      monday = '0',
                      tuesday = '0',
                      wednesday = '0',
                      thursday = '0',
                      friday = '0',
                      saturday = '0',
                      sunday = '0'
                    } = ts
                    return (
                      <tr key={(localId || id)}>
                        <td>
                          <AvField type='select' name={`sheets[${idx}].deliverableId`} value={deliverableId} valueParser={window.parseInt} disabled={readOnly}>
                            <option disabled value='-1'>Select Deliverable</option>
                            {
                              deliverables.map((d) => (
                                <option key={d.id} value={d.id}>{d.name}</option>
                              ))
                            }
                          </AvField>
                        </td>
                        <td>
                          <AvField type='select' name={`sheets[${idx}].milestoneId`} value={milestoneId} valueParser={window.parseInt} disabled={readOnly}>
                            <option disabled value='-1'>Select Milestone</option>
                            {
                              milestones.map((d) => (
                                <option key={d.id} value={d.id}>{d.name}</option>
                              ))
                            }
                          </AvField>
                        </td>
                        <td><AvField min={0} max={24} step={0.5} type='number' name={`sheets[${idx}].monday`} disabled={readOnly} value={String(monday)} valueParser={window.parseFloat} /></td>
                        <td><AvField min={0} max={24} step={0.5} type='number' name={`sheets[${idx}].tuesday`} disabled={readOnly} value={String(tuesday)} valueParser={window.parseFloat} /></td>
                        <td><AvField min={0} max={24} step={0.5} type='number' name={`sheets[${idx}].wednesday`} disabled={readOnly} value={String(wednesday)} valueParser={window.parseFloat} /></td>
                        <td><AvField min={0} max={24} step={0.5} type='number' name={`sheets[${idx}].thursday`} disabled={readOnly} value={String(thursday)} valueParser={window.parseFloat} /></td>
                        <td><AvField min={0} max={24} step={0.5} type='number' name={`sheets[${idx}].friday`} disabled={readOnly} value={String(friday)} valueParser={window.parseFloat} /></td>
                        <td><AvField min={0} max={24} step={0.5} type='number' name={`sheets[${idx}].saturday`} disabled={readOnly} value={String(saturday)} valueParser={window.parseFloat} /></td>
                        <td><AvField min={0} max={24} step={0.5} type='number' name={`sheets[${idx}].sunday`} disabled={readOnly} value={String(sunday)} valueParser={window.parseFloat} /></td>
                        <td>
                          {
                            !readOnly && (
                              <SowaasIconButton id='btnRemoveTuRow' icon='fa fa-trash' onClick={() => { handleRemoveRow(idx) }} disabled={readOnly} />
                            )
                          }
                          {
                            (id !== undefined) && (
                              <AvInput type='hidden' value={id} name={`sheets[${idx}].id`} />
                            )
                          }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            {
              mode === 'add' && (
                <SowaasButton id='btnAddAnotherTuRow' className='float-left' title='ADD ANOTHER' onClick={addTimesheet} />
              )
            }
            {
              mode === 'add' && (
                <SowaasButton id='btnSubmitTu' className='float-right' title='Save' type='submit' />
              )
            }
            {
              mode === 'approve' && (
                <SowaasButton id='btnApproveTu' className='float-right' title='Approve' type='submit' />
              )
            }
          </Col>
        </Row>
      </StyledCard>
    </AvForm>
  )
}

export default TimeUtilForm

import React from 'react'
import styled from 'styled-components'

import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

const StyledList = styled.ol`
  padding-left: 15px !important;
`

const dummyMessages = [
  { text: 'Message 1' },
  { text: 'Message 2' },
  { text: 'Message 3' }
]

const Status = () => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle className='mb-2'>
            Status
          </CardTitle>
          <Row>
            <Col sm='6'>
              <CardTitle>Sales</CardTitle>
              <StyledList>
                {dummyMessages.map((messages, idx) => (
                  <li key={idx}>
                    <Col sm='12'>
                      {messages.text}
                    </Col>
                  </li>
                ))}
              </StyledList>
            </Col>
            <Col sm='6'>
              <CardTitle>Legal</CardTitle>
              <StyledList>
                {dummyMessages.map((messages, idx) => (
                  <li key={idx}>
                    <Col sm='12'>
                      {messages.text}
                    </Col>
                  </li>
                ))}
              </StyledList>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <CardTitle>Solution</CardTitle>
              <StyledList>
                {dummyMessages.map((messages, idx) => (
                  <li key={idx}>
                    <Col sm='12'>
                      {messages.text}
                    </Col>
                  </li>
                ))}
              </StyledList>
            </Col>
            <Col sm='6'>
              <CardTitle>Finance</CardTitle>
              <StyledList>
                {dummyMessages.map((messages, idx) => (
                  <li key={idx}>
                    <Col sm='12'>
                      {messages.text}
                    </Col>
                  </li>
                ))}
              </StyledList>
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <CardTitle>Engage</CardTitle>
              <StyledList>
                {dummyMessages.map((messages, idx) => (
                  <li key={idx}>
                    <Col sm='12'>
                      {messages.text}
                    </Col>
                  </li>
                ))}
              </StyledList>
            </Col>
            <Col sm='6'>
              <CardTitle>Other</CardTitle>
              <StyledList>
                {dummyMessages.map((messages, idx) => (
                  <li key={idx}>
                    <Col sm='12'>
                      {messages.text}
                    </Col>
                  </li>
                ))}
              </StyledList>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default Status

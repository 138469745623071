import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { loginUser, loginUserFailed } from '../../../store/auth/login/actions'

// import images
import logo from '../../../assets/images/logo.png'

import api from '../../../helpers/api'
import SowaasButton from '../../../components/sowaasButton/sowaasButton'
import HelpButton from '../../../components/helpButton/helpButton'

const StyledLogo = styled.img`
  width: 300px;
  margin: 0 50px;
`

const Login = ({ history, error, loginUser }) => {
  const [passwordEnabled, setPasswordEnabled] = useState(false)

  const defaultUsername = _.get(process.env, 'REACT_APP_DEFAULT_LOGIN_USERNAME', '')
  const defaultPassword = _.get(process.env, 'REACT_APP_DEFAULT_LOGIN_PASSWORD', '')

  const handleValidSubmit = async (event, values) => {
    if (!passwordEnabled) {
      await api.setupUnauthenticatedClient()
      try {
        await api.unauthenticatedClient.apis.ApplicationUser.get_api_ApplicationUser_providers({ email: values.email })
      } catch (exception) {
        history.push('/error')
      } finally {
        setPasswordEnabled(true)
      }
    } else {
      loginUser(values, history)
    }
  }

  return (
    <>
      <div className='account-pages my-5 pt-sm-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <div className='gradient-background'>
                  <Row>
                    <Col className='col-7'>
                      <div className='text-primary p-4'>
                        <StyledLogo src={logo} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className='pt-0'>
                  <div className='p-2'>
                    <AvForm
                      className='form-horizontal mt-4'
                      onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}
                    >
                      {error && error.message && error.message.length > 0 ? <Alert color='danger'>{error.message}</Alert> : null}
                      <div className='form-group'>
                        <AvField
                          name='username'
                          label='Username'
                          className='form-control'
                          placeholder='Enter username'
                          type='username'
                          required
                          value={defaultUsername}
                        />
                      </div>
                      {
                        passwordEnabled && (
                          <div className='form-group'>
                            <AvField
                              name='password'
                              label='Password'
                              placeholder='Enter password'
                              type='password'
                              required
                              value={defaultPassword}
                            />
                          </div>
                        )
                      }
                      <div className='mt-4'>
                        <SowaasButton className='btn-block' title={passwordEnabled ? 'Log In' : 'Continue'} type='submit' />
                      </div>
                      <div className='mt-4 text-center'>
                        <Link to='/forgot-password' className='text-muted'>
                          <i className='mdi mdi-lock mr-1' />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <p style={{ textAlign: 'center' }}>© {new Date().getFullYear()} SOWaaS</p>
            </Col>
          </Row>
        </Container>
      </div>
      <HelpButton />
    </>
  )
}

const mapStatetoProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(connect(mapStatetoProps, { loginUser, loginUserFailed })(Login))

import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import HomeTile from './homeTile'
import PageTitle from '../../components/pageTitle/pageTitle'
import { useHistory } from 'react-router-dom'

const Home = () => {
  const { t } = useTranslation()
  const view = useSelector((state) => state.User.role)
  const history = useHistory()

  const tileClicked = (to) => {
    history.push(to)
  }

  const renderTiles = (view) => {
    const tilesToRender = []

    switch (view) {
      case 'recruiter_consultant':
        tilesToRender.push({ title: 'Create New Service', subtitle: 'Create a New SOW/Project Service for your Customer / 3rd Party', icon: 'bx bx-plus-circle', to: '/new' })
        tilesToRender.push({ title: 'View Existing Service', subtitle: 'View or update an Existing SOW/Project service already in operation', icon: 'bx bx-layer', to: '/existing' })
        break
      case 'recruiter_executive':
        tilesToRender.push({ title: 'Review & Approve', subtitle: 'Review and Approve a New Service or Activity submitted for your authorisation', icon: 'bx bx-plus-circle', to: '/dashboard' })
        tilesToRender.push({ title: 'Check Existing Services', subtitle: 'View an existing SOW/Project service already in operation', icon: 'bx bx-layer', to: '/existing' })
        break
      case 'legal_finance':
        tilesToRender.push({ title: 'Review & Approve', subtitle: 'Review and Approve a New Service or Activity submitted for your authorisation', icon: 'bx bx-plus-circle', to: '/dashboard' })
        tilesToRender.push({ title: 'Check Existing Services', subtitle: 'View an existing SOW/Project service already in operation', icon: 'bx bx-layer', to: '/existing' })
        break
      case 'customer':
        tilesToRender.push({ title: 'Review Your Services', subtitle: 'Check on the progress of your existing SOW/Project service already in operation', icon: 'bx bx-layer', to: '/existing' })
        tilesToRender.push({ title: 'Review & Approve', subtitle: 'Review and Approve a New Service or Activity submitted for your authorisation', icon: 'bx bx-plus-circle', to: '/dashboard' })
        break
      case 'thirdparty':
        tilesToRender.push({ title: 'Update Your Services', subtitle: 'Check and update the progress of your existing SOW/Project service already in operation', icon: 'bx bx-layer', to: '/existing' })
        tilesToRender.push({ title: 'Review & Approve', subtitle: 'Check and approve a New Service or Activity submitted for your review', icon: 'bx bx-plus-circle', to: '/dashboard' })
        break
    }

    tilesToRender.push({ title: 'Check Your Activity', subtitle: 'Check your messages, notifications and calendar events', icon: 'bx bx-pulse', to: '/activity-feed' })

    return tilesToRender.map((t, idx) => (
      <HomeTile key={idx} title={t.title} subtitle={t.subtitle} icon={t.icon} to={t.to} onClick={tileClicked} />
    ))
  }

  return (
    <>
      <Helmet>
        <title>{t('dashboard_title')} | {t('app_name')}</title>
      </Helmet>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm={{ size: 10, offset: 1 }}>
              <Row className='justify-content-center'>
                <Col sm='12' className='my-5'>
                  <PageTitle title='What would you like to do?' centeredText />
                </Col>
              </Row>
              <Row className='justify-content-center'>
                {renderTiles(view)}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default (Home)

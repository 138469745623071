import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import BooleanToggle from '../booleanToggle/booleanToggle'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ExtendedInput = styled.textarea`
  height: 100px;
  wrap: hard;
`

const strengthRange = [
  { id: 1, value: 'one' },
  { id: 2, value: 'two' },
  { id: 3, value: 'three' },
  { id: 4, value: 'four' },
  { id: 5, value: 'five' },
  { id: 6, value: 'six' },
  { id: 7, value: 'seven' },
  { id: 8, value: 'eight' },
  { id: 9, value: 'nine' },
  { id: 10, value: 'ten' }
]

const Competitor = () => {
  const [competitorKnown, setCompetitorKnown] = useState()
  const [competitor, setCompetitor] = useState('')
  const [incumbent, setImcumbent] = useState('')
  const [strength, setStrength] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState('')
  return (
    <>
      <StyledCard>
        <Row>
          <Col sm='4'>
            <div className='styledTitle'>
              Competitor Known:
            </div>
          </Col>
          <Col sm='8'>
            <BooleanToggle value={competitorKnown} onValueChanged={setCompetitorKnown} />
          </Col>
        </Row>
      </StyledCard>
      <StyledCard>
        <Row>
          <Col sm='4'>
            <div className='styledTitle'>
              Name:
            </div>
          </Col>
          <Col sm='8'>
            <input
              className='form-control'
              type='text'
              placeholder='Enter competitor'
              onChange={(event) => setCompetitor(event.target.value)}
              value={competitor}
            />
          </Col>
        </Row>
      </StyledCard>
      <StyledCard>
        <Row>
          <Col sm='4'>
            <div className='styledTitle'>
              Incumbent:
            </div>
          </Col>
          <Col sm='8'>
            <BooleanToggle value={incumbent} onValueChanged={setImcumbent} />
          </Col>
        </Row>
      </StyledCard>
      <StyledCard>
        <Row>
          <Col sm='4'>
            <div className='styledTitle'>
              Strength:
            </div>
          </Col>
          <Col sm='8'>
            <div className='btn-group btn-group-toggle' data-toggle='buttons'>
              {strengthRange.map((range, idx) => (
                <label
                  key={idx}
                  className={
                    strength === range.id
                      ? 'btn btn-primary active'
                      : 'btn btn-primary'
                  }
                >
                  <input
                    type='radio'
                    name='options'
                    id={range.id}
                    onClick={() => { setStrength(range.id) }}
                  />{' '}
                  {range.id}
                </label>
              ))}
            </div>
          </Col>
        </Row>
      </StyledCard>
      <StyledCard>
        <Row>
          <Col sm='4'>
            <div className='styledTitle'>
              Additional info:
            </div>
          </Col>
          <Col sm='8'>
            <ExtendedInput
              className='form-control'
              type='text'
              placeholder='Enter description'
              onChange={(event) => setAdditionalInfo(event.target.value)}
              value={additionalInfo}
            />
          </Col>
        </Row>
      </StyledCard>
    </>
  )
}

export default Competitor

import React from 'react'
import { Col, Row, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'

import styled from 'styled-components'

const StyledInput = styled(Input)`
  border-color: ${props => props.backgroundColor || 'white'};
  background-color: ${props => props.backgroundColor || 'white'};
  color: ${props => props.textColor || 'black'};
  text-align: center;
  border-left: 1px solid #ced4da;
`

const StyledRow = styled(Row)`
  margin-bottom: 0.75rem;
`

const TableEntry = ({ count = 0, title = '', backgroundColor = 'white', textColor = 'black' }) => {
  return (
    <InputGroup>
      <InputGroupAddon addonType='prepend'>
        <InputGroupText>{count}</InputGroupText>
      </InputGroupAddon>
      <StyledInput value={title} backgroundColor={backgroundColor} textColor={textColor} />
    </InputGroup>
  )
}

const RaidStatusTable = ({ title, cCount = 0, hCount = 0, mCount = 0, lCount = 0, nCount = 0, total = 0 }) => {
  return (
    <>
      <Row>
        <Col sm='12'>
          <div className='styledTitle'>
            {title}
          </div>
        </Col>
      </Row>
      <StyledRow>
        <Col sm='12'>
          <TableEntry count={cCount} title='Critical' backgroundColor='red' textColor='white' />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm='12'>
          <TableEntry count={hCount} title='High' backgroundColor='orange' textColor='black' />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm='12'>
          <TableEntry count={mCount} title='Moderate' backgroundColor='yellow' textColor='black' />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm='12'>
          <TableEntry count={lCount} title='Low' backgroundColor='lightblue' textColor='black' />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm='12'>
          <TableEntry count={nCount} title='Negligible' backgroundColor='lightgrey' textColor='black' />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col sm='12'>
          <Input style={{ textAlign: 'center' }} value={total} />
        </Col>
      </StyledRow>
    </>
  )
}

export default RaidStatusTable

import React, { useState } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import ClientInfo from '../../components/clientInfo/ClientInfo'
import OpportunityInfo from '../../components/opportunityInfo/OpportunityInfo'
import OpportunityStage from '../../components/opportunityStage/OpportunityStage'
import FinancialInfo from '../../components/financialInfo/FinancialInfo'
import Competition from '../../components/competition/Competition'
import PreSalesInformation from '../../components/preSalesInformation/PreSalesInformation'

const StyledButton = styled.button`
  float: right;
  width: auto;
  margin: 0 5px !important;
`

const SalesOpportunity = () => {
  const [customer] = useState('')
  const [stakeholder] = useState('')
  const [project] = useState('')
  const [stage, setStage] = useState('Project selection')

  const stageUpdated = (stage) => {
    setStage(stage)
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col sm='12'>
              <h1 style={{ display: 'inline-block' }} className='mb-3'>Sales Opportunity</h1>
            </Col>
          </Row>
          <Row>
            <Col xl='12'>
              <Card className='overflow-hidden'>
                <CardBody>
                  <Row>
                    <Col sm='7'>
                      <ClientInfo
                        customer={customer}
                        stakeholder={stakeholder}
                      />
                      <OpportunityInfo
                        project={project}
                        stageUpdated={stageUpdated}
                      />
                    </Col>
                    <Col sm='5'>
                      <OpportunityStage stage={stage} />
                      <FinancialInfo />
                      <Competition />
                      <PreSalesInformation />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <StyledButton className='btn btn-primary btn-block waves-effect waves-light' type='submit'>
                        Submit
                      </StyledButton>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withRouter(SalesOpportunity)

import {
  GET_RAIDCATEGORIES,
  ADD_RAIDCATEGORIES,
  CLEAR_RAIDCATEGORIES,
  GET_PRIORITIES,
  ADD_PRIORITIES,
  CLEAR_PRIORITIES,
  CLEAR_ORGANISATION_USERS,
  ADD_ORGANISATION_USERS,
  GET_ORGANISATION_USERS,
  GET_ENUMS,
  SET_ENUM
} from './actionTypes'

export const clearRaidCategories = () => ({
  type: CLEAR_RAIDCATEGORIES
})

export const getRaidCategories = () => ({
  type: GET_RAIDCATEGORIES
})

export const addRaidCategories = (raidCategories) => ({
  type: ADD_RAIDCATEGORIES,
  payload: { raidCategories }
})

export const clearPriorities = () => ({
  type: CLEAR_PRIORITIES
})

export const getPriorities = () => ({
  type: GET_PRIORITIES
})

export const addPriorities = (priorities) => ({
  type: ADD_PRIORITIES,
  payload: { priorities }
})

export const clearOrganisationUsers = () => ({
  type: CLEAR_ORGANISATION_USERS
})

export const getOrganisationUsers = () => ({
  type: GET_ORGANISATION_USERS
})

export const addOrganisationUsers = (organisationUsers) => ({
  type: ADD_ORGANISATION_USERS,
  payload: { organisationUsers }
})

export const getEnums = () => ({
  type: GET_ENUMS
})

export const setEnum = (name, value) => ({
  type: SET_ENUM,
  payload: { name, value }
})

import React from 'react'
import styled from 'styled-components'

import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'

const StyledList = styled.ul`
  padding-left: 30px !important;
`

const StyledButtons = styled.button`
  float: right;
  width: auto;
  margin: 0 5px !important;
`

const dummyMessages = [
  { text: 'Barclays needs 5 developers to build mobile app' },
  { text: 'Ted Baker ready to discuss finances' },
  { text: 'Lorem ipsum dolor sit amet consectetur' },
  { text: 'Ut enim ad minim veniam quis nostrud' },
  { text: 'Excepteur sint occaecat cupidatat non proident' },
  { text: 'Barclays needs 5 developers to build mobile app' },
  { text: 'Ted Baker ready to discuss finances' },
  { text: 'Lorem ipsum dolor sit amet consectetur' },
  { text: 'Ut enim ad minim veniam quis nostrud' },
  { text: 'Excepteur sint occaecat cupidatat non proident' }
]

const OpportunitiesSummary = () => {
  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle className='mb-2'>
            Top 10 Opportunities in Flight
          </CardTitle>
          <Row>
            <StyledList>
              {dummyMessages.map((messages, idx) => (
                <li key={idx}>
                  <Col sm='12'>
                    {messages.text}
                  </Col>
                </li>
              ))}
            </StyledList>
          </Row>
          <Row>
            <Col sm='12'>
              <StyledButtons className='btn btn-primary btn-block waves-effect waves-light' type='submit'>
                More
              </StyledButtons>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default OpportunitiesSummary

import React from 'react'
import styled from 'styled-components'

import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

import Notification from '../../components/notification/notification'

const StyledHeading = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  padding-bottom: 20px;
  margin: 0 2px;
`

const StyledEmptyText = styled.p`
  text-align: center;
`

const NotificationsHub = ({ notifications = [] }) => {
  return (
    <div className='page-content'>
      <Container fluid>
        <Row>
          <Col xl='12'>
            <Card className='overflow-hidden'>
              <CardBody>
                <Row>
                  <Col sm='12'>
                    <StyledHeading>
                      <CardTitle className='mb-2'>
                        Notifications Hub
                      </CardTitle>
                    </StyledHeading>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    {
                      notifications.length === 0 && (
                        <StyledEmptyText>
                          No Notifications currently received
                        </StyledEmptyText>
                      )
                    }
                    {
                      notifications.length > 0 && (
                        <>
                          {notifications.map((notifs, idx) => (
                            <Notification notification={notifs} key={idx} />
                          ))}
                        </>
                      )
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default (NotificationsHub)

import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import SowaasIconButton from '../sowaasIconButton/sowaasIconButton'

const OrganisationMenu = ({ organisations = [], currentOrganisation, organisationClicked, organisationSettingsClicked }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const { name = '' } = currentOrganisation

  return (
    <>
      <Dropdown isOpen={menuOpen} toggle={() => setMenuOpen(!menuOpen)} className='d-inline-block'>
        <DropdownToggle className='btn header-item waves-effect' id='page-header-user-dropdown' tag='button'>
          <span className='d-none d-xl-inline-block ml-2 mr-1'>{name}</span>
          <i className='mdi mdi-chevron-down d-none d-xl-inline-block' />
        </DropdownToggle>
        <DropdownMenu right>
          {
            organisations.map((o, idx) => (
              <div key={idx}>
                <DropdownItem tag='div' className='dropdown-item'>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ flex: 1 }} className='pt-1 pr-2' onClick={() => { organisationClicked(o) }}>{o.name}</span>
                    <SowaasIconButton icon='mdi mdi-cog-outline' className='ml-1' size='sm' onClick={() => { organisationSettingsClicked(o) }} />
                  </div>
                </DropdownItem>
                {
                  idx < (organisations.length - 1) && (
                    <div className='dropdown-divider' />
                  )
                }
              </div>
            ))
          }
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default OrganisationMenu

import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import styled from 'styled-components'
import _ from 'lodash'

import EngagementRow from './engagementRow'
import EngagementReportingFrequency from './engagementReportingFrequency'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import BooleanToggle from '../booleanToggle/booleanToggle'

const StyledCard = styled.div`
  padding: 5px 0;
`

const EngagementDesignForm = ({ project = {}, onEngagmentDesignSaved, onBackToSOWs, readOnly = false, embedded = false }) => {
  const { solution = {}, engagementRequired = true } = project
  const { statementOfWorks = [], engagementDesign = {} } = solution
  const {
    id,
    engagementDesignLevels,
    engagementDesignReportingLevels
  } = engagementDesign

  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const phases = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.phases || []))

  if (phases.length === 0) {
    return (
      <Row>
        <Col sm='12' className='mt-3'>
          You do not current have any customer SOWs created, go to the&nbsp;&nbsp;<SowaasButton style={{ display: 'inline-block' }} onClick={onBackToSOWs} title='Statements Of Work' />&nbsp;&nbsp;screen to add one.
        </Col>
      </Row>
    )
  }

  const [engagementRequiredValue, setEngagementRequiredValue] = useState(engagementRequired)

  useEffect(() => {
    setEngagementRequiredValue(engagementRequired)
  }, [engagementRequired])

  const handleSubmit = (e, errors, values) => {
    if (errors.length === 0) {
      values.engagementDesignLevels = Object.values(values.engagementDesignLevels)
      onEngagmentDesignSaved(values)
    }
  }

  const renderEngagementRow = (type, phase = undefined, index) => {
    const title = phase ? phase.name : 'All Phases'

    const phaseId = _.get(phase, 'id', null)
    const levelData = _.find(engagementDesignLevels, { type: type, phaseId: phaseId })

    return (
      <EngagementRow readOnly={readOnly} key={`${type}-${index}`} index={index} title={title} type={type} level={levelData || {}} />
    )
  }

  const renderEngagementRows = (phases, type) => {
    return (
      <>
        {
          phases.map((p, idx) => renderEngagementRow(type, p, idx + 1))
        }
      </>
    )
  }

  const renderReportingRow = (phase, index) => {
    const phaseId = _.get(phase, 'id', null)
    const reportingData = _.find(engagementDesignReportingLevels, { phaseId: phaseId })

    return (
      <EngagementReportingFrequency readOnly={readOnly} key={`rf-${phaseId}`} name={`engagementDesignReportingLevels[${index}]`} phase={phase} reporting={reportingData || {}} />
    )
  }

  const renderReportingRows = (phases, readOnly) => {
    return (
      <>
        {
          phases.map((p, idx) => renderReportingRow(p, idx))
        }
      </>
    )
  }

  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      <AvInput type='hidden' name='id' value={id} />
      <StyledCard>
        <Row className='mb-3'>
          <Col sm='3'>
            <div className='styledTitle'>
              Service Delivery Required
            </div>
          </Col>
          <Col sm='1'>
            <BooleanToggle disabled={readOnly} className='mb-0' name='engagementRequired' onValueChanged={v => { setEngagementRequiredValue(v) }} currentValue={engagementRequiredValue} />
          </Col>
        </Row>
        {
          engagementRequiredValue && (
            <>
              <Row>
                <Col sm='3'>
                  <div className='styledTitleBold'>
                    Service Management Activity
                    <InfoMarker id='imPPSDM' title='Service Management Activity' bodyCopy='If you have elected to phase the activity of the SOW Service, you have the option to define different Service Management activity per phase. If you choose to select No, the same level of Management will be applied to all phases.' />
                  </div>
                </Col>
              </Row>
              {renderEngagementRows(phases, 'Management')}
              <Row>
                <Col sm='3'>
                  <div className='styledTitleBold'>
                    Service Reporting Activity
                    <InfoMarker id='imPPSR' title='Service Reporting Activity' bodyCopy='If you have elected to phase the activity of the SOW Service, you have the option to define different reporting activity per phase. If you choose to select No, the same level & frequency of reporting will be applied to all phases.' />
                  </div>
                </Col>
              </Row>
              {renderEngagementRows(phases, 'Reporting')}
              <Row className='mb-3'>
                <Col sm='3'>
                  <div className='styledTitleBold'>
                    Reporting Frequency
                    <InfoMarker id='imReportingRequency' title='Reporting Frequency' bodyCopy='Define the level of service reporting you will provide (to Customer), or receive (from Supplier) for each phase as either Daily, Weekly, Bi-Weekly, Montly or Quarterly.' />
                  </div>
                </Col>
              </Row>
              {
                renderReportingRows(phases)
              }
            </>
          )
        }
        {
          embedded === false && (
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right' title={(readOnly ? 'Next' : 'Save')} type='submit' />
              </Col>
            </Row>
          )
        }
      </StyledCard>
    </AvForm>
  )
}

export default EngagementDesignForm

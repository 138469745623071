import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid'
import MessagingModal from './MessagingModal'
import { Link } from 'react-router-dom'
import { addMessage } from '../../store/message/actions'

const MessagingHubSidebar = ({ unreadCount = 0 }) => {
  const dispatch = useDispatch()
  const [modal, setmodal] = useState(false)
  const name = useSelector(state => state.User.name)

  const onSendMessage = (subject = '', to = '', message = '') => {
    const id = uuidv4()
    const newMessage = {
      id: id,
      Sender: 0,
      Bold: 1,
      EEID: id,
      Project: 'JH Test Company Ltd - Test Project JH',
      'Thread ID': id,
      Title: subject,
      Author: 'engage@aardent.com',
      Recipient: to,
      Message: message,
      Date: new Date().toISOString(),
      Username: 'engage',
      Name: name,
      Avatar: 'avatar2',
      Unread: true
    }

    dispatch(addMessage(newMessage))
    setmodal(false)
  }

  return (
    <>
      <Card className='email-leftbar'>
        <Button type='button' color='success' className='waves-effect waves-light' onClick={() => { setmodal(!modal) }} block>
          Compose
        </Button>
        <div className='mail-list mt-4'>
          <Link to='/messaging-hub' className='active'><i className='mdi mdi-email-outline mr-2' /> Inbox <span className='ml-1 float-right'>({unreadCount})</span></Link>
          <Link to='#'><i className='mdi mdi-trash-can-outline mr-2' />Trash</Link>
        </div>
      </Card>
      <MessagingModal setmodal={setmodal} modal={modal} onSend={onSendMessage} />
    </>
  )
}

export default MessagingHubSidebar

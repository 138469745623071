import React, { useState } from 'react'
import { AvField, AvInput } from 'availity-reactstrap-validation'
import MoneyInput from '../moneyInput/moneyInput'
import GroupToggle from '../groupToggle/groupToggle'

const rateTypeValues = [
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
  { label: 'Month', value: 'month' },
  { label: 'Other', value: 'other' }
]

const SupplierContactRow = ({ idx, s = {} }) => {
  const {
    id = '',
    firstName = '',
    lastName = '',
    rateTimeframe = '',
    rate = '',
    otherRateTimeframe = '',
    contactPhone = '',
    contactEmail = ''
  } = s

  const [rateTimeframeValue, setRateTimeframeValue] = useState(rateTimeframe)

  return (
    <tr>
      <td>
        {
          id !== '' && (
            <AvInput type='hidden' name={`contacts[${idx}].id`} value={id} />
          )
        }

        <AvField
          name={`contacts[${idx}].firstName`}
          value={firstName}
          errorMessage='Enter valid first name'
          validate={{ required: { value: true } }}
        />
      </td>
      <td>
        <AvField
          errorMessage='Enter valid last name'
          validate={{ required: { value: true } }}
          name={`contacts[${idx}].lastName`}
          value={lastName}
        />
      </td>
      <td>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '10rem', marginRight: '1rem' }}>
            <MoneyInput value={rate} name={`contacts[${idx}].rate`} onChange={() => {}} />
          </div>
          <GroupToggle name={`contacts[${idx}].rateTimeframe`} items={rateTypeValues} currentValue={rateTimeframeValue} groupClassName='mb-0' className='mb-0' onValueChanged={setRateTimeframeValue} />
        </div>
        {
          (rateTimeframeValue === 'other') && (
            <AvField
              placeholder='Enter other'
              name={`contacts[${idx}].otherRateTimeframe`}
              type='text'
              errorMessage='Enter valid rate'
              className='form-control mt-2'
              validate={{ required: { value: true } }}
              value={otherRateTimeframe}
            />
          )
        }
      </td>
      <td>
        <AvField
          errorMessage='Enter valid contact phone'
          validate={{ required: { value: true } }}
          name={`contacts[${idx}].contactPhone`}
          value={contactPhone}
        />
      </td>
      <td>
        <AvField
          errorMessage='Enter valid contact email'
          validate={{ required: { value: true } }}
          name={`contacts[${idx}].contactEmail`}
          value={contactEmail}
        />
      </td>
    </tr>
  )
}

export default SupplierContactRow

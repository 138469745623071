import {
  ADD_PROJECT,
  ADD_PROJECTS,
  UPDATE_PROJECT,
  CLEAR_PROJECTS,
  POST_PROJECT,
  PATCH_PROJECT,
  GET_PROJECTS,
  ADD_SOLUTION,
  UPDATE_SOLUTION,
  ADD_SOLUTION_SOW,
  UPDATE_SOLUTION_SOW,
  DELETE_SOLUTION_SOW,
  POST_SOLUTION_SOW,
  PUT_SOLUTION_SOW,
  DEL_SOLUTION_SOW,
  ADD_SOLUTION_FINANCIAL_DESIGN,
  PUT_SOLUTION_FINANCIAL_DESIGN,
  UPDATE_SOLUTION_SUPPLIERS,
  PUT_SOLUTION_SUPPLIERS,
  ADD_SOLUTION_LEGAL_DESIGN,
  UPDATE_SOLUTION_LEGAL_DESIGN,
  PUT_SOLUTION_LEGAL_DESIGN,
  ADD_SOLUTION_ENGAGEMENT_DESIGN,
  UPDATE_SOLUTION_ENGAGEMENT_DESIGN,
  PUT_SOLUTION_ENGAGEMENT_DESIGN,
  ADD_ENGAGEMENT,
  UPDATE_ENGAGEMENT,
  UPDATE_ENGAGEMENT_FINANCE,
  PATCH_ENGAGEMENT_FINANCE,
  ADD_CHANGE_REQUEST,
  ADD_CHANGE_REQUESTS,
  UPDATE_CHANGE_REQUEST,
  DELETE_CHANGE_REQUEST,
  POST_CHANGE_REQUEST,
  PUT_CHANGE_REQUEST,
  DEL_CHANGE_REQUEST,
  ADD_INVOICE,
  ADD_INVOICES,
  UPDATE_INVOICE,
  DELETE_INVOICE,
  POST_INVOICE,
  PUT_INVOICE,
  DEL_INVOICE,
  ADD_KMDB,
  ADD_KMDBS,
  UPDATE_KMDB,
  DELETE_KMDB,
  POST_KMDB,
  PUT_KMDB,
  DEL_KMDB,
  ADD_TU,
  ADD_TUS,
  UPDATE_TU,
  DELETE_TU,
  POST_TU,
  PUT_TU,
  DEL_TU,
  ADD_SERVICE_REPORT,
  ADD_SERVICE_REPORTS,
  UPDATE_SERVICE_REPORT,
  DELETE_SERVICE_REPORT,
  POST_SERVICE_REPORT,
  PUT_SERVICE_REPORT,
  DEL_SERVICE_REPORT,
  ADD_TASK,
  ADD_TASKS,
  UPDATE_TASK,
  DELETE_TASK,
  POST_TASK,
  PUT_TASK,
  DEL_TASK,
  GET_SOLUTION,
  PUT_SOLUTION,
  GET_ENGAGEMENT,
  PUT_ENGAGEMENT,
  ADD_TIME_UTILISATION,
  UPDATE_TIME_UTILISATION,
  ADD_TIME_UTILISATION_SHEET,
  UPDATE_TIME_UTILISATION_SHEET,
  ADD_INVOICE_ITEM,
  UPDATE_INVOICE_ITEM,
  ADD_CHANGE_REQUEST_CHANGE,
  UPDATE_CHANGE_REQUEST_CHANGE,
  ADD_RAID_RISK,
  UPDATE_RAID_RISK,
  ADD_RAID_ASSUMPTION,
  UPDATE_RAID_ASSUMPTION,
  ADD_RAID_ISSUE,
  UPDATE_RAID_ISSUE,
  ADD_RAID_DEPENDENCY,
  UPDATE_RAID_DEPENDENCY,
  PATCH_PROPOSAL_DESIGN,
  UPDATE_PROPOSAL_DESIGN,
  SYNC_FINANCIAL_DESIGN,
  SYNC_SOW_DESIGN,
  SYNC_LEGAL_DESIGN,
  SYNC_ENGAGEMENT_DESIGN,
  SYNC_SUPPLIERS_DESIGN,
  UPDATE_FINANCIAL_DESIGN,
  UPDATE_LEGAL_DESIGN,
  UPDATE_ENGAGEMENT_DESIGN,
  UPDATE_SUPPLIERS_DESIGN,
  GET_STATEMENT_OF_WORK,
  GET_ENGAGEMENT_DESIGN,
  GET_FINANCIAL_DESIGN,
  POST_RAID_RISK,
  POST_RAID_ASSUMPTION,
  PATCH_RAID_ASSUMPTION,
  PATCH_RAID_ISSUE,
  POST_RAID_DEPENDENCY,
  PATCH_RAID_DEPENDENCY,
  POST_RAID_ISSUE,
  PATCH_RAID_RISK,
  SYNC_INVOICE,
  SYNC_TIME_UTIL,
  SYNC_CHANGE_REQUEST,
  SYNC_KMDB,
  GET_CHANGE_REQUEST,
  GET_INVOICE,
  GET_SERVICE_REPORT,
  GET_TIME_UTILISATION,
  GET_KMDB,
  GET_LEGAL_DESIGN,
  APPROVE_FINANCE,
  APPROVE_LEGAL,
  APPROVE_MANAGEMENT,
  APPROVE_CHANGE_REQUEST,
  APPROVE_INVOICE
} from './actionTypes'

export const addProject = project => ({
  type: ADD_PROJECT,
  payload: { project }
})

export const addProjects = projects => ({
  type: ADD_PROJECTS,
  payload: { projects }
})

export const clearProjects = () => ({
  type: CLEAR_PROJECTS
})

export const patchProject = project => ({
  type: PATCH_PROJECT,
  payload: { project }
})

export const postProject = project => ({
  type: POST_PROJECT,
  payload: { project }
})

export const getProjects = () => ({
  type: GET_PROJECTS
})

export const getSolution = solution => ({
  type: GET_SOLUTION,
  payload: { solution }
})

export const addSolution = (project, solution) => ({
  type: ADD_SOLUTION,
  payload: { project, solution }
})

export const updateSolution = solution => ({
  type: UPDATE_SOLUTION,
  payload: { solution }
})

export const putSolution = solution => ({
  type: PUT_SOLUTION,
  payload: { solution }
})

export const addSolutionSow = (solution, sow) => ({
  type: ADD_SOLUTION_SOW,
  payload: { solution, sow }
})

export const updateSolutionSow = (project, sow) => ({
  type: UPDATE_SOLUTION_SOW,
  payload: { project, sow }
})

export const deleteSolutionSow = (solution, sow) => ({
  type: DELETE_SOLUTION_SOW,
  payload: { solution, sow }
})

export const postSolutionSow = (solution, solutionSow) => ({
  type: POST_SOLUTION_SOW,
  payload: { solution, solutionSow }
})

export const putSolutionSow = solutionSow => ({
  type: PUT_SOLUTION_SOW,
  payload: { solutionSow }
})

export const delSolutionSow = solutionSow => ({
  type: DEL_SOLUTION_SOW,
  payload: { solutionSow }
})

export const addSolutionFinancialDesign = (solution, fd) => ({
  type: ADD_SOLUTION_FINANCIAL_DESIGN,
  payload: { solution, fd }
})

export const updateSolutionFinancialDesign = (project, financialDesign) => ({
  type: UPDATE_FINANCIAL_DESIGN,
  payload: { project, financialDesign }
})

export const putSolutionFinancialDesign = fd => ({
  type: PUT_SOLUTION_FINANCIAL_DESIGN,
  payload: { fd }
})

export const updateSolutionSuppliers = (solution, solutionSuppliers) => ({
  type: UPDATE_SOLUTION_SUPPLIERS,
  payload: { solution, solutionSuppliers }
})

export const putSolutionSuppliers = (solution, solutionSuppliers) => ({
  type: PUT_SOLUTION_SUPPLIERS,
  payload: { solution, solutionSuppliers }
})

export const addSolutionLegalDesign = (solution, legalDesign) => ({
  type: ADD_SOLUTION_LEGAL_DESIGN,
  payload: { solution, legalDesign }
})

export const updateSolutionLegalDesign = (solution, legalDesign) => ({
  type: UPDATE_SOLUTION_LEGAL_DESIGN,
  payload: { solution, legalDesign }
})

export const putSolutionLegalDesign = legalDesign => ({
  type: PUT_SOLUTION_LEGAL_DESIGN,
  payload: { legalDesign }
})

export const addSolutionEngagementDesign = (solution, engagementDesign) => ({
  type: ADD_SOLUTION_ENGAGEMENT_DESIGN,
  payload: { solution, engagementDesign }
})

export const updateSolutionEngagementDesign = (project, engagementDesign) => ({
  type: UPDATE_SOLUTION_ENGAGEMENT_DESIGN,
  payload: { project, engagementDesign }
})

export const putSolutionEngagementDesign = engagementDesign => ({
  type: PUT_SOLUTION_ENGAGEMENT_DESIGN,
  payload: { engagementDesign }
})

export const getEngagement = engagement => ({
  type: GET_ENGAGEMENT,
  payload: { engagement }
})

export const putEngagment = engagement => ({
  type: PUT_ENGAGEMENT,
  payload: { engagement }
})

export const addEngagement = (project, engagement) => ({
  type: ADD_ENGAGEMENT,
  payload: { project, engagement }
})

export const updateEngagement = engagement => ({
  type: UPDATE_ENGAGEMENT,
  payload: { engagement }
})

export const updateEngagementFinance = engagementFinance => ({
  type: UPDATE_ENGAGEMENT_FINANCE,
  payload: { engagementFinance }
})

export const patchEngagmentFinance = engagementFinance => ({
  type: PATCH_ENGAGEMENT_FINANCE,
  payload: { engagementFinance }
})

export const addChangeRequest = (engagement, changeRequest) => ({
  type: ADD_CHANGE_REQUEST,
  payload: { engagement, changeRequest }
})

export const addChangeRequests = (engagement, changeRequests) => ({
  type: ADD_CHANGE_REQUESTS,
  payload: { engagement, changeRequests }
})

export const updateChangeRequest = (project, changeRequest) => ({
  type: UPDATE_CHANGE_REQUEST,
  payload: { project, changeRequest }
})

export const postChangeRequest = (engagement, changeRequest) => ({
  type: POST_CHANGE_REQUEST,
  payload: { engagement, changeRequest }
})

export const putChangeRequest = changeRequest => ({
  type: PUT_CHANGE_REQUEST,
  payload: { changeRequest }
})

export const delChangeRequest = changeRequest => ({
  type: DEL_CHANGE_REQUEST,
  payload: { changeRequest }
})

export const deleteChangeRequest = changeRequest => ({
  type: DELETE_CHANGE_REQUEST,
  payload: { changeRequest }
})

export const addInvoice = (engagement, invoice) => ({
  type: ADD_INVOICE,
  payload: { engagement, invoice }
})

export const addInvoices = invoices => ({
  type: ADD_INVOICES,
  payload: { invoices }
})

export const updateInvoice = (project, invoice) => ({
  type: UPDATE_INVOICE,
  payload: { project, invoice }
})

export const deleteInvoice = invoice => ({
  type: DELETE_INVOICE,
  payload: { invoice }
})

export const postInvoice = (engagement, invoice) => ({
  type: POST_INVOICE,
  payload: { engagement, invoice }
})

export const putInvoice = invoice => ({
  type: PUT_INVOICE,
  payload: { invoice }
})

export const delInvoice = invoice => ({
  type: DEL_INVOICE,
  payload: { invoice }
})

export const addKmdb = (engagement, kmdb) => ({
  type: ADD_KMDB,
  payload: { engagement, kmdb }
})

export const addKmdbs = kmdbs => ({
  type: ADD_KMDBS,
  payload: { kmdbs }
})

export const updateKmdb = (project, kmdb) => ({
  type: UPDATE_KMDB,
  payload: { project, kmdb }
})

export const deleteKmdb = kmdb => ({
  type: DELETE_KMDB,
  payload: { kmdb }
})

export const postKmdb = (engagement, kmdb) => ({
  type: POST_KMDB,
  payload: { engagement, kmdb }
})

export const putKmdb = kmdb => ({
  type: PUT_KMDB,
  payload: { kmdb }
})

export const delKmdb = kmdb => ({
  type: DEL_KMDB,
  payload: { kmdb }
})

export const addTu = (engagement, tu) => ({
  type: ADD_TU,
  payload: { engagement, tu }
})

export const addTus = tus => ({
  type: ADD_TUS,
  payload: { tus }
})

export const updateTu = tu => ({
  type: UPDATE_TU,
  payload: { tu }
})

export const deleteTu = tu => ({
  type: DELETE_TU,
  payload: { tu }
})

export const postTu = (engagement, tu) => ({
  type: POST_TU,
  payload: { engagement, tu }
})

export const putTu = tu => ({
  type: PUT_TU,
  payload: { tu }
})

export const delTu = tu => ({
  type: DEL_TU,
  payload: { tu }
})

export const addServiceReport = (project, serviceReport) => ({
  type: ADD_SERVICE_REPORT,
  payload: { project, serviceReport }
})

export const addServiceReports = (engagement, serviceReports) => ({
  type: ADD_SERVICE_REPORTS,
  payload: { engagement, serviceReports }
})

export const updateServiceReport = (project, serviceReport) => ({
  type: UPDATE_SERVICE_REPORT,
  payload: { project, serviceReport }
})

export const deleteServiceReport = serviceReport => ({
  type: DELETE_SERVICE_REPORT,
  payload: { serviceReport }
})

export const postServiceReport = (project, serviceReport) => ({
  type: POST_SERVICE_REPORT,
  payload: { project, serviceReport }
})

export const putServiceReport = serviceReport => ({
  type: PUT_SERVICE_REPORT,
  payload: { serviceReport }
})

export const delServiceReport = serviceReport => ({
  type: DEL_SERVICE_REPORT,
  payload: { serviceReport }
})

export const addTask = (engagement, task) => ({
  type: ADD_TASK,
  payload: { engagement, task }
})

export const addTasks = (engagement, tasks) => ({
  type: ADD_TASKS,
  payload: { engagement, tasks }
})

export const updateTask = task => ({
  type: UPDATE_TASK,
  payload: { task }
})

export const deleteTask = task => ({
  type: DELETE_TASK,
  payload: { task }
})

export const postTask = (engagement, task) => ({
  type: POST_TASK,
  payload: { engagement, task }
})

export const putTask = task => ({
  type: PUT_TASK,
  payload: { task }
})

export const delTask = task => ({
  type: DEL_TASK,
  payload: { task }
})

export const updateProject = project => {
  return {
    type: UPDATE_PROJECT,
    payload: { project }
  }
}

export const addTimeUtilisation = (engagement, timeUtilisation) => {
  return {
    type: ADD_TIME_UTILISATION,
    payload: { engagement, timeUtilisation }
  }
}

export const updateTimeUtilisation = (project, timeUtilisation) => {
  return {
    type: UPDATE_TIME_UTILISATION,
    payload: { project, timeUtilisation }
  }
}

export const addTimeUtilisationSheet = (timeUtilisation, timeUtilisationSheet) => {
  return {
    type: ADD_TIME_UTILISATION_SHEET,
    payload: { timeUtilisation, timeUtilisationSheet }
  }
}

export const updateTimeUtilisationSheet = timeUtilisationSheet => {
  return {
    type: UPDATE_TIME_UTILISATION_SHEET,
    payload: { timeUtilisationSheet }
  }
}

export const addInvoiceItem = (invoice, invoiceItem) => {
  return {
    type: ADD_INVOICE_ITEM,
    payload: { invoice, invoiceItem }
  }
}

export const updateInvoiceItem = (invoiceItem) => {
  return {
    type: UPDATE_INVOICE_ITEM,
    payload: { invoiceItem }
  }
}

export const addChangeRequestChange = (changeRequest, changeRequestChange) => {
  return {
    type: ADD_CHANGE_REQUEST_CHANGE,
    payload: { changeRequest, changeRequestChange }
  }
}

export const updateChangeRequestChange = changeRequestChange => {
  return {
    type: UPDATE_CHANGE_REQUEST_CHANGE,
    payload: { changeRequestChange }
  }
}

export const addRaidRisk = (project, raidRisk) => {
  return {
    type: ADD_RAID_RISK,
    payload: { project, raidRisk }
  }
}

export const updateRaidRisk = (project, raidRisk) => {
  return {
    type: UPDATE_RAID_RISK,
    payload: { project, raidRisk }
  }
}

export const addRaidAssumption = (project, raidAssumption) => {
  return {
    type: ADD_RAID_ASSUMPTION,
    payload: { project, raidAssumption }
  }
}

export const updateRaidAssumption = (project, raidAssumption) => {
  return {
    type: UPDATE_RAID_ASSUMPTION,
    payload: { project, raidAssumption }
  }
}

export const addRaidIssue = (project, raidIssue) => {
  return {
    type: ADD_RAID_ISSUE,
    payload: { project, raidIssue }
  }
}

export const updateRaidIssue = (project, raidIssue) => {
  return {
    type: UPDATE_RAID_ISSUE,
    payload: { project, raidIssue }
  }
}

export const addRaidDependency = (project, raidDependency) => {
  return {
    type: ADD_RAID_DEPENDENCY,
    payload: { project, raidDependency }
  }
}

export const updateRaidDependency = (project, raidDependency) => {
  return {
    type: UPDATE_RAID_DEPENDENCY,
    payload: { project, raidDependency }
  }
}

export const patchProposalDesign = (project, proposalDesign) => {
  return {
    type: PATCH_PROPOSAL_DESIGN,
    payload: { project, proposalDesign }
  }
}

export const updateProposalDesign = (project, proposalDesign) => {
  return {
    type: UPDATE_PROPOSAL_DESIGN,
    payload: { project, proposalDesign }
  }
}

export const syncFinancialDesign = (project, financialDesign) => {
  return {
    type: SYNC_FINANCIAL_DESIGN,
    payload: { project, financialDesign }
  }
}

export const updateFinancialDesign = (project, financialDesign) => {
  return {
    type: UPDATE_FINANCIAL_DESIGN,
    payload: { project, financialDesign }
  }
}

export const syncSowDesign = (project, sowDesign) => {
  return {
    type: SYNC_SOW_DESIGN,
    payload: { project, sowDesign }
  }
}

export const syncLegalDesign = (project, legalDesign) => {
  return {
    type: SYNC_LEGAL_DESIGN,
    payload: { project, legalDesign }
  }
}

export const updateLegalDesign = (project, legalDesign) => {
  return {
    type: UPDATE_LEGAL_DESIGN,
    payload: { project, legalDesign }
  }
}

export const syncEngagementDesign = (project, engagementDesign) => {
  return {
    type: SYNC_ENGAGEMENT_DESIGN,
    payload: { project, engagementDesign }
  }
}

export const updateEngagementDesign = (project, engagementDesign) => {
  return {
    type: UPDATE_ENGAGEMENT_DESIGN,
    payload: { project, engagementDesign }
  }
}

export const syncSuppliersDesign = (project, suppliersDesign) => {
  return {
    type: SYNC_SUPPLIERS_DESIGN,
    payload: { project, suppliersDesign }
  }
}

export const updateSuppliersDesign = (project, suppliersDesign) => {
  return {
    type: UPDATE_SUPPLIERS_DESIGN,
    payload: { project, suppliersDesign }
  }
}

export const getStatementOfWork = (project, sowId) => {
  return {
    type: GET_STATEMENT_OF_WORK,
    payload: { project, sowId }
  }
}

export const getEngagementDesign = (project, engagementDesignId) => {
  return {
    type: GET_ENGAGEMENT_DESIGN,
    payload: { project, engagementDesignId }
  }
}

export const getFinancialDesign = (project, financialDesignId) => {
  return {
    type: GET_FINANCIAL_DESIGN,
    payload: { project, financialDesignId }
  }
}

export const getLegalDesign = (project, legalDesignId) => {
  return {
    type: GET_LEGAL_DESIGN,
    payload: { project, legalDesignId }
  }
}

export const postRaidRisk = (project, raidRisk) => {
  return {
    type: POST_RAID_RISK,
    payload: { project, raidRisk }
  }
}

export const patchRaidRisk = (project, raidRisk) => {
  return {
    type: PATCH_RAID_RISK,
    payload: { project, raidRisk }
  }
}

export const postRaidAssumption = (project, raidAssumption) => {
  return {
    type: POST_RAID_ASSUMPTION,
    payload: { project, raidAssumption }
  }
}

export const patchRaidAssumption = (project, raidAssumption) => {
  return {
    type: PATCH_RAID_ASSUMPTION,
    payload: { project, raidAssumption }
  }
}

export const postRaidIssue = (project, raidIssue) => {
  return {
    type: POST_RAID_ISSUE,
    payload: { project, raidIssue }
  }
}

export const patchRaidIssue = (project, raidIssue) => {
  return {
    type: PATCH_RAID_ISSUE,
    payload: { project, raidIssue }
  }
}

export const postRaidDependency = (project, raidDependency) => {
  return {
    type: POST_RAID_DEPENDENCY,
    payload: { project, raidDependency }
  }
}

export const patchRaidDependency = (project, raidDependency) => {
  return {
    type: PATCH_RAID_DEPENDENCY,
    payload: { project, raidDependency }
  }
}

export const syncInvoice = (project, invoice) => {
  return {
    type: SYNC_INVOICE,
    payload: { project, invoice }
  }
}

export const syncTimeUtil = (project, timeUtil) => {
  return {
    type: SYNC_TIME_UTIL,
    payload: { project, timeUtil }
  }
}

export const syncChangeRequest = (project, changeRequest) => {
  return {
    type: SYNC_CHANGE_REQUEST,
    payload: { project, changeRequest }
  }
}

export const syncKmdb = (project, kmdb) => {
  return {
    type: SYNC_KMDB,
    payload: { project, kmdb }
  }
}

export const getChangeRequest = (project, changeRequestId) => {
  return {
    type: GET_CHANGE_REQUEST,
    payload: { project, changeRequestId }
  }
}

export const getInvoice = (project, invoiceId) => {
  return {
    type: GET_INVOICE,
    payload: { project, invoiceId }
  }
}

export const getKmdb = (project, kmdbId) => {
  return {
    type: GET_KMDB,
    payload: { project, kmdbId }
  }
}

export const getServiceReport = (project, serviceReportId) => {
  return {
    type: GET_SERVICE_REPORT,
    payload: { project, serviceReportId }
  }
}

export const getTimeUtilisation = (project, timeUtilisationId) => {
  return {
    type: GET_TIME_UTILISATION,
    payload: { project, timeUtilisationId }
  }
}

export const approveFinance = (project, comments) => {
  return {
    type: APPROVE_FINANCE,
    payload: { project, comments }
  }
}

export const approveLegal = (project, comments) => {
  return {
    type: APPROVE_LEGAL,
    payload: { project, comments }
  }
}

export const approveManagement = (project, comments) => {
  return {
    type: APPROVE_MANAGEMENT,
    payload: { project, comments }
  }
}

export const approveChangeRequest = (changeRequest) => {
  return {
    type: APPROVE_CHANGE_REQUEST,
    payload: { changeRequest }
  }
}

export const approveInvoice = (invoice) => {
  return {
    type: APPROVE_INVOICE,
    payload: { invoice }
  }
}

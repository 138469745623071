import { takeEvery, fork, put, all } from 'redux-saga/effects'
import errorService from '../../../helpers/errorService'

// Login Redux States
import { EDIT_PROFILE } from './actionTypes'
import { profileError } from './actions'

function * editProfile () { // ({ payload: { user } }) {
  try {
    /* if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtProfile, '/post-jwt-profile', { username: user.username, idx: user.idx })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      const response = yield call(postFakeProfile, '/post-fake-profile', { username: user.username, idx: user.idx })
      yield put(profileSuccess(response))
    } */
  } catch (error) {
    errorService.logError(error)
    yield put(profileError(error))
  }
}
export function * watchProfile () {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function * ProfileSaga () {
  yield all([
    fork(watchProfile)
  ])
}

export default ProfileSaga

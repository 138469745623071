import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Card, CardBody, CardTitle, Table } from 'reactstrap'
import smallDateFormatter from '../../helpers/smallDateFormatter'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButtonInteraction from '../sowaasButtonInteraction/sowaasButtonInteraction'
import _ from 'lodash'
import NotificationModal from '../notificationModal/notificationModal'
import TimeUtilModal from '../timeUtilModal/timeUtilModal'
import ChangeRequestModal from '../changeRequestModal/changeRequestModal'
import InvoiceModal from '../invoiceModal/invoiceModal'
import projects from '../../helpers/demoMode/projects.json'
import { useDispatch } from 'react-redux'
import { markNotificationAsRead } from '../../store/notification/actions'
import { approveChangeRequest, approveInvoice } from '../../store/project/actions'
import VideoMarker from '../videoMarker/videoMarker'

const StyledHeading = styled.div`
  padding-bottom: 20px;
  margin: 0 2px;
  display: flex;
  justify-content: space-between;
`

const Notifications = ({ notifications, role = '', hideView = false }) => {
  const dispatch = useDispatch()

  const [notificationModalOpen, setNotificationModalOpen] = useState(false)
  const filteredNotifications = _.chain(notifications).filter({ role }).value()

  const [tuModalOpen, setTuModalOpen] = useState(false)
  const [crModalOpen, setCrModalOpen] = useState(false)
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)
  const [viewInvoiceModalOpen, setViewInvoiceModalOpen] = useState(false)

  const GoButton = () => {
    return (
      <>
        <SowaasButtonInteraction className='float-right' title='View All' icon='mdi mdi-arrow-right' to='/activity-feed' />
      </>
    )
  }

  const showNotification = (notification) => {
    dispatch(markNotificationAsRead(notification))
    setNotificationModalOpen(notification)
  }

  const onClickCTA = (notification) => {
    setNotificationModalOpen(false)

    switch (notification.notificationType) {
      case 'StatusUpdateTU':
        setTuModalOpen(true)
        break
      case 'ReviewTU':
        setTuModalOpen(true)
        break
      case 'ReviewCR':
        setCrModalOpen(true)
        break
      case 'ReviewSOWService':
        break
      case 'StatusUpdateInvoice':
        setViewInvoiceModalOpen(true)
        break
      case 'ReviewInvoice':
        setInvoiceModalOpen(true)
        break
    }
  }

  const onApproveTu = () => {

  }

  const onApproveCr = (changeRequest) => {
    dispatch(approveChangeRequest(changeRequest))
  }

  const onApproveInvoice = (invoice) => {
    dispatch(approveInvoice(invoice))
  }

  return (
    <>
      <Card className='overflow-hidden' style={{ height: '100%' }}>
        <div className='aquaVerticalGradient' style={{ height: '8px' }} />
        <CardBody className='flex-card'>
          <Row>
            <Col sm='7'>
              <StyledHeading>
                <CardTitle className='mb-2'>
                  Notifications
                  <InfoMarker title='Notifications' bodyCopy='To display latest information' name='imNotifications' />
                  <VideoMarker id='vmNotifications' title='Notifications' videoUrl='https://vimeo.com/500148795/bd2569eb2b' />
                </CardTitle>
              </StyledHeading>
            </Col>
            <Col sm='5'>
              {
                (hideView === false) && (
                  <GoButton />
                )
              }
            </Col>
          </Row>
          {
            filteredNotifications.length === 0 && (
              <div className='center-aligned-box'>
                <h6>No notifications found</h6>
              </div>
            )
          }
          {
            filteredNotifications.length > 0 && (
              <>
                <Table hover style={{ flex: 1 }}>
                  <tbody>
                    {filteredNotifications.map((n, idx) => (
                      <tr key={idx} onClick={() => { showNotification(n) }}>
                        <td style={{ width: '2rem' }}>
                          {(n.read === true) ? null : <span className='fas fa-circle text-info' />}
                        </td>
                        <td>{n.subject}</td>
                        <td style={{ width: '5rem' }}>{smallDateFormatter(n.sentAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Row sm='12'>
                  <Col sm='6' className='pt-2'>
                    {filteredNotifications.length} To Be Actioned
                  </Col>
                </Row>
              </>
            )
          }
        </CardBody>
      </Card>
      {
        (notificationModalOpen !== false) && (
          <NotificationModal isOpen setOpen={setNotificationModalOpen} notification={notificationModalOpen} onClickCTA={onClickCTA} />
        )
      }
      {
        (tuModalOpen !== false) && (
          <TimeUtilModal
            isOpen
            setOpen={setTuModalOpen}
            project={projects[0]}
            tu={projects[0].engagement.timeUtilisations[0]}
            mode='view'
            onSave={onApproveTu}
          />
        )
      }
      {
        (crModalOpen !== false) && (
          <ChangeRequestModal
            isOpen
            setOpen={setCrModalOpen}
            project={projects[0]}
            changeRequest={projects[0].engagement.changeRequests[0]}
            mode='approve'
            onSave={onApproveCr}
          />
        )
      }
      {
        (viewInvoiceModalOpen !== false) && (
          <InvoiceModal
            isOpen
            setOpen={setViewInvoiceModalOpen}
            project={projects[0]}
            invoice={projects[0].engagement.invoices[0]}
            mode='view'
            onSave={() => {}}
          />
        )
      }
      {
        (invoiceModalOpen !== false) && (
          <InvoiceModal
            isOpen
            setOpen={setInvoiceModalOpen}
            project={projects[0]}
            invoice={projects[0].engagement.invoices[0]}
            mode='approve'
            onSave={onApproveInvoice}
          />
        )
      }
    </>
  )
}

export default Notifications

import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'

import Notifications from '../../components/notifications/Notifications'
import Messaging from '../../components/messaging/Messaging'
import Calendar from '../../components/calendar/Calendar'
import SolutionsSummary from '../../components/solutionsSummary/SolutionsSummary'

import LegalFinanceProjectReview from '../../components/legalFinanceProjectReview/legalFinanceProjectReview'
import PageTitle from '../../components/pageTitle/pageTitle'

const LegalFinanceDashbaord = ({ messaging, notifications, history }) => {
  const projects = useSelector((state) => state.Project.projects)
  const { StatementOfWorkType } = useSelector(state => state.Lookup.enums)
  const suppliers = useSelector((state) => state.Supplier.suppliers)
  const { role } = useSelector((state) => state.User)

  const solutionClicked = (solution) => {
    history.push(`/new/${solution.id}`)
  }

  const engagementClicked = (solution) => {
    history.push(`/engagement/${solution.id}`)
  }

  return (
    <>
      <Row className='mb-3'>
        <Col sm='8'>
          <PageTitle title='Dashboard' icon='bxs-dashboard' />
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col sm='4'>
          <Messaging messages={messaging} role={role} />
        </Col>
        <Col sm='4'>
          <Calendar />
        </Col>
        <Col sm='4'>
          <Notifications notifications={notifications} role={role} />
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <LegalFinanceProjectReview projects={projects} statementOfWorkTypes={StatementOfWorkType} suppliers={suppliers} />
        </Col>
      </Row>
      <Row>
        <Col sm='12'>
          <SolutionsSummary solutionsSummary={projects} solutionClicked={solutionClicked} engagementClicked={engagementClicked} />
        </Col>
      </Row>
    </>
  )
}

export default LegalFinanceDashbaord

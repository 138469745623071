import React from 'react'
import { Col, Row, Card, CardBody, CardTitle, Table } from 'reactstrap'
import moment from 'moment'
import InfoMarker from '../infoMarker/infoMarker'

const MilestonesCard = ({ milestones }) => {
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody className='pb-3'>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Milestones
                <InfoMarker title='Milestones' bodyCopy='Displayed here will be a list of the Milestones as defined in the SOW for quick and easy reference' />
              </CardTitle>
            </Col>
          </Row>
          <Row sm='12'>
            <Table hover className='mb-0'>
              <tbody>
                {
                  milestones.map((m, idx) => (
                    <tr key={idx}>
                      <td>{m.name}</td>
                      <td style={{ width: '40%', textAlign: 'right' }}>{moment(m.start).format('DD/MM/YY')} - {moment(m.end).format('DD/MM/YY')}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default MilestonesCard

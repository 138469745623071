import {
  ADD_CUSTOMER,
  ADD_CUSTOMERS,
  CLEAR_CUSTOMERS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMERS,
  POST_CUSTOMER,
  PUT_CUSTOMER,
  DEL_CUSTOMER
} from './actionTypes'

export const addCustomer = customer => ({
  type: ADD_CUSTOMER,
  payload: { customer }
})

export const addCustomers = customers => ({
  type: ADD_CUSTOMERS,
  payload: { customers }
})

export const clearCustomers = () => ({
  type: CLEAR_CUSTOMERS,
  payload: {}
})

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: { customer }
})

export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: { customer }
})

export const getCustomers = () => ({
  type: GET_CUSTOMERS
})

export const postCustomer = customer => ({
  type: POST_CUSTOMER,
  payload: { customer }
})

export const putCustomer = customer => ({
  type: PUT_CUSTOMER,
  payload: { customer }
})

export const delCustomer = customer => ({
  type: DEL_CUSTOMER,
  payload: { customer }
})

import React from 'react'
import SidebarContent from './SidebarContent'

const Sidebar = () => {
  return (
    <>
      <div className='vertical-menu' style={{ backgroundColor: '#14266e' }}>
        <div data-simplebar className='h-100'>
          <SidebarContent />
          <div style={{ position: 'fixed', bottom: '10px', left: '10px', color: 'white' }}>
            v1.0.5 {(process.env.REACT_APP_DEMO_MODE === 'true' ? '- DEMO' : '')}
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar

import React from 'react'
import { AvField, AvInput } from 'availity-reactstrap-validation'
import RateAndMetricInput from './rateAndMetricInput'
import SowaasButton from '../sowaasButton/sowaasButton'

const FinancialCostRow = ({ fd, idx, phase, readOnly, suppliers, removeRow }) => {
  const {
    id = '',
    cost = '',
    costQuantity = '',
    costFrequency = '-1',
    costFrequencyOther = '',
    charge = '',
    chargeQuantity = '',
    chargeFrequency = '-1',
    chargeFrequencyOther,
    supplierId = '-1'
  } = fd

  return (
    <tr key={idx}>
      <td style={{ width: '13rem' }}>
        {
          (id !== '') && (
            <AvInput type='hidden' name={`costs.[${phase.id}-${idx}].id`} value={id} />
          )
        }
        <AvInput type='hidden' name={`costs.[${phase.id}-${idx}].phaseId`} value={phase.id} />
        <AvField disabled={readOnly} type='select' name={`costs.[${phase.id}-${idx}].supplierId`} value={supplierId} required>
          <option disabled value='-1'>Select Supplier</option>
          {
            suppliers.map((supplier, idx) => (
              <option key={idx} value={supplier.id}>{supplier.name}</option>
            ))
          }
        </AvField>
      </td>
      <RateAndMetricInput
        name={`costs.[${phase.id}-${idx}].cost`}
        value={cost}
        quatity={costQuantity}
        frequency={costFrequency}
        frequencyOther={costFrequencyOther}
        readOnly={readOnly}
      />
      <RateAndMetricInput
        name={`costs.[${phase.id}-${idx}].charge`}
        value={charge}
        quatity={chargeQuantity}
        frequency={chargeFrequency}
        frequencyOther={chargeFrequencyOther}
        readOnly={readOnly}
      />
      {
        (readOnly === false) && (
          <td style={{ width: '5.5rem' }}>
            <SowaasButton disabled={readOnly} title='REMOVE' onClick={() => { removeRow(idx) }} />
          </td>
        )
      }
    </tr>
  )
}

export default FinancialCostRow

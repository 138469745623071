import React, { useState } from 'react'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import errorService from '../../helpers/errorService'

const MoneyInput = ({ onChange = () => {}, value = '', name, required = true, disabled = false }) => {
  const formatNumber = (number) => {
    return Number(number.replace(/[^0-9.]/g, ''))
  }

  const formatCurrency = (number) => {
    try {
      const currNumber = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 }).format(number)
      return currNumber
    } catch (ex) {
      errorService.logError(ex)
    }
  }

  const incomingValue = Number(value)
  const [currencyValue, setCurrencyValue] = useState(formatCurrency(incomingValue))
  const [numberValue, setNumberValue] = useState(incomingValue)

  React.useEffect(() => {
    const incomingValue = Number(value)
    setCurrencyValue(formatCurrency(incomingValue))
    setNumberValue(incomingValue)
  }, [value])

  const onChangeInternal = (event) => {
    const newValue = event.target.value
    const trailingPoint = (newValue.substring(newValue.length - 1) === '.')

    const numberValue = formatNumber(event.target.value)
    setNumberValue(numberValue)

    const currencyValue = formatCurrency(numberValue) + (trailingPoint ? '.' : '')
    setCurrencyValue(currencyValue)

    onChange(numberValue)
  }

  return (
    <>
      <AvGroup>
        <InputGroup>
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>£</InputGroupText>
          </InputGroupAddon>
          <Input
            type='text'
            value={currencyValue}
            onChange={onChangeInternal}
            disabled={disabled}
          />
          <AvInput
            name={name}
            type='text'
            required={required}
            onChange={onChangeInternal}
            value={(!isNaN(numberValue) ? numberValue : '')}
            style={{ display: 'none' }}
          />
          <AvFeedback>This field is required.</AvFeedback>
        </InputGroup>
      </AvGroup>
    </>
  )
}

export default MoneyInput

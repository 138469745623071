import React, { useState } from 'react'
import { Table, Row, Col } from 'reactstrap'
import KmdbItemModal from '../kmdbItemModal/kmdbItemModal'
import SowaasButton from '../sowaasButton/sowaasButton'
import moment from 'moment'

const KmdbLog = ({ kmdbItems = [], project = {}, onSave }) => {
  const [kmdbItemModalOpen, setKmdbItemModalOpen] = useState(false)

  const renderTable = () => {
    return (
      <>
        <Table className='mt-3'>
          <thead>
            <tr>
              <th style={{ width: '8rem' }}>
                ID
              </th>
              <th>
                Provider
              </th>
              <th>
                Created
              </th>
              <th style={{ width: '5rem' }} />
            </tr>
          </thead>
          <tbody>
            {
              kmdbItems.map((i, idx) => (
                <tr key={i.id}>
                  <td>{i.slug}</td>
                  <td>{i.provider}</td>
                  <td>{moment(i.createdAt).format('DD/MM/YY')}</td>
                  <td><SowaasButton title='View' onClick={() => { setKmdbItemModalOpen(i) }} /></td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        <Row>
          <Col sm='12'>
            <SowaasButton className='float-right' onClick={() => { setKmdbItemModalOpen({}) }} title='Add Item' />
          </Col>
        </Row>
      </>
    )
  }

  const renderEmptyMessage = () => {
    return (
      <>
        <Row>
          <Col sm='12' className='pt-4'>
            <div className='styledTitle'>Your Knowledge Management Log is currently empty, get started by adding your first item below</div>
            <p style={{ textAlign: 'center' }}>
              <SowaasButton onClick={() => { setKmdbItemModalOpen({}) }} title='Add Item' />
            </p>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      {kmdbItems.length > 0 && (
        renderTable()
      )}
      {kmdbItems.length === 0 && (
        renderEmptyMessage()
      )}
      {
        kmdbItemModalOpen !== false && (
          <KmdbItemModal isOpen setOpen={setKmdbItemModalOpen} kmdb={kmdbItemModalOpen} onSave={onSave} project={project} />
        )
      }
    </>
  )
}

export default KmdbLog

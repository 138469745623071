import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom'
import templates from '../../helpers/JSON/templates.json'

const SidebarContent = () => {
  const view = useSelector((state) => state.User.role)

  let template = templates[templates.length - 1] // start with the last one
  for (const temp of templates) {
    if (temp.roles.includes(view)) {
      template = temp
      break
    }
  }

  return (
    <>
      <div id='sidebar-menu'>
        <ul className='metismenu list-unstyled mt-3' id='side-menu'>
          {template.menu.items.map(({ label, route, icon }, idx) => {
            return (
              <li key={idx}>
                <NavLink to={route} activeStyle={{ borderRight: '4px solid #0DFFE7' }} exact>
                  <i className={`bx ${icon}`} style={{ color: '#0DFFE7' }} />
                  <span style={{ fontSize: '13px' }}>{label}</span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default withRouter(SidebarContent)

import React from 'react'
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { AvForm } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ApprovalModal = ({ isOpen, setOpen, onSubmit, title = '', message = '', confirmMessage = '' }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSubmit(values)
      onClose()
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} role='dialog' autoFocus centered className='stakeholderModal' tabIndex='-1' toggle={onClose}>
        <div className='modal-content'>
          <ModalHeader toggle={onClose}>
            {title}
          </ModalHeader>
          <ModalBody>
            <Col sm='12'>
              <AvForm className='validation-required' onSubmit={handleSubmit}>
                <StyledCard>
                  <Row>
                    <Col sm='12'>
                      {message}
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col sm='12'>
                      <SowaasButton className='float-right' title={confirmMessage} type='submit' />
                    </Col>
                  </Row>
                </StyledCard>
              </AvForm>
            </Col>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default ApprovalModal

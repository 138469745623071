import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, Table } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import SowaasIconButton from '../sowaasIconButton/sowaasIconButton'
import MoneyInput from '../moneyInput/moneyInput'
import _ from 'lodash'
import produce from 'immer'
import uniqid from 'uniqid'
import moment from 'moment'

const InvoiceRow = ({ idx, onUpdate, onRemove, item = {}, readOnly = true }) => {
  const {
    description,
    value
  } = item

  return (
    <>
      <tr>
        <td style={{ paddingTop: '1.3rem' }}>{idx + 1}</td>
        <td><AvField disabled={readOnly} name={`items[${idx}].description`} value={description} /></td>
        <td><MoneyInput disabled={readOnly} onChange={onUpdate} name={`items[${idx}].value`} value={value} /></td>
        {
          !readOnly && (
            <td>
              <SowaasIconButton id={`btnInvoiceItemRemove${idx}`} onClick={() => { onRemove(idx) }} icon='fa fa-trash' />
            </td>
          )
        }
      </tr>
    </>
  )
}

const Invoice = ({ onSubmit, suppliers, invoice = {}, project = {}, phases, readOnly = true, mode = 'add' }) => {
  const {
    supplierId = '-1',
    phaseId = '-1',
    recipient = 'Customer',
    items = [],
    slug = '',
    poNumber = ''
  } = invoice

  const [itemsValue, setItemsValue] = useState(items)
  const [recipientValue, setRecipientValue] = useState(recipient)

  const addItem = () => {
    const newItems = produce(itemsValue, draftItems => {
      draftItems.push({ localId: uniqid() })
    })
    setItemsValue(newItems)
  }

  const [total, setTotal] = useState(0)

  const handleSubmit = (e, errors, values) => {
    if (errors.length === 0) {
      onSubmit(values)
    }
  }

  const handleRemoveRow = (idx) => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      const newItems = produce(itemsValue, draftItems => {
        draftItems.splice(idx, 1)
      })
      setItemsValue(newItems)
      onUpdate()
    }
  }

  const recipientChanged = (e) => {
    const recipientInputValue = e.target.value
    setRecipientValue(recipientInputValue)
  }

  const renderInvoiceTable = () => {
    return (
      <div className='table-responsive'>
        <Table className='table-nowrap'>
          <thead>
            <tr>
              <th style={{ width: '70px' }}>No.</th>
              <th>Item</th>
              <th style={{ width: '15rem' }}>Price (excl VAT)</th>
              <th style={{ width: '3rem' }} />
            </tr>
          </thead>
          <tbody>
            {
              itemsValue.map((i, idx) => (
                <InvoiceRow readOnly={readOnly} onUpdate={onUpdate} key={(i.localId || i.id)} idx={idx} onRemove={handleRemoveRow} item={i} />
              ))
            }
            {
              readOnly === false && (
                <tr>
                  <td colSpan='4' className='text-right' style={{ display: 'flex', borderTop: 'none' }}>
                    <SowaasButton id='btnAddInvoiceItem' onClick={addItem} title='Add Item' />
                    <InfoMarker title='Add Item' bodyCopy='Allows you to create another invoice item' />
                  </td>
                </tr>
              )
            }
            <tr>
              <td colSpan='4' className='border-0'>
                <h4 className='m-0 text-right'>
                  Total (excl VAT)&nbsp;&nbsp;
                  {new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'GBP' }).format(total)}
                </h4>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }

  const renderEmptyInvoice = () => {
    return (
      <>
        <Row>
          <Col sm='12'>
            <div className='styledTitle'>Your Invoice is currently empty, get started by adding your first item below</div>
            <p className='mt-3' style={{ display: 'flex' }}>
              <SowaasButton id='btnAddInvoiceItem' onClick={() => { addItem() }} title='Add Item' />
              <InfoMarker title='Add Item' bodyCopy='Allows you to create another invoice item' />
            </p>
          </Col>
        </Row>
      </>
    )
  }

  const formRef = React.useRef()

  const onUpdate = () => {
    setTimeout(() => { // we have to wait for form state to catch up
      const values = _.pickBy(formRef.current._inputs, (value, key) => { return key.includes('.value') })
      const valuesOfObject = _.values(values)
      const total = _.sumBy(valuesOfObject, 'value')
      setTotal(total)
    }, 250)
  }

  useEffect(() => {
    onUpdate()
  }, [])

  return (
    <AvForm ref={formRef} onSubmit={handleSubmit}>
      <Row>
        <Col lg='12'>
          <Card>
            <CardBody>
              {
                slug !== '' && (
                  <Row>
                    <Col sm='2'>
                      <div className='styledTitle'>Invoice #</div>
                    </Col>
                    <Col sm='1' className='pt-1 text-right pr-0'>
                      <InfoMarker title='Invoice Number' bodyCopy='The system will auto generate a unique Invoice number' />
                    </Col>
                    <Col sm='4'>
                      <AvField type='text' name='slug' value={slug} disabled />
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col sm='2'>
                  <div className='styledTitle'>Project Name</div>
                </Col>
                <Col sm='1' className='pt-1 text-right pr-0'>
                  <InfoMarker title='Project Name' bodyCopy='' />
                </Col>
                <Col sm='4'>
                  <AvField type='text' name='name' value={project.name} disabled />
                </Col>
              </Row>
              <Row>
                <Col sm='2'>
                  <div className='styledTitle'>Start Date</div>
                </Col>
                <Col sm='1' className='pt-1 text-right pr-0'>
                  <InfoMarker title='Start Date' bodyCopy='' />
                </Col>
                <Col sm='4'>
                  <AvField type='text' name='startDate' value={moment(project.startDate).format('DD/MM/YYYY')} disabled />
                </Col>
              </Row>
              <Row>
                <Col sm='2'>
                  <div className='styledTitle'>End Date</div>
                </Col>
                <Col sm='1' className='pt-1 text-right pr-0'>
                  <InfoMarker title='End Date' bodyCopy='' />
                </Col>
                <Col sm='4'>
                  <AvField type='text' name='name' value={moment(project.endDate).format('DD/MM/YYYY')} disabled />
                </Col>
              </Row>
              <Row>
                <Col sm='2'>
                  <div className='styledTitle'>
                    Recipient
                  </div>
                </Col>
                <Col sm='1' className='pt-1 text-right pr-0'>
                  <InfoMarker title='Recipient' bodyCopy='Simply select who the intended recipient will be from the dropdown list.' />
                </Col>
                <Col sm='4'>
                  <AvField disabled={readOnly} type='select' name='recipient' onChange={recipientChanged} value={recipientValue}>
                    <option value='Customer'>{project.projectProcurer.name}</option>
                    <option value='Supplier'>Supplier</option>
                  </AvField>
                </Col>
                {
                  recipientValue === 'Supplier' && (
                    <Col sm='4'>
                      <AvField disabled={readOnly} type='select' name='supplierId' value={supplierId}>
                        <option disabled value='-1'>Select Supplier</option>
                        {
                          suppliers.map((supplier, idx) => (
                            <option key={idx} value={supplier.id}>{supplier.name}</option>
                          ))
                        }
                      </AvField>
                    </Col>
                  )
                }
              </Row>
              <Row>
                <Col sm='2'>
                  <div className='styledTitle'>Phase</div>
                </Col>
                <Col sm='1' className='pt-1 text-right pr-0'>
                  <InfoMarker title='Phase' bodyCopy='' />
                </Col>
                <Col sm='4'>
                  <AvField disabled={readOnly} type='select' name='phaseId' value={phaseId} valueParser={window.parseInt}>
                    <option disabled value='-1'>Select Phase</option>
                    {
                      phases.map(phase => (
                        <option key={phase.id} value={phase.id}>{phase.name}</option>
                      ))
                    }
                  </AvField>
                </Col>
              </Row>
              <Row>
                <Col sm='2'>
                  <div className='styledTitle'>PO Number</div>
                </Col>
                <Col sm='1' className='pt-1 text-right pr-0'>
                  <InfoMarker title='PO Number' bodyCopy='Enter the relevant PO number this invoice should be attributed against.' />
                </Col>
                <Col sm='4'>
                  <AvField disabled={readOnly} type='text' name='poNumber' required value={poNumber} />
                </Col>
              </Row>
              <hr />
              <div className='py-2 mt-3'>
                <h3 className='font-size-15 font-weight-bold'>
                  Invoice Summary
                  <InfoMarker title='Invoice Summary' bodyCopy='Provides the option to add as many items to invoice against based on Deliverable, Milestone, Time or any other description the recipient needs.' id='imInvoiceSummary' />
                </h3>
              </div>
              {itemsValue.length > 0 && (
                renderInvoiceTable()
              )}
              {(itemsValue.length === 0) && (readOnly === false) && (
                renderEmptyInvoice()
              )}
              <div className='d-print-none'>
                {
                  mode === 'add' && (
                    <SowaasButton id='btnSubmitInvoice' type='submit' title='Save' className='float-right' />
                  )
                }
                {
                  mode === 'approve' && (
                    <SowaasButton id='btnApproveInvoice' className='float-right' title='Approve' type='submit' />
                  )
                }
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </AvForm>
  )
}

export default Invoice

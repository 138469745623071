import React, { useState } from 'react'
import { Table, FormGroup, Row, Col, Label, Input } from 'reactstrap'
import Fuse from 'fuse.js'

const SupplierTable = ({ suppliers = [], onSupplierSelected }) => {
  const [searchValue, setSearchValue] = useState('')

  const fuse = new Fuse(suppliers, {
    keys: ['name', 'contacts.firstName', 'contacts.lastName']
  })
  const filteredSuppliers = (searchValue.length > 0 ? fuse.search(searchValue) : suppliers.map((c) => ({ item: c })))

  return (
    <>
      <Row>
        <Col sm={{ size: 2, offset: 10 }}>
          <FormGroup row>
            <Label for='searchBox' sm={5} style={{ textAlign: 'right' }}>Search</Label>
            <Col sm={7}>
              <Input type='text' name='searchBox' id='searchBox' placeholder='' onChange={(e) => { setSearchValue(e.target.value) }} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Table hover>
        <thead>
          <tr>
            <th>Supplier Name</th>
            <th>Supplier Company Name</th>
            <th>Primary Contact</th>
            <th>Email</th>
            <th>Telephone Number</th>
            <th>Supplier ID</th>
          </tr>
        </thead>
        <tbody>
          {
            filteredSuppliers.map(({ item }, idx) => (
              <tr key={idx} onClick={() => { onSupplierSelected(item) }}>
                <td>{item.name}</td>
                <td>{item.tradingName}</td>
                <td>{item.contacts[0].firstName} {item.contacts[0].lastName}</td>
                <td>{item.contacts[0].contactEmail}</td>
                <td>{item.contacts[0].contactPhone}</td>
                <td>{item.slug}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </>
  )
}

export default SupplierTable

import {
  ADD_NOTIFICATION,
  ADD_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  PUT_NOTIFICATION,
  MARK_NOTIFICATION_READ,
  UPDATE_NOTIFICATION
} from './actionTypes'

export const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  payload: { notification }
})

export const addNotifications = notifications => ({
  type: ADD_NOTIFICATIONS,
  payload: { notifications }
})

export const updateNotification = notification => ({
  type: UPDATE_NOTIFICATION,
  payload: { notification }
})

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
  payload: {}
})

export const deleteNotification = notification => ({
  type: DELETE_NOTIFICATION,
  payload: { notification }
})

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS
})

export const putNotification = notification => ({
  type: PUT_NOTIFICATION,
  payload: { notification }
})

export const markNotificationAsRead = notification => ({
  type: MARK_NOTIFICATION_READ,
  payload: { notification }
})

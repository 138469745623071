import React from 'react'

const dateFormatter = (date) => {
  const formattedDate = new Date(date)
  const month = () => {
    switch (formattedDate.getMonth()) {
      case 0: return 'Jan'
      case 1: return 'Feb'
      case 2: return 'Mar'
      case 3: return 'Apr'
      case 4: return 'May'
      case 5: return 'Jun'
      case 6: return 'Jul'
      case 7: return 'Aug'
      case 8: return 'Sep'
      case 9: return 'Oct'
      case 10: return 'Nov'
      case 11: return 'Dec'
      default: return ''
    }
  }

  return (
    <>
      {formattedDate.getMonth()}/{month(formattedDate)}/{formattedDate.getFullYear()} {formattedDate.getHours()}:{formattedDate.getMinutes()}
    </>
  )
}

export default dateFormatter

import React, { useState } from 'react'
import { Col, Card, CardBody, CardTitle, Row } from 'reactstrap'
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation'
import BooleanToggle from '../booleanToggle/booleanToggle'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import moment from 'moment'

const NewProject = ({ onProjectAdd, existingProject = {}, ctaLabel = 'Save', readOnly = false }) => {
  let {
    id = '',
    name = '',
    startDate = '',
    endDate = '',
    suppliersRequired = false,
    proposalRequired = false,
    engagementRequired = false,
    numberOfSuppliersRequired = 0
  } = existingProject

  React.useEffect(() => {
    setSuppliersRequiredValue(suppliersRequired)
  }, [existingProject])

  if (startDate !== '') {
    startDate = moment(startDate).format('YYYY-MM-DD')
  }

  if (endDate !== '') {
    endDate = moment(endDate).format('YYYY-MM-DD')
  }

  const [suppliersRequiredValue, setSuppliersRequiredValue] = useState(suppliersRequired)

  const editingExisting = (id !== '')

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      if (values.numberOfSuppliersRequired && values.numberOfSuppliersRequired !== '') {
        values.numberOfSuppliersRequired = Number(values.numberOfSuppliersRequired)
      }
      onProjectAdd(values)
    }
  }

  return (
    <>
      <AvForm className='validation-required' onSubmit={handleSubmit}>
        {
          editingExisting && (
            <AvInput name='id' type='hidden' value={id} />
          )
        }
        <Card className='overflow-hidden'>
          <CardBody>
            <CardTitle className='mb-4'>
              {(editingExisting ? 'Update' : 'Create New')} SOW Service
              <InfoMarker title='Create New SOW Service' bodyCopy='We will outline the initial Service details for the new Statement of Work (SOW) you intend to create, along with Supplier, Proposal and Deilvery documentation where applicable' id='imNewProject' />
            </CardTitle>
            <Row style={{ marginBottom: '1rem' }}>
              <Col sm='2'>
                <div className='styledTitle'>
                  Service Name
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right'>
                <InfoMarker title='Service Name' bodyCopy={"Give the Service a relevant name, e.g. 'Amazon AppFlow Implementation' or 'Project Kodiak' "} id='imServiceName' />
              </Col>
              <Col sm='9'>
                <AvField
                  name='name'
                  placeholder='Service Name'
                  type='text'
                  errorMessage='Service Name'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  value={name}
                  disabled={readOnly}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
              <Col sm='2'>
                <div className='styledTitle'>
                  Service Start Date
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right'>
                <InfoMarker title='Service Start Date' bodyCopy='The exact Start Date of the Service might not be known at this stage. Please enter an estimated date and this can be amended later on.' id='imServiceStartDate' />
              </Col>
              <Col sm='9'>
                <AvField
                  name='startDate'
                  placeholder='Service Start Date'
                  type='date'
                  errorMessage='Service Start Date'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  value={startDate}
                  disabled={readOnly}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
              <Col sm='2'>
                <div className='styledTitle'>
                  Service End Date
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right'>
                <InfoMarker title='Service End Date' bodyCopy='The exact End Date of the Service might not be known at this stage. Please enter an estimated date and this can be amended later on.' id='imServiceEndDate' />
              </Col>
              <Col sm='9'>
                <AvField
                  name='endDate'
                  placeholder='Service End Date'
                  type='date'
                  errorMessage='Service End Date'
                  className='form-control'
                  validate={{ required: { value: true } }}
                  value={endDate}
                  disabled={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  Contractors / Suppliers Required
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right'>
                <InfoMarker title='Contractors / Suppliers Required' bodyCopy='Will you need to use third-party Contractors/Suppliers/Personal Service Companies (PSCs) to deliver this Service?' id='imSuppliersRequired' />
              </Col>
              <Col sm='2'>
                <BooleanToggle disabled={readOnly} name='suppliersRequired' currentValue={suppliersRequiredValue} onValueChanged={setSuppliersRequiredValue} />
              </Col>
              {
                suppliersRequiredValue && (
                  <Col sm='5'>
                    <Row>
                      <Col sm='7'>
                        <div className='styledTitle'>
                          Number of Contractors / Suppliers Required
                        </div>
                      </Col>
                      <Col sm='1' className='pt-1 text-right'>
                        <InfoMarker title='Number of Contractors / Suppliers Required' bodyCopy='How many Contractors, Suppliers and/or PSCs are you likely to need to deliver this Service? You can amend this number throughout the Design phase.' id='imNumberOfSuppliers' />
                      </Col>
                      <Col sm='3'>
                        <AvField
                          name='numberOfSuppliersRequired'
                          type='number'
                          min='1'
                          max='50'
                          step='1'
                          errorMessage='Enter number'
                          className='form-control'
                          validate={{ required: { value: true } }}
                          value={numberOfSuppliersRequired}
                          disabled={readOnly}
                        />
                      </Col>
                    </Row>
                  </Col>
                )
              }
            </Row>
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  Proposal Required
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right'>
                <InfoMarker title='Proposal Required' bodyCopy='Do you need to prepare a Proposal outlining the Service, outcomes and costs for key stakeholders to review?' id='imProposalRequired' />
              </Col>
              <Col sm='9'>
                <BooleanToggle disabled={readOnly} name='proposalRequired' currentValue={proposalRequired} onValueChanged={() => {}} />
              </Col>
            </Row>
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  Service Delivery Required
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right'>
                <InfoMarker title='Service Delivery Required' bodyCopy='Does the Service need ongoing Delivery management, reporting and governance for Customers and Suppliers?' id='imDeliveryManagement' />
              </Col>
              <Col sm='1'>
                <BooleanToggle disabled={readOnly} name='engagementRequired' currentValue={engagementRequired} onValueChanged={() => {}} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right' title={(readOnly ? 'Next' : ctaLabel)} type='submit' />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </AvForm>
    </>
  )
}

export default NewProject

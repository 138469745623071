import React from 'react'
import styled from 'styled-components'

import { Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap'

// Dummy Data
import { getSalesInformation } from './actions'

const StyledHeading = styled.div`
padding-bottom: 20px;
`

const StyledTableHeaders = styled.div`
padding: 0 15px;
display: grid;
grid-template-columns: repeat(6, 1fr);
text-align: left;
`

const StyledBar = styled(Button)`
width: 100%;
margin: 5px 0;
`

const StyledBarContent = styled.div`
display: grid;
grid-template-columns: repeat(6, 1fr);
text-align: left;
`
const PaddedText = styled.div`
margin-right: 5px;
`

const SalesSummaryExpanded = ({ history }) => {
  const opportunityClicked = (opportunity) => {
    history.push(`/sales-opportunity/${opportunity.id}`)
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xl='12'>
              <Card className='overflow-hidden'>
                <CardBody>
                  <Row>
                    <Col sm='12'>
                      <StyledHeading>
                        <CardTitle className='mb-2'>
                          Sales Information
                        </CardTitle>
                      </StyledHeading>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm='12'>
                      <StyledTableHeaders>
                        <PaddedText>
                          Company
                        </PaddedText>
                        <PaddedText>
                          Project
                        </PaddedText>
                        <PaddedText>
                          Originator
                        </PaddedText>
                        <PaddedText>
                          Probability
                        </PaddedText>
                        <PaddedText>
                          Status
                        </PaddedText>
                        <PaddedText>
                          Value
                        </PaddedText>
                      </StyledTableHeaders>
                    </Col>
                  </Row>
                  <Row>
                    {getSalesInformation().map((sales, idx) => (
                      <Col sm='12' key={idx}>
                        <StyledBar color='secondary' outline className='waves-effect' onClick={() => { opportunityClicked(sales) }}>
                          <StyledBarContent>
                            <PaddedText>
                              {sales.company}
                            </PaddedText>
                            <PaddedText>
                              {sales.project}
                            </PaddedText>
                            <PaddedText>
                              {sales.owner}
                            </PaddedText>
                            <PaddedText>
                              {sales.probability * 100}%
                            </PaddedText>
                            <PaddedText>
                              {sales.status}
                            </PaddedText>
                            <PaddedText>
                              £{sales.projectValue}
                            </PaddedText>
                          </StyledBarContent>
                        </StyledBar>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default (SalesSummaryExpanded)

import {
  ADD_NOTIFICATION,
  ADD_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION
} from './actionTypes'
import produce from 'immer'

const initState = {
  notifications: []
}

const Notification = (state = initState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const nextState = produce(state, draftState => {
        draftState.notifications.push(action.payload.notification)
      })
      return nextState
    }
    case ADD_NOTIFICATIONS: {
      const nextState = produce(state, draftState => {
        draftState.notifications.push(...action.payload.notifications)
      })
      return nextState
    }
    case CLEAR_NOTIFICATIONS: {
      const nextState = produce(state, draftState => {
        draftState.notifications = []
      })
      return nextState
    }
    case UPDATE_NOTIFICATION: {
      const { notification } = action.payload
      const foundIndex = state.notifications.findIndex(x => x.id === notification.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.notifications[foundIndex] = notification
        })
        return nextState
      }
      return state
    }
    case DELETE_NOTIFICATION: {
      const { notification } = action.payload
      const foundIndex = state.notifications.findIndex(x => x.id === notification.id)
      if (foundIndex !== -1) {
        const nextState = produce(state, draftState => {
          draftState.notifications.splice(foundIndex, 1)
        })
        return nextState
      }
      return state
    }
    default:
      return state
  }
}

export default Notification

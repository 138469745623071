import { takeEvery, fork, put, all } from 'redux-saga/effects'
import errorService from '../../helpers/errorService'
import _ from 'lodash'

// Login Redux States
import {
  GET_ENGAGEMENT,
  GET_PROJECTS,
  PATCH_PROJECT,
  GET_SOLUTION,
  POST_PROJECT,
  PUT_SOLUTION_SUPPLIERS,
  POST_SERVICE_REPORT,
  PATCH_ENGAGEMENT_FINANCE,
  POST_TIME_UTILISATION,
  PATCH_TIME_UTILISATION,
  POST_TIME_UTILISATION_SHEET,
  PATCH_TIME_UTILISATION_SHEET,
  POST_INVOICE,
  PATCH_INVOICE,
  POST_INVOICE_ITEM,
  PATCH_INVOICE_ITEM,
  POST_CHANGE_REQUEST,
  PATCH_CHANGE_REQUEST,
  POST_CHANGE_REQUEST_CHANGE,
  PATCH_CHANGE_REQUEST_CHANGE,
  POST_RAID_RISK,
  PATCH_RAID_RISK,
  POST_RAID_ASSUMPTION,
  PATCH_RAID_ASSUMPTION,
  POST_RAID_ISSUE,
  PATCH_RAID_ISSUE,
  POST_RAID_DEPENDENCY,
  PATCH_RAID_DEPENDENCY,
  POST_KMDB,
  PATCH_KMDB,
  PATCH_PROPOSAL_DESIGN,
  SYNC_FINANCIAL_DESIGN,
  SYNC_SOW_DESIGN,
  SYNC_LEGAL_DESIGN,
  SYNC_ENGAGEMENT_DESIGN,
  SYNC_SUPPLIERS_DESIGN,
  GET_STATEMENT_OF_WORK,
  GET_ENGAGEMENT_DESIGN,
  GET_FINANCIAL_DESIGN,
  SYNC_INVOICE,
  SYNC_TIME_UTIL,
  SYNC_KMDB,
  SYNC_CHANGE_REQUEST,
  GET_INVOICE,
  GET_CHANGE_REQUEST,
  GET_TIME_UTILISATION,
  GET_SERVICE_REPORT,
  GET_KMDB,
  GET_LEGAL_DESIGN,
  APPROVE_FINANCE,
  APPROVE_LEGAL,
  APPROVE_MANAGEMENT,
  APPROVE_INVOICE,
  APPROVE_CHANGE_REQUEST
} from './actionTypes'
import {
  addProject,
  addProjects,
  addSolution,
  addEngagement,
  updateSolutionSuppliers,
  addServiceReport,
  updateEngagementFinance,
  updateProject,
  addTimeUtilisation,
  updateTimeUtilisation,
  addTimeUtilisationSheet,
  updateTimeUtilisationSheet,
  addInvoice,
  updateInvoice,
  addInvoiceItem,
  updateInvoiceItem,
  addChangeRequest,
  updateChangeRequest,
  addChangeRequestChange,
  updateChangeRequestChange,
  addRaidRisk,
  updateRaidRisk,
  addRaidAssumption,
  updateRaidAssumption,
  addRaidIssue,
  updateRaidIssue,
  addRaidDependency,
  updateRaidDependency,
  addKmdb,
  updateKmdb,
  updateProposalDesign,
  updateFinancialDesign,
  updateSolutionSow,
  updateLegalDesign,
  updateEngagementDesign,
  updateSuppliersDesign,
  updateSolutionEngagementDesign,
  updateSolutionFinancialDesign,
  updateServiceReport,
  clearProjects
} from './actions'

import { addToast } from '../toast/actions'

import api from '../../helpers/api'
import produce from 'immer'

function * getProjects () {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Project.get_api_Project()
    if (status === 200) {
      yield put(clearProjects())
      yield put(addProjects(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getSolution ({ payload: { project, solution } }) {
  try {
    const client = yield api.getClient()
    const { status = -1, body = {} } = yield client.apis.Solution.get_api_Solution__id_({ id: solution.id })
    if (status === 200) {
      yield put(addSolution(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getEngagement ({ payload: { project, engagement } }) {
  try {
    const client = yield api.getClient()
    const { status = -1, body = {} } = yield client.apis.Engagement.get_api_Engagement__id_({ id: engagement.id })
    if (status === 200) {
      yield put(addEngagement(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postProject ({ payload: { project } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Project.post_api_Project({}, { requestBody: project })
    if (status === 200) {
      yield put(addProject(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchProject ({ payload: { project } }) {
  try {
    const { id, ...rest } = project

    const client = yield api.getClient()

    const { status } = yield client.apis.Project.patch_api_Project__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateProject(project))
      yield put(addToast('SOW Service Updated', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postServiceReport ({ payload: { project, serviceReport } }) {
  try {
    const client = yield api.getClient()
    serviceReport.engagementId = project.engagement.id
    const { status, body = {} } = yield client.apis.ServiceReport.post_api_ServiceReport({}, { requestBody: serviceReport })
    if (status === 200) {
      yield put(addServiceReport(project, body))
      yield put(addToast('Service Report Added', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * putProjectSolutionSuppliers ({ payload: { solution, solutionSuppliers } }) {
  try {
    // const client = yield api.getClient()
    // const { status, body = {} } = yield client.apis.Project.post_api_Project({}, { requestBody: project })
    // if (status === 200) {
    yield put(updateSolutionSuppliers(solution, solutionSuppliers))
    // }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchEngagementFinance ({ payload: { engagementFinance } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = engagementFinance

    const { status } = yield client.apis.EngagementFinance.patch_api_EngagementFinance__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateEngagementFinance(engagementFinance))
      yield put(addToast('Finance Updated', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postTimeUtilisation ({ payload: { engagement, timeUtilisation } }) {
  try {
    const client = yield api.getClient()
    timeUtilisation.engagementId = engagement.id
    const { status, body = {} } = yield client.apis.TimeUtilisation.post_api_TimeUtilisation({}, { requestBody: timeUtilisation })
    if (status === 200) {
      yield put(addTimeUtilisation(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchTimeUtilisation ({ payload: { timeUtilisation } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = timeUtilisation
    const { status } = yield client.apis.TimeUtilisation.patch_api_TimeUtilisation__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateTimeUtilisation(timeUtilisation))
      yield put(addToast('Time Utilisation Updated', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postTimeUtilisationSheet ({ payload: { timeUtilisation, timeUtilisationSheet } }) {
  try {
    const client = yield api.getClient()
    timeUtilisationSheet.timeUtilisationId = timeUtilisation.id
    const { status, body = {} } = yield client.apis.TimeUtilisation.post_api_TimeUtilisation({}, { requestBody: timeUtilisationSheet })
    if (status === 200) {
      yield put(addTimeUtilisationSheet(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchTimeUtilisationSheet ({ payload: { timeUtilisationSheet } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = timeUtilisationSheet
    const { status } = yield client.apis.TimeUtilisationSheet.patch_api_TimeUtilisationSheet__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateTimeUtilisationSheet(timeUtilisationSheet))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postInvoice ({ payload: { engagement, invoice } }) {
  try {
    const client = yield api.getClient()
    invoice.engagementId = engagement.id
    const { status, body = {} } = yield client.apis.Invoice.post_api_Invoice({}, { requestBody: invoice })
    if (status === 200) {
      yield put(addInvoice(body))
      yield put(addToast('Invoice Added', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchInvoice ({ payload: { invoice } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = invoice
    const { status } = yield client.apis.Invoice.patch_api_Invoice__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateInvoice(invoice))
      yield put(addToast('Invoice Updated', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postInvoiceItem ({ payload: { invoice, invoiceItem } }) {
  try {
    const client = yield api.getClient()
    invoiceItem.invoiceId = invoice.id
    const { status, body = {} } = yield client.apis.InvoiceItem.post_api_InvoiceItem({}, { requestBody: invoiceItem })
    if (status === 200) {
      yield put(addInvoiceItem(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchInvoiceItem ({ payload: { invoiceItem } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = invoiceItem
    const { status } = yield client.apis.InvoiceItem.patch_api_InvoiceItem__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateInvoiceItem(invoiceItem))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postChangeRequest ({ payload: { engagement, changeRequest } }) {
  try {
    const client = yield api.getClient()
    changeRequest.engagementId = engagement.id
    const { status, body = {} } = yield client.apis.ChangeRequest.post_api_ChangeRequest({}, { requestBody: changeRequest })
    if (status === 200) {
      yield put(addChangeRequest(body))
      yield put(addToast('Change Request Added', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchChangeRequest ({ payload: { changeRequest } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = changeRequest
    const { status } = yield client.apis.ChangeRequest.patch_api_ChangeRequest__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateChangeRequest(changeRequest))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postChangeRequestChange ({ payload: { changeRequest, changeRequestChange } }) {
  try {
    const client = yield api.getClient()
    changeRequestChange.changeRequestId = changeRequest.id
    const { status, body = {} } = yield client.apis.ChangerequestChange.post_api_ChangeRequestChange({}, { requestBody: changeRequestChange })
    if (status === 200) {
      yield put(addChangeRequestChange(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchChangeRequestChange ({ payload: { changeRequestChange } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = changeRequestChange
    const { status } = yield client.apis.ChangeRequestChange.patch_api_ChangeRequestChange__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateChangeRequestChange(changeRequestChange))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postRaidRisk ({ payload: { project, raidRisk } }) {
  try {
    const client = yield api.getClient()
    raidRisk.engagementId = project.engagement.id
    const { status, body = {} } = yield client.apis.RaidRisk.post_api_RaidRisk({}, { requestBody: raidRisk })
    if (status === 200) {
      yield put(addRaidRisk(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchRaidRisk ({ payload: { project, raidRisk } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = raidRisk
    delete rest.slug
    const { status } = yield client.apis.RaidRisk.patch_api_RaidRisk__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateRaidRisk(project, raidRisk))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postRaidAssumption ({ payload: { project, raidAssumption } }) {
  try {
    const client = yield api.getClient()
    raidAssumption.engagementId = project.engagement.id
    const { status, body = {} } = yield client.apis.RaidAssumption.post_api_RaidAssumption({}, { requestBody: raidAssumption })
    if (status === 200) {
      yield put(addRaidAssumption(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchRaidAssumption ({ payload: { project, raidAssumption } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = raidAssumption
    delete rest.slug
    const { status } = yield client.apis.RaidAssumption.patch_api_RaidAssumption__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateRaidAssumption(project, raidAssumption))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postRaidIssue ({ payload: { project, raidIssue } }) {
  try {
    const client = yield api.getClient()
    raidIssue.engagementId = project.engagement.id
    const { status, body = {} } = yield client.apis.RaidIssue.post_api_RaidIssue({}, { requestBody: raidIssue })
    if (status === 200) {
      yield put(addRaidIssue(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchRaidIssue ({ payload: { project, raidIssue } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = raidIssue
    delete rest.slug
    const { status } = yield client.apis.RaidIssue.patch_api_RaidIssue__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateRaidIssue(project, raidIssue))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postRaidDependency ({ payload: { project, raidDependency } }) {
  try {
    const client = yield api.getClient()
    raidDependency.engagementId = project.engagement.id
    const { status, body = {} } = yield client.apis.RaidDependency.post_api_RaidDependency({}, { requestBody: raidDependency })
    if (status === 200) {
      yield put(addRaidDependency(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchRaidDependency ({ payload: { project, raidDependency } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = raidDependency
    delete rest.slug
    const { status } = yield client.apis.RaidDependency.patch_api_RaidDependency__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateRaidDependency(project, raidDependency))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postKmdb ({ payload: { engagement, kmdb } }) {
  try {
    const client = yield api.getClient()
    kmdb.engagementId = engagement.id
    const { status, body = {} } = yield client.apis.Kmdb.post_api_Kmdb({}, { requestBody: kmdb })
    if (status === 200) {
      yield put(addKmdb(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchKmdb ({ payload: { kmdb } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = kmdb
    delete rest.slug
    const { status } = yield client.apis.Kmdb.patch_api_Kmdb__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateKmdb(kmdb))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * patchProposalDesign ({ payload: { project, proposalDesign } }) {
  try {
    const client = yield api.getClient()
    const { id, ...rest } = proposalDesign
    const { status } = yield client.apis.ProposalDesign.patch_api_ProposalDesign__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateProposalDesign(project, proposalDesign))
      yield put(addToast('Proposal Updated', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncSowDesign ({ payload: { project, sowDesign } }) {
  try {
    const client = yield api.getClient()

    const {
      id = '',
      deliverables = [],
      milestones = [],
      phases = [],
      ...sowData
    } = sowDesign

    let newSowDesign

    if (id !== '') {
      const requestBody = { ...sowData }
      if (requestBody.supplierId) {
        requestBody.supplierId = Number(requestBody.supplierId)
      }

      const { status } = yield client.apis.StatementOfWork.patch_api_StatementOfWork__id_({ id }, { requestBody })
      if (status === 200) {
        newSowDesign = { ...requestBody, id }
      }
    } else {
      const requestBody = { ...sowData, solutionId: project.solution.id }
      if (requestBody.supplierId) {
        requestBody.supplierId = Number(requestBody.supplierId)
      }

      const { status, body = {} } = yield client.apis.StatementOfWork.post_api_StatementOfWork({}, { requestBody })
      if (status === 200) {
        newSowDesign = body
      }
    }

    newSowDesign.phases = []
    newSowDesign.deliverables = []
    newSowDesign.milestones = []

    for (let i = 0; i < phases.length; i += 1) {
      const phase = phases[i]
      if ('id' in phase) {
        const { id, ...rest } = phase
        const requestBody = { ...rest }
        const { status } = yield client.apis.Phase.patch_api_Phase__id_({ id }, { requestBody })
        if (status === 200) {
          newSowDesign.phases[i] = phase
        }
      } else {
        const requestBody = { ...phase, statementOfWorkId: newSowDesign.id }
        const { status, body = {} } = yield client.apis.Phase.post_api_Phase({}, { requestBody })
        if (status === 200) {
          newSowDesign.phases.push(body)
        }
      }
    }

    // patch/post milestones
    for (let i = 0; i < milestones.length; i += 1) {
      const milestone = milestones[i]
      if ('id' in milestone) {
        const { id, ...rest } = milestone
        const requestBody = { ...rest }
        const { status } = yield client.apis.Milestone.patch_api_Milestone__id_({ id }, { requestBody })
        if (status === 200) {
          newSowDesign.milestones[i] = milestone
        }
      } else {
        const requestBody = { ...milestone, statementOfWorkId: newSowDesign.id }
        const { status, body = {} } = yield client.apis.Milestone.post_api_Milestone({}, { requestBody })
        if (status === 200) {
          newSowDesign.milestones.push(body)
        }
      }
    }

    // patch/post deliverables
    for (let i = 0; i < deliverables.length; i += 1) {
      const deliverable = deliverables[i]
      if ('id' in deliverable) {
        const { id, ...rest } = deliverable
        const requestBody = { ...rest }
        const { status } = yield client.apis.Deliverable.patch_api_Deliverable__id_({ id }, { requestBody })
        if (status === 200) {
          newSowDesign.deliverables[i] = deliverable
        }
      } else {
        const requestBody = { ...deliverable, statementOfWorkId: newSowDesign.id }
        const { status, body = {} } = yield client.apis.Deliverable.post_api_Deliverable({}, { requestBody })
        if (status === 200) {
          newSowDesign.deliverables.push(body)
        }
      }
    }

    yield put(updateSolutionSow(project, newSowDesign))

    yield put(addToast('SoW Design Updated', 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncLegalDesign ({ payload: { project, legalDesign } }) {
  try {
    const client = yield api.getClient()

    const legalDesignCustomerFiles = _.get(legalDesign, 'legalDesignCustomerFiles', [])
    const legalDesignSupplierFiles = _.flatten(_.get(legalDesign, 'legalDesignSupplierFiles', []).map(
      (sF) => {
        return [
          { supplierId: sF.supplierId, ...sF.NDA },
          { supplierId: sF.supplierId, ...sF.SSA }
        ]
      }
    ))
    const legalDesignOtherFiles = _.get(legalDesign, 'legalDesignOtherFiles', [])

    const newLegalDesign = {
      id: legalDesign.id,
      legalDesignCustomerFiles: [],
      legalDesignSupplierFiles: [],
      legalDesignOtherFiles: []
    }

    // patch/post legalDesignCustomerFiles
    for (let i = 0; i < legalDesignCustomerFiles.length; i += 1) {
      const customerFile = produce(legalDesignCustomerFiles[i], (draftCustomerFile) => {
        draftCustomerFile.legalDesignId = legalDesign.id
      })

      if ('id' in customerFile) {
        const { id, ...rest } = customerFile
        const requestBody = { ...rest }
        delete requestBody.legalDesignId
        const { status } = yield client.apis.LegalDesignCustomerFile.patch_api_LegalDesignCustomerFile__id_({ id }, { requestBody })
        if (status === 200) {
          newLegalDesign.legalDesignCustomerFiles[i] = customerFile
        }
      } else {
        const requestBody = { ...customerFile, legalDesignId: legalDesign.id }
        const { status, body = {} } = yield client.apis.LegalDesignCustomerFile.post_api_LegalDesignCustomerFile({}, { requestBody })
        if (status === 200) {
          newLegalDesign.legalDesignCustomerFiles.push(body)
        }
      }
    }

    // patch/post legalDesignSupplierFiles
    for (let i = 0; i < legalDesignSupplierFiles.length; i += 1) {
      const supplierFile = produce(legalDesignSupplierFiles[i], (draftSupplierFile) => {
        draftSupplierFile.legalDesignId = legalDesign.id
      })

      if ('id' in supplierFile) {
        const { id, ...rest } = supplierFile
        const requestBody = { ...rest }
        delete requestBody.legalDesignId
        delete requestBody.supplierId
        const { status } = yield client.apis.LegalDesignSupplierFile.patch_api_LegalDesignSupplierFile__id_({ id }, { requestBody })
        if (status === 200) {
          newLegalDesign.legalDesignSupplierFiles[i] = supplierFile
        }
      } else {
        const requestBody = { ...supplierFile, legalDesignId: legalDesign.id }
        const { status, body = {} } = yield client.apis.LegalDesignSupplierFile.post_api_LegalDesignSupplierFile({}, { requestBody })
        if (status === 200) {
          newLegalDesign.legalDesignSupplierFiles.push(body)
        }
      }
    }

    // patch/post legalDesignSupplierFiles
    for (let i = 0; i < legalDesignOtherFiles.length; i += 1) {
      const otherFile = produce(legalDesignOtherFiles[i], (draftOtherFile) => {
        draftOtherFile.legalDesignId = legalDesign.id
      })

      if ('id' in otherFile) {
        const { id, ...rest } = otherFile
        const requestBody = { ...rest }
        delete requestBody.legalDesignId
        const { status } = yield client.apis.LegalDesignOtherFile.patch_api_LegalDesignOtherFile__id_({ id }, { requestBody })
        if (status === 200) {
          newLegalDesign.legalDesignOtherFiles[i] = otherFile
        }
      } else {
        const requestBody = { ...otherFile, legalDesignId: legalDesign.id }
        const { status, body = {} } = yield client.apis.LegalDesignOtherFile.post_api_LegalDesignOtherFile({}, { requestBody })
        if (status === 200) {
          newLegalDesign.legalDesignOtherFiles.push(body)
        }
      }
    }

    yield put(updateLegalDesign(project, newLegalDesign))
    yield put(addToast('Legal Design Updated', 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncEngagementDesign ({ payload: { project, engagementDesign } }) {
  try {
    const client = yield api.getClient()

    const {
      id = '',
      engagementDesignLevels = [],
      engagementDesignReportingLevels = [],
      ...engagementDesignData
    } = engagementDesign

    let newEngagementDesign

    // sync engagemnt design
    const requestBody = { ...engagementDesignData }

    const { status } = yield client.apis.EngagementDesign.patch_api_EngagementDesign__id_({ id }, { requestBody })
    if (status === 200) {
      newEngagementDesign = { ...requestBody, id }
    }

    newEngagementDesign.engagementDesignLevels = []
    newEngagementDesign.engagementDesignReportingLevels = []

    // sync levels
    for (let i = 0; i < engagementDesignLevels.length; i += 1) {
      const level = engagementDesignLevels[i]

      if (level.phaseId) {
        level.phaseId = Number(level.phaseId)
      } else {
        level.phaseId = null
      }

      if (level.value) {
        level.value = Number(level.value)
      }

      if ('id' in level) {
        const { id, ...rest } = level
        const requestBody = { ...rest }
        const { status } = yield client.apis.EngagementDesignLevel.patch_api_EngagementDesignLevel__id_({ id }, { requestBody })
        if (status === 200) {
          newEngagementDesign.engagementDesignLevels[i] = level
        }
      } else {
        const requestBody = { ...level, engagementDesignId: newEngagementDesign.id }
        const { status, body = {} } = yield client.apis.EngagementDesignLevel.post_api_EngagementDesignLevel({}, { requestBody })
        if (status === 200) {
          newEngagementDesign.engagementDesignLevels.push(body)
        }
      }
    }

    // sync reporting
    for (let i = 0; i < engagementDesignReportingLevels.length; i += 1) {
      const level = engagementDesignReportingLevels[i]
      if ('id' in level) {
        const { id, ...rest } = level
        const requestBody = { ...rest }
        if (requestBody.phaseId) {
          requestBody.phaseId = Number(requestBody.phaseId)
        }
        const { status } = yield client.apis.EngagementDesignReportingLevel.patch_api_EngagementDesignReportingLevel__id_({ id }, { requestBody })
        if (status === 200) {
          newEngagementDesign.engagementDesignReportingLevels[i] = level
        }
      } else {
        const requestBody = { ...level, engagementDesignId: newEngagementDesign.id }
        if (requestBody.phaseId) {
          requestBody.phaseId = Number(requestBody.phaseId)
        }
        const { status, body = {} } = yield client.apis.EngagementDesignReportingLevel.post_api_EngagementDesignReportingLevel({}, { requestBody })
        if (status === 200) {
          newEngagementDesign.engagementDesignReportingLevels.push(body)
        }
      }
    }

    yield put(updateEngagementDesign(project, newEngagementDesign))
    yield put(addToast('Delivery Design Updated', 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncFinancialDesign ({ payload: { project, financialDesign } }) {
  try {
    const client = yield api.getClient()

    const {
      id = '',
      costs = [],
      ...financialDesignData
    } = financialDesign

    let newFinancialDesign

    if (financialDesignData.grossMarginRequired) {
      financialDesignData.grossMarginRequired = Number(financialDesignData.grossMarginRequired)
    }

    // sync financial design
    const requestBody = { ...financialDesignData }

    const { status } = yield client.apis.FinancialDesign.patch_api_FinancialDesign__id_({ id }, { requestBody })
    if (status === 200) {
      newFinancialDesign = { ...requestBody, id }
    }

    newFinancialDesign.costs = []

    // sync costs
    for (let i = 0; i < costs.length; i += 1) {
      const cost = costs[i]

      if (cost.phaseId) {
        cost.phaseId = Number(cost.phaseId)
      }

      if (cost.supplierId) {
        cost.supplierId = Number(cost.supplierId)
      }

      if (cost.chargeQuantity) {
        cost.chargeQuantity = Number(cost.chargeQuantity)
      }

      if (cost.costQuantity) {
        cost.costQuantity = Number(cost.costQuantity)
      }

      if ('id' in cost) {
        const { id, ...rest } = cost
        const requestBody = { ...rest }
        const { status } = yield client.apis.FinancialDesignCost.patch_api_FinancialDesignCost__id_({ id }, { requestBody })
        if (status === 200) {
          newFinancialDesign.costs[i] = cost
        }
      } else {
        const requestBody = { ...cost, financialDesignId: newFinancialDesign.id }
        const { status, body = {} } = yield client.apis.FinancialDesignCost.post_api_FinancialDesignCost({}, { requestBody })
        if (status === 200) {
          newFinancialDesign.costs.push(body)
        }
      }
    }

    yield put(updateFinancialDesign(project, financialDesign))
    yield put(addToast('Financial Design Updated', 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncSuppliersDesign ({ payload: { project, suppliersDesign } }) {
  try {
    const client = yield api.getClient()
    const updatedSolutionSuppliers = []
    for (let i = 0; i < suppliersDesign.length; i += 1) {
      const supplier = suppliersDesign[i]

      if ('id' in supplier) {
        const { id, ...rest } = supplier
        const requestBody = { ...rest, solutionId: project.solution.id }
        if (requestBody.supplierId) {
          requestBody.supplierId = Number(requestBody.supplierId)
        }

        const { status } = yield client.apis.SolutionSupplier.patch_api_SolutionSupplier__id_({ id }, { requestBody })
        if (status === 200) {
          updatedSolutionSuppliers.push(supplier)
        }
      } else {
        const requestBody = { ...supplier, solutionId: project.solution.id }
        if (requestBody.supplierId) {
          requestBody.supplierId = Number(requestBody.supplierId)
        }

        const { status, body = {} } = yield client.apis.SolutionSupplier.post_api_SolutionSupplier({}, { requestBody })
        if (status === 200) {
          updatedSolutionSuppliers.push(body)
        }
      }
    }
    yield put(updateSuppliersDesign(project, updatedSolutionSuppliers))
    yield put(addToast('Suppliers Design Updated', 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * getStatementOfWork ({ payload: { project, sowId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.StatementOfWork.get_api_StatementOfWork__id_({ id: sowId })
    if (status === 200) {
      yield put(updateSolutionSow(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getEngagementDesign ({ payload: { project, engagementDesignId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.EngagementDesign.get_api_EngagementDesign__id_({ id: engagementDesignId })
    if (status === 200) {
      yield put(updateSolutionEngagementDesign(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getLegalDesign ({ payload: { project, legalDesignId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.LegalDesign.get_api_LegalDesign__id_({ id: legalDesignId })
    if (status === 200) {
      yield put(updateLegalDesign(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getFinancialDesign ({ payload: { project, financialDesignId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.FinancialDesign.get_api_FinancialDesign__id_({ id: financialDesignId })
    if (status === 200) {
      yield put(updateSolutionFinancialDesign(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncInvoice ({ payload: { project, invoice } }) {
  try {
    const client = yield api.getClient()

    const {
      id = '',
      items = [],
      ...invoiceData
    } = invoice

    let newInvoice

    if (id !== '') {
      const requestBody = { ...invoiceData }
      const { status } = yield client.apis.Invoice.patch_api_Invoice__id_({ id }, { requestBody })
      if (status === 200) {
        newInvoice = { ...requestBody, id }
      }
    } else {
      const requestBody = { ...invoiceData, engagementId: project.engagement.id }
      const { status, body = {} } = yield client.apis.Invoice.post_api_Invoice({}, { requestBody })
      if (status === 200) {
        newInvoice = body
      }
    }

    newInvoice.items = []

    // sync items
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i]
      if ('id' in item) {
        const { id, ...rest } = item
        const requestBody = { ...rest }
        const { status } = yield client.apis.InvoiceItem.patch_api_InvoiceItem__id_({ id }, { requestBody })
        if (status === 200) {
          newInvoice.items[i] = item
        }
      } else {
        const requestBody = { ...item, invoiceId: newInvoice.id }
        const { status, body = {} } = yield client.apis.InvoiceItem.post_api_InvoiceItem({}, { requestBody })
        if (status === 200) {
          newInvoice.items.push(body)
        }
      }
    }

    yield put(updateInvoice(project, newInvoice))
    yield put(addToast(`Invoice ${(id === '' ? 'Added' : 'Updated')}`, 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncTimeUtil ({ payload: { project, timeUtil } }) {
  try {
    const client = yield api.getClient()

    const {
      id = '',
      sheets = [],
      ...timeUtilData
    } = timeUtil

    let newTimeUtil

    if (id !== '') {
      const requestBody = { ...timeUtilData }
      const { status } = yield client.apis.TimeUtilisation.patch_api_TimeUtilisation__id_({ id }, { requestBody })
      if (status === 200) {
        newTimeUtil = { ...requestBody, id }
      }
    } else {
      const requestBody = { ...timeUtilData, engagementId: project.engagement.id }
      const { status, body = {} } = yield client.apis.TimeUtilisation.post_api_TimeUtilisation({}, { requestBody })
      if (status === 200) {
        newTimeUtil = body
      }
    }

    newTimeUtil.sheets = []

    // sync sheets
    for (let i = 0; i < sheets.length; i += 1) {
      const sheet = sheets[i]
      if ('id' in sheet) {
        const { id, ...rest } = sheet
        const requestBody = { ...rest }
        const { status } = yield client.apis.TimeUtilisationSheet.patch_api_TimeUtilisationSheet__id_({ id }, { requestBody })
        if (status === 200) {
          newTimeUtil.sheets[i] = sheet
        }
      } else {
        const requestBody = { ...sheet, timeUtilisationId: newTimeUtil.id }
        const { status, body = {} } = yield client.apis.TimeUtilisationSheet.post_api_TimeUtilisationSheet({}, { requestBody })
        if (status === 200) {
          newTimeUtil.sheets.push(body)
        }
      }
    }

    yield put(updateTimeUtilisation(project, newTimeUtil))
    yield put(addToast(`Time Utilisation ${(id === '' ? 'Added' : 'Updated')}`, 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncKmdb ({ payload: { project, kmdb } }) {
  try {
    const client = yield api.getClient()

    const {
      id = '',
      fileId,
      ...kmdbData
    } = kmdb

    let newKmdb

    if (id !== '') {
      const requestBody = { ...kmdbData }
      const { status } = yield client.apis.Kmdb.patch_api_Kmdb__id_({ id }, { requestBody })
      if (status === 200) {
        newKmdb = { ...requestBody, id }
      }
    } else {
      const requestBody = { ...kmdbData, engagementId: project.engagement.id }
      const { status, body = {} } = yield client.apis.Kmdb.post_api_Kmdb({}, { requestBody })
      if (status === 200) {
        newKmdb = body
      }
    }

    yield put(updateKmdb(project, newKmdb))
    yield put(addToast('Kmdb Updated', 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * syncChangeRequest ({ payload: { project, changeRequest } }) {
  try {
    const client = yield api.getClient()

    const {
      id = '',
      changes = [],
      ...changeRequestData
    } = changeRequest

    let newChangeRequest

    if (id !== '') {
      const requestBody = { ...changeRequestData }
      const { status } = yield client.apis.ChangeRequest.patch_api_ChangeRequest__id_({ id }, { requestBody })
      if (status === 200) {
        newChangeRequest = { ...requestBody, id }
      }
    } else {
      const requestBody = { ...changeRequestData, engagementId: project.engagement.id }
      const { status, body = {} } = yield client.apis.ChangeRequest.post_api_ChangeRequest({}, { requestBody })
      if (status === 200) {
        newChangeRequest = body
      }
    }

    newChangeRequest.changes = []

    // sync changes
    for (let i = 0; i < changes.length; i += 1) {
      const change = changes[i]
      if ('id' in change) {
        const { id, ...rest } = change
        const requestBody = { ...rest }
        const { status } = yield client.apis.ChangeRequestChange.patch_api_ChangeRequestChange__id_({ id }, { requestBody })
        if (status === 200) {
          newChangeRequest.changes[i] = change
        }
      } else {
        const requestBody = { ...change, changeRequestId: newChangeRequest.id }
        const { status, body = {} } = yield client.apis.ChangeRequestChange.post_api_ChangeRequestChange({}, { requestBody })
        if (status === 200) {
          newChangeRequest.changes.push(body)
        }
      }
    }

    yield put(updateChangeRequest(project, newChangeRequest))
    yield put(addToast(`Change Request ${(id === '' ? 'Added' : 'Updated')}`, 'success'))
  } catch (error) {
    errorService.logError(error)
  }
}

function * getInvoice ({ payload: { project, invoiceId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Invoice.get_api_Invoice__id_({ id: invoiceId })
    if (status === 200) {
      yield put(updateInvoice(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getChangeRequest ({ payload: { project, changeRequestId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.ChangeRequest.get_api_ChangeRequest__id_({ id: changeRequestId })
    if (status === 200) {
      yield put(updateChangeRequest(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getTimeUtilisation ({ payload: { project, timeUtilisationId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.TimeUtilisation.get_api_TimeUtilisation__id_({ id: timeUtilisationId })
    if (status === 200) {
      yield put(updateTimeUtilisation(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getServiceReport ({ payload: { project, serviceReportId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.ServiceReport.get_api_ServiceReport__id_({ id: serviceReportId })
    if (status === 200) {
      yield put(updateServiceReport(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * getKmdb ({ payload: { project, kmdbId } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Kmdb.get_api_Kmdb__id_({ id: kmdbId })
    if (status === 200) {
      yield put(updateKmdb(project, body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * approveFinance ({ payload: { project, comments } }) {
  try {
    const nextProject = produce(project, draftProject => {
      draftProject.financeApproved = true
      draftProject.financeComments = comments
    })

    const { id, ...rest } = nextProject

    const client = yield api.getClient()

    const { status } = yield client.apis.Project.patch_api_Project__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateProject(nextProject))
      yield put(addToast('SOW Service Finance Approved', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * approveLegal ({ payload: { project, comments } }) {
  try {
    const nextProject = produce(project, draftProject => {
      draftProject.legalApproved = true
      draftProject.legalComments = comments
    })

    const { id, ...rest } = nextProject

    const client = yield api.getClient()

    const { status } = yield client.apis.Project.patch_api_Project__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateProject(nextProject))
      yield put(addToast('SOW Service Legal Approved', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * approveManagement ({ payload: { project, comments } }) {
  try {
    const nextProject = produce(project, draftProject => {
      draftProject.managementApproved = true
      draftProject.legalComments = comments
    })

    const { id, ...rest } = nextProject

    const client = yield api.getClient()

    const { status } = yield client.apis.Project.patch_api_Project__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateProject(nextProject))
      yield put(addToast('SOW Service Management Approved', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * approveChangeRequest ({ payload: { changeRequest } }) {
  try {
    const nextChangeRequest = produce(changeRequest, draftChangeRequest => {
      draftChangeRequest.approved = true
    })

    const { id, ...rest } = nextChangeRequest

    const client = yield api.getClient()

    const { status } = yield client.apis.ChangeRequest.patch_api_ChangeRequest__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateChangeRequest(nextChangeRequest))
      yield put(addToast('Change Request Approved', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * approveInvoice ({ payload: { invoice } }) {
  try {
    const nextInvoice = produce(invoice, draftInvoice => {
      draftInvoice.approved = true
    })

    const { id, ...rest } = nextInvoice

    const client = yield api.getClient()

    const { status } = yield client.apis.Invoice.patch_api_Invoice__id_({ id }, { requestBody: rest })
    if (status === 200) {
      yield put(updateInvoice(nextInvoice))
      yield put(addToast('Invoice Approved', 'success'))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchProjects () {
  yield takeEvery(GET_PROJECTS, getProjects)
  yield takeEvery(POST_PROJECT, postProject)
  yield takeEvery(PATCH_PROJECT, patchProject)
  yield takeEvery(GET_SOLUTION, getSolution)
  yield takeEvery(GET_ENGAGEMENT, getEngagement)
  yield takeEvery(PUT_SOLUTION_SUPPLIERS, putProjectSolutionSuppliers)
  yield takeEvery(POST_SERVICE_REPORT, postServiceReport)
  yield takeEvery(PATCH_ENGAGEMENT_FINANCE, patchEngagementFinance)
  yield takeEvery(POST_TIME_UTILISATION, postTimeUtilisation)
  yield takeEvery(PATCH_TIME_UTILISATION, patchTimeUtilisation)
  yield takeEvery(POST_TIME_UTILISATION_SHEET, postTimeUtilisationSheet)
  yield takeEvery(PATCH_TIME_UTILISATION_SHEET, patchTimeUtilisationSheet)
  yield takeEvery(POST_INVOICE, postInvoice)
  yield takeEvery(PATCH_INVOICE, patchInvoice)
  yield takeEvery(POST_INVOICE_ITEM, postInvoiceItem)
  yield takeEvery(PATCH_INVOICE_ITEM, patchInvoiceItem)
  yield takeEvery(POST_CHANGE_REQUEST, postChangeRequest)
  yield takeEvery(PATCH_CHANGE_REQUEST, patchChangeRequest)
  yield takeEvery(POST_CHANGE_REQUEST_CHANGE, postChangeRequestChange)
  yield takeEvery(PATCH_CHANGE_REQUEST_CHANGE, patchChangeRequestChange)
  yield takeEvery(POST_RAID_RISK, postRaidRisk)
  yield takeEvery(PATCH_RAID_RISK, patchRaidRisk)
  yield takeEvery(POST_RAID_ASSUMPTION, postRaidAssumption)
  yield takeEvery(PATCH_RAID_ASSUMPTION, patchRaidAssumption)
  yield takeEvery(POST_RAID_ISSUE, postRaidIssue)
  yield takeEvery(PATCH_RAID_ISSUE, patchRaidIssue)
  yield takeEvery(POST_RAID_DEPENDENCY, postRaidDependency)
  yield takeEvery(PATCH_RAID_DEPENDENCY, patchRaidDependency)
  yield takeEvery(POST_KMDB, postKmdb)
  yield takeEvery(PATCH_KMDB, patchKmdb)
  yield takeEvery(PATCH_PROPOSAL_DESIGN, patchProposalDesign)
  yield takeEvery(SYNC_FINANCIAL_DESIGN, syncFinancialDesign)
  yield takeEvery(SYNC_SOW_DESIGN, syncSowDesign)
  yield takeEvery(SYNC_LEGAL_DESIGN, syncLegalDesign)
  yield takeEvery(SYNC_ENGAGEMENT_DESIGN, syncEngagementDesign)
  yield takeEvery(SYNC_SUPPLIERS_DESIGN, syncSuppliersDesign)
  yield takeEvery(GET_STATEMENT_OF_WORK, getStatementOfWork)
  yield takeEvery(GET_ENGAGEMENT_DESIGN, getEngagementDesign)
  yield takeEvery(GET_FINANCIAL_DESIGN, getFinancialDesign)
  yield takeEvery(GET_LEGAL_DESIGN, getLegalDesign)
  yield takeEvery(GET_INVOICE, getInvoice)
  yield takeEvery(GET_CHANGE_REQUEST, getChangeRequest)
  yield takeEvery(GET_TIME_UTILISATION, getTimeUtilisation)
  yield takeEvery(GET_SERVICE_REPORT, getServiceReport)
  yield takeEvery(GET_KMDB, getKmdb)
  yield takeEvery(SYNC_INVOICE, syncInvoice)
  yield takeEvery(SYNC_TIME_UTIL, syncTimeUtil)
  yield takeEvery(SYNC_KMDB, syncKmdb)
  yield takeEvery(SYNC_CHANGE_REQUEST, syncChangeRequest)
  yield takeEvery(APPROVE_FINANCE, approveFinance)
  yield takeEvery(APPROVE_LEGAL, approveLegal)
  yield takeEvery(APPROVE_MANAGEMENT, approveManagement)
  yield takeEvery(APPROVE_CHANGE_REQUEST, approveChangeRequest)
  yield takeEvery(APPROVE_INVOICE, approveInvoice)
}

function * projectSaga () {
  yield all([
    fork(watchProjects)
  ])
}

export default projectSaga

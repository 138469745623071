import React, { useState } from 'react'
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import _ from 'lodash'
import classnames from 'classnames'
import FinancialDesignTab from './financialDesignTab'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import SowaasButton from '../sowaasButton/sowaasButton'

const FinancialDesignForm = ({ project = {}, onFinancialDesignSaved, onBackToSOWs, readOnly = false, embedded = false }) => {
  const { solution = {} } = project
  const { statementOfWorks = [], solutionSuppliers = [], financialDesign = {} } = solution
  const {
    id,
    costs = [],
    calculations = []
  } = financialDesign

  const suppliers = _.filter(solutionSuppliers, (sol) => { return sol.supplier !== null }).map((ss) => { return ss.supplier })
  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === 'Customer' })
  const phases = _.flatten(_.map(customerSOWs, (cSOW) => cSOW.phases || []))

  if (phases.length === 0) {
    return (
      <Row>
        <Col sm='12' className='mt-3'>
          You do not current have any customer SOWs created, go to the&nbsp;&nbsp;<SowaasButton style={{ display: 'inline-block' }} onClick={onBackToSOWs} title='Statements Of Work' />&nbsp;&nbsp;screen to add one.
        </Col>
      </Row>
    )
  }

  const [activeTab, setActiveTab] = useState(0)

  const handleSubmit = (e, errors, values) => {
    if (errors.length === 0) {
      values.costs = Object.values(values.costs)
      onFinancialDesignSaved(values)
    }
  }

  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      <AvInput type='hidden' name='id' value={id} />
      <Nav tabs className='mt-3'>
        {
          phases.map((p, idx) => (
            <NavItem key={idx}>
              <NavLink
                className={classnames({ active: activeTab === idx })}
                onClick={() => { setActiveTab(idx) }}
              >
                {p.name} Phase
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <TabContent activeTab={activeTab}>
        {
          phases.map((p, idx) => (
            <TabPane tabId={idx} key={idx}>
              <FinancialDesignTab phase={p} idx={idx} suppliers={suppliers} costs={costs} readOnly={readOnly} calculations={calculations} />
            </TabPane>
          ))
        }
      </TabContent>
      {
        embedded === false && (
          <Row>
            <Col sm='12'>
              <SowaasButton className='float-right' title='Save' type='submit' />
            </Col>
          </Row>
        )
      }
    </AvForm>
  )
}

export default FinancialDesignForm

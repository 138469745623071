import React, { useState } from 'react'
import { AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'

const GroupToggle = ({ onValueChanged, name = 'toggle', disabled = false, items = [], currentValue, className = null, groupClassName = null }) => {
  const [value, setValue] = useState(currentValue)

  React.useEffect(() => {
    setValue(currentValue)
  }, [currentValue])

  const colour = (disabled === true ? 'inactiveToggle' : 'inactiveToggle')

  const buttonStyles = { color: '#fff', padding: '8px 12px', minHeight: '1.9rem', textTransform: 'uppercase', display: 'flex', alignItems: 'center', justifyContent: 'center' }

  return (
    <AvRadioGroup name={name} disabled={disabled} value={currentValue} defaultValue={currentValue} className={groupClassName}>
      <div className={`btn-group btn-group-toggle form-group ${className}`} data-toggle='buttons'>
        {
          items.map((i, idx) => (
            <label key={idx} className={value === i.value ? `btn ${colour} aquaVerticalGradient` : `btn ${colour}`} style={buttonStyles}>
              <AvRadio type='radio' value={i.value} onClick={() => { setValue(i.value); onValueChanged(i.value) }} />
              {' '}
              {i.label}
            </label>
          ))
        }
      </div>
    </AvRadioGroup>
  )
}

export default GroupToggle

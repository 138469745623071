import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import InvoiceModal from '../invoiceModal/invoiceModal'
import SowaasButton from '../sowaasButton/sowaasButton'
import SowaasButtonInteraction from '../sowaasButtonInteraction/sowaasButtonInteraction'

const InvoiceCard = ({ approvalInvoices = [], project = {}, onSave }) => {
  const [addInvoiceModalOpen, setAddInvoiceModalOpen] = useState(false)
  const [approveInvoiceModalOpen, setApproveInvoiceModalOpen] = useState(false)
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-3'>
                Invoice
                <InfoMarker title='Invoice' bodyCopy='A useful way of quickly creating and/or approving invoicing for services provided by Suppliers or to Customers.' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setAddInvoiceModalOpen(true) }} title='New Invoice' />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col sm='12'>
              <SowaasButtonInteraction
                onClick={() => { setApproveInvoiceModalOpen(approvalInvoices[0]) }}
                disabled={approvalInvoices.length === 0}
                className='btn-block'
                title={`Approve Invoice${approvalInvoices.length > 1 ? 's' : ''} (${approvalInvoices.length})`}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {
        (addInvoiceModalOpen !== false) && (
          <InvoiceModal
            onSave={onSave}
            project={project}
            isOpen={(addInvoiceModalOpen !== false)}
            setOpen={setAddInvoiceModalOpen}
            mode='add'
          />
        )
      }
      {
        (approveInvoiceModalOpen !== false) && (
          <InvoiceModal
            onSave={onSave}
            project={project}
            invoice={approveInvoiceModalOpen}
            isOpen={(approveInvoiceModalOpen !== false)}
            setOpen={setApproveInvoiceModalOpen}
            mode='approve'
          />
        )
      }
    </>
  )
}

export default InvoiceCard

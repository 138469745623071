import React from 'react'
import GlossaryItem from './glossaryItem'
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import glossaryData from '../../helpers/JSON/glossary.json'

// <VideoMarker title='Help Centre Video' id='vmHelpCenter' videoUrl='https://vimeo.com/499206980/4537b8241f' />

const Glossary = () => {
  return (
    <Card>
      <CardBody>
        <CardTitle>
          SOWaaS Glossary of Terms
        </CardTitle>
        <CardSubtitle className='mb-4 mt-3'>
          Here's a guide to some of the abbreviations and terms used in the sale, solution and delivery of Statement of Work Services.
        </CardSubtitle>
        <div id='accordion'>
          {
            glossaryData.map(({ term, description }) => (
              <GlossaryItem key={term} title={term} content={description} />
            ))
          }
        </div>
      </CardBody>
    </Card>
  )
}

export default Glossary

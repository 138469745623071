/* local */
export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_PROJECTS = 'ADD_PROJECTS'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS'
/* API */
export const POST_PROJECT = 'POST_PROJECT'
export const PATCH_PROJECT = 'PATCH_PROJECT'
export const GET_PROJECTS = 'GET_PROJECTS'

/* LOCAL */
export const ADD_SOLUTION = 'ADD_SOLUTION'
export const UPDATE_SOLUTION = 'UPDATE_SOLUTION'
/* API */
export const GET_SOLUTION = 'GET_SOLUTION'
export const PUT_SOLUTION = 'PUT_SOLUTION'

/* LOCAL */
export const ADD_SOLUTION_SOW = 'ADD_SOLUTION_SOW'
export const UPDATE_SOLUTION_SOW = 'UPDATE_SOLUTION_SOW'
export const DELETE_SOLUTION_SOW = 'DELETE_SOLUTION_SOW'
/* API */
export const POST_SOLUTION_SOW = 'POST_SOLUTION_SOW'
export const PUT_SOLUTION_SOW = 'PUT_SOLUTION_SOW'
export const DEL_SOLUTION_SOW = 'DEL_SOLUTION_SOW'

/* LOCAL */
export const ADD_SOLUTION_FINANCIAL_DESIGN = 'ADD_SOLUTION_FINANCIAL_DESIGN'
export const UPDATE_SOLUTION_FINANCIAL_DESIGN = 'UPDATE_SOLUTION_FINANCIAL_DESIGN'
/* API */
export const PUT_SOLUTION_FINANCIAL_DESIGN = 'PUT_SOLUTION_FINANCIAL_DESIGN'

/* LOCAL */
export const UPDATE_SOLUTION_SUPPLIERS = 'UPDATE_SOLUTION_SUPPLIERS'
/* API */
export const PUT_SOLUTION_SUPPLIERS = 'PUT_SOLUTION_SUPPLIERS'

/* LOCAL */
export const ADD_SOLUTION_LEGAL_DESIGN = 'ADD_SOLUTION_LEGAL_DESIGN'
export const UPDATE_SOLUTION_LEGAL_DESIGN = 'UPDATE_SOLUTION_LEGAL_DESIGN'
/* API */
export const PUT_SOLUTION_LEGAL_DESIGN = 'PUT_SOLUTION_LEGAL_DESIGN'

/* LOCAL */
export const ADD_SOLUTION_ENGAGEMENT_DESIGN = 'ADD_SOLUTION_ENGAGEMENT_DESIGN'
export const UPDATE_SOLUTION_ENGAGEMENT_DESIGN = 'UPDATE_SOLUTION_ENGAGEMENT_DESIGN'
/* API */
export const PUT_SOLUTION_ENGAGEMENT_DESIGN = 'PUT_SOLUTION_ENGAGEMENT_DESIGN'

/* LOCAL */
export const ADD_ENGAGEMENT = 'ADD_ENGAGEMENT'
export const UPDATE_ENGAGEMENT = 'UPDATE_ENGAGEMENT'
/* API */
export const GET_ENGAGEMENT = 'GET_ENGAGEMENT'
export const PUT_ENGAGEMENT = 'PUT_ENGAGEMENT'

/* LOCAL */
export const UPDATE_ENGAGEMENT_FINANCE = 'UPDATE_ENGAGEMENT_FINANCE'

/* API */
export const PATCH_ENGAGEMENT_FINANCE = 'PATCH_ENGAGEMENT_FINANCE'

/* LOCAL */
export const ADD_CHANGE_REQUEST = 'ADD_CHANGE_REQUEST'
export const ADD_CHANGE_REQUESTS = 'ADD_CHANGE_REQUESTS'
export const UPDATE_CHANGE_REQUEST = 'UPDATE_CHANGE_REQUEST'
export const DELETE_CHANGE_REQUEST = 'DELETE_CHANGE_REQUEST'
/* API */
export const POST_CHANGE_REQUEST = 'POST_CHANGE_REQUEST'
export const PUT_CHANGE_REQUEST = 'PUT_CHANGE_REQUEST'
export const DEL_CHANGE_REQUEST = 'DEL_CHANGE_REQUEST'

/* LOCAL */
export const ADD_INVOICE = 'ADD_INVOICE'
export const ADD_INVOICES = 'ADD_INVOICES'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
/* API */
export const POST_INVOICE = 'POST_INVOICE'
export const PUT_INVOICE = 'PUT_INVOICE'
export const DEL_INVOICE = 'DEL_INVOICE'

/* LOCAL */
export const ADD_KMDB = 'ADD_KMDB'
export const ADD_KMDBS = 'ADD_KMDBS'
export const UPDATE_KMDB = 'UPDATE_KMDB'
export const DELETE_KMDB = 'DELETE_KMDB'
/* API */
export const POST_KMDB = 'POST_KMDB'
export const PUT_KMDB = 'PUT_KMDB'
export const DEL_KMDB = 'DEL_KMDB'

/* LOCAL */
export const ADD_TU = 'ADD_TU'
export const ADD_TUS = 'ADD_TUS'
export const UPDATE_TU = 'UPDATE_TU'
export const DELETE_TU = 'DELETE_TU'
/* API */
export const POST_TU = 'POST_TU'
export const PUT_TU = 'PUT_TU'
export const DEL_TU = 'DEL_TU'

/* LOCAL */
export const ADD_SERVICE_REPORT = 'ADD_SERVICE_REPORT'
export const ADD_SERVICE_REPORTS = 'ADD_SERVICE_REPORTS'
export const UPDATE_SERVICE_REPORT = 'UPDATE_SERVICE_REPORT'
export const DELETE_SERVICE_REPORT = 'DELETE_SERVICE_REPORT'
/* API */
export const POST_SERVICE_REPORT = 'POST_SERVICE_REPORT'
export const PUT_SERVICE_REPORT = 'PUT_SERVICE_REPORT'
export const DEL_SERVICE_REPORT = 'DEL_SERVICE_REPORT'

/* LOCAL */
export const ADD_TASK = 'ADD_TASK'
export const ADD_TASKS = 'ADD_TASKS'
export const UPDATE_TASK = 'UPDATE_TASK'
export const DELETE_TASK = 'DELETE_TASK'
/* API */
export const POST_TASK = 'POST_TASK'
export const PUT_TASK = 'PUT_TASK'
export const DEL_TASK = 'DEL_TASK'

/* LOCAL */
export const ADD_RAIDLOG = 'ADD_RAIDLOG'
export const ADD_RAIDLOGS = 'ADD_RAIDLOGS'
export const UPDATE_RAIDLOG = 'UPDATE_RAIDLOG'
export const DELETE_RAIDLOG = 'DELETE_RAIDLOG'
/* API */
export const POST_RAIDLOG = 'POST_RAIDLOG'

export const POST_TIME_UTILISATION = 'POST_TIME_UTILISATION'
export const PATCH_TIME_UTILISATION = 'PATCH_TIME_UTILISATION'
export const POST_TIME_UTILISATION_SHEET = 'POST_TIME_UTILISATION_SHEET'
export const PATCH_TIME_UTILISATION_SHEET = 'PATCH_TIME_UTILISATION_SHEET'

export const SYNC_INVOICE = 'SYNC_INVOICE'
export const PATCH_INVOICE = 'PATCH_INVOICE'
export const POST_INVOICE_ITEM = 'POST_INVOICE_ITEM'
export const PATCH_INVOICE_ITEM = 'PATCH_INVOICE_ITEM'

export const PATCH_CHANGE_REQUEST = 'PATCH_CHANGE_REQUEST'
export const POST_CHANGE_REQUEST_CHANGE = 'POST_CHANGE_REQUEST_CHANGE'
export const PATCH_CHANGE_REQUEST_CHANGE = 'PATCH_CHANGE_REQUEST_CHANGE'
export const POST_RAID_RISK = 'POST_RAID_RISK'
export const PATCH_RAID_RISK = 'PATCH_RAID_RISK'
export const POST_RAID_ASSUMPTION = 'POST_RAID_ASSUMPTION'
export const PATCH_RAID_ASSUMPTION = 'PATCH_RAID_ASSUMPTION'
export const POST_RAID_ISSUE = 'POST_RAID_ISSUE'
export const PATCH_RAID_ISSUE = 'PATCH_RAID_ISSUE'
export const POST_RAID_DEPENDENCY = 'POST_RAID_DEPENDENCY'
export const PATCH_RAID_DEPENDENCY = 'PATCH_RAID_DEPENDENCY'
export const PATCH_KMDB = 'PATCH_KMDB'

export const ADD_TIME_UTILISATION = 'ADD_TIME_UTILISATION'
export const UPDATE_TIME_UTILISATION = 'UPDATE_TIME_UTILISATION'
export const ADD_TIME_UTILISATION_SHEET = 'ADD_TIME_UTILISATION_SHEET'
export const UPDATE_TIME_UTILISATION_SHEET = 'UPDATE_TIME_UTILISATION_SHEET'
export const ADD_INVOICE_ITEM = 'ADD_INVOICE_ITEM'
export const UPDATE_INVOICE_ITEM = 'UPDATE_INVOICE_ITEM'
export const ADD_CHANGE_REQUEST_CHANGE = 'ADD_CHANGE_REQUEST_CHANGE'
export const UPDATE_CHANGE_REQUEST_CHANGE = 'UPDATE_CHANGE_REQUEST_CHANGE'
export const ADD_RAID_RISK = 'ADD_RAID_RISK'
export const UPDATE_RAID_RISK = 'UPDATE_RAID_RISK'
export const ADD_RAID_ASSUMPTION = 'ADD_RAID_ASSUMPTION'
export const UPDATE_RAID_ASSUMPTION = 'UPDATE_RAID_ASSUMPTION'
export const ADD_RAID_ISSUE = 'ADD_RAID_ISSUE'
export const UPDATE_RAID_ISSUE = 'UPDATE_RAID_ISSUE'
export const ADD_RAID_DEPENDENCY = 'ADD_RAID_DEPENDENCY'
export const UPDATE_RAID_DEPENDENCY = 'UPDATE_RAID_DEPENDENCY'
export const PATCH_PROPOSAL_DESIGN = 'PATCH_PROPOSAL_DESIGN'
export const UPDATE_PROPOSAL_DESIGN = 'UPDATE_PROPOSAL_DESIGN'

export const SYNC_FINANCIAL_DESIGN = 'SYNC_FINANCIAL_DESIGN'
export const SYNC_SOW_DESIGN = 'SYNC_SOW_DESIGN'
export const SYNC_LEGAL_DESIGN = 'SYNC_LEGAL_DESIGN'
export const SYNC_ENGAGEMENT_DESIGN = 'SYNC_ENGAGEMENT_DESIGN'
export const SYNC_SUPPLIERS_DESIGN = 'SYNC_SUPPLIERS_DESIGN'

export const UPDATE_FINANCIAL_DESIGN = 'UPDATE_FINANCIAL_DESIGN'
export const UPDATE_SOW_DESIGN = 'UPDATE_SOW_DESIGN'
export const UPDATE_LEGAL_DESIGN = 'UPDATE_LEGAL_DESIGN'
export const UPDATE_ENGAGEMENT_DESIGN = 'UPDATE_ENGAGEMENT_DESIGN'
export const UPDATE_SUPPLIERS_DESIGN = 'UPDATE_SUPPLIERS_DESIGN'

export const GET_STATEMENT_OF_WORK = 'GET_STATEMENT_OF_WORK'

export const GET_ENGAGEMENT_DESIGN = 'GET_ENGAGEMENT_DESIGN'
export const GET_FINANCIAL_DESIGN = 'GET_FINANCIAL_DESIGN'

export const ADD_TU_SHEET = 'ADD_TU_SHEET'
export const DELETE_CHANGE_REQUEST_CHANGE = 'DELETE_CHANGE_REQUEST_CHANGE'
export const DELETE_INVOICE_ITEM = 'DELETE_INVOICE_ITEM'
export const DELETE_TU_SHEET = 'DELETE_TU_SHEET'
export const UPDATE_TU_SHEET = 'UPDATE_TU_SHEET'

export const SYNC_TIME_UTIL = 'SYNC_TIME_UTIL'
export const SYNC_KMDB = 'SYNC_KMDB'
export const SYNC_CHANGE_REQUEST = 'SYNC_CHANGE_REQUEST'

export const GET_CHANGE_REQUEST = 'GET_CHANGE_REQUEST'
export const GET_INVOICE = 'GET_INVOICE'
export const GET_KMDB = 'GET_KMDB'
export const GET_SERVICE_REPORT = 'GET_SERVICE_REPORT'
export const GET_TIME_UTILISATION = 'GET_TIME_UTILISATION'
export const GET_LEGAL_DESIGN = 'GET_LEGAL_DESIGN'

export const APPROVE_FINANCE = 'APPROVE_FINANCE'
export const APPROVE_LEGAL = 'APPROVE_LEGAL'
export const APPROVE_MANAGEMENT = 'APPROVE_MANAGEMENT'
export const APPROVE_CHANGE_REQUEST = 'APPROVE_CHANGE_REQUEST'
export const APPROVE_INVOICE = 'APPROVE_INVOICE'

import React from 'react'
import styled from 'styled-components'
import { Col, Row, Table } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import MoneyInput from '../moneyInput/moneyInput'

const StyledCard = styled.div`
  padding: 5px 0;
`

const FinancesForm = ({ onSubmit, mode = 'full', readOnly = false }) => {
  const phaseValues = []

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSubmit({ ...values })
    }
  }

  return (
    <>
      <Row>
        <Col sm='12'>
          <p style={{ fontSize: '13px' }}>Note: all values exclude VAT.</p>
        </Col>
      </Row>
      <AvForm className='validation-required' onSubmit={handleSubmit}>
        <StyledCard>
          <Row>
            <Col>
              <div className='styledTitle'>
                Total Service Value
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='Total Service Value' bodyCopy='The overall SOW Service value' id='imTotalProjectValue' />
            </Col>
            <Col sm='9'>
              <MoneyInput name='totalProjectValue' disabled />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='styledTitle'>
                Invoiced to date (Total)
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='Invoiced to date (Total)' bodyCopy='The total value that has been invoiced for this Service to date' id='imTotalInvoiced' />
            </Col>
            <Col sm='9'>
              <MoneyInput name='totalInvoicedToDate' disabled />
            </Col>
          </Row>
          {
            mode === 'full' && (
              <>
                <Row>
                  <Col>
                    <div className='styledTitle'>
                      Charges Captured - not invoiced
                    </div>
                  </Col>
                  <Col sm='auto' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Charges Captured - not invoiced' bodyCopy='The amount of potential charge to the customer that has been recorded but not yet invoiced, this can change on a daily/weekly basis.' id='imChargesCaptured' />
                  </Col>
                  <Col sm='9'>
                    <MoneyInput name='chargesCaptured' disabled />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='styledTitle'>
                      Costs Captured to date
                    </div>
                  </Col>
                  <Col sm='auto' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Costs Captured to date' bodyCopy='The total costs from Suppliers captured to date for this Service' id='imCostsCaptured' />
                  </Col>
                  <Col sm='9'>
                    <MoneyInput name='costsCaptured' disabled />
                  </Col>
                </Row>
              </>
            )
          }
          <Row>
            <Col>
              <div className='styledTitle'>
                Per Phase Value
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='Per Phase Value' bodyCopy='If there are phases within the SOW, the value of each phase is provided here' id='imPhaseValue' />
            </Col>
            <Col sm='9'>
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: '60%' }}>Phase</th>
                    <th style={{ width: '40%' }}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    phaseValues.map((phase, idx) => (
                      <tr key={idx}>
                        <td>{phase.phase}</td>
                        <td>{phase.value}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='styledTitle'>
                Invoiced to date (Per Phase)
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='Invoiced to date (Per Phase)' bodyCopy='If there are phases within the SOW, the total amount invoiced to date for each phase is provided here' id='imPhasesInvoiced' />
            </Col>
            <Col sm='9'>
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: '60%' }}>Phase</th>
                    <th style={{ width: '40%' }}>Invoiced</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    phaseValues.map((phase, idx) => (
                      <tr key={idx}>
                        <td>{phase.phase}</td>
                        <td>{phase.value}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
          {
            mode === 'full' && (
              <>
                <Row>
                  <Col>
                    <div className='styledTitle'>
                      Charges Captured - not invoiced (Per Phase)
                    </div>
                  </Col>
                  <Col sm='auto' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Charges Captured - not invoiced (Per Phase)' bodyCopy='If there are phases within the SOW, the amount of potential charge for the customer but net yet invoiced for each phase is provided here' id='imChargesCapturedPerPhase' />
                  </Col>
                  <Col sm='9'>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ width: '60%' }}>Phase</th>
                          <th style={{ width: '40%' }}>Charges Captured</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          phaseValues.map((phase, idx) => (
                            <tr key={idx}>
                              <td>{phase.phase}</td>
                              <td>{phase.value}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='styledTitle'>
                      Costs Captured to date (Per Phase)
                    </div>
                  </Col>
                  <Col sm='auto' className='pt-1 text-right pr-0'>
                    <InfoMarker title='Costs Captured to date (Per Phase)' bodyCopy='If there are phases within the SOW, the total costs from Suppliers for each phase is provided here' id='imChargesCapturedPerPhase' />
                  </Col>
                  <Col sm='9'>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{ width: '60%' }}>Phase</th>
                          <th style={{ width: '40%' }}>Costs Captured</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          phaseValues.map((phase, idx) => (
                            <tr key={idx}>
                              <td>{phase.phase}</td>
                              <td>{phase.value}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </>
            )
          }
          <Row>
            <Col>
              <div className='styledTitle'>
                PO Number
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='PO Number' bodyCopy='The relevant PO number associated with this SOW' id='imPoNumber' />
            </Col>
            <Col sm='9'>
              <AvField name='poNumber' required disabled={readOnly} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='styledTitle'>
                PO Value (if captured from customer)
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='PO Value (if captured from customer)' bodyCopy='The value of the PO from the customer is displayed here, if no PO value was recorded, the contract value will be used' id='imPoValue' />
            </Col>
            <Col sm='9'>
              <MoneyInput name='poValue' disabled={readOnly} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='styledTitle'>
                PO Amount Remaining
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='PO Amount Remaining' bodyCopy='The balance remaining in the PO budget (if known) or the balance of total contract value is shown here' id='imPoAmountRemaining' />
            </Col>
            <Col sm='9'>
              <MoneyInput name='poAmountRemaining' disabled={readOnly} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='styledTitle'>
                PO End Date (if captured from customer)
              </div>
            </Col>
            <Col sm='auto' className='pt-1 text-right pr-0'>
              <InfoMarker title='PO End Date (if captured from customer)' bodyCopy='If captured from the customer, the PO expiry date is shown here.' id='imPoEndDate' />
            </Col>
            <Col sm='9'>
              <AvField name='poEndDate' type='date' disabled={readOnly} />
            </Col>
          </Row>
          {
            (readOnly === false) && (
              <Row>
                <Col sm='12'>
                  <SowaasButton type='submit' className='float-right' title={`Save ${'&'} Close`} />
                </Col>
              </Row>
            )
          }
        </StyledCard>
      </AvForm>
    </>
  )
}

export default FinancesForm

import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Col, Row } from 'reactstrap'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import BooleanToggle from '../booleanToggle/booleanToggle'
import SowaasButton from '../sowaasButton/sowaasButton'
import InfoMarker from '../infoMarker/infoMarker'
import FileUpload from '../fileUpload/fileUpload'
import produce from 'immer'
import SupplierRow from './supplierRow'
import OtherRow from './otherRow'

const StyledCard = styled.div`
  padding: 5px 0;
`

const LegalDesignForm = ({ project = {}, onLegalDesignSaved, onBackToSuppliers, readOnly = false, embedded = false }) => {
  const { solution = {} } = project
  const { solutionSuppliers = [], legalDesign } = solution

  const legalDesignId = _.get(legalDesign, 'id', '')

  const suppliers = _.filter(solutionSuppliers, (sol) => { return sol.supplier !== null }).map((ss) => { return ss.supplier })

  const legalDesignCustomerFiles = _.get(legalDesign, 'legalDesignCustomerFiles', [])
  const legalDesignSupplierFiles = _.get(legalDesign, 'legalDesignSupplierFiles', [])
  const legalDesignOtherFiles = _.get(legalDesign, 'legalDesignOtherFiles', [])

  const msaRecord = _.find(legalDesignCustomerFiles, (sf) => (_.get(sf, 'file.fileType', '') === 'MSA')) || { fileProvided: false, id: '' }
  const ndaRecord = _.find(legalDesignCustomerFiles, (sf) => (_.get(sf, 'file.fileType', '') === 'NDA')) || { fileProvided: false, id: '' }

  const [customerMSARequired, setCustomerMSARequired] = useState(msaRecord.fileProvided)
  const [customerNDARequired, setCustomerNDARequired] = useState(ndaRecord.fileProvided)

  const [legalDesignOtherFilesValue, setLegalDesignOtherFilesValue] = useState(legalDesignOtherFiles)

  const noSuppliers = () => {
    return (
      <Row>
        <Col sm='12' className='mb-4'>
          You do not current have any suppliers added to this SOW Service go to the&nbsp;&nbsp;<SowaasButton style={{ display: 'inline-block' }} onClick={onBackToSuppliers} title='Supplier' />&nbsp;&nbsp;screen to add one.
        </Col>
      </Row>
    )
  }

  const handleSubmit = (e, errors, values) => {
    if (errors.length === 0) {
      onLegalDesignSaved(values)
    }
  }

  const addAnotherOtherFile = () => {
    const nextOtherFiles = produce(legalDesignOtherFilesValue, draftOtherFiles => {
      draftOtherFiles.push({})
    })
    setLegalDesignOtherFilesValue(nextOtherFiles)
  }

  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      {
        (legalDesignId !== '') && (
          <AvInput type='hidden' name='id' value={legalDesignId} />
        )
      }
      <StyledCard>
        <Row>
          <Col sm='12'>
            <div className='styledTitleBold'>
              Customer Requirements
            </div>
          </Col>
        </Row>
        <Row className='ml-3'>
          {
            (msaRecord.id) !== '' && (
              <AvInput type='hidden' name='legalDesignCustomerFiles[0].id' value={msaRecord.id} />
            )
          }
          <Col sm='3'>
            <div className='styledTitle'>
              MSA Provided
              <InfoMarker id='imMTP' title='MSA Provided' bodyCopy="If the customer has provided their standard MSA / Terms for review, upload that here for your Legal stakeolder to review. If Customer hasn't provided MSA / Terms, you can opt to send the Customer your standard services agreement." />
            </div>
          </Col>
          <Col sm='2'>
            <BooleanToggle disabled={readOnly} name='legalDesignCustomerFiles[0].fileProvided' onValueChanged={setCustomerMSARequired} currentValue={customerMSARequired} />
          </Col>
          {
            customerMSARequired === true && (
              <Col sm='4'>
                <FileUpload readOnly={readOnly} formName='legalDesignCustomerFiles[0].fileId' fileType='MSA' existingFile={msaRecord.file} />
              </Col>
            )
          }
        </Row>
        <Row className='ml-3'>
          {
            (ndaRecord.id) !== '' && (
              <AvInput type='hidden' name='legalDesignCustomerFiles[1].id' value={ndaRecord.id} />
            )
          }
          <Col sm='3'>
            <div className='styledTitle'>
              NDA Recieved
              <InfoMarker id='imNDA' title='NDA Recieved' bodyCopy='It is recommended at all times for an NDA to be signed between parties if discussing potentially sensitive or confidential items. You can upload the NDA from your Customer here, or elect to send a standard mutual NDA for signing.' />
            </div>
          </Col>
          <Col sm='2'>
            <BooleanToggle disabled={readOnly} name='legalDesignCustomerFiles[1].fileProvided' onValueChanged={setCustomerNDARequired} currentValue={customerNDARequired} />
          </Col>
          {
            customerNDARequired === true && (
              <Col sm='4'>
                <FileUpload readOnly={readOnly} formName='legalDesignCustomerFiles[1].fileId' fileType='NDA' existingFile={ndaRecord.file} />
              </Col>
            )
          }
        </Row>
        <Row>
          <Col sm='12'>
            <div className='styledTitleBold'>
              Supplier Requirements
            </div>
          </Col>
        </Row>
        {
          suppliers.length === 0 && noSuppliers()
        }
        {
          suppliers.map((supplier, idx) => {
            const supplierFiles = _.filter(legalDesignSupplierFiles, { supplierId: supplier.id })
            return (
              <SupplierRow key={supplier.id} supplier={supplier} supplierFiles={supplierFiles} idx={idx} readOnly={readOnly} />
            )
          })
        }
        {
          legalDesignOtherFilesValue.map((other, idx) => {
            return (
              <OtherRow key={idx} idx={idx} other={other} readOnly={readOnly} />
            )
          })
        }
        {
          (readOnly === false) && (
            <Row className='ml-3'>
              <Col sm='3' />
              <Col sm='2'>
                <SowaasButton title='Add Another File' onClick={addAnotherOtherFile} />
              </Col>
            </Row>
          )
        }
        {
          embedded === false && (
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right' title='Save' type='submit' />
              </Col>
            </Row>
          )
        }
      </StyledCard>
    </AvForm>
  )
}

export default LegalDesignForm

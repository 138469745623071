import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import classnames from 'classnames'
import MoneyInput from '../moneyInput/moneyInput'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import SowaasIconButton from '../sowaasIconButton/sowaasIconButton'
import produce from 'immer'
import uniqid from 'uniqid'
import _ from 'lodash'
import SOWModal from '../sowModal/sowModal'

const StyledCard = styled.div`
  padding: 5px 0;
`

const ChangeRequestForm = ({
  mode = 'add',
  changeRequest = {},
  project = {},
  readOnly = true,
  onSave,
  statementOfWorks = [],
  suppliers = [],
  deliverables = [],
  milestones = []
}) => {
  const {
    id = '',
    // requestor = 'Customer',
    // requestorSupplierId = -1,
    // requestorCustomerId = -1,
    recipient = 'Customer',
    recipientSupplierId = -1,
    // recipientCustomerId = -1,
    originalStatementOfWorkId = -1,
    date = new Date().toISOString().substring(0, 10),
    changes = [{ localId: uniqid() }],
    slug = ''
  } = changeRequest

  const projectName = _.get(project, 'name', '')
  let projectStart = _.get(project, 'startDate', '')
  if (projectStart !== '' || projectStart == null || projectStart !== undefined) {
    projectStart = projectStart.substring(0, 10)
  }

  let projectEnd = _.get(project, 'endDate', '')
  if (projectEnd !== '' || projectEnd == null || projectEnd !== undefined) {
    projectEnd = projectEnd.substring(0, 10)
  }

  let crDate = date
  if (crDate !== '' || crDate == null || crDate !== undefined) {
    crDate = crDate.substring(0, 10)
  }

  const [activeTab, setActiveTab] = useState(0)
  const [changesValue, setChangesValue] = useState(changes)

  // const [requesterValue, setRequesterValue] = useState(requestor)
  const [recipientValue, setRecipientValue] = useState(recipient)
  const [sow, setSow] = useState((originalStatementOfWorkId === -1 ? false : originalStatementOfWorkId))
  const [sowModalOpen, setSowModalOpen] = useState(false)

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const addChange = () => {
    const newChanges = [...changesValue]
    newChanges.push({ localId: uniqid() })
    setChangesValue(newChanges)
  }

  const deleteChange = (idx) => {
    if (window.confirm('Are you sure you would like to remove this change?')) {
      const nextChanges = produce(changesValue, draftChanges => {
        draftChanges.splice(idx, 1)
      })
      setChangesValue(nextChanges)
    }
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSave(values)
    }
  }

  const openCurrentSow = () => {
    const fullSow = _.find(statementOfWorks, { id: Number(sow) })
    if (fullSow) {
      setSowModalOpen(fullSow)
    }
  }

  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      {
        (id !== '') && (
          <AvInput type='hidden' name='id' value={id} />
        )
      }
      <StyledCard>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Requestor
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Requestor' bodyCopy='You can select who the change request notice (CRN) is being created by selecting either Customer or Supplier.' id='imRequestor' />
          </Col>
          <Col sm='9'>
            <AvField disabled={readOnly} type='select' name='requestor' onChange={(event) => {} /* setRequesterValue(event.target.value) */}>
              <option value='Customer'>Cloud People Ltd</option>
              <option value='Supplier'>Supplier</option>
            </AvField>
          </Col>
        </Row>
        {/*
          requesterValue === 'Supplier' && (
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  Requestor Supplier
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right pr-0'>
                <InfoMarker title='Requestor Supplier' bodyCopy='' id='imSupplier' />
              </Col>
              <Col sm='9'>
                <AvField disabled={readOnly} type='select' name='requestorSupplierId' value={requestorSupplierId} required valueParser={window.parseInt}>
                  <option disabled value='-1'>Select Supplier</option>
                  {
                    suppliers.map((supplier, idx) => (
                      <option key={idx} value={supplier.id}>{supplier.name}</option>
                    ))
                  }
                </AvField>
              </Col>
            </Row>
          ) */
        }
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Recipient
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Recipient' bodyCopy='You can select who the change request notice (CRN) is intended for by selecting either Customer or Supplier.' id='imRecipient' />
          </Col>
          <Col sm='9'>
            <AvField disabled={readOnly} type='select' name='recipient' onChange={(event) => setRecipientValue(event.target.value)}>
              <option value='Customer'>Successful Recruitment Ltd</option>
              <option value='Supplier'>Supplier</option>
            </AvField>
          </Col>
        </Row>
        {
          recipientValue === 'Supplier' && (
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  Recipient Supplier
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right pr-0'>
                <InfoMarker title='Recipient Supplier' bodyCopy='' id='imSupplier' />
              </Col>
              <Col sm='9'>
                <AvField disabled={readOnly} type='select' name='recipientSupplierId' value={recipientSupplierId} required valueParser={window.parseInt}>
                  <option disabled value='-1'>Select Supplier</option>
                  {
                    suppliers.map((supplier, idx) => (
                      <option key={idx} value={supplier.id}>{supplier.name}</option>
                    ))
                  }
                </AvField>
              </Col>
            </Row>
          )
        }
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Original SOW
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Original SOW' bodyCopy="You will see the original SOW (or SOW's if multiples apply) from which you can then choose the relevant SOW to amend." id='imOriginalSOW' />
          </Col>
          <Col sm='7'>
            <AvField disabled={readOnly} type='select' name='originalStatementOfWorkId' value={originalStatementOfWorkId} valueParser={window.parseInt} onChange={(e) => setSow(e.target.value)}>
              <option disabled value='-1'>Select Statement Of Work</option>
              {
                statementOfWorks.map((sow) => (
                  <option key={sow.id} value={sow.id}>{sow.statementOfWorkType}</option>
                ))
              }
            </AvField>
          </Col>
          <Col sm='2'>
            <SowaasButton id='btnViewSow' title='View' className='btn-block' onClick={openCurrentSow} />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Change Request Date
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Change Request Date' bodyCopy='Defaults to todays date unless you override this' id='imCRDate' />
          </Col>
          <Col sm='9'>
            <AvField type='date' name='date' value={crDate} required disabled={readOnly} />
          </Col>
        </Row>
        {
          (slug !== '') && (
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  Change Request Number
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right pr-0'>
                <InfoMarker title='Change Request Number' bodyCopy='A unique identifed is assigned by the system to track this CRN throughout the review/approval process.' id='imCRNumber' />
              </Col>
              <Col sm='9'>
                <AvField type='text' name='slug' value={slug} disabled />
              </Col>
            </Row>
          )
        }
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Project Name
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Project Name' bodyCopy='The original project name relating to this SOW will be displayed here.' id='imProjectName' />
          </Col>
          <Col sm='9'>
            <AvField name='projectName' disabled value={projectName} />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Original Start Date
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Original Start Date' bodyCopy='When the SOW originally started' id='imOriginalStartDate' />
          </Col>
          <Col sm='9'>
            <AvField name='originalStartDate' type='date' value={projectStart} disabled />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Original End Date
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Original End Date' bodyCopy='When the SOW was due to finish' id='imOriginalEndDate' />
          </Col>
          <Col sm='9'>
            <AvField name='originalEndDate' type='date' value={projectEnd} disabled />
          </Col>
        </Row>
        <div>
          <Nav tabs>
            {
              changesValue.map((c, idx) => (
                <NavItem key={idx}>
                  <NavLink
                    className={classnames({ active: activeTab === idx })}
                    onClick={() => { toggle(idx) }}
                  >
                    Change {idx + 1}
                  </NavLink>
                </NavItem>
              ))
            }
            {
              !readOnly && (
                <NavItem style={{ display: 'flex' }}>
                  <SowaasButton id='btnAddChange' onClick={addChange} title='Add Change' />
                  <InfoMarker title='Add Change' bodyCopy='You can add as many changes as needed for this SOW by simply adding a new change feature for each impact area.' />
                </NavItem>
              )
            }
          </Nav>
          <TabContent activeTab={activeTab}>
            {
              changesValue.map((c, idx) => {
                const {
                  id = '',
                  localId,
                  reason = '',
                  dateChangeGoesLive = '',
                  changeOfScope = '',
                  changeOfCost = '',
                  changeOfServices = '',
                  deliverableId = -1,
                  milestoneId = -1,
                  changeOfAssumptions = '',
                  customerResponsibilities = '',
                  changeOfResponsibilities = '',
                  signee = ''
                } = c

                let dateChangeGoesLiveValue = dateChangeGoesLive
                if (dateChangeGoesLive !== '' || dateChangeGoesLive == null || dateChangeGoesLive !== undefined) {
                  dateChangeGoesLiveValue = dateChangeGoesLiveValue.substring(0, 10)
                }

                return (
                  <TabPane tabId={idx} key={(localId || id)}>
                    <StyledCard className='pt-3'>
                      {
                        id !== '' && (
                          <AvInput type='hidden' name={`changes[${idx}].id`} value={id} />
                        )
                      }
                      <Row>
                        <Col sm='2'>
                          <div className='styledTitle'>
                            Reason for Change
                          </div>
                        </Col>
                        <Col sm='1' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Reason for Change' bodyCopy='Enter details for the reason(s) for this change based on the what the intended impact will be.' id='imReasonForChange' />
                        </Col>
                        <Col sm='9'>
                          <AvField name={`changes[${idx}].reason`} value={reason} disabled={readOnly} required />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='2'>
                          <div className='styledTitle'>
                            Date Change Goes Live
                          </div>
                        </Col>
                        <Col sm='1' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Date Change Goes Live' bodyCopy='Enter the date when this change will come into effect' id='imDateChangeGoesLive' />
                        </Col>
                        <Col sm='9'>
                          <AvField type='date' name={`changes[${idx}].startDate`} disabled={readOnly} value={dateChangeGoesLiveValue} required />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='2'>
                          <div className='styledTitle'>
                            Change of Scope
                          </div>
                        </Col>
                        <Col sm='1' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Change of Scope' bodyCopy='Enter details of what is changing within the scope of work being provided under this SOW' id='imChangeOfScope' />
                        </Col>
                        <Col sm='9'>
                          <AvField name={`changes[${idx}].changeOfScope`} value={changeOfScope} disabled={readOnly} required />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='2'>
                          <div className='styledTitle'>
                            Change of Cost/Price (exc VAT)
                          </div>
                        </Col>
                        <Col sm='1' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Change of Cost/Price (exc VAT)' bodyCopy='Enter details of how this change will impact the cost/price of the original SOW.' id='imValue' />
                        </Col>
                        <Col sm='9'>
                          <MoneyInput name={`changes[${idx}].changeOfCost`} onChange={() => {}} value={changeOfCost} disabled={readOnly} required />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='2'>
                          <div className='styledTitle'>
                            Change of Services being provided
                          </div>
                        </Col>
                        <Col sm='1' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Change of Services being provided' bodyCopy='Detail how the services will change when compared to the original SOW services.' id='imChangeOfServices' />
                        </Col>
                        <Col sm='9'>
                          <AvField name={`changes[${idx}].changeOfServices`} value={changeOfServices} disabled={readOnly} required />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='2'>
                          <div className='styledTitle'>
                            Change of Deliverable / Milestone
                          </div>
                        </Col>
                        <Col sm='1' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Change of Deliverable / Milestone' bodyCopy='Select from the list the relevant Deliverable or Milestone that this change impacts.' id='imChangeOfDeliverable' />
                        </Col>
                        <Col sm='4'>
                          <div className='styledTitle'>Deliverable</div>
                          <AvField type='select' name={`changes[${idx}].deliverableId`} value={deliverableId} valueParser={window.parseInt} disabled={readOnly}>
                            <option disabled value='-1'>Select Deliverable</option>
                            {
                              deliverables.map((d) => (
                                <option key={d.id} value={d.id}>{d.name}</option>
                              ))
                            }
                          </AvField>
                        </Col>
                        <Col sm='4'>
                          <div className='styledTitle'>Milestone</div>
                          <AvField type='select' name={`changes[${idx}].milestoneId`} value={milestoneId} valueParser={window.parseInt} disabled={readOnly}>
                            <option disabled value='-1'>Select Milestone</option>
                            {
                              milestones.map((m) => (
                                <option key={m.id} value={m.id}>{m.name}</option>
                              ))
                            }
                          </AvField>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='styledTitle'>
                            Change of Assumptions
                          </div>
                        </Col>
                        <Col sm='auto' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Change of Assumptions' bodyCopy='If the original SOW assumptions have been changed, enter those details here' id='imChangeOfAssumptions' />
                        </Col>
                        <Col sm='9'>
                          <AvField name={`changes[${idx}].changeOfAssumptions`} value={changeOfAssumptions} disabled={readOnly} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='styledTitle'>
                            Customer Responsibilities
                          </div>
                        </Col>
                        <Col sm='auto' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Customer Responsibilities' bodyCopy='' id='imCustomResponsibilities' />
                        </Col>
                        <Col sm='9'>
                          <AvField name={`changes[${idx}].customerResponsibilities`} value={customerResponsibilities} disabled={readOnly} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='styledTitle'>
                            Change of Responsibilities / Dependencies
                          </div>
                        </Col>
                        <Col sm='auto' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Change of Responsibilities / Dependencies' bodyCopy='Any change to the responsiblities or dependencies from the original SOW will be documented here.' id='imChangeOfResponsibilities' />
                        </Col>
                        <Col sm='9'>
                          <AvField name={`changes[${idx}].changeOfResponsibilities`} value={changeOfResponsibilities} disabled={readOnly} />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm='2'>
                          <div className='styledTitle'>
                            Authorising Party
                          </div>
                        </Col>
                        <Col sm='1' className='pt-1 text-right pr-0'>
                          <InfoMarker title='Authorising Party' bodyCopy='Enter details of who is authorised to sign off and accept this change.' id='imSignee' />
                        </Col>
                        <Col sm='9'>
                          <AvField name={`changes[${idx}].signee`} value={signee} disabled={readOnly} />
                        </Col>
                      </Row>
                      {
                        !readOnly && (
                          <Row>
                            <Col sm='12'>
                              <SowaasIconButton id={`btnDeleteChange[${idx}]`} icon='fa fa-trash' onClick={() => { deleteChange(idx) }} />
                            </Col>
                          </Row>
                        )
                      }
                    </StyledCard>
                  </TabPane>
                )
              })
            }
          </TabContent>
        </div>
        <Row>
          <Col sm='12'>
            {
              mode === 'add' && (
                <SowaasButton className='float-right' title='Save' type='submit' />
              )
            }
            {
              mode === 'approve' && (
                <>
                  <SowaasButton className='float-right' title='Approve' type='submit' />
                </>
              )
            }
          </Col>
        </Row>
      </StyledCard>
      {
        (sowModalOpen !== false) && (
          <SOWModal isOpen={(sowModalOpen !== false)} setOpen={setSowModalOpen} mode='' suppliers={suppliers} sow={sowModalOpen} readOnly />
        )
      }
    </AvForm>
  )
}

export default ChangeRequestForm

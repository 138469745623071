import { takeEvery, fork, put, all } from 'redux-saga/effects'

import { GET_EVENTS, POST_EVENT, PUT_EVENT, DEL_EVENT } from './actionTypes'
import { addEvent, addEvents, clearEvents, deleteEvent } from './actions'

import errorService from '../../helpers/errorService'

import api from '../../helpers/api'

function * getEvents () {
  try {
    const client = yield api.getClient()
    const { status = -1, body = {} } = yield client.apis.Event.get_api_Event()
    if (status === 200) {
      yield put(clearEvents())
      yield put(addEvents(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * postEvent ({ payload: { event } }) {
  try {
    const client = yield api.getClient()
    const { status, body = {} } = yield client.apis.Event.post_api_Event({}, { requestBody: event })
    if (status === 200) {
      yield put(addEvent(body))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * delEvent ({ payload: { event } }) {
  try {
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Event.delete_api_Event__id_({ id: event.id })
    if (status === 200) {
      yield put(deleteEvent(event))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

function * putEvent ({ payload: { event } }) {
  try {
    const client = yield api.getClient()
    const { status = -1 } = yield client.apis.Event.put_api_Event__id_({ id: event.id }, { requestBody: event })
    if (status === 200) {
      yield put(deleteEvent(event))
      yield put(addEvent(event))
    }
  } catch (error) {
    errorService.logError(error)
  }
}

export function * watchGetEvents () {
  yield takeEvery(GET_EVENTS, getEvents)
}

export function * watchPostEvent () {
  yield takeEvery(POST_EVENT, postEvent)
}

export function * watchPutEvent () {
  yield takeEvery(PUT_EVENT, putEvent)
}

export function * watchDelEvents () {
  yield takeEvery(DEL_EVENT, delEvent)
}

function * eventSaga () {
  yield all([
    fork(watchGetEvents),
    fork(watchPostEvent),
    fork(watchPutEvent),
    fork(watchDelEvents)
  ])
}

export default eventSaga

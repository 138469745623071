import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import confirm from 'reactstrap-confirm'
import logo from '../../assets/images/logo.png'
import ProfileMenu from '../../components/profileMenu/profileMenu'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../store/auth/login/actions'
import OrganisationMenu from '../../components/organisationMenu/organisationMenu'
import DemoRoleSwitchMenu from '../../components/demoRoleSwitchMenu/demoRoleSwitchMenu'

const StyledLogo = styled.div`
  height: 73px;
  padding: 15px 0;
  background-color: #14266e !important;
`

const StyledHeaderBar = styled.div`
  display: flex;
  flex: 1;
  height: 40px;
  justify-content: flex-end;
  margin: 18px;
  align-items: center;
`
const StyledHeaderContainer = styled.div`
  display: flex;
  flex: 1;
`

const Header = ({ history }) => {
  const dispatch = useDispatch()

  const userLoggedOut = async () => {
    const confirmResult = await confirm({ title: 'Are you sure?', message: 'Are you sure that you would like to log out?' })

    if (confirmResult) {
      dispatch(logoutUser(history))
    }
  }

  const onOrganisationSettingsClicked = (o) => {
    history.push(`/organisation/${o.id}`)
  }

  const { name, role, company } = useSelector(state => state.User)

  const organisations = [{ name: 'SOWaaS DEMO', id: 1 }]

  return (
    <>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <StyledHeaderContainer>
            <StyledLogo className='navbar-brand-box'>
              <Link to='/'>
                <span className='logo-sm'>
                  <img src={logo} alt='' height='55px' />
                </span>
              </Link>
            </StyledLogo>
            <StyledHeaderBar>
              {
                (process.env.REACT_APP_DEMO_MODE === 'true') && (
                  <DemoRoleSwitchMenu currentRole={role} history={history} />
                )
              }
              <ProfileMenu onLogout={userLoggedOut} name={`${name} (${company})`} />
              <OrganisationMenu
                organisations={organisations}
                currentOrganisation={organisations[0]}
                organisationClicked={() => {}}
                organisationSettingsClicked={onOrganisationSettingsClicked}
              />
            </StyledHeaderBar>
          </StyledHeaderContainer>
        </div>
      </header>
    </>
  )
}

export default withRouter(Header)

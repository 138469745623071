import React from 'react'
import { Col, Row, Modal, ModalHeader, ModalBody, Table } from 'reactstrap'

const ManagementSalesSummaryModal = ({ isOpen, setOpen, title = '' }) => {
  const onClose = () => {
    setOpen(!isOpen)
  }

  return (
    <Modal isOpen={isOpen} role='dialog' autoFocus centered className='financesModal' tabIndex='-1' toggle={onClose} size='customer'>
      <div className='modal-content'>
        <ModalHeader toggle={onClose}>
          {title}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm='12'>
              <Table>
                <thead>
                  <tr>
                    <th>Unique ID</th>
                    <th>Customer Name</th>
                    <th>Name</th>
                    <th>Revenue</th>
                    <th>Gross Margin</th>
                    <th>Sales/Rec Consultant</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PRJ-001</td>
                    <td>Warwick Enterprises</td>
                    <td>AWS Cloud Design & Assurance</td>
                    <td>£100,000</td>
                    <td>£50,000</td>
                    <td>Mia Smith</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ManagementSalesSummaryModal

import React, { useState } from 'react'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import _ from 'lodash'
import InfoMarker from '../infoMarker/infoMarker'
import SOWModal from '../sowModal/sowModal'
import SowTable from './sowTable'
import SowaasButton from '../sowaasButton/sowaasButton'

const SowDesign = ({ project = {}, onSowDesignSaved, types = [], onContinue, readOnly = false, orgSuppliers = [], embedded = false }) => {
  const [customerSowModalIsOpen, setCustomerSowModalIsOpen] = useState(false)
  const [sowEditing, setSowEditing] = useState({})

  const [supplierSowModalIsOpen, setSupplierSowModalIsOpen] = useState(false)

  const {
    solution = {},
    startDate,
    endDate
  } = project

  const {
    statementOfWorks = [],
    solutionSuppliers = []
  } = solution

  const ssIds = _.map(solutionSuppliers, (ss) => ss.supplierId)
  const suppliers = _.filter(orgSuppliers, (s) => { return ssIds.includes(s.id) })
  const customerSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === types[0] })
  const supplierSOWs = _.filter(statementOfWorks, (sow) => { return sow.statementOfWorkType === types[1] })

  const processSOWForDuplication = (sow) => {
    const newSow = _.assign({}, sow)
    newSow.id = ''
    newSow.phases = _.map(sow.phases, v => _.assign({}, v, { id: '' }))
    newSow.milestones = _.map(sow.milestones, v => _.assign({}, v, { id: '' }))
    newSow.deliverables = _.map(sow.deliverables, v => _.assign({}, v, { id: '' }))
    return newSow
  }

  const duplicateCustomerSow = (sow) => {
    if (window.confirm('Are you sure you wish to duplicate this Customer SoW?')) {
      setSowEditing(processSOWForDuplication(sow))
      setCustomerSowModalIsOpen(true)
    }
  }

  const duplicateSupplierSow = (sow) => {
    if (window.confirm('Are you sure you wish to duplicate this Supplier SoW?')) {
      setSowEditing(processSOWForDuplication(sow))
      setSupplierSowModalIsOpen(true)
    }
  }

  return (
    <>
      <Card className='overflow-hidden'>
        <CardBody>
          <CardTitle className='mb-3'>
            Statement of Work (SOW) Design
            <InfoMarker title='Statement of Work (SOW) Design' bodyCopy='This section is where you will document the construct of the SOW you will provide to your Customer and/or Supplier. The SOW will outline the scope, deliverables, milestones, timelines and acceptance criteria of the Service.' id='imSolutionSuppliers' />
          </CardTitle>
          <SowTable
            className='my-4'
            sows={customerSOWs}
            type='Customer'
            onRowClicked={(sow) => { setSowEditing(sow); setCustomerSowModalIsOpen(true) }}
            onRowDuplicated={duplicateCustomerSow}
            onAddSow={() => { setSowEditing({}); setCustomerSowModalIsOpen(true) }}
            readOnly={readOnly}
          />
          <SowTable
            sows={supplierSOWs}
            type='Supplier'
            onRowClicked={(sow) => { setSowEditing(sow); setSupplierSowModalIsOpen(true) }}
            onRowDuplicated={duplicateSupplierSow}
            onAddSow={() => { setSowEditing({}); setSupplierSowModalIsOpen(true) }}
            suppliers={suppliers}
            readOnly={readOnly}
          />
          {
            embedded === false && (
              <Row className='mt-4'>
                <Col sm='12'>
                  <SowaasButton className='float-right' title={(readOnly ? 'Next' : 'Save')} onClick={onContinue} />
                </Col>
              </Row>
            )
          }
        </CardBody>
      </Card>
      {
        customerSowModalIsOpen && (
          <SOWModal readOnly={readOnly} mode={types[0]} isOpen={customerSowModalIsOpen} setOpen={setCustomerSowModalIsOpen} onSubmit={onSowDesignSaved} sow={sowEditing} startDate={startDate} endDate={endDate} />
        )
      }
      {
        supplierSowModalIsOpen && (
          <SOWModal readOnly={readOnly} mode={types[1]} isOpen={supplierSowModalIsOpen} setOpen={setSupplierSowModalIsOpen} onSubmit={onSowDesignSaved} sow={sowEditing} suppliers={suppliers} startDate={startDate} endDate={endDate} />
        )
      }
    </>
  )
}

export default SowDesign

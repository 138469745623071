import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Media, Button } from 'reactstrap'
import smallDateFormatter from '../../helpers/smallDateFormatter'
import MessagingHubSidebar from './MessagingHubSidebar'
import MessagingModal from './MessagingModal'
import { addMessage } from '../../store/message/actions'
import { Editor, EditorState, convertFromRaw } from 'draft-js'
import errorService from '../../helpers/errorService'

const StyledButton = styled(Button)`
  width: 100px;
  float: right;
`

const StyledMediaBody = styled.div`
  width: 100%;
  display: grid;
  padding-right: 20px;
  grid-template-columns: '90% 10%';
  grid-template-areas:
    'name date'
    'subject .';
  color: #74788d;
`

const Name = styled.div`
  grid-area: name;
  font-size: 12pt;
  color: #495057;
`

const Date = styled.div`
  grid-area: date;
  text-align: right;
  color: #74788d;
`

const MessageBody = () => {
  const dispatch = useDispatch()
  const [modal, setmodal] = useState(false)
  const { messageId } = useParams()
  const messages = useSelector((state) => state.Message.messages)
  const name = useSelector((state) => state.User.name)

  const emails = messages.filter(message => {
    return message.EEID === messageId
  })

  let unreadMessageSum = 0
  messages.forEach(m => {
    unreadMessageSum += (m.Unread ? 1 : 0)
  })

  const onMessageSend = (subject = '', to = '', message = '', replyingTo = undefined) => {
    let newMessage = {}

    const now = new window.Date().toISOString()

    if (replyingTo) {
      newMessage = {
        id: 5591252110993284,
        Sender: 0,
        Bold: 1,
        EEID: replyingTo.EEID,
        Project: 'JH Test Company Ltd - Test Project JH',
        'Thread ID': replyingTo.EEID,
        Title: replyingTo.Title,
        Author: 'engage@aardent.com',
        Recipient: 'engage@aardent.com',
        Message: message,
        Date: now,
        Username: 'engage',
        Name: name,
        Avatar: 'avatar2',
        Unread: true
      }
    } else {
      newMessage = {
        id: 55912521109932768,
        Sender: 0,
        Bold: 1,
        EEID: '123123',
        Project: 'JH Test Company Ltd - Test Project JH',
        'Thread ID': '123123',
        Title: subject,
        Author: 'engage@aardent.com',
        Recipient: to,
        Message: message,
        Date: '2020-06-30T11:36:14Z',
        Username: 'engage',
        Name: name,
        Avatar: 'avatar2',
        Unread: true
      }
    }

    dispatch(addMessage(newMessage))
    setmodal(false)
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col xs='12'>
              <MessagingHubSidebar unreadCount={unreadMessageSum} />
              <div className='email-rightbar mb-3'>
                <Card>
                  <CardBody>
                    {emails.map((email, idx) => {
                      try {
                        const contentState = convertFromRaw(JSON.parse(email.Message))
                        const editorState = EditorState.createWithContent(contentState)
                        return (
                          <div key={idx}>
                            <Media className='mb-4'>
                              <img className='d-flex mr-3 rounded-circle avatar-sm' src='https://www.gravatar.com/avatar/1?d=mp' alt={email.Avatar} />
                              <StyledMediaBody>
                                <Name className='pt-3'>{email.Name}</Name>
                                <Date>{smallDateFormatter(email.Date)}</Date>
                              </StyledMediaBody>
                            </Media>
                            <h4 className='mt-0 font-size-16'>{email.Title}</h4>
                            <Editor editorState={editorState} readOnly />
                            <hr />
                          </div>
                        )
                      } catch (ex) {
                        errorService.logError(ex)
                        return null
                      }
                    })}
                    <StyledButton type='button' color='success' className='waves-effect waves-light' onClick={() => { setmodal(!modal) }} block>
                      Reply
                    </StyledButton>
                  </CardBody>
                </Card>
                <MessagingModal setmodal={setmodal} modal={modal} isReply replyingTo={emails[0]} onSend={onMessageSend} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default MessageBody

import React from 'react'
import { Row, Col, Container, Card, CardBody, Table } from 'reactstrap'
import { Link } from 'react-router-dom'

import logo from '../../assets/images/logo.png'
import { getInvoiceDetails } from './actions'
import dateFormatter from '../../helpers/dateFormatter'

const InvoiceDetails = () => {
  const data = getInvoiceDetails()[0]

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <div className='invoice-title'>
                    <h4 className='float-right font-size-16'>Order # {data.id}</h4>
                    <div className='mb-4'>
                      <img src={logo} alt='logo' height='20' />
                    </div>
                  </div>
                  <hr />
                  <Row>
                    <Col xs='6'>
                      <address>
                        <strong>Billed To:</strong><br />
                        {data.customerName}<br />
                        {data.address}<br />
                        {data.street}<br />
                        {data.postcode}
                      </address>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='6' className='mt-3'>
                      <address>
                        <strong>Payment Method:</strong><br />
                        {data.payment}<br />
                        {data.emailAddress}
                      </address>
                    </Col>
                    <Col xs='6' className='mt-3 text-right'>
                      <address>
                        <strong>Order Date:</strong><br />
                        {dateFormatter(new Date())}<br /><br />
                      </address>
                    </Col>
                  </Row>
                  <div className='py-2 mt-3'>
                    <h3 className='font-size-15 font-weight-bold'>Details of work</h3>
                  </div>
                  <div className='table-responsive'>
                    <Table className='table-nowrap'>
                      <thead>
                        <tr>
                          <th style={{ width: '70px' }}>No.</th>
                          <th>Item</th>
                          <th className='text-right'>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.deliverables.map((deliverable, idx) => (
                          <tr key={idx}>
                            <td>{deliverable.id}</td>
                            <td>{deliverable.title}</td>
                            <td className='text-right'>£{deliverable.price}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan='2' className='text-right'>Sub Total</td>
                          <td className='text-right'>£{data.subTotal}</td>
                        </tr>
                        <tr>
                          <td colSpan='2' className='border-0 text-right'>
                            <strong>VAT @ 20%</strong>
                          </td>
                          <td className='border-0 text-right'>£{data.vat}</td>
                        </tr>
                        <tr>
                          <td colSpan='2' className='border-0 text-right'>
                            <strong>Total</strong>
                          </td>
                          <td className='border-0 text-right'><h4 className='m-0'>£{data.total}</h4></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className='d-print-none'>
                    <div className='float-right'>
                      <Link to='#' onClick={() => { window.print() }} className='btn btn-success waves-effect waves-light mr-2'><i className='fa fa-print' /></Link>
                      <Link to='#' className='btn btn-primary w-md waves-effect waves-light'>Send</Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default InvoiceDetails

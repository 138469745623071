import React, { useState } from 'react'
import moment from 'moment'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import InfoMarker from '../infoMarker/infoMarker'
import SowaasButton from '../sowaasButton/sowaasButton'
import SowaasIconButton from '../sowaasIconButton/sowaasIconButton'
import SowaasTextArea from '../sowaasTextArea/SowaasTextArea'

const StyledCard = styled.div`
  padding: 5px 0;
`
const SoWItem = ({ name, placeholder, date, minDate, maxDate, readOnly }) => {
  if (date === undefined) {
    date = moment()
  }

  return (
    <AvField disabled={readOnly} required name={name} type='date' placeholder={placeholder} value={((date || '') === '' ? '' : moment(date).format('YYYY-MM-DD'))} min={minDate} max={maxDate} />
  )
}

const SoWForm = ({ onSubmit, mode = 'Customer', suppliers = [], sow = {}, startDate, endDate, readOnly }) => {
  const {
    id = '',
    supplierId = '-1',
    scope = '',
    objectives = '',
    services = '',
    acceptance = '',
    serviceManagement = '',
    phases = [],
    deliverables = [],
    milestones = []
  } = sow

  const minDate = moment(startDate).format('YYYY-MM-DD')
  const maxDate = moment(endDate).format('YYYY-MM-DD')

  const [phasesValue, setPhasesValue] = useState(phases)
  const [deliverablesValue, setDeliverablesValue] = useState(deliverables)
  const [milestonesValue, setMilestonesValue] = useState(milestones)

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      onSubmit(values)
    }
  }

  const addPhase = () => {
    const newPhases = [...phasesValue, {}]
    setPhasesValue(newPhases)
  }

  const deletePhase = (phase, idx) => {
    if (confirm('Are you sure you would like to delete this Phase?')) { // eslint-disable-line
      const newPhases = [...phasesValue]
      newPhases.splice(idx, 1)
      setPhasesValue(newPhases)
    }
  }

  const addDeliverable = () => {
    const newDeliverables = [...deliverablesValue, '']
    setDeliverablesValue(newDeliverables)
  }

  const deleteDeliverable = (deliverable, idx) => {
    if (confirm('Are you sure you would like to delete this Deliverable?')) { // eslint-disable-line
      const newDeliverables = [...deliverablesValue]
      newDeliverables.splice(idx, 1)
      setDeliverablesValue(newDeliverables)
    }
  }

  const addMilestone = () => {
    const newMilstones = [...milestonesValue, '']
    setMilestonesValue(newMilstones)
  }

  const deleteMilestone = (milestone, idx) => {
    if (confirm('Are you sure you would like to delete this Milestone?')) { // eslint-disable-line
      const newMilestones = [...milestonesValue]
      newMilestones.splice(idx, 1)
      setMilestonesValue(newMilestones)
    }
  }

  return (
    <AvForm className='needs-validation' onSubmit={handleSubmit}>
      <AvInput name='statementOfWorkType' value={mode} type='hidden' />
      {
        id !== '' && (
          <AvInput name='id' value={id} type='hidden' />
        )
      }
      <StyledCard>
        {
          mode === 'Supplier' && (
            <Row>
              <Col sm='2'>
                <div className='styledTitle'>
                  Select Supplier
                </div>
              </Col>
              <Col sm='1' className='pt-1 text-right pr-0'>
                <InfoMarker title='Select Supplier' bodyCopy='' id='imSelectSupplier' />
              </Col>
              <Col sm='9' className='form-group'>
                <AvField disabled={readOnly} name='supplierId' type='select' value={supplierId}>
                  <option disabled value='-1'>Select Supplier</option>
                  {
                    suppliers.map((c, idx) => (
                      <option key={idx} value={c.id}>{c.name}</option>
                    ))
                  }
                </AvField>
              </Col>
            </Row>
          )
        }
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Scope
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            {
              mode === 'Customer' && (
                <InfoMarker title='Scope' bodyCopy={(<>The Scope is the highlevel outline of what is going to be provided through the SOW:<br /><br />This will be an outline of their needs and what you will provide</>)} id='imScope' />
              )
            }
            {
              mode === 'Supplier' && (
                <InfoMarker title='Scope' bodyCopy={(<>The Scope is the highlevel outline of what is going to be provided through the SOW:<br /><br />This will be an outline of what you expect the Supplier to provide as part of the Service you are delivering to your Customer</>)} id='imScope' />
              )
            }
          </Col>
          <Col sm='9' className='form-group'>
            <SowaasTextArea
              name='scope'
              placeholder='Enter scope'
              required
              value={scope}
              disabled={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Objectives
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Objectives' bodyCopy='Provide a summary of the main objectives you intend to provide to the Customer (or receive from the Supplier) as part of this SOW Service. Each objective should be specific, measurable and bound by a timeframe.' id='imObjectives' />
          </Col>
          <Col sm='9' className='form-group'>
            <SowaasTextArea
              name='objectives'
              placeholder='Enter objectives'
              required
              value={objectives}
              disabled={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Services
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Services' bodyCopy="Details the actual Service activity, that you (or the supplier) will provide during the timeframe i.e. 'consultancy services to support in the implementation of new IT infrastructure'." id='imServices' />
          </Col>
          <Col sm='9' className='form-group'>
            <SowaasTextArea
              name='services'
              placeholder='Enter services'
              required
              value={services}
              disabled={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Phases
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Phases' bodyCopy='Where applicable, you can detail the phasing of the Services being undertaken. This is a good approach as it provides clarity and better defines how the Service will move through each stage of Delivery. If not applicable you can delete all the pre-populated phases and/or rename them accordingly.' id='imPhases' />
          </Col>
          <Col sm='9' className='form-group'>
            {
              phasesValue.length > 0 && (
                <Row>
                  <Col sm='5'>
                    <div className='styledTitle'>
                      Name
                    </div>
                  </Col>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Start Date
                    </div>
                  </Col>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      End Date
                    </div>
                  </Col>
                </Row>
              )
            }
            {
              phasesValue.map((p, idx) => (
                <Row key={idx}>
                  {
                    (p.id || '') !== '' && (
                      <AvInput name={`phases[${idx}].id`} type='hidden' value={p.id} />
                    )
                  }
                  <Col sm='5'>
                    <AvField
                      name={`phases[${idx}].name`}
                      type='text'
                      placeholder='Enter phase name'
                      required
                      value={p.name || ''}
                      disabled={readOnly}
                    />
                  </Col>
                  <Col sm='3'>
                    <SoWItem readOnly={readOnly} name={`phases[${idx}].start`} placeholder='Start Date' date={p.start} minDate={minDate} maxDate={maxDate} />
                  </Col>
                  <Col sm='3'>
                    <SoWItem readOnly={readOnly} name={`phases[${idx}].end`} placeholder='End Date' date={p.end} minDate={minDate} maxDate={maxDate} />
                  </Col>
                  <Col sm='1'>
                    {
                      !readOnly && (
                        <SowaasIconButton disabled={readOnly} icon='mdi mdi-delete-outline' onClick={() => { deletePhase(p, idx) }} />
                      )
                    }
                  </Col>
                </Row>
              ))
            }
            {
              !readOnly && (
                <SowaasButton disabled={readOnly} onClick={addPhase} title='Add Phase' />
              )
            }
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Deliverables
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Deliverables' bodyCopy="Define what will actually be delivered by you (or the Supplier) as part of this Service. Be specific and detailed, try not to be ambiguous. i.e 'Produce a report' is not ideal. 'Produce a migration report, detailing how infrastructure will transition from in-house IT estate to Amazon, including potential failure points and quality checklist' is much better" id='imDeliverables' />
          </Col>
          <Col sm='9' className='form-group'>
            {
              deliverablesValue.length > 0 && (
                <Row>
                  <Col sm='5'>
                    <div className='styledTitle'>
                      Name
                    </div>
                  </Col>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Start Date
                    </div>
                  </Col>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      End Date
                    </div>
                  </Col>
                </Row>
              )
            }
            {
              deliverablesValue.map((p, idx) => (
                <Row key={idx}>
                  {
                    (p.id || '') !== '' && (
                      <AvInput name={`deliverables[${idx}].id`} type='hidden' value={p.id} />
                    )
                  }
                  <Col sm='5'>
                    <AvField
                      name={`deliverables[${idx}].name`}
                      type='text'
                      placeholder='Enter deliverable name'
                      required
                      value={p.name || ''}
                      disabled={readOnly}
                    />
                  </Col>
                  <Col sm='3'>
                    <SoWItem readOnly={readOnly} name={`deliverables[${idx}].start`} placeholder='Start Date' date={p.start} minDate={minDate} maxDate={maxDate} />
                  </Col>
                  <Col sm='3'>
                    <SoWItem readOnly={readOnly} name={`deliverables[${idx}].end`} placeholder='End Date' date={p.end} minDate={minDate} maxDate={maxDate} />
                  </Col>
                  <Col sm='1'>
                    {
                      !readOnly && (
                        <SowaasIconButton disabled={readOnly} icon='mdi mdi-delete-outline' onClick={() => { deleteDeliverable(p, idx) }} />
                      )
                    }
                  </Col>
                </Row>
              ))
            }
            {
              !readOnly && (
                <SowaasButton disabled={readOnly} onClick={addDeliverable} title='Add Deliverable' />
              )
            }
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Milestones
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Milestones' bodyCopy="Milestones define the point in time when a deliverable will be achieved, or part of a deliverable (if large enough) will be provided to the customer (or to you in the case of the Supplier's SOW). Milestones are usually the trigger point for part payment of the applicable deliverable." id='imMilestones' />
          </Col>
          <Col sm='9' className='form-group'>
            {
              milestonesValue.length > 0 && (
                <Row>
                  <Col sm='5'>
                    <div className='styledTitle'>
                      Name
                    </div>
                  </Col>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      Start Date
                    </div>
                  </Col>
                  <Col sm='3'>
                    <div className='styledTitle'>
                      End Date
                    </div>
                  </Col>
                </Row>
              )
            }
            {
              milestonesValue.map((p, idx) => (
                <Row key={idx}>
                  {
                    (p.id || '') !== '' && (
                      <AvInput name={`milestones[${idx}].id`} type='hidden' value={p.id} />
                    )
                  }
                  <Col sm='5'>
                    <AvInput
                      name={`milestones[${idx}].name`}
                      type='text'
                      placeholder='Enter milestone name'
                      required
                      value={p.name || ''}
                      disabled={readOnly}
                    />
                  </Col>
                  <Col sm='3'>
                    <SoWItem readOnly={readOnly} name={`milestones[${idx}].start`} placeholder='Start Date' date={p.start} minDate={minDate} maxDate={maxDate} />
                  </Col>
                  <Col sm='3'>
                    <SoWItem readOnly={readOnly} name={`milestones[${idx}].end`} placeholder='End Date' date={p.end} minDate={minDate} maxDate={maxDate} />
                  </Col>
                  <Col sm='1'>
                    {
                      !readOnly && (
                        <SowaasIconButton disabled={readOnly} icon='mdi mdi-delete-outline' onClick={() => { deleteMilestone(p, idx) }} />
                      )
                    }
                  </Col>
                </Row>
              ))
            }
            {
              !readOnly && (
                <SowaasButton disabled={readOnly} onClick={addMilestone} title='Add Milestone' />
              )
            }
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Acceptance
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Acceptance' bodyCopy='Who will sign-off or accept that deliverables and milestones have been met and what will be provided to the Customer (or from the Supplier) to prove and substantiate the activity is complete.' id='imAcceptance' />
          </Col>
          <Col sm='9' className='form-group'>
            <SowaasTextArea
              name='acceptance'
              placeholder='Enter acceptance'
              required
              value={acceptance}
              disabled={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm='2'>
            <div className='styledTitle'>
              Service Management & Reporting
            </div>
          </Col>
          <Col sm='1' className='pt-1 text-right pr-0'>
            <InfoMarker title='Service Management & Reporting' bodyCopy='This allows you to detail the level of management and reporting you will provide to the SOW Service, if you have selected that Service Delivery is required, this section will be auto-populated later and as such will be greyed out.' id='imServiceAndReporting' />
          </Col>
          <Col sm='9' className='form-group'>
            <SowaasTextArea
              name='serviceManagement'
              placeholder='Enter service management and reporting'
              required
              value={serviceManagement}
              disabled
            />
          </Col>
        </Row>
        {
          !readOnly && (
            <Row>
              <Col sm='12'>
                <SowaasButton className='float-right' title='Save' type='submit' />
              </Col>
            </Row>
          )
        }
      </StyledCard>
    </AvForm>
  )
}

export default SoWForm

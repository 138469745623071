import React, { useState } from 'react'
import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap'
import InfoMarker from '../infoMarker/infoMarker'
import ProjectPlanModal from '../projectPlanModal/projectPlanModal'
import SowaasButton from '../sowaasButton/sowaasButton'

const ProjectPlanCard = ({ project }) => {
  const [projectPlanShown, setProjectPlanShown] = useState(false)
  return (
    <>
      <Card className='overflow-hidden card-100'>
        <CardBody>
          <Row>
            <Col sm='12'>
              <CardTitle className='mb-2'>
                Project Plan
                <InfoMarker title='Project Plan' bodyCopy='' />
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <SowaasButton className='btn-block' onClick={() => { setProjectPlanShown(true) }} title='View Project Plan' />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ProjectPlanModal isOpen={projectPlanShown} setOpen={setProjectPlanShown} project={project} />
    </>
  )
}

export default ProjectPlanCard

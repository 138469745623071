import React from 'react'
import { Row, Col } from 'reactstrap'

// Import components
import PipelinePosition from '../../components/pipelinePosition/PipelinePosition'
import Status from '../../components/status/Status'
import Messaging from '../../components/messaging/Messaging'
import Notifications from '../../components/notifications/Notifications'

const ExecDashboard = ({ messaging, notifications }) => {
  return (
    <>
      <Row>
        <Col sm='6'>
          <PipelinePosition />
          <Messaging messages={messaging} />
        </Col>
        <Col sm='6'>
          <Status />
          <Notifications notifications={notifications} />
        </Col>
      </Row>
    </>
  )
}

export default ExecDashboard

import React, { useState } from 'react'
import styled from 'styled-components'
import dateFormatter from '../../helpers/dateFormatter'
import { Row, Col, Button } from 'reactstrap'
import { useToasts } from 'react-toast-notifications'

const StyledBar = styled.div`
  width: 100%;
  padding: 15px;
  border: 1px solid #74788d;
  border-radius: 0.25rem;
  margin: 5px 0px;
`

const StyledBarContent = styled.div`
  display: grid;
  grid-template-rows:
    '80% 20%'
    '100%';
`

const AlignLeft = styled.div`
  text-align: left;
`

const AlignRight = styled.div`
  text-align: right;
`

const Notification = ({ notification }) => {
  const { addToast } = useToasts()
  const [approved, setApproved] = useState(false)

  const onApproved = () => {
    addToast('Approval Sent', { appearance: 'success', autoDismiss: true })
    setApproved(true)
  }

  return (
    <Col sm='12' className='mb-3'>
      <StyledBar>
        <StyledBarContent>
          <Row>
            <Col sm='8'>
              <AlignLeft>
                <div className='styledTitle mb-2'>
                  {notification.notificationTitle}
                </div>
              </AlignLeft>
            </Col>
            <Col sm='4'>
              <AlignRight>
                {dateFormatter(notification.date)}
              </AlignRight>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <AlignLeft>
                {notification.notificationMessage}
              </AlignLeft>
            </Col>
          </Row>
          {
            notification.type && (
              <Row>
                <Col sm='12' style={{ justifyContent: 'flex-end' }}>
                  {approved && (
                    <Button color='primary' className='btn float-right'>
                      Raise Invoice
                      <i className='mdi mdi-receipt ml-1' />
                    </Button>)}
                  {!approved && (
                    <Button color='primary' className='btn float-right' onClick={onApproved}>
                      Approve
                      <i className='mdi mdi-check ml-1' />
                    </Button>)}
                </Col>
              </Row>
            )
          }
        </StyledBarContent>
      </StyledBar>
    </Col>
  )
}

export default Notification

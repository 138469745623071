import React from 'react'
import { Row, Col } from 'reactstrap'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import SowDesign from '../../components/sowDesign/SowDesign'
import SolutionOverview from '../../components/solutionOverview/solutionOverview'
import ProposalDesign from '../../components/proposalDesign/proposalDesign'
import FinancialDesign from '../../components/financialDesign/financialDesign'
import FurtherInfoRequired from '../../components/furtherInfoRequired/furtherInfoRequired'
import LegalDesign from '../../components/legalDesign/legalDesign'
import SolutionSuppliers from '../../components/solutionSuppliers/solutionSuppliers'
import EngagementDesign from '../../components/engagementDesign/engagementDesign'
import SolutionSignoff from '../../components/solutionSignoff/solutionSignoff'
import CustomerOnboard from '../../components/customerOnboard/customerOnboard'

const Solution = () => {
  const { solutionId } = useParams()
  const solutions = useSelector((state) => state.Project.projects)
  const solution = solutions.find(s => s.id === solutionId)

  return (
    <>
      <Row>
        <Col sm='6'>
          <SolutionOverview salesMode solution={solution} />
          <ProposalDesign solution={solution} />
          <FinancialDesign />
          <FurtherInfoRequired />
          <LegalDesign solution={solution} />
        </Col>
        <Col sm='6'>
          <SolutionSuppliers solution={solution} />
          <SowDesign />
          <EngagementDesign />
          <SolutionSignoff />
          <CustomerOnboard solution={solution} />
        </Col>
      </Row>
    </>
  )
}

export default Solution

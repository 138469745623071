import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <Container fluid>
          <Row>
            <Col md={6}>
              2020 © <a href='https://www.sowaas.com' target='_blank' rel='noopener noreferrer'>SOWaaS.com</a>
            </Col>
            <Col md={6}>
              <div className='text-sm-right d-none d-sm-block'>
                Provided by Aardent Ltd | Company Number 11726578 | Registered Address 20 Old Bailey, St Paul's, London. EC4M 7LN
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer

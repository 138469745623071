import React from 'react'
import { Col, Row } from 'reactstrap'
import { AvInput, AvField } from 'availity-reactstrap-validation'
import FileUpload from '../fileUpload/fileUpload'

const OtherRow = ({ other = {}, readOnly = true, idx }) => {
  const {
    id = '',
    file = {}
  } = other

  return (
    <Row key={idx} className='ml-3'>
      {
        (id !== '') && (
          <AvInput name='id' value={id} type='hidden' />
        )
      }
      <Col sm='3'>
        <div className='styledTitle'>
          {(idx === 0 ? 'Other' : '')}
        </div>
      </Col>
      <Col sm='2'>
        <AvField name={`legalDesignOtherFiles[${idx}].description`} value={other.description} placeholder='Please provide a description' disabled={readOnly} />
      </Col>
      <Col sm='4'>
        <FileUpload formName={`legalDesignOtherFiles[${idx}].fileId`} readOnly={readOnly} existingFile={file} />
      </Col>
    </Row>
  )
}

export default OtherRow
